
import {Component, Vue} from 'vue-property-decorator'

import VSheet from 'vuetify/lib/components/VSheet'

@Component({
  name: 'Sheet',
  extends: VSheet as any,
})
export default class Sheet extends Vue {
}
