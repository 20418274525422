

















import {Vue, Component, VModel} from 'vue-property-decorator'
import CountryList from '../lib/kepler/countries'
import {State} from 'vuex-class'
import {VAutocomplete, VLayout} from 'vuetify/lib'
@Component({
  components: {VAutocomplete, VLayout},
})
export default class CountrySelector extends Vue {
  @State((state) => state.configuration.appConfig?.default_country_code) public defaultCountryCode?: string
  @VModel({ type: String }) protected innerValue!: string

  protected items = CountryList
  protected created() {
    if (!this.innerValue && this.defaultCountryCode) {
      this.innerValue = this.defaultCountryCode.toUpperCase()
    }
  }
}
