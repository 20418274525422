
import {Component, Vue} from 'vue-property-decorator'

import { VBottomNav } from 'vuetify/lib'

@Component({
  name: 'BottomNav',
  extends: VBottomNav as any,
})
export default class BottomNav extends Vue {
  protected mounted() {
    this.$emit('mounted')
  }
}
