














import {Component} from 'vue-property-decorator'
import Vue from 'vue'
import Flex from '../../components/proxy/Flex.vue'
import Layout from '../../components/proxy/Layout.vue'
import CloseButton from '../../components/CloseButton.vue'
import TextField from '../../components/proxy/Inputs/TextField.vue'
import Container from '../../components/proxy/Container.vue'

import Button from '../../components/Button.vue'
import Card from '@/components/proxy/Card/Card.vue'

@Component({
  components: {Card, Button, Container, TextField, CloseButton, Layout, Flex},
  name: 'BikeLockInstructions',
})
export default class BikeLockInstructions extends Vue {}
