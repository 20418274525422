





















import {Vue, Component, Prop} from 'vue-property-decorator'
import {State} from 'vuex-class'
import {DialogState} from '@/store/modules/dialogs'
import {EventBus} from '@/main'
import Utils from '@/utils'

@Component({
  components: {
    CloseButton: Utils.loadComponent('CloseButton'),
    Button: Utils.loadComponent('Button'),
    Card: Utils.loadComponent('proxy/Card/Card'),
    Img: Utils.loadComponent('proxy/Image'),
  },
  name: 'FuelPinDialog',
})
export default class FuelPinDialog extends Vue {
  @State('dialogState') public dialogState!: typeof DialogState

  @Prop() private singleAction!: boolean
  @Prop({default: true}) private emitConfirm!: boolean
  @Prop({default: 'img/error.svg'}) private imageState!: string
  @Prop({default: '****'}) private pin!: string
  @Prop({default: '--'}) private odometer!: string
  @Prop({default: '---'}) private data!: string
  @Prop() private confirmText!: string
  @Prop() private cancelText!: string
  @Prop({default: true}) private showCloseButton!: boolean
  @Prop({}) private callback?: () => void

  protected get imageType(): string {
    return `img/${this.imageState}`
  }

  protected get confirmButton() {
    return this.confirmText ? this.confirmText : this.$t('action.proceed')
  }

  protected get cancelButton() {
    return this.cancelText ? this.cancelText : this.$t('action.cancel')
  }

  protected confirm() {
    EventBus.$emit(this.dialogState[this.dialogState.length - 1].dialogConfirmedEvent)
    this.$dialog.close()
  }
}
