export class RegistrationRequest {
  public client_type_id!: string
  public isOrganization: boolean = false
  public username!: string
  public name!: string
  public surname!: string
  public phone!: string
  public email!: string
  public mobile!: string
  public birthdate!: string
  public fiscal_code!: string
  public vat_number!: string
  public organization_name!: string
  public address: Address = new Address()
  public billing_address: Address = new Address()
  public driver_license: DriverLicense = new DriverLicense()
}

export class Address {
  public country!: string
  public region!: string
  public city!: string
  public postal_code!: string
  public street!: string
}

export class DriverLicense {
  [key: string]: string | DocumentAttachment | undefined

  public document_type_id: string = ''
  public number: string = ''
  public issuer?: string
  public issuer_country?: string
  public issue_date?: string
  public expiry_date?: string
  public attachments?: DocumentAttachment = {}
}
export interface DocumentAttachment {
  [key: string]: string
}
