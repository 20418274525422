
































import {Component, Vue} from 'vue-property-decorator'

import Utils from '@/utils'
import {ChangePasswordRequest} from '@/lib/kepler/interfaces'
import Validations from '@/lib/Validations'
import sdk from '@/lib/kepler/sdk'
import ConfirmDialog from '@/views/ConfirmDialog.vue'

@Component({
  components: {
    Layout: Utils.loadComponent('proxy/Layout'),
    Card: Utils.loadComponent('proxy/Card/Card'),
    CloseButton: Utils.loadComponent('CloseButton'),
    TextField: Utils.loadComponent('proxy/Inputs/TextField'),
    FormTag: Utils.loadComponent('proxy/Inputs/FormTag'),
    Button: Utils.loadComponent('Button'),
  },
  name: 'ChangePasswordDialog',
})
export default class ChangePasswordDialog extends Vue {
  public showPassword: boolean = false
  public valid: boolean = false
  public changePasswordRequest: ChangePasswordRequest = {
    new_password: '',
    new_password_retype: '',
    old_password: '',
  }
  protected rules: any = Validations.rules

  public created() {
    if (!this.$isLogged()) {
      this.$dialog.close()
    }
  }

  public submit() {
    sdk.profile.change_password(this.changePasswordRequest).then((r) => {
      const response = r.data
      if (r.data.result_code === 'password-changed') {
        this.$dialog.close()
        this.$dialog.open(ConfirmDialog, {
          props: {
            imageState: 'success.svg',
            confirmText: this.$t('common.continue'),
            code: '',
            subtitle: response.messages.join('\n'),
            title: response.result,
            singleAction: true,
            emitConfirm: false,
          },
        })
      }
    })
  }
}
