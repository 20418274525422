import AbstractHardware from '@/lib/hardware/classes/AbstractHardware'
import bikeBtSdk from '@/lib/bikeBtSdk/bikeBtSdk'
import sdk from '@/lib/kepler/sdk'

export interface BleBikeConfig {
  plate: string
  reservationNumber: number
  pinRequester: () => Promise<string>
  closeDialog: () => void
}

export default class B810BikeLockHardware extends AbstractHardware {
  constructor(conf: BleBikeConfig) {
    super(conf)
    this.logger = (x) => {
      alert(x)
    }
  }

  private logger: ((s: string) => void)

  private cachedPin: string | null = null

  private getUserPin(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      if (this.cachedPin) {
        this.logger("USING CACHED PIN")
        resolve(this.cachedPin)
      } else {
        this.logger("MISSING CACHED PIN")
        const conf = this.config as BleBikeConfig
        conf.pinRequester().then(resolve).catch(reject)
      }
    })
  }

  private getLockPin(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      resolve("2826670847")
      //this.getUserPin().then((pin: string) => {
      //  const conf = this.config as BleBikeConfig
      //  const reservationNumber = conf.reservationNumber
      //  sdk.booking.open({reservationNumber, pin}).then((x: any) => {
      //    const lockPin = x.data?.response?.device.pin
      //    if (lockPin) {
      //      this.logger("GOT LOCK PIN FROM KEPLER: " + lockPin)
      //      this.cachedPin = pin
      //      resolve(lockPin)
      //    } else {
      //      reject('NO PIN IN KEPLER RESPONSE')
      //    }
      //  }).catch((e) => {
      //    this.cachedPin = null
      //    reject(e)
      //  })
      //}).catch(reject)
    })
  }

  private cachedAddress: string | null = null

  private getLockAddress(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      if (this.cachedAddress) {
        resolve(this.cachedAddress)
      } else {
        // Need to search for the motherfucker
        const conf = this.config as BleBikeConfig
        bikeBtSdk.searchByPlate(conf.plate, (s) => {
          this.cachedAddress = s
          resolve(s)
        }, (e) => {
          reject(e)
        })
      }
    })
  }

  public init(statusCB: (msg: string) => void) {
    //if (window.cordova.platformId === 'browser') {
    //  return Promise.reject('browser is not supported on b810 hardware')
    //}

    this.logger = statusCB

    const conf = this.config as BleBikeConfig

    this.addAction('open', () => new Promise((resolve, reject) => {
      this.cachedPin = null
      Promise.all([this.getLockAddress(), this.getLockPin()]).then(values => {
        bikeBtSdk.openNew(values[0], "2826670847", this.logger).then(resolve).catch(reject)
      }).catch(reject)
    }), 'ble-online')

    // this.addAction('check_status', () => new Promise((resolve, reject) => {
    //   Promise.all([this.getLockAddress(), this.getLockPin()]).then(values => {
    //     bikeBtSdk.statusNew(values[0], values[1], this.logger).then(resolve).catch(reject)
    //   }).catch(reject)
    // }))

    this.addAction('close', () => {
      conf.closeDialog()
      return Promise.resolve()
    }, 'js-alert')

    return Promise.resolve()
  }

  public terminateCheck() {
    return new Promise<void>((resolve, reject) => {
      Promise.all([this.getLockAddress(), this.getLockPin()]).then(values => {
        this.logger("terminate: requesting status")
        bikeBtSdk.statusNew(values[0], values[1], this.logger).then((s) => {
          this.logger("terminate: got status " + s)
          if (s == "CLOSED" || s == "OPENED") {
            // GET GPS POSITION
            this.logger("requesting position")

            this.accurate_position().then((position: any) => {
              this.logger("position: " + JSON.stringify(position.coords))

              const conf = this.config as BleBikeConfig
              const reservationNumber = conf.reservationNumber
              sdk.hooks.pushReservationData(reservationNumber, {
                timestamp: "" + Math.floor(Date.now() / 1000),
                event: "STATUS",
                door_status: s == "CLOSED" ? "LOCKED" : "UNLOCKED",
                position: {
                  latitude: position.coords.latitude,
                  longitude: position.coords.longitude,
                  accuracy: position.coords.accuracy,
                  timestamp: this.unixToDate(position.timestamp)
                }
              }).then(() => {
                this.logger("terminate: pushed to server")
                if (s == "CLOSED") {
                  resolve()
                } else {
                  reject("CANNOT_TERMINATE_WITH_LOCK_" + s)
                }
              }).catch(reject)
            }).catch((error) => {
              this.logger("position: error " + error)
              reject('CANNOT_LOCK_GPS')
            })

          } else {
            reject(s)
          }
        }).catch(reject)
      }).catch(reject)
    })
  }

  private unixToDate(unix: any) {
    var t = new Date(unix);
    return t.getFullYear() + "-" + ('0' + (t.getMonth() + 1)).slice(-2) + "-" + ('0' + t.getDate()).slice(-2) + " " + t.getHours() + ":" + ('0' + t.getMinutes()).slice(-2) + ":" + ('0' + t.getSeconds()).slice(-2);
  }

  private accurate_position(maxWait: number = 10, requiredAccuracy: number = 20): Promise<any> {
    return new Promise<any>(((resolve, reject) => {
        let positionWatch: any
        let timeoutWatch: any

        let skippedFirst = false

        const clean = () => {
          this.logger("accurate_position: clean")
          clearTimeout(timeoutWatch)
          navigator.geolocation.clearWatch(positionWatch)
        }

        const onError = (msg: string) => {
          this.logger("accurate_position: error " + msg)
          clean()
          reject(msg)
        }

        const onSuccess = (position: any) => {
          this.logger("accurate_position: success " + position.coords.latitude + "," + position.coords.longitude)
          clean()
          resolve(position)
        }

        positionWatch = navigator.geolocation.watchPosition((position: any) => {
          if (skippedFirst) {
            if (position.coords.accuracy <= requiredAccuracy) {
              onSuccess(position)
              this.logger("accurate_position: got " + position.coords.latitude + "," + position.coords.longitude + " " + position.coords.accuracy)
            }
          } else {
            skippedFirst = true
            this.logger("accurate_position: skipping_first")
          }
        }, (error: any) => {
          onError("GPS_ERROR_" + JSON.stringify(error))
        }, {
          maximumAge: 0,
          timeout: maxWait * 1000,
          enableHighAccuracy: true,
        })

        timeoutWatch = setTimeout(() => {
          this.logger("accurate_position: timeout")
          onError("TIMEOUT")
        }, maxWait * 1000)
      }

    ))
  }

  public cleanup() {
    if (this.cachedAddress) {
      bikeBtSdk.disconnect(this.cachedAddress)
    }
  }


}
