










import {Vue, Component} from 'vue-property-decorator'
import {State, Action, Getter} from 'vuex-class'
import {DialogState} from '@/store/modules/dialogs'
import {PopupState} from '@/store/modules/popups'
import Utils from '@/utils'
import {Popup} from '@/lib/kepler/interfaces'

@Component({
  components: {
    OfflineAlert: Utils.loadComponent('OfflineAlert'),
    TopBarPopUp: Utils.loadComponent('TopBarPopUp'),
  },
})
export default class AppModals extends Vue {
  @State('popupState') public popupState!: typeof PopupState
  @State('dialogState') public dialogState!: typeof DialogState
  @Action('closeOverlays') public closeOverlays!: () => void
  @Getter('popupIsOpen') public popupIsOpen!: boolean
  @Getter('dialogIsOpen') public dialogIsOpen!: boolean
  @Getter('fullscreenPopup') public fullscreenPopup?: Popup
  @Getter('isOnline') public isOnline!: boolean

  public created() {
    if (!this.fullscreenPopup) {
      this.closeOverlays()
    }
  }
}
