import AbstractHardware from '@/lib/hardware/classes/AbstractHardware'

import OmniScooter from '@/lib/BtSDKs/OmniScooter'

interface OmniScooterConf {
  mac: string,
  key: string,
}

export default class OmniScooterHardware extends AbstractHardware {
  private omniScooterConf : OmniScooterConf

  constructor(conf: OmniScooterConf) {
    super(conf)
    this.omniScooterConf = conf
  }

  public init(statusCB: (msg: string) => void) {
    if (window.cordova.platformId === 'browser') {
      return Promise.reject('browser is not supported on omni-scooter hardware')
    }

    let manager = new OmniScooter(statusCB, this.omniScooterConf.mac, this.omniScooterConf.key)

    statusCB('Launching omni init...')
    statusCB('OMNI MAC: '+this.omniScooterConf.mac)
    statusCB('OMNI KEY: '+this.omniScooterConf.key)
    return manager.init()
      .then(() => {
        this.addAction('open', () => new Promise((resolve, reject) => {
          manager.unlock().then(resolve).catch(reject)
        }), 'ble')
        this.addAction('close', () => new Promise((resolve, reject) => {
          manager.lock().then(resolve).catch(reject)
        }), 'ble')
      })
  }
}
