
import {Component, Vue} from 'vue-property-decorator'

import { VListTileAvatar } from 'vuetify/lib'

@Component({
  name: 'ListTileAvatar',
  extends: VListTileAvatar as any,
})
export default class ListTileAvatar extends Vue {

}
