


























import {Vue, Component, Prop} from 'vue-property-decorator'
import {Action, State} from 'vuex-class'
import Utils from '@/utils'
import {
  AvailabilityResponse,
  BookTimeSelectRequest,
  ReservationResponse,
  UserMesh,
  VehicleSlot,
} from '@/lib/kepler/interfaces'

@Component({
  components: {
    RangeSelector: Utils.loadComponent('rangeSelector/rangeSelector'),
    RangeEstimate: Utils.loadComponent('rangeSelector/rangeEstimate'),
    ProgressCircular: Utils.loadComponent('proxy/ProgressCircular'),
    Card: Utils.loadComponent('proxy/Card/Card'),
    CloseButton: Utils.loadComponent('CloseButton'),
    Container: Utils.loadComponent('proxy/Container'),
    Layout: Utils.loadComponent('proxy/Layout'),
    Button: Utils.loadComponent('Button'),
  },
  name: 'QuickBookDialog',
})
export default class QuickBookDialog extends Vue {
  @State((state) => state.profile.userMesh) public userMesh!: UserMesh[] | undefined

  @Action('getUserMesh') public getUserMesh: any

  @Prop() public vehicleSlot!: VehicleSlot
  @Prop() public availability!: AvailabilityResponse[]
  @Prop() public onConfirm!: (startEnd: BookTimeSelectRequest) => void

  public loading: boolean = false
  public reservation?: ReservationResponse
  public estimateRequest: BookTimeSelectRequest | null = null

  protected get hasBookingModeSubscription() {
    const mesh = this.userMesh
    if (mesh === undefined) {
      this.loading = true
      this.getUserMesh()
      return 'loader'
    }
    let value = false
    mesh.forEach((mode: UserMesh) => {
      const matchBookingMode = mode.booking_mode.toLowerCase() === this.vehicleSlot.reservation_type.toLowerCase()
      const matchVehicleType = mode.vehicle_type.toLowerCase() === this.vehicleSlot.vehicle.category.type.toLowerCase()
      if (matchBookingMode && matchVehicleType && mode.available) {
        value = true
      }
    })
    return value
  }

  protected getEstimate(obj: BookTimeSelectRequest) {
    this.$set(this, 'estimateRequest', obj)
  }

  protected confirm() {
    if (this.estimateRequest) {
      this.onConfirm(this.estimateRequest)
    }
  }
}
