









































import {Vue, Component} from 'vue-property-decorator'
import {State, Action, Getter} from 'vuex-class'
import {ChangePaymentMethod, PaymentMethod, Wallet, Dialog} from '@/lib/kepler/interfaces'
import {EventBus} from '@/main'
import ConfirmDialogCallback from '@/views/ConfirmDialogCallback.vue'
import AddCardDialog from '@/views/AddCardDialog.vue'
import {VSlideYReverseTransition} from 'vuetify/lib'
import Utils from '@/utils'

@Component({
  name: 'PaymentMethods',
  components: {
    Divider: Utils.loadComponent('proxy/Divider'),
    Sheet: Utils.loadComponent('proxy/Sheet'),
    CustomIcon: Utils.loadComponent('CustomIcon'),
    Icon: Utils.loadComponent('proxy/Icon'),
    ProgressCircular: Utils.loadComponent('proxy/ProgressCircular'),
    CheckBox: Utils.loadComponent('proxy/Inputs/CheckBox'),
    EmptyList: Utils.loadComponent('EmptyList'),
    Button: Utils.loadComponent('Button'),
    Container: Utils.loadComponent('proxy/Container'),
    Layout: Utils.loadComponent('proxy/Layout'),
    Flex: Utils.loadComponent('proxy/Flex'),
    CardButton: Utils.loadComponent('CardButton'),
    VSlideYReverseTransition,
  },
})
export default class PaymentMethods extends Vue {
  @State((state) => state.wallet.paymentMethods) public paymentMethods?: PaymentMethod[]

  @Getter('defaultWallet') public defaultWallet?: Wallet
  @Getter('defaultPaymentMethodId') public defaultPaymentMethodId!: string | null

  @Action('getWallets') public getWallets!: () => Promise<Wallet[]>
  @Action('getPaymentMethods') public getPaymentMethods!: () => Promise<PaymentMethod[]>
  @Action('selectPaymentMethod') public selectPaymentMethod!: (payload: PaymentMethod) => void
  @Action('setDefaultPaymentMethod') public setDefaultPaymentMethod!: (payload: ChangePaymentMethod) => Promise<void>
  @Action('deletePaymentMethod') public deletePaymentMethod!: (id: string) => Promise<void>
  @Action('openDialog') public openDialog!: (dialog: Dialog) => void

  protected loading: string = ''

  protected selectDefaultMethod(m: PaymentMethod) {
    const defWId = this.defaultWallet?.id
    const defPId = this.defaultPaymentMethodId
    if (defWId && (defPId !== m.id)) {
      EventBus.$on('updated_payment_method', () => {
        this.loading = ''
        EventBus.$off('updated_payment_method')
      })
      this.loading = m.id
      this.setDefaultPaymentMethod({
        payment_method_id: m.id,
        wallet_id: defWId,
      })
    }
  }

  protected addCard() {
    this.openDialog(new Dialog(AddCardDialog, {}))
  }

  protected confirmDelete(m: PaymentMethod) {
    this.openDialog(new Dialog(ConfirmDialogCallback, {
      title: this.$t('profile.wallet.deleteCard.confirmation.title'),
      subtitle: this.$t('profile.wallet.deleteCard.confirmation.subtitle'),
      confirmText: this.$t('profile.wallet.deleteCard.confirmation.button'),
      confirmColor: 'error',
      confirmCallback: () => {
        this.loading = m.id
        this.deletePaymentMethod(m.id).then(() => {
          // TODO: maybe add a success notification someday
        }).finally(() => {
          this.loading = ''
          EventBus.$off('dialogConfirm')
          this.init()
        })
      },
    }))
  }

  protected init() {
    return Promise.allSettled([
      this.getWallets,
      this.getPaymentMethods,
    ])
  }

  protected created() {
    EventBus.$on('paymentMethodAdded', this.init)
    this.init()
  }

  protected beforeDestroy() {
    EventBus.$off('paymentMethodAdded', this.init)
  }
}
