
import {Vue, Component} from 'vue-property-decorator'

import {VItemGroup} from 'vuetify/lib'

@Component({
  name: 'ItemGroup',
  extends: VItemGroup as any,
})
export default class ItemGroup extends Vue {
}
