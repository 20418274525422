


















































import {Component, Prop} from 'vue-property-decorator'
import {FieldConfigs, SubscriptionPlan, VehicleType} from '@/lib/kepler/interfaces'
import DateHelper from '@/lib/DateHelper'
import Utils from '@/utils'

import {Getter} from 'vuex-class'
import {PlanColorMixin} from '@/lib/vuetify/VuetifyColorHelper'
import {mixins} from 'vue-class-component'

@Component({
  components: {
    Layout: Utils.loadComponent('proxy/Layout'),
    Flex: Utils.loadComponent('proxy/Flex'),
    CustomIcon: Utils.loadComponent('CustomIcon'),
    Icon: Utils.loadComponent('proxy/Icon'),
    GradientCard: Utils.loadComponent('GradientCard'),
    VehicleIcon: Utils.loadComponent('VehicleIcon'),
  },
  mixins: [PlanColorMixin],
})
export default class CardPlan extends mixins<PlanColorMixin>(PlanColorMixin) {
  @Getter('fieldConfigs') public fieldConfigs?: FieldConfigs

  @Prop({
    type: Object,
    required: true,
  }) protected plan!: SubscriptionPlan

  @Prop({
    type: Boolean,
    default: false,
  }) protected isDefault!: boolean

  protected duration: string = ''
  protected planVehicleTypes: VehicleType[] = []
  protected planColors: { bottom: string, top: string } | null = null
  protected planCSS: string | null = null

  protected parseDays(val: number) {
    return DateHelper.parseMinutes(this, val * 1440)
  }

  protected getDuration() {
    const d = this.plan.duration
    switch (this.plan.duration) {
      case 30:
        return this.$t('activities.plans.card.duration.monthly')
      case 60:
        return this.$t('activities.plans.card.duration.two-monthly')
      case 90:
        return this.$t('activities.plans.card.duration.quarterly')
      case 365:
        return this.$t('activities.plans.card.duration.annual')
      default:
        if (d > 3000) {
          return this.$t('activities.plans.card.duration.unlimited')
        } else if (!(d % 30)) {
          return this.$t('activities.plans.card.duration.months', {n: d / 30})
        } else {
          return this.$t('activities.plans.card.duration.days', {n: d})
        }
    }
  }

  protected created() {
    this.duration = this.getDuration()
    if (this.plan?.rates) {
      for (const rate of this.plan.rates) {
        const type = rate.vehicle_category.type
        if (!this.planVehicleTypes.includes(type)) {
          this.planVehicleTypes.push(type)
        }
      }
    }
    this.planColors = this.getPlanColors(this.plan, this.isDefault)
    this.planCSS = this.getPlanCSS(this.isDefault)
  }

  protected get hideFree() {
    return this.fieldConfigs?.hide_free_plan_string
  }
}
