import AbstractHardware from '@/lib/hardware/classes/AbstractHardware'

interface Huf {
  init: (keyring: string, grantId: string, cb: (status: string) => void) => Promise<string>
  open: () => Promise<string>
  close: () => Promise<string>
}

interface HufConf {
  keyring: any
}

export default class HufHardware extends AbstractHardware {
  private readonly keyring: any
  private huf = (window as any).Huf as Huf

  constructor(conf: HufConf) {
    super(conf)
    this.keyring = conf.keyring
  }

  public init(statusCB: (msg: string) => void) {
    if (window.cordova.platformId === 'browser') {
      return Promise.reject('browser is not supported on huf hardware')
    }
    if (!this.huf) {
      return Promise.reject('huf hardware not supported')
    }
    statusCB('Launching huf init...')
    statusCB('HUF GRANT: '+this.keyring['tacsLeaseTokenTable'][0]['vehicleAccessGrantId'])
    statusCB('HUF USER: '+this.keyring['tacsLeaseTokenTable'][0]['leaseToken']['userId'])
    return this.huf.init(JSON.stringify(this.keyring), this.keyring['tacsLeaseTokenTable'][0]['vehicleAccessGrantId'], (s) => {
      statusCB(s)
    })
      .then(() => {
        this.addAction('open', () => new Promise((resolve, reject) => {
          this.huf.open().then(resolve).catch(reject)
        }), 'ble')
        this.addAction('close', () => new Promise((resolve, reject) => {
          this.huf.close().then(resolve).catch(reject)
        }), 'ble')
      })
  }
}
