







import {Vue, Component, Prop} from 'vue-property-decorator'
import Utils from '@/utils'
import {ExtraActionRequest, ExtraActionResponse, ReservationResponse} from '@/lib/kepler/interfaces'
import ConfirmDialog from '@/views/ConfirmDialog.vue'
import {Action} from 'vuex-class'

@Component({
  components: {
    Layout: Utils.loadComponent('proxy/Layout'),
    Button: Utils.loadComponent('Button'),
  },
  name: 'ExtraActions',
})

export default class ExtraActions extends Vue {
  @Prop() public reservation!: ReservationResponse

  @Action('sendExtraAction') public sendExtraAction!: (p: ExtraActionRequest) => Promise<ExtraActionResponse>

  protected loading: boolean = false

  protected get extraActions() {
    const obj: { [key: string]: string } = {}
    const hwAct = Object.entries(this.reservation.extra?.hardware_actions || {})
    if (hwAct.length) {
      hwAct.forEach(([key, val]) => {
        obj[key] = this.$isAvailable('booking.action.extra.' + key) || val
      })
    }
    return obj
  }

  public sendExtra(operation: string) {
    const reservationNumber = this.reservation.number
    this.loading = true
    this.sendExtraAction({reservationNumber, operation}).then((r) => {
      if (r.result) {
        this.$dialog.open(ConfirmDialog, {
          props: {
            showCloseButton: true,
            imageState: '',
            code: '',
            title: r.operation,
            subtitle: r.result,
            singleAction: true,
          },
        })
      }
    }).finally(() => {
      this.loading = false
    })
  }
}
