import {ResourceLoader} from '../utils/ResourceLoader'
import {Base} from './Base'
import {LatLng} from '../models/LatLng'

// @ts-ignore
export class Yandex extends Base {

  public loadResources() {
    // https://tech.yandex.com/maps/jsapi/doc/2.1/quick-start/index-docpage/
    return [
      ResourceLoader('script', 'yandex-map', 'https://api-maps.yandex.ru/2.1/?lang=ru_RU&apikey=' + this.config.apiKey),
    ]
  }

  public initMap(element: HTMLElement, initConfig: any, s: any): void {
    // @ts-ignore
    ymaps.ready(() => {
      // @ts-ignore
      this.map = new ymaps.Map(element, {
        center: [initConfig.center.latitude, initConfig.center.longitude],
        zoom: initConfig.zoom,
      })
      s()
    })
  }

  public setClickListener(l: (p: LatLng) => void): void {

  }

  public pan(p: LatLng): void {
    this.map.setCenter(this.toPoint(p))
  }

  protected toPoint(p: LatLng) {
    return [p.lat, p.lng]
  }
}
