



















import {Component, Prop} from 'vue-property-decorator'
import MonthSwitcher from './MonthSwitcher.vue'
import Utils from '@/utils'
import {VList, VListTile, VListTileContent} from 'vuetify/lib'
import Layout from '@/components/proxy/Layout.vue'

@Component({
  components: {
    Layout,
    MonthSwitcher,
    Container: Utils.loadComponent('proxy/Container'),
    Divider: Utils.loadComponent('proxy/Divider'),
    VList,
    VListTile,
    VListTileContent,
    ProgressCircular: Utils.loadComponent('proxy/ProgressCircular'),
  },
})
export default class MonthList extends MonthSwitcher {
  @Prop({
    type: Array,
    default: () => [],
  }) private list?: object[]

  @Prop({
    type: Boolean,
    default: false,
  }) private isLoading!: boolean

  private emit(month: string) {
    this.$emit('monthListChanged', month)
  }
}
