
















import {Component, Prop, Vue} from 'vue-property-decorator'
import Utils from '@/utils'
import {Lot, ParkingLot} from '@/lib/kepler/interfaces'

@Component({
  components: {
    Container: Utils.loadComponent('proxy/Container'),
    Layout: Utils.loadComponent('proxy/Layout'),
    Card: Utils.loadComponent('proxy/Card/Card'),
    CardTitle: Utils.loadComponent('proxy/Card/CardTitle'),
    CardText: Utils.loadComponent('proxy/Card/CardText'),
    Chip: Utils.loadComponent('proxy/Chip'),
    Flex: Utils.loadComponent('proxy/Flex'),
    Img: Utils.loadComponent('proxy/Image'),
  },
})
export default class ParkingCardPopup extends Vue {
  @Prop({type: Object, required: true}) public lot!: ParkingLot | Lot

  protected get counter() {
    if ('parking_slots_availability' in this.lot) {
      const aval = this.lot.parking_slots_availability
      const length = aval.total
      const available = aval.available

      const arr: boolean[] = []
      for (let i = 0; i < length; i++) {
        arr.push(i < available)
      }

      return {
        length,
        arr,
      }
    }
    return null
  }

  protected get availabilityText() {
    if ('parking_slots_availability' in this.lot) {
      const a = this.lot.parking_slots_availability
      const total = a.total
      const available = a.available
      const vehicles = a.available_vehicles
      return this.$t('map.parking.availability', {available, total, vehicles})
    }
    return null
  }
}
