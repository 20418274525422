
import {Component, Vue} from 'vue-property-decorator'

import { VProgressLinear } from 'vuetify/lib'

@Component({
  name: 'ProgressLinear',
  extends: VProgressLinear as any,
})
export default class ProgressLinear extends Vue {

}
