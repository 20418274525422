import { render, staticRenderFns } from "./IsLogged.vue?vue&type=template&id=10993b60&lang=pug&"
import script from "./IsLogged.vue?vue&type=script&lang=ts&"
export * from "./IsLogged.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports