
































































































































































import {Vue, Component, Watch} from 'vue-property-decorator'
import Utils from '@/utils'
import ServiceMesh from '@/lib/serviceMesh'
import {Branding, refreshBranding} from '@/lib/plugins/theme'
import storage from '@/lib/storage'
import {VuetifyTheme} from 'vuetify'
import Button from '@/components/Button.vue'
import {VAlert, VTextarea, VTextField} from 'vuetify/lib'
import Card from '@/components/proxy/Card/Card.vue'
import {VehicleType} from '@/lib/kepler/interfaces'

interface ParsedTheme {
  buttons: Record<string, any>,
  cards: {
    [name: string]: {
      top: string,
      bottom: string,
      angle: number,
      override: string,
      customCss: Record<string, string>,
    },
  },
  common: {
    success: string,
    warning: string,
    error: string,
    accent: string,
    primary: string,
    info: string,
  },
  mesh: Record<string, Partial<Record<VehicleType, {value: any; key: string}>>>
}

@Component({
  components: {
    Card,
    Button,
    VTextarea,
    VTextField,
    VAlert,
    TextField: Utils.loadComponent('proxy/Inputs/TextField'),
    Accordion: Utils.loadComponent('proxy/Accordion/Accordion'),
    AccordionContent: Utils.loadComponent('proxy/Accordion/AccordionContent'),
    GradientCard: Utils.loadComponent('GradientCard'),
    Avatar: Utils.loadComponent('proxy/Avatar'),
    CustomIcon: Utils.loadComponent('CustomIcon'),
    Icon: Utils.loadComponent('proxy/Icon'),
    VehicleIcon: Utils.loadComponent('VehicleIcon'),
    Container: Utils.loadComponent('proxy/Container'),
    Layout: Utils.loadComponent('proxy/Layout'),
    ColorCard: Utils.loadComponent('ColorCard'),
  },
  name: 'DevBranding',
})

export default class DevBranding extends Vue {
  public branding: Branding | null = null
  public editColor: string | null = null
  public editNumber: number = 1
  public hideBranding: boolean = true
  public brandingImport: string = ''
  public brandingHash: number = 0

  public get theme(): undefined | ParsedTheme {
    if (this.editNumber) {
      // force reevaluation of the computed
    }
    if (this.branding?.theme) {
      const theme = this.branding.theme
      const angle = this.branding.gradientAngle
      const classes = this.branding.customClasses
      const css = this.branding.customCss
      const mesh = ServiceMesh.colors

      /* tslint:disable: cyclomatic-complexity */ // refactor this, duh.
      const getVal = (key: string, location:
        'theme' | 'mesh' | 'angle' | 'css' | 'classes',
      ) => {
        let value
        switch (location) {
          case 'theme':
            value = key in theme ? theme[key as keyof typeof theme] : null
            break
          case 'mesh':
            value = key in mesh ? mesh[key] : null
            break
          case 'angle':
            value = key in angle ? angle[key] : null
            break
          case 'css':
            value = key in css ? css[key] : null
            break
          case 'classes':
            value = key in classes ? classes[key] : null
        }
        return {key, value}
      }

      const getCard = (k: string) => {
        const t = theme as any // argh
        return {
          top: t[k + 'Top'],
          bottom: t[k + 'Bottom'],
          override: t[k + 'ContrastOverride'],
          angle: this.$branding.gradientAngle[k],
          customCss: this.$branding.customCss[k],
        }
      }

      return {
        common: {
          primary: theme.primary,
          // secondary: theme.secondary,
          accent: theme.accent,
          error: theme.error,
          info: theme.info,
          success: theme.success,
          warning: theme.warning,
        },
        buttons: {
          buttonDefaultColor: theme.buttonDefaultColor,
          buttonDefaultColorOverride: theme.buttonDefaultColorOverride,
        },
        cards: {
          topBar: getCard('topBar'),
          profile: getCard('profile'),
          wallet: getCard('wallet'),
          topUp: getCard('topUp'),
          coupon: getCard('coupon'),
          planDefault: getCard('planDefault'),
          plan: getCard('plan'),
          document: getCard('document'),
          toc: getCard('toc'),
          overviewWallet: getCard('overviewWallet'),
          invoice: getCard('invoice'),
          mission: getCard('mission'),
          multipleChoice: getCard('multipleChoice'),
        },
        mesh: {
          'Free Floating': {
            KICKSCOOTER: getVal('FFKICKSCOOTER', 'mesh'),
            BIKE: getVal('FFBIKE', 'mesh'),
            SCOOTER: getVal('FFSCOOTER', 'mesh'),
            AMI: getVal('FFAMI', 'mesh'),
            CAR: getVal('FFCAR', 'mesh'),
            MINIBUS: getVal('FFMINIBUS', 'mesh'),
            VAN: getVal('FFVAN', 'mesh'),
          },
          'Round Trip': {
            KICKSCOOTER: getVal('RTKICKSCOOTER', 'mesh'),
            BIKE: getVal('RTBIKE', 'mesh'),
            SCOOTER: getVal('RTSCOOTER', 'mesh'),
            AMI: getVal('RTAMI', 'mesh'),
            CAR: getVal('RTCAR', 'mesh'),
            MINIBUS: getVal('RTMINIBUS', 'mesh'),
            VAN: getVal('RTVAN', 'mesh'),
            PLATFORM: getVal('RTPLATFORM', 'mesh'),
          },
          'Rent': {
            KICKSCOOTER: getVal('STRKICKSCOOTER', 'mesh'),
            BIKE: getVal('STRBIKE', 'mesh'),
            SCOOTER: getVal('STRSCOOTER', 'mesh'),
            AMI: getVal('STRAMI', 'mesh'),
            CAR: getVal('STRCAR', 'mesh'),
            MINIBUS: getVal('STRMINIBUS', 'mesh'),
            VAN: getVal('STRVAN', 'mesh'),
          },
          'One Way': {
            KICKSCOOTER: getVal('OWFFKICKSCOOTER', 'mesh'),
            BIKE: getVal('OWFFBIKE', 'mesh'),
            SCOOTER: getVal('OWFFSCOOTER', 'mesh'),
            AMI: getVal('OWFFAMI', 'mesh'),
            CAR: getVal('OWFFCAR', 'mesh'),
            MINIBUS: getVal('OWFFMINIBUS', 'mesh'),
            VAN: getVal('OWFFVAN', 'mesh'),
          },
          'Corporate': {
            CAR: getVal('CRTCAR', 'mesh'),
          },
          'Special': {
            HORSE: getVal('RTHORSE', 'mesh'),
          },
          // 'Parking': {
          //   PARKING: getVal('PARKING', 'theme'),
          // },
        },
      }
    }
  }

  public get validImport() {
    return !!this.brandingImport && this.testJSON(this.brandingImport) === true
  }

  public setColor(name: string, color: string) {
    if (this.branding?.theme) {
      this.$set(this.branding.theme, name, color)
      this.updateBranding(this.branding)
    }
  }

  public copyBranding() {
    navigator.clipboard.writeText(JSON.stringify(this.branding, undefined, 2))
  }

  public parseBranding() {
    if (!this.branding) {
      this.init()
    }
    if (this.testJSON(this.brandingImport) === true) {
      this.updateBranding(JSON.parse(this.brandingImport))
    }
  }

  public resetBranding() {
    this.branding = storage.load('base_branding-' + this.appName)

    if (this.branding?.theme) {
      this.updateBranding(this.branding)
      storage.delete('branding-' + this.appName)
      this.$router.push('/')
    }
  }

  public testJSON(strJson: any) {
    try {
      const parsed = JSON.parse(strJson)
      if (parsed && typeof parsed === 'object' && 'dark' in parsed && 'theme' in parsed) {
        return true
      }
    } catch {
      return 'there\'s a problem with the JSON format'
    }
    return 'there\'s a problem with the JSON format'
  }

  public testColor(c: string) {
    const s = new Option().style
    s.color = c
    return s.color !== '' ? true : 'invalid color'
  }

  public get appName() {
    return this.$env.PAGE_TITLE.toLowerCase().replace(' ', '_')
  }

  public calcBrandingHash() {
    const branding = JSON.stringify(this.branding)
    this.brandingHash = this.cyrb53(branding)
  }

  public init() {
    if (!storage.check('branding-' + this.appName)) {
      storage.save('branding-' + this.appName, this.$branding)
      storage.save('base_branding-' + this.appName, this.$branding)
    }
    if (this.branding === null) {
      this.branding = {} as Branding
    }
    storage.restore('branding-' + this.appName, this.branding)
  }

  public created() {
    if (storage.check('branding-' + this.appName)) {
      this.init()
    }
  }

  @Watch('branding', {deep: true})
  public updateBranding(v: Branding) {
    storage.save('branding-' + this.appName, v)
    refreshBranding(v)
    this.$branding = v
    this.$vuetify.theme = v.theme as unknown as VuetifyTheme
    this.editNumber++
    this.editColor = null
    this.calcBrandingHash()
  }

  public cyrb53(str: string, seed = 0) {
    /* tslint:disable:no-bitwise */
    let h1 = 0xdeadbeef ^ seed
    let h2 = 0x41c6ce57 ^ seed
    for (let i = 0, ch; i < str.length; i++) {
      ch = str.charCodeAt(i)
      h1 = Math.imul(h1 ^ ch, 2654435761)
      h2 = Math.imul(h2 ^ ch, 1597334677)
    }

    h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^ Math.imul(h2 ^ (h2 >>> 13), 3266489909)
    h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^ Math.imul(h1 ^ (h1 >>> 13), 3266489909)

    return 4294967296 * (2097151 & h2) + (h1 >>> 0)
    /* tslint:enable:no-bitwise */
  }
}
