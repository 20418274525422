var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationProvider',{attrs:{"name":_vm.$attrs.label,"rules":_vm.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('VInput',{staticClass:"phone-with-prefix-component mx-0",class:{'mb-3':!!_vm.prefixHint},attrs:{"hint":_vm.prefixHint,"persistent-hint":"","disabled":_vm.$attrs.disabled || false,"error-messages":errors,"value":_vm.innerValue,"browser-autocomplete":"tel"}},[_c('VLayout',{staticClass:"inner-input"},[_c('VAutocomplete',{staticClass:"prefix-select",class:_vm.lengthClass,attrs:{"label":_vm.$t('registration.driver.prefix'),"items":_vm.countries,"item-text":"name","error":!!errors.length && !_vm.phoneObject.prefix,"return-object":true,"required":_vm.$attrs.required || false,"disabled":_vm.$attrs.disabled || false,"browser-autocomplete":"tel-country-code","filter":_vm.prefixFilterFunction},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('VLayout',[_c('span',[_vm._v(_vm._s(item.dial_code))])])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('VListTileContent',[_c('VListTileTitle',[_vm._v(_vm._s(item.name))]),_c('VListTileSubTitle',[_vm._v(_vm._s(item.dial_code))])],1),_c('VListTileAction',[_c('img',{staticClass:"mr-2 flag-icon",attrs:{"src":("img/flags/4x3/" + (item.code.toLowerCase()) + ".svg")}})])]}}],null,true),model:{value:(_vm.phoneObject.prefix),callback:function ($$v) {_vm.$set(_vm.phoneObject, "prefix", $$v)},expression:"phoneObject.prefix"}}),_c('VTextField',_vm._b({attrs:{"mask":"###-###-#####","error":!!errors.length,"inputmode":"numeric","append-outer-icon":valid? 'mdi-check' : errors? 'mdi-close': '',"type":"tel","browser-autocomplete":"tel-national"},model:{value:(_vm.phoneObject.number),callback:function ($$v) {_vm.$set(_vm.phoneObject, "number", $$v)},expression:"phoneObject.number"}},'VTextField',_vm.$attrs,false))],1)],1),(_vm.verification)?_c('FlowField',{key:_vm.verification.field.id,attrs:{"field":_vm.verification.field,"step":_vm.verification.step,"phoneVerificationEnabled":true,"disabled":!!_vm.$attrs.disabled}}):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }