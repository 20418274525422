














import {Vue, Component, Prop, Watch} from 'vue-property-decorator'

import {VCheckbox} from 'vuetify/lib'
import {ValidationProvider} from 'vee-validate'

@Component({
  components: {VCheckbox, ValidationProvider},
  name: 'CheckboxFieldWithValidation',
})
export default class CheckboxFieldWithValidation extends Vue {
  @Prop({
    type: [String, Object],
    default: '',
  }) public readonly rules!: string | object

  @Prop({
    type: Boolean,
    default: '',
  }) public readonly required!: boolean

  @Prop({
    type: String,
    default: null,
  }) public readonly defaultValue!: string | null

  public innerValue: string | boolean | null = null
  public trueValue: string | true = true
  public falseValue: string | false = false

  @Watch('innerValue')
  public onInnerValueChange(newVal: any) {
    this.$emit('input', newVal)
  }

  @Watch('value')
  public onValueChange(newVal: any) {
    this.innerValue = newVal
  }

  private created() {
    // console.log(this.$attrs.value, this.innerValue, this.defaultValue)

    if (this.defaultValue) {
      if (this.defaultValue.includes('|')) {
        const [trueValue, falseValue] = this.defaultValue.split('|')
        this.trueValue = trueValue
        this.falseValue = falseValue
      } else {
        this.trueValue = this.defaultValue
      }
    }

    if (this.$attrs.value !== this.defaultValue) {
      this.innerValue = this.$attrs.value
    } else {
      this.innerValue = this.falseValue
    }

    this.onInnerValueChange(this.innerValue)
  }
}
