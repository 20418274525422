






import {Component, Vue} from 'vue-property-decorator'
import {VContainer, VLayout, VProgressCircular} from 'vuetify/lib'
import {Action} from 'vuex-class'

@Component({
  components: {VContainer, VLayout, VProgressCircular},
  name: 'LogoutView',
})
export default class Logout extends Vue {
  @Action('closeOverlays') public closeOverlays!: () => void
  @Action('logout') protected logout!: any

  private created() {
    this.logout().then(() => {
      this.closeOverlays()
      this.$router.push({name: 'home'})
    })
  }
}
