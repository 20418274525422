













import {Vue, Component, Prop} from 'vue-property-decorator'
import {instruction} from '@/lib/kepler/interfaces'
import Container from '@/components/proxy/Container.vue'
import Img from '@/components/proxy/Image.vue'

@Component({
  name: 'VehicleInstructions',
  components: {Container, Img},
})
export default class VehicleInstructions extends Vue {
  @Prop() public instructions!: instruction[]

  public imgObj(value: string) {
    const [src, url] = value.split('|')
    return {src, url}
  }
  public openLinkIfPresent(value: string) {
    const url = this.imgObj(value).url
    if (url) {
      cordova.InAppBrowser.open(url, '_system')
    }
  }
}
