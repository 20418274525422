import _Vue from 'vue'

export class EnvObject {
  public API_URL = ''
  public LOGO_URL = ''
  public PAGE_TITLE = ''
  public GOOGLE_MAPS_KEY = ''
  public RELEASE = ''
  public ACCENT_COLOR = ''
  public MINIMUM_DISTANCE_FROM_VEHICLE_FOR_UNLOCK = 0
  public LOCALE_DATE_FORMAT = 'YYYY-MM-DD'
  public GOOGLE_MAP_PROXY_URL = ''
  public APP_VERSION_NUMBER = 0
  public ANDROID_STORE_URL = ''
  public IOS_STORE_URL = ''
  public WEB_APP_URL = ''
  public FORCE_MAP_MODE = ''
  public KEPLER_API_LEVEL = 0
}

const envInstance = new EnvObject()

for (const property in envInstance) {
  if (envInstance.hasOwnProperty(property)) {
    (envInstance as any)[property] = (process as any).env['VUE_APP_' + property]
  }
}

// Set up the page title
document.title = envInstance.PAGE_TITLE

export default {
  install: (Vue: typeof _Vue): void => {
    Vue.prototype.$env = envInstance
  },
}
export {envInstance}
