import {BleDevice} from "@/lib/BtSDKs/BleDevice";

export abstract class SerialDevice extends BleDevice {
  protected SERVICE_UART_UUID = '6e400001-b5a3-f393-e0a9-e50e24dcca9e'
  protected CHAR_UART_TX_UUID = '6e400002-b5a3-f393-e0a9-e50e24dcca9e'
  protected CHAR_UART_RX_UUID = '6e400003-b5a3-f393-e0a9-e50e24dcca9e'

  private commandCallback: any = null

  private timeoutWatchdog: any = null

  protected initStatus: string | null = null

  protected send(payload: any) {
    return new Promise<any>((resolve, reject) => {
      if (this.commandCallback == null) {

        let clearWatchdog = () => {
          if (this.timeoutWatchdog != null) {
            clearTimeout(this.timeoutWatchdog)
            this.timeoutWatchdog = null
          }
        }

        this.commandCallback = (resp: any) => {
          clearWatchdog()
          resolve(resp)
          this.commandCallback = null
        }

        let fail = (reason: string) => {
          clearWatchdog()
          reject("NOT_READY")
          this.commandCallback = null
          this.initStatus = null
          // Auto re-ready?
        }

        this.setup().then(() => {
          this.write(this.SERVICE_UART_UUID, this.CHAR_UART_TX_UUID, payload).then(() => {
            this.timeoutWatchdog = setTimeout(() => {
              this.commandCallback = null
              reject("TIMEOUT_RESPONSE")
            }, 9999)
          }).catch(fail)
        }).catch(fail)
      } else {
        reject("COMMAND_ALREADY_IN_PROGRESS")
      }
    })
  }

  setup() {
    return new Promise<string>((resolve, reject) => {
      if (this.initStatus == "initializing") {
        reject("initializing in progress")
        return
      }
      if (this.initStatus == "initialized") {
        resolve("already initialized")
        return
      }

      this.initStatus = "initializing"
      this.scanAndConnect().then(() => {
        this.subscribe(this.SERVICE_UART_UUID, this.CHAR_UART_RX_UUID, (data) => {
          if (this.commandCallback != null) {
            this.commandCallback(data)
          } else {
            this.log("ERROR_NO_CALLBACK")
          }
        }).then(() => {
          this.initStatus = "initialized"
          resolve("init complete")
        })
      }).catch((x) => {
        this.initStatus = "failed"
        reject(x)
      })

    })
  }
}
