import _Vue from 'vue'

export default {
  install: function (Vue: typeof _Vue): void {
    Vue.prototype.$style = (style: any[]) => {
      let output = ''
      for (const key in style) {
        output += key + ':' + style[key] + ';'
      }
      return output
    }
  },
}
