
import {Component, Vue} from 'vue-property-decorator'

import { VListTileContent } from 'vuetify/lib'

@Component({
  name: 'ListTileContent',
  extends: VListTileContent as any,
})
export default class ListTileContent extends Vue {

}
