
import {Component, Vue} from 'vue-property-decorator'

import { VDialog } from 'vuetify/lib'

@Component({
  name: 'Dialog',
  extends: VDialog as any,
})
export default class Dialog extends Vue {

}
