










import {Vue, Component, Prop} from 'vue-property-decorator'
import {State} from 'vuex-class'
import {FlowInputsState} from '@/store/modules/flowInputs'
import {FlowOutputsState} from '@/store/modules/flowOutputs'
import {FlowInputField, FlowInputStep} from '@/lib/kepler/interfaces'

import FlowField from '@/components/flow/FlowField.vue'
import {VBtn, VIcon, VLayout, VProgressCircular} from 'vuetify/lib'

@Component({
  components: {
    FlowField,
    VBtn,
    VIcon,
    VLayout,
    VProgressCircular,
  },
  name: 'Summary',
})
export default class Summary extends Vue {
  @State('flowOutputs') public flowOutputs!: FlowOutputsState
  @State('flowInputs') public flowInputs!: FlowInputsState

  @Prop() public output?: Array<{ [k: string]: any }> | { [k: string]: any }
  @Prop() public field?: FlowInputField
  @Prop() public step?: FlowInputStep

  public disabled: boolean = true
  public loading: boolean = true
  public steps: FlowInputStep[] = []

  public context: string = ''
  public currentInput: { steps: FlowInputStep[] } | null = null

  public fields: FlowInputField[] = []

  protected get editable() {
    return !this.field?.validation?.includes('readonly')
  }

  protected created() {
    this.context = this.step?.context || ''
    this.currentInput = this.flowInputs[this.context] || null
    this.fields = this.populateFields()
    this.loading = false
  }

  protected populateFields() {
    const arr: FlowInputField[] = []
    if (this.currentInput && this.currentInput.steps.length) {
      this.currentInput.steps.forEach((step) => {
        if (step.fields && step.id !== this.step?.id) {
          step.fields.forEach((field) => {
            const notHidden = this.notHidden(field)
            const present = notHidden ? this.presentInCurrentStepOutput(field) : null
            if (notHidden && present) {
              arr.push(field)
            }
          })
        }
      })
    }
    return arr
  }

  protected notHidden(field: FlowInputField) {
    return ![
      'tocs',
      'selfie',
      'invite',
      'summary',
    ].includes(field.type)
  }

  protected presentInCurrentStepOutput(field: FlowInputField) {
    const paths: string[] = []
    Object.values(this.step?.output_format || {}).forEach((value: string | Record<string, string>) => {
      if (typeof value === 'string') {
        paths.push(value)
      } else {
        paths.push(...Object.values(value))
      }
    })

    for (const path of paths) {
      if (path === field.id) {
        return true
      }
      const idx = path.split('.').reverse().findIndex((key) => key === field.id)
      if (idx >= 0) {
        return true
      }
    }

    return false
  }
}
