import AbstractHardware from '@/lib/hardware/classes/AbstractHardware'

interface Mib {
  init: (jwt: string, userId: string, cb: (status: string) => void) => Promise<string>
  open: (cidpu: string) => Promise<string>
  close: (cidpu: string) => Promise<string>
  list: () => Promise<string>
}

interface MibVirtualKey {
  cidpu: string
  token: string
  user_id: string
}

export default class MibHardware extends AbstractHardware {
  private readonly cidpu: string
  private readonly token: string
  private readonly user_id: string
  private mib = (window as any).Mib as Mib

  constructor(conf: { virtual_key: MibVirtualKey }) {
    super(conf)
    this.cidpu = conf.virtual_key.cidpu
    this.token = conf.virtual_key.token
    this.user_id = conf.virtual_key.user_id
  }

  public init(statusCB: (msg: string) => void) {
    if (window.cordova.platformId === 'browser') {
      return Promise.reject('browser is not supported on mib hardware')
    }
    if (!this.mib) {
      return Promise.reject('mib hardware not supported')
    }
    statusCB('Launching mib init...')
    return this.mib.init(this.token, this.user_id, (s) => {
      statusCB(s)
    })
      .then(() => {
        this.addAction('open', () => new Promise((resolve, reject) => {
          this.mib.open(this.cidpu).then(resolve).catch(reject)
        }), 'ble', 3)
        this.addAction('close', () => new Promise((resolve, reject) => {
          this.mib.close(this.cidpu).then(resolve).catch(reject)
        }), 'ble', 2)
        // this.addAction('list', () => new Promise((resolve, reject) => {
        //  this.mib.list().then((g)=>{s
        //    let d = JSON.parse(g.toUpperCase())
        //    let cc = this.cidpu.toUpperCase()
        //    d[cc]['KEY']['CIDPU'] = "-CIDPU-"
        //    d = d[cc]
        //    resolve(JSON.stringify(d,null,1))
        //  }).catch(reject)
        //}), "ble")
      })
  }
}
