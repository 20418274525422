import {RootState} from '@/store'
import {ActionTree, GetterTree, MutationTree} from 'vuex'
import {Popup} from '@/lib/kepler/interfaces'
import Utils from '@/utils'

export const PopupState: Popup[] = []

const mutations: MutationTree<typeof PopupState> = {
  INIT_POPUP_STATE(pState) {
    pState = []
  },
  OPEN_POPUP(pState, payload: Popup) {
    pState.push(payload)
  },
  CLOSE_POPUP(pState, idx?: number | string) {
    let index = -1
    if (typeof idx === 'string') {
      index = pState.findIndex((p) => p.id === idx)
    } else if (typeof idx === 'number') {
      index = idx
    }
    index >= 0 ? pState.splice(index, 1) : pState.pop()
  },
  FLUSH_POPUP_QUEUE(pState) {
    Utils.flushArray(pState)
  },
}

const actions: ActionTree<typeof PopupState, RootState> = {
  init({commit}) {
    commit('INIT_POPUP_STATE')
  },
  openPopup({commit, dispatch}, popup: Popup) {
    commit('OPEN_POPUP', popup)
    return popup.id
  },
  closePopup({commit}, id?: string) {
    commit('CLOSE_POPUP', id)
  },
  closeOverlays({commit}) {
    commit('FLUSH_POPUP_QUEUE')
  },
  flushPopups({commit}) {
    commit('FLUSH_POPUP_QUEUE')
  },
}

const getters: GetterTree<typeof PopupState, RootState> = {
  popupIsOpen: (state) => !!state.length,
  fullscreenPopup: (state) => state.find((p) => p.fullScreen),
  popupTitle: (state) =>  state.length ? state[state.length - 1].popupTitle || '' : null,
}

export default {
  state: PopupState,
  mutations,
  actions,
  getters,
}
