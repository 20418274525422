import AbstractHardware from '@/lib/hardware/classes/AbstractHardware'

interface Geotab {
  init: (keyring: string, cb: (status: string) => void) => Promise<string>
  open: () => Promise<string>
  close: () => Promise<string>
}

export interface GeotabConf {
  keyring: any
}

export default class GeotabHardware extends AbstractHardware {

  private readonly keyring: any

  private geotab = (window as any).Geotab as Geotab

  constructor(conf: GeotabConf) {
    super(conf)
    this.keyring = conf.keyring
  }

  public init(statusCB: (msg: string) => void) {
    if (window.cordova.platformId === 'browser') {
      return Promise.reject('browser is not supported on geotab hardware')
    }
    if (!this.geotab) {
      return Promise.reject('geotab hardware not supported')
    }
    statusCB('Launching geotab init...')
    return this.geotab.init(this.keyring, (s) => {
      statusCB(s)
    })
      .then(() => {
        this.addAction('open', () => new Promise((resolve, reject) => {
          this.geotab.open().then(resolve).catch(reject)
        }), 'ble')
        this.addAction('close', () => new Promise((resolve, reject) => {
          this.geotab.close().then(resolve).catch(reject)
        }), 'ble')
      })
  }
}
