





























import {Component, Prop, Vue} from 'vue-property-decorator'
import {DamagePoints, DamagePosition, Report, VehicleSlot} from '@/lib/kepler/interfaces'
import {EventBus} from '@/main'
import VehicleAddReport from '@/views/Vehicle/VehicleAddReport.vue'
import VehicleReport from '@/components/entities/vehicle/VehicleReport.vue'
import Utils from '@/utils'
import moment from 'moment'
import sdk from '@/lib/kepler/sdk'

@Component({
  components: {
    Button: Utils.loadComponent('Button'),
    Card: Utils.loadComponent('proxy/Card/Card'),
    Icon: Utils.loadComponent('proxy/Icon'),
    Btn: Utils.loadComponent('proxy/Btn'),
    EmptyList: Utils.loadComponent('EmptyList'),
    Dmp: Utils.loadComponent('DamagePoints'),
    CardButton: Utils.loadComponent('CardButton'),
    Container: Utils.loadComponent('proxy/Container'),
    Layout: Utils.loadComponent('proxy/Layout'),
    Flex: Utils.loadComponent('proxy/Flex'),
    Chip: Utils.loadComponent('proxy/Chip'),
  },
  name: 'VehicleStatus',
})
export default class VehicleStatus extends Vue {
  @Prop({
    type: String,
    default: () => 'accent',
  }) protected plusColor!: string
  protected reports: Report[] = []
  protected vehicleOutline: null | HTMLImageElement = null
  protected damagePoints: DamagePoints[] | undefined = []
  @Prop() private vehicleSlot!: VehicleSlot

  protected get imgOutline() {
    return this.vehicle.category.damage_picture
  }

  protected get vehicle() {
    return this.vehicleSlot.vehicle
  }

  protected mounted() {
    EventBus.$on('reportAdded', this.loadReports)
    this.loadReports()
  }

  protected destroyed() {
    EventBus.$off('reportAdded', this.loadReports)
  }

  protected addReport() {
    this.$popup.open(VehicleAddReport, {
      props: {
        vehicleSlot: this.vehicleSlot,
        vehicle: this.vehicle,
        reservationId: null,
        callback: this.loadReports,
      },
      title: this.$t('vehicle.report.diary_report'),
    })
  }

  protected loadReports() {
    sdk.booking.diaryReports(this.vehicle.id).then((r) => {
      this.reports = r.data
    })
  }

  protected openVehicleReport(report: Report) {
    this.$popup.open(VehicleReport, {
      props: {report},
      title: this.$t('vehicle.report.diary_report'),
    })
  }

  protected format(time: string) {
    return moment(time).format(this.$datetimeformat())
  }

  protected getDamagePoints() {
    const outline = this.$refs.vehicleImage as HTMLImageElement | undefined
    if (outline) {
      this.reports.forEach((item) => {
        if (item.damage_position_raw) {
          item.damage_position_raw.forEach((dmg: DamagePosition) => {
            this.damagePoints!.push({
              title: item.id,
              left: ((dmg.left) * outline.clientWidth) / dmg.containerWidth,
              top: ((dmg.top) * outline.clientHeight) / dmg.containerHeight,
            })
          })
        }
      })
    }
  }
}
