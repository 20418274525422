
import {Component, Vue} from 'vue-property-decorator'
import { VForm } from 'vuetify/lib'

@Component({
  name: 'FormTag',
  extends: VForm as any,
})
export default class FormTag extends Vue {

}
