





































import {Vue, Component} from 'vue-property-decorator'
import Utils from '@/utils'
import {Action, State} from 'vuex-class'
import sdk from '@/lib/kepler/sdk'

import {
  AppConfig,
  BookRequest,
  EstimateCategories,
  SearchRequest, UserMesh,
  VehicleCategory,
  VehicleSlot,
  Pos,
} from '@/lib/kepler/interfaces'
import Locate from '@/lib/location'
import VehicleBookingSummary from '../components/entities/vehicle/VehicleBookingSummary.vue'

class PositionClass {
  public lat: number
  public lng: number
  public acc: number | null

  constructor(lat?: number, lng?: number, acc?: number | null) {
    this.lat = lat || 0
    this.lng = lng || 0
    this.acc = acc || null
  }
}

@Component({
  components: {
    CategorySelector: Utils.loadComponent('CategorySelector'),
    VehicleListing: Utils.loadView('VehicleListing'),
    EmptyList: Utils.loadComponent('EmptyList'),
    IsLogged: Utils.loadComponent('IsLogged'),
    CheckInOutPicker: Utils.loadComponent('CheckInOutPicker'),
    Container: Utils.loadComponent('proxy/Container'),
    Layout: Utils.loadComponent('proxy/Layout'),
    FormTag: Utils.loadComponent('proxy/Inputs/FormTag'),
    Card: Utils.loadComponent('proxy/Card/Card'),
    Accordion: Utils.loadComponent('proxy/Accordion/Accordion'),
    AccordionContent: Utils.loadComponent('proxy/Accordion/AccordionContent'),
    Chip: Utils.loadComponent('proxy/Chip'),
    Button: Utils.loadComponent('Button'),
  },
  name: 'RTVehicleSearch',
})

export default class RTVehicleSearch extends Vue {
  @State((state) => state.configuration.appConfig) public appConfig!: AppConfig
  @State((state) => state.booking.categories) public categoriesState!: VehicleCategory[]
  @State((state) => state.profile.userPosition) public userPosition!: Pos | null
  @State((state) => state.profile.userMesh) public userMesh!: UserMesh[]

  @Action('setTopbarTitle') public setTopbarTitle!: (t: string) => void

  public searchComplete: boolean = false
  public position: PositionClass = new PositionClass()
  // TODO: use this for something
  public positionUnavailable: boolean = true
  public features: any[] = []
  public bookingMode: string = 'RT'
  public categoriesEstimate: EstimateCategories[] = []
  public loading: boolean = false
  public estimateLoading: boolean = false

  protected searchRequest: SearchRequest = {
    start: undefined,
    end: undefined,
    booking_mode: undefined,
    latitude: undefined,
    longitude: undefined,
    vehicle_type_id: undefined,
    vehicle_category_id: undefined,
  }
  protected searchResponse: VehicleSlot[] = []

  protected bookRequest: Partial<BookRequest> = {
    start: null,
    end: null,
    plate: null,
    memo: null,
  }

  protected get category() {
    return this.searchRequest.vehicle_category_id || null
  }

  protected set category(id: string | null) {
    this.$set(this.searchRequest, 'vehicle_category_id', id)
  }

  protected selectedFeatures() {
    return this.features.filter((feature) => {
      return feature.selected === true
    })
  }

  protected populateFields(val: { start: number, end: number }) {
    this.searchRequest.start = val.start
    this.searchRequest.end = val.end
  }

  protected setPos() {
    const conf = this.appConfig
    if (this.userPosition) {
      this.$set(this, 'position', this.userPosition)
    } else if (conf) {
      this.$set(this, 'position', new PositionClass(conf.default_latitude, conf.default_longitude))
    }
  }

  protected locate() {
    Locate.locate(
      (pos) => {
        this.position = new PositionClass(pos.lat, pos.lng, pos.acc)
      },
      this.position,
      () => {
        this.positionUnavailable = true
        // TODO: investigate further use for error callback
      },
    )
  }

  protected created() {
    const params = this.$route.params
    this.bookingMode = params.bookingMode || params.mode || 'RT' // because it could also be CRT
    if (this.$route.params.type) {
      const type = this.$route.params.type.toLowerCase()
      const mesh = this.userMesh.find((m) => m.vehicle_type.toLowerCase() === type)
      this.searchRequest.vehicle_type_id = mesh?.vehicle_type_id
    }
  }

  protected mounted() {
    this.setPos()
    this.locate()

  }

  protected getCategoryEstimate() {
    const start = this.searchRequest.start as number
    const end = this.searchRequest.end as number
    const hours = (Math.abs(start - end as number) / 3600)
    this.estimateLoading = true
    sdk.booking.estimateAll({
      distance: Math.floor(hours * 7),
      start,
      end,
      booking_mode: this.bookingMode,
    }).then((r) => {
      this.categoriesEstimate = r.data
      this.estimateLoading = false
    }, () => {
      this.estimateLoading = false
    })
  }

  protected submit() {
    this.searchRequest.booking_mode = this.bookingMode
    this.searchRequest.latitude = this.position.lat
    this.searchRequest.longitude = this.position.lng
    // this.searchRequest.vehicle_category_id = 'ZEdw40'
    // this.searchRequest.vehicle_type_id = null
    this.loading = true
    sdk.booking.search(this.searchRequest).then((r) => {
      this.searchResponse = r.data
      this.setTopbarTitle('vehicle.vehicle_listing')
      this.searchComplete = true
      this.loading = false
    })
    this.getCategoryEstimate()
  }

  protected selectVehicle(vehicleSlot: VehicleSlot) {
    this.bookRequest.plate = vehicleSlot.vehicle.plate
    this.bookRequest.start = this.searchRequest.start
    this.bookRequest.end = this.searchRequest.end
    this.$popup.open(VehicleBookingSummary, {
      props: {vehicleSlot, reservationRequest: this.bookRequest},
      title: this.$t('vehicle.booking_summary'),
    })
  }
}
