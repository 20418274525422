











import {Vue, Component, VModel, Prop} from 'vue-property-decorator'
import {VLayout, VFlex, VIcon, VCheckbox} from 'vuetify/lib'

@Component({components: {VLayout, VFlex, VIcon, VCheckbox}})
export default class ChecklistItem extends Vue {
  @VModel({required: true, type: Boolean, default: false}) private internalValue!: string
  @Prop({required: true, type: String, default: () => ''}) private title!: string
  @Prop({required: false, type: String, default: () => ''}) private description!: string
  @Prop({required: false, type: String}) private icon?: string
}
