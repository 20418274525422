import Vue from 'vue'
import {ActionTree, MutationTree} from 'vuex'
import sdk from '@/lib/kepler/sdk'
import {LicenseType} from '@/lib/kepler/interfaces'
import {RootState} from '@/store'

export class LicenseTypesState {
  public licenseTypes?: LicenseType[] = undefined
  public licenseAt: number | null = null
}

const mutations: MutationTree<LicenseTypesState> = {
  SET_LICENSE_TYPES(licensesState, payload) {
    Vue.set(licensesState, 'licenseTypes', payload)
  },
  SET_LICENSE_AT(licensesState, payload) {
    Vue.set(licensesState, 'licenseAt', payload)
  },
}

const actions: ActionTree<LicenseTypesState, RootState> = {
  init({state, dispatch}) {
    if (sdk.timestamps.verify(state.licenseAt)) {
      dispatch('getLicenseTypes')
    }
  },
  getLicenseTypes({commit, dispatch}) {
    return sdk.people.licenseTypes().then((response) => {
      commit('SET_LICENSE_TYPES', response.data)
      commit('SET_LICENSE_AT', sdk.timestamps.now())
      dispatch('sleep', 'licence_types')
    })
  },
}

export default {
  state: new LicenseTypesState(),
  mutations,
  actions,
}
