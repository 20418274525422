
import {Component, Vue} from 'vue-property-decorator'

import { VAvatar } from 'vuetify/lib'

@Component({
  name: 'Avatar',
  extends: VAvatar as any,
})
export default class Avatar extends Vue {
}
