
import {Component, Vue} from 'vue-property-decorator'

import { VFlex } from 'vuetify/lib'

@Component({
  name: 'Flex',
  extends: VFlex as any,
})
export default class Flex extends Vue {}
