
import {Component, Vue} from 'vue-property-decorator'
import { VStepper } from 'vuetify/lib'

@Component({
  name: 'Stepper',
  extends: VStepper as any,
})
export default class Stepper extends Vue {

}
