











import {Vue, Component, Prop, Watch} from 'vue-property-decorator'

import {VSelect} from 'vuetify/lib'
import {ValidationProvider} from 'vee-validate'

@Component({
  components: {VSelect, ValidationProvider},
  name: 'SelectFieldWithValidation',
})
export default class SelectFieldWithValidation extends Vue {
  @Prop({
    type: [String, Object],
    default: '',
  }) public readonly rules: any

  @Prop({
    type: String,
    default: null,
  }) public readonly value!: string | null

  public innerValue: string | null = null

  @Watch('innerValue')
  public onInnerValueChange(newVal: any) {
    this.$emit('input', newVal)
  }

  @Watch('value')
  public onValueChange(newVal: any) {
    this.innerValue = newVal
  }

  private created() {
    if (this.value) {
      this.innerValue = this.value
    }
  }
}
