import AbstractHardware from '@/lib/hardware/classes/AbstractHardware'

import OmniLock from '@/lib/BtSDKs/OmniLock'

interface OmniLockConf {
  mac: string,
  key: string,
  closeDialog: () => {},
  type: string,
}

export default class OmniLockHardware extends AbstractHardware {
  private omniLockConf: OmniLockConf

  private manager!: OmniLock

  constructor(conf: OmniLockConf) {
    super(conf)
    this.omniLockConf = conf
  }

  public init(statusCB: (msg: string) => void) {
    if (window.cordova.platformId === 'browser') {
      return Promise.resolve('browser is not supported on omni-lock hardware')
    }

    this.manager = new OmniLock(statusCB, this.omniLockConf.mac, this.omniLockConf.key, this.omniLockConf.type, ()=>{})

    statusCB('Launching omni init...')
    statusCB('OMNI MAC: ' + this.omniLockConf.mac)
    statusCB('OMNI KEY: ' + this.omniLockConf.key)
    return this.manager.init()
      .then(() => {
        this.addAction('open', () => new Promise((resolve, reject) => {
          this.manager.unlock().then(resolve).catch(reject)
        }), 'ble')
        //this.addAction('close', () => {
        //  this.omniLockConf.closeDialog()
        //  return Promise.resolve()
        //}, 'js-alert')
      })
  }

  public cleanup() {
    this.manager.disconnect()
  }
}
