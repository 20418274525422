






























import {Vue, Component, Prop} from 'vue-property-decorator'
import {Action} from 'vuex-class'

import {ReservationResponse} from '@/lib/kepler/interfaces'
import VuetifyColorHelper from '@/lib/vuetify/VuetifyColorHelper'
import ServiceMesh from '@/lib/serviceMesh'
import DateHelper from '@/lib/DateHelper'
import {EventBus} from '@/main'
import moment from 'moment'
import Utils from '@/utils'
import ActiveReservationBar from '@/components/entities/reservation/ActiveReservationBar.vue'

@Component({
  components: {
    Plate: Utils.loadComponent('Plate'),
    ProgressCircular: Utils.loadComponent('proxy/ProgressCircular'),
    Layout: Utils.loadComponent('proxy/Layout'),
    Avatar: Utils.loadComponent('proxy/Avatar'),
    Icon: Utils.loadComponent('proxy/Icon'),
  },
  name: 'ActiveReservationCard',
})
export default class ActiveReservationCard extends ActiveReservationBar {

  protected progress: number = 0
  protected timeLeft: string = ''

  protected get standardColor() {
    return this.isLate ? VuetifyColorHelper.color('error') : 'inherit'
  }

  protected get remainingTime() {
    const end = this.reservation.end_timestamp
    if (end === null) {
      return null
    }
    const diff = () => {
      return moment.duration(moment.unix(end).diff(moment()))
    }

    const obj = {
      days: diff().days(),
      hours: diff().hours(),
      minutes: diff().minutes(),
    }

    let str = ''

    for (const [unit, n] of Object.entries(obj)) {
      // console.log(unit, n)
      if (n !== 0) {
        str += `${Math.abs(n)}${unit.charAt(0)} `
      }
    }

    return str.trim()
  }

  protected onCron1() {
    this.current().then(() => {
      this.updateProgress(this.reservation)
    })
  }

  protected mounted() {
    this.updateProgress(this.reservation)
    EventBus.$on('cron1', this.onCron1)
  }

  protected destroyed() {
    EventBus.$off('cron1', this.onCron1)
  }

  protected updateProgress(reservation: ReservationResponse) {
    const timeStart: any = reservation.start_timestamp
    const timeEnd: any = reservation.end_timestamp
    const now = new Date().getTime() / 1000
    const totalHours = (timeEnd - timeStart) / 3600
    const hoursSpent = (now - timeStart) / 3600
    this.progress = 100 / totalHours * hoursSpent

    if (reservation.vehicle_slot.reservation_type === 'RT') {
      this.timeLeft = DateHelper.getTimeOffset(now, timeEnd).formatTimeOffset('dhm')
      return
    }

    this.timeLeft = DateHelper.getTimeOffset(timeStart, new Date()).formatTimeOffset('dhm')
  }

  protected openReservationDetail() {
    this.$router.push({name: 'reservation', params: {id: this.reservation.id}})
  }
}
