import {Vue, Component} from 'vue-property-decorator'
import {
  BookRequest,
  ReservationResponse,
} from '@/lib/kepler/interfaces'
import LoaderDialog from '@/views/LoaderDialog.vue'
import VehicleConfirmBooking from '@/views/Vehicle/VehicleConfirmBooking.vue'
import {Action} from 'vuex-class'

@Component({})
export default class BookingMixin extends Vue {
  @Action('book') public bookAction!: (req: BookRequest) => Promise<ReservationResponse>

  protected bookRequest: BookRequest = {start: null, end: null, memo: null, plate: null, packet_id: null}

  protected sendBooking(callback?: (r?: ReservationResponse) => void) {
    this.$dialog.open(LoaderDialog, {props: {text: this.$t('common.loading')}})
      .then((id) => {
        this.bookAction(this.bookRequest)
          .finally(() => {
            this.$dialog.close(id)
          })
          .then((r) => {
            const confirmCallback = callback ? () => callback(r) : undefined
            this.$popup.open(VehicleConfirmBooking, {
              props: {vehicleSlot: r.vehicle_slot, confirmCallback}, hideTopbar: true,
            })
          })
          .catch(() => '💩')
      })

  }
}
