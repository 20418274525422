







import {Component} from 'vue-property-decorator'

import NMapChildren from './NMapChildren.vue'

@Component({
  components: {},
})
export default class NMapMarkerCluster extends NMapChildren {

  public isNMapMarkerCluster = true

  private waitInProgress: boolean = false

  private libLoaded: boolean = false

  public createCluster() {
    this.item = this.providerInstance().markerCluster(this.item, this.$children)
  }

  public createClusterSafe() {
    const initialized = this.$children.filter((child: any) => {
      return child.item != null
    })

    if (this.$children.length > 0) {
      if ((this.$children.length === initialized.length)) {
        this.createCluster()
      } else {
        if (!this.waitInProgress) {
          this.waitInProgress = true
          setTimeout(() => {
            this.waitInProgress = false
            this.$emit('map-ready')
            this.createClusterSafe()
          }, 100)
        }
      }
    }
  }

  public init() {
    Promise.all(this.providerInstance().markerClusterLib() || []).then(() => {
      // Lib loaded make childrens initialize [PLUS SHIT HERE HOTFIX]
      // if (this.providerInstance?.provider != 'here') {
      //   this.ready = true // I think this is not needed
      //   this.$emit('map-ready')
      // }
      this.libLoaded = true
      this.createClusterSafe()
    })
  }

  public updated() {
    if (this.libLoaded) {
      this.createClusterSafe()
    }
  }
}
