
import {Component, Vue} from 'vue-property-decorator'
import {VCheckbox} from 'vuetify/lib'

@Component({
  name: 'CheckBox',
  extends: VCheckbox as any,
})
export default class CheckBox extends Vue {
}
