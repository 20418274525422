import _Vue from 'vue'
import store from '@/store/index'

export enum LogLevels {
  'debug',
  'info',
  'warn',
  'error'
}

export enum LogColorByLevel {
  'debug' = '#9e9e9e',
  'info' = '#2196f3',
  'warn' = '#ffc107',
  'error' = '#f44336'
}

const getMethodName = (): string => {
  let error: Error
  try {
    // noinspection ExceptionCaughtLocallyJS
    throw new Error('')
  } catch (e) {
    if (e instanceof Error) {
      error = e
      // IE9 does not have .stack property
      if (error.stack === undefined) {
        return ''
      }
      let stackTrace = error.stack.split('\n')[3]
      if (/ /.test(stackTrace)) {
        stackTrace = stackTrace.trim().split(' ')[1]
      }
      if (stackTrace && stackTrace.indexOf('.') > -1) {
        stackTrace = stackTrace.split('.')[1]
      }
      return stackTrace
    }
    return ''
  }
}

const convertToText = (obj?: Record<string, any> | Array<any> | Function | null, recurse = 0): string => {
  const arr = []
  if (obj === undefined || obj === null) {
    return String(obj)
  }

  switch (typeof obj) {
    case 'function':
      arr.push(obj.toString())
      break
    case 'object':
      if (Array.isArray(obj)) {
        for (let prop in obj) {
          arr.push(convertToText(obj[prop]))
        }
        return '[' + arr.join(',') + ']'
      } else {
        for (let prop of Object.keys(obj)) {
          if (Object.hasOwn(obj, prop) && Object.keys(obj) && recurse <= 10) {
            // recurse <= 10 to avoid overflowing the memory in case some funny mf dumps the whole store
            arr.push(prop + ': ' + convertToText(obj[prop], recurse++))
          }
        }
        return '{' + arr.join(',') + '}'
      }
    default:
      arr.push(JSON.stringify(obj))
  }
  return arr.join(',')
}

const channels: { [channel: string]: (v: any, level: LogLevels, method?: string) => void } = {
  'console': (v, level, method = '') => {
    if (v === 'playmooooooooooooooooove') {
      console.log('%c ', 'padding:28px 119px;line-height:100px;background:url(https://storage.colossus.playmoove.com/cdn/_playmoove/logo.svg) no-repeat;')
      return
    }
    const prefix = `${LogLevels[level].padEnd(5, ' ')} | ${method} | `

    switch (level) {
      case LogLevels.info:
        console.info(...['%c' + prefix + '%s', 'color:' + LogColorByLevel['info'], v])
        break
      case LogLevels.warn:
        console.warn(...['%c' + prefix + '%s', 'color:' + LogColorByLevel['warn'], v])
        break
      case LogLevels.error:
        console.error(...['%c' + prefix + '%s', 'color:' + LogColorByLevel['error'], v])
        break
      default:
        console.log(...['%c' + prefix + '%s', 'color:' + LogColorByLevel['debug'], v])
    }
  },
  store: (v, level, method = '') => {
    if (store.getters.debugMode || level > 1) { // only warn, error and fatal if not debugging
      const prefix = `${LogLevels[level].padEnd(5, ' ')} | ${method} | `
      store.dispatch('log', `${Date.now()}>>${prefix}${convertToText(v)}`)
    }
  },
  // sentry: (v, level, method = '') => {},
}

export const log = (val: any, level: LogLevels = 0) => {
  const method = getMethodName() // doesn't bork in firefox
  Object.values(channels).forEach((f) => {
    f(val, level, method)
  })
}

export type log = typeof log

export default {
  install(Vue: typeof _Vue): void {
    Vue.prototype.$log = log
  },
}
