












import {Vue, Component, Prop, Watch} from 'vue-property-decorator'
import {VLayout, VDivider} from 'vuetify/lib'
import ChecklistItem from '@/components/ChecklistItem.vue'
import {CheckItem} from '@/lib/kepler/interfaces'

@Component({
  components: {
    VLayout,
    VDivider,
    ChecklistItem,
  },
})
export default class Checklist extends Vue {
  @Prop({required: true, type: Array, default: () => []}) private list!: CheckItem[]
  @Prop({required: false, type: String, default: () => ''}) private title!: string
  @Prop({required: false, type: String, default: () => ''}) private description!: string

  private internalList: { [id: string]: boolean } = {}

  @Watch('internalList', {deep: true, immediate: false})
  private onListChange() {
    this.$emit('change', this.internalList)
  }

  private created() {
    this.list.forEach((item: CheckItem) => {
      this.$set(this.internalList, item.id, false)
    })
  }
}
