






import {Component, Mixins, Prop} from 'vue-property-decorator'
import {VIcon} from 'vuetify/lib'
// import {VIcon} from 'vuetify/src/components'
import Colorable from 'vuetify/src/mixins/colorable'
import Sizeable from 'vuetify/src/mixins/sizeable'
import Themeable from 'vuetify/lib/mixins/themeable'
import {VNodeData} from 'vue/types/vnode'

enum SIZE_MAP {
  small = '16px',
  default = '24px',
  medium = '28px',
  large = '36px',
  xLarge = '40px',
}

@Component({
  name: 'CustomIcon',
  components: {VIcon},
  mixins: [Themeable, Colorable, Sizeable],
})
export default class CustomIcon extends Mixins(Themeable, Colorable, Sizeable) {
  @Prop({type: Boolean, default: false}) public disabled!: boolean
  @Prop({type: Boolean, default: false}) public left!: boolean
  @Prop({type: Boolean, default: false}) public right!: boolean

  @Prop({type: [Number, String]}) public size!: number | string
  @Prop({type: Boolean, default: false}) public small!: boolean
  @Prop({type: Boolean, default: false}) public medium!: boolean
  @Prop({type: Boolean, default: false}) public large!: boolean
  @Prop({type: Boolean, default: false}) public xLarge!: boolean

  @Prop({type: String, required: false}) public color!: string
  @Prop({type: String, required: false}) public url?: string
  @Prop({type: String, required: false}) public paymentMethod?: string

  public isDark!: boolean
  public themeClasses!: object
  public setTextColor!: (color?: string | false) => VNodeData

  protected get customClasses() {
    const obj = {
      'v-icon--disabled': this.disabled,
      'v-icon--left': this.left,
      'v-icon--link': this.$listeners.click || this.$listeners['!click'],
      'v-icon--right': this.right,
      ...this.themeClasses,
      ...this.setTextColor(this.color).class,
    }
    if (this.paymentMethod) {
      obj['pf pf-' + this.paymentMethod.toLowerCase().replace(' ', '-')] = true
    }
    return obj
  }

  protected get styles() {
    let styles = {}
    if (this.url) {
      styles = {
        ...styles,
        backgroundImage: `url(${this.url})`,
        backgroundPosition: 'center',
        backgroundSize: 'contain',
      }
      if (this.url.endsWith('pin_white.svg') && !this.isDark) {
        styles = {...styles, filter: 'brightness(0.4)'}
      }
    }
    const textStyle = this.setTextColor(this.color).style
    if (textStyle && typeof textStyle === 'object') {
      styles = {...styles, ...textStyle}
    }
    const size = this.getSize()
    if (size) {
      styles = {...styles, fontSize: size, height: size, minWidth: size}
    }
    return styles
  }

  protected getSize() {
    const sizes: { [key: string]: boolean } = {
      small: this.small,
      medium: this.medium,
      large: this.large,
      xLarge: this.xLarge,
    }

    const explicitSizeKey = Object.keys(sizes).find((key) => sizes[key]) as keyof typeof SIZE_MAP | undefined
    const explicitSize = explicitSizeKey && SIZE_MAP[explicitSizeKey]
    return explicitSize || this.convertToUnit(this.size || SIZE_MAP.default)
  }

  protected convertToUnit(str: string | number | null | undefined, unit = 'px'): string | undefined {
    if (str === null || str === '') {
      return undefined
    } else if (isNaN(+str!)) {
      return String(str)
    } else {
      return `${Number(str)}${unit}`
    }
  }
}
