




import {Vue, Component, Prop} from 'vue-property-decorator'
import lottie, * as Lottie from 'lottie-web'

export interface LottieWrapperOptions {
  loop: boolean
  autoplay: boolean
  animationData: any
  rendererSettings: Lottie.SVGRendererConfig
}

@Component({})
export default class LottieWrapper extends Vue {
  @Prop({
    type: Object,
    required: true,
  }) public options!: LottieWrapperOptions
  @Prop({
    type: Number,
  }) public height!: number
  @Prop({
    type: Number,
  }) public width!: number
  public anim: Lottie.AnimationItem | null = null

  public get style() {
    return {
      width: this.width ? `${this.width}px` : '100%',
      height: this.height ? `${this.height}px` : '100%',
      overflow: 'hidden',
      margin: '0 auto',
    }
  }

  protected mounted() {
    const config: Lottie.AnimationConfigWithData = {
      container: this.$refs.lottie_animation_wrapper as Element,
      renderer: 'svg',
      loop: this.options.loop,
      autoplay: this.options.autoplay,
      animationData: this.options.animationData,
      rendererSettings: this.options.rendererSettings,
    }

    this.anim = lottie.loadAnimation(config)
    this.$emit('animCreated', this.anim)
  }
}
