








import {Vue, Component, Prop} from 'vue-property-decorator'
import Utils from '@/utils'
import {Action, State} from 'vuex-class'
import {VLayout, VBtn, VIcon} from 'vuetify/lib'
import {FlowInputField, FlowInputStep} from '@/lib/kepler/interfaces'

@Component({
  components: {
    FlowField: Utils.loadComponent('flow/FlowField'),
    VLayout,
    VBtn,
    VIcon,
  },
  name: 'RegistrationSummaryComponent',
})
export default class RegistrationSummaryComponent extends Vue {
  @State('flowOutputs') public flowOutputs!: { [k: string]: any }

  @Action('stepsApi') public stepsApi: any

  @Prop() public output?: Array<{ [k: string]: any }> | { [k: string]: any }
  @Prop() public field?: FlowInputField
  @Prop() public step?: FlowInputStep

  public disabled: boolean = true
  public steps: FlowInputStep[] = []

  protected get context() {
    return this.step?.context
  }

  protected get currentOutput() {
    if (this.context) {
      return this.flowOutputs[this.context]
    }
  }

  protected get fieldList() {
    const arr: string[] = []
    if (Array.isArray(this.output)) {
      const tryToPush = (key: string) => {
        const k = typeof Utils.getProp(this.currentOutput, key.split('.')) !== undefined
        if (k) {
          arr.push(key)
        }
      }
      const tryIfObj = (val: string | object) => {
        if (typeof val === 'string') {
          tryToPush(val)
        } else {
          Object.values(val).forEach((v: string | object) => {
            tryIfObj(v)
          })
        }
      }
      this.output.forEach((out, index) => {
        if (!this.hiddenOutput(index)) {
          Object.values(out).forEach((value) => {
            tryIfObj(value)
          })
        }
      })
    } else if (this.output) {
      const tryToPush = (key: string) => {
        const k = typeof Utils.getProp(this.currentOutput, key.split('.')) !== undefined
        if (k) {
          arr.push(key)
        }
      }
      const tryIfObj = (val: string | object) => {
        if (typeof val === 'string') {
          tryToPush(val)
        } else {
          Object.values(val).forEach((v: string | object) => {
            tryIfObj(v)
          })
        }
      }
      if (!this.hiddenOutput(0)) {
        Object.values(this.output).forEach((value) => {
          tryIfObj(value)
        })
      }
    }
    return arr
  }

  protected hiddenOutput(index: number) {
    const f = this.field
    if (f) {
      const v = f.validation
      if (v && typeof v === 'object' && v.hidden) {
        return v.hidden.includes(index)
      } else if (v && typeof v === 'string' && JSON.parse(v).hidden.length) {
        return JSON.parse(v).hidden?.includes(index)
      }
    }
  }

  protected get fields() {
    const arr: FlowInputField[] = []
    this.steps.forEach((step) => {
      arr.push(...this.getFields(step.fields))
    })
    return arr
  }

  protected getFields(fields?: FlowInputField[]) {
    // field types excluded from summary
    const hiddenTypes = ['tocs', 'selfie']

    const arr: FlowInputField[] = []
    if (fields) {
      fields.forEach((field) => {
        if (this.fieldList.includes(field.id) && !hiddenTypes.includes(field.type)) {
          arr.push(field)
        }
      })
    }
    return arr
  }

  protected created() {
    this.stepsApi().then((r: FlowInputStep[]) => {
      this.$set(this, 'steps', r)
    })
  }
}
