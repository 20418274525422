
















import {Vue, Component} from 'vue-property-decorator'
import sdk from '../lib/kepler/sdk'
import {EventBus} from '@/main'
import Utils from '@/utils'

@Component({
  components: {
    Button: Utils.loadComponent('Button'),
    LoopingBG: Utils.loadComponent('LoopingBG'),
    Container: Utils.loadComponent('proxy/Container'),
    Layout: Utils.loadComponent('proxy/Layout'),
  },
  name: 'IsLogged',
})
export default class IsLogged extends Vue {

  protected isLogged: boolean = false

  protected mounted() {
    this.isLogged = this.logged()
    EventBus.$on('login', () => {
      this.isLogged = this.logged()
    })
  }

  protected login() {
    this.$router.push({name: 'login'})
  }

  protected signUp() {
    this.$router.push({name: 'registration'})
  }

  protected logged() {
    return sdk.people.isLogged()
  }
}
