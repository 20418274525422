import {Vue, Component} from 'vue-property-decorator'
import {Action, Getter, State} from 'vuex-class'
import {AppConfig, FieldConfigs, Pos, Position} from '@/lib/kepler/interfaces'
import {MapFiltersState, SearchParameters} from '@/store/modules/map'
import ServiceMesh from '@/lib/serviceMesh'
import {EventBus} from '@/main'

@Component
export class FiltersLogic extends Vue {
  @State((state) => state.configuration.appConfig) public appConfig!: AppConfig
  @State((state) => state.profile.userPosition) public userPosition!: Pos | null
  @State('filters') public filters!: MapFiltersState

  @Getter('fieldConfigs') public fieldConfigs!: FieldConfigs

  @Action('_initFilters') public initFilters!: (position: Position) => Promise<void>
  @Action('_toggle') public toggle!: (path: [string, string]) => void
  @Action('_parameter') public updateParameter!: (p: SearchParameters) => void

  public loading: boolean = false
  public modified: boolean = false

  public mesh = new ServiceMesh()

  public get userPos(): Position {
    return {
      latitude: this.userPosition?.lat || this.fieldConfigs.default_latitude || 0,
      longitude: this.userPosition?.lng || this.fieldConfigs.default_longitude || 0,
    }
  }

  protected init() {
    if (!this.loading) {
      this.loading = true
      // initialize filters based on user position and create config
      this.initFilters(this.userPos).finally(() => {
        this.loading = false
        this.modified = false
      })
    }
  }

  protected created() {
    this.init()
    EventBus.$on('recenter', this.init)
  }

  protected destroyed() {
    EventBus.$off('recenter', this.init)
  }
}
