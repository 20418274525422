import store from '@/store/index'
import _Vue from 'vue'
import {Dialog, Popup} from '@/lib/kepler/interfaces'
import {Component as VueComponent} from 'vue/types/options'
import {log} from '@/lib/plugins/logger'

interface DialogOptions {
  title?: string
  props?: Record<string, any>
  hideTopbar?: boolean // unused?
  confirmedEvent?: string | null // old and shit?
}

interface PopupOptions {
  title?: string
  props?: Record<string, any>
  hideTopbar?: boolean
  fullscreen?: boolean
}

const _dialog = {
  open: (component: VueComponent, options?: DialogOptions) => {
    log('dialog | ' + component.name, 0)
    const dialog = new Dialog(
      component,
      options?.props ?? {},
      options?.title,
      !!options?.hideTopbar,
      options?.confirmedEvent ?? null,
    )
    return store.dispatch('openDialog', dialog)
  },
  close: (idx?: string | number) => {
    log('dialog', 0)
    store.dispatch('closeDialog', idx)
  }
}

const _popup = {
  open: (component: VueComponent, options?: PopupOptions): Promise<string> => {
    log('popup | ' + component.name, 0)
    const popup = new Popup(
      component,
      options?.props ?? {},
      options?.title,
      !!options?.hideTopbar,
      !!options?.fullscreen,
    )
    return store.dispatch('openPopup', popup)
  },
  close: (id?: string) => {
    log('popup', 0)
    store.dispatch('closePopup', id)
  }
}

export type dialog = typeof _dialog
export type popup = typeof _popup

export default {
  install(Vue: typeof _Vue): void {
    Vue.prototype.$popup = _popup
    Vue.prototype.$dialog = _dialog
  },
}
