import {ResourceLoader} from '../utils/ResourceLoader'
import {Base} from './Base'
import {LatLng} from '../models/LatLng'

export class Here extends Base {

  public loadResources() {
    return [
      ResourceLoader('script', 'here-map-core', 'https://js.api.here.com/v3/3.1/mapsjs-core.js'),
    ]
  }

  public initMap(element: HTMLElement, initConfig: any, s: any): void {
    // ResourceLoader('script', 'here-map-ui', 'https://js.api.here.com/v3/3.1/mapsjs-ui.js'),
    Promise.all([
      ResourceLoader('script', 'here-map-service', 'https://js.api.here.com/v3/3.1/mapsjs-service.js'),
      ResourceLoader('script', 'here-map-mapevents', 'https://js.api.here.com/v3/3.1/mapsjs-mapevents.js'), // DRAGS! needed to make draggable
    ]).then(() => {
      // https://developer.here.com/documentation/examples/maps-js/styles/interactive-basemap
      // @ts-ignore
      const platform = new H.service.Platform({
        apikey: this.config.apiKey,
      })
      const defaultLayers = platform.createDefaultLayers()
      // @ts-ignore
      this.map = new H.Map(
        element,
        defaultLayers.vector.normal.map,
        {
          zoom: initConfig.zoom,
          center: {lat: initConfig.center.latitude, lng: initConfig.center.longitude},
        })
      // Set map draggable
      // https://developer.here.com/documentation/examples/maps-js/styles/interactive-basemap.
      // @ts-ignore
      // noinspection JSUnusedLocalSymbols
      const behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(this.map))
      // Add a resize listener to make sure that the map occupies the whole container
      window.addEventListener('resize', () => this.map.getViewPort().resize())
      s()
    })
  }

  public setClickListener(l: (p: LatLng) => void): void {
    this.map.addEventListener('tap', (evt: any) => {
      // https://developer.here.com/documentation/examples/maps-js/events/position-on-mouse-click
      const coord = this.map.screenToGeo(evt.currentPointer.viewportX, evt.currentPointer.viewportY)
      l(new LatLng(coord.lat, coord.lng))
    })
  }

  public pan(p: LatLng): void {
    // https://developer.here.com/documentation/examples/maps-js/maps/map-at-specified-location
    this.map.setCenter({lat: p.lat, lng: p.lng})
  }

  public zoom(level: number) {
    this.map.setZoom(level)
  }

  public cleanChild(c: any): void {
    this.map.removeObject(c)
  }

  public addPolyline(points: LatLng[], lineWidth: number, color: string, tooltip: string | null = null): void {
    // https://developer.here.com/documentation/examples/maps-js/geoshapes/polyline-on-the-map
    // @ts-ignore
    const lineString = new H.geo.LineString()
    points.forEach((p: LatLng) => {
      lineString.pushPoint(p)
    })
    // @ts-ignore
    let i = new H.map.Polyline(
      lineString, {
        style: {
          lineWidth: lineWidth,
          strokeColor: color,
        },
      },
    )
    this.map.addObject(i)
    return i
  }

  public addMarker(point: LatLng, icon: string, popupElement: any, click: () => void): void {
    // @ts-ignore
    const i = new H.map.Marker(point)
    this.map.addObject(i)
    return i
  }

  public addCircle(point: LatLng, radius: number, popupElement: any, click: () => void) {
    // https://developer.here.com/documentation/examples/maps-js/geoshapes/circle-on-the-map
    // @ts-ignore
    let i = new H.map.Circle(
      point, // lat, lng
      radius,
      {
        style: {},
      },
    )

    this.map.addObject(i)

    return i
  }

  public markerClusterLib(): any[] {
    const h = 'https://js.api.here.com/v3/3.1/mapsjs-clustering.js'
    return [
      ResourceLoader('script', 'here-map-marker-clusterer', h),
    ]
  }

  public markerCluster(item: any, child: any): void {
    // Bypass reactivity edge-case
    // @ts-ignore
    if (typeof H === 'undefined') {
      return
    }

    // @ts-ignore
    let i = new H.map.layer.ObjectLayer(new H.clustering.Provider(child.map((c: any) => {
      // @ts-ignore
      return new H.clustering.DataPoint(c.position.lat, c.position.lng)
    }), {
      clusteringOptions: {
        // Maximum radius of the neighbourhood
        eps: 32,
        // minimum weight of points required to form a cluster
        minWeight: 2,
      },
    }))
    this.map.addLayer(i)
    return i

  }
}
