
import {Component, Vue} from 'vue-property-decorator'

import { VMenu } from 'vuetify/lib'

@Component({
  name: 'Menu',
  extends: VMenu as any,
})
export default class Menu extends Vue {

}
