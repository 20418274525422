





































import {Component, Prop, Vue} from 'vue-property-decorator'
import {EstimateCategories, SearchRequest, VehicleSlot} from '@/lib/kepler/interfaces'
import collect from 'collect.js'
import moment from 'moment'
import Utils from '@/utils'

@Component({
  components: {
    CardVehicleList: Utils.loadComponent('CardVehicleList'),
    Container: Utils.loadComponent('proxy/Container'),
    Divider: Utils.loadComponent('proxy/Divider'),
    Layout: Utils.loadComponent('proxy/Layout'),
    Sheet: Utils.loadComponent('proxy/Sheet'),
    Flex: Utils.loadComponent('proxy/Flex'),
    Card: Utils.loadComponent('proxy/Card/Card'),
    Button: Utils.loadComponent('Button'),
    CustomIcon: Utils.loadComponent('CustomIcon'),
  },
  name: 'VehicleListing',
})

export default class VehicleListing extends Vue {
  @Prop() public vehicleSlots!: VehicleSlot[]
  @Prop() public searchRequest!: SearchRequest
  @Prop() public estimateCategories!: EstimateCategories[]
  @Prop() public estimateLoading!: boolean

  public get slotsByLots() {
    const c = collect(this.vehicleSlots)
    return c.groupBy('name').all()
  }

  private formatDistance(dist: number) {
    const num = (Math.round(dist) / 1000)
    return Math.round(num * 100) / 100
  }

  private formatTime(timestamp: number) {
    const t = moment.unix(timestamp)
    return t.format(this.$dateformat()) + '\n ' + t.format(this.$timeformat())
  }

  private categoryEstimate(catId: string) {
    const c = collect(this.estimateCategories).first((item: EstimateCategories) => {
      return item.rate.id === catId
    })
    return c && c.costs ? c.costs.time_cost : null
  }
}
