





import {Vue, Component, Prop} from 'vue-property-decorator'
import {Action} from 'vuex-class'
import CameraPreset from '@/lib/camera/cameraPresets'
import {dataURLToBlob} from '@/lib/BlobHelper'

import CameraDialog from '@/views/uploader_flow/CameraDialog.vue'
import CropDialog from '@/views/uploader_flow/CropDialog.vue'
import CameraStorageDialog from '@/views/uploader_flow/CameraStorageDialog.vue'
import ImageUploadDialog from '@/views/uploader_flow/ImageUploadDialog.vue'

import {VBtn} from 'vuetify/lib'
import {Attachment, Dialog} from '@/lib/kepler/interfaces'
import MultipleDialogCallback from '@/views/MultipleDialogCallback.vue'
import DocumentUploadDialog from '@/views/uploader_flow/DocumentUploadDialog.vue'
import Button from '@/components/Button.vue'

enum CameraEvent {
  gotPicture = 'gotPicture',
  gotCroppedPicture = 'gotCroppedPicture',
  gotToken = 'gotToken',
  gotUploadResponse = 'gotUploadResponse',
  gotDocument = 'gotDocument',
}

enum InputOptions {
  camera = 'camera',
  storage = 'storage',
  document = 'document',
}

@Component({
  components: {Button, VBtn},
})
export default class CameraButton extends Vue {
  @Action('openDialog') public openDialog!: (dialog: Dialog) => void

  @Prop({
    type: String,
    default: () => 'base',
  }) public presetName!: string

  public loading: boolean = false

  public get preset() {
    return new CameraPreset(this.presetName)
  }

  public get canCrop() {
    return this.preset.canCrop
  }

  public get canUpload() {
    return this.preset.canUseUpload
  }

  public choiceDialog(): Promise<InputOptions> {
    return new Promise((resolve) => {
      const options: Array<{ text: string, value: InputOptions }> = []

      if (this.preset.canUseCamera) {
        options.push({
          text: this.$t('uploader.input_dialog.upload_photo'),
          value: InputOptions.camera,
        })
      }
      if (this.preset.canUseStorage) {
        options.push({
          text: this.$t('uploader.input_dialog.upload_picture'),
          value: InputOptions.storage,
        })
      }
      if (this.preset.canUseDocument) {
        options.push({
          text: this.$t('uploader.input_dialog.upload_document'),
          value: InputOptions.document,
        })
      }

      this.openDialog(new Dialog(MultipleDialogCallback, {
        options,
        callback: (option: InputOptions) => {
          resolve(option)
        },
      }))
    })
  }

  public PictureFlow() {
    const optionsArray: InputOptions[] = []
    if (this.preset.canUseCamera) {
      optionsArray.push(InputOptions.camera)
    }
    if (this.preset.canUseStorage) {
      optionsArray.push(InputOptions.storage)
    }
    if (this.preset.canUseDocument) {
      optionsArray.push(InputOptions.document)
    }

    const baseFlow = () => {
      if (optionsArray.length > 1) {
        this.choiceDialog().then((option) => {
          switch (option) {
            case InputOptions.camera:
              cameraFlow()
              break
            case InputOptions.storage:
              storageFlow()
              break
            case InputOptions.document:
              documentFlow()
              break
          }
        })
      } else if (optionsArray.length === 1) {
        switch (optionsArray[0]) {
          case InputOptions.camera:
            cameraFlow()
            break
          case InputOptions.storage:
            storageFlow()
            break
          case InputOptions.document:
            documentFlow()
            break
        }
      }
    }
    const cropUploadFlow = (r: HTMLImageElement, cropCancelCb: () => void) => {
      if (this.canCrop) {
        const c = () => this.useCrop(r, cropCancelCb).then((b) => {
          if (this.canUpload) {
            this.useUpload(b, c)
          }
        })
        c()
      } else if (this.canUpload) {
        const c = () => this.useUpload(dataURLToBlob(r.src), baseFlow)
        c()
      }
    }
    const cameraFlow = () => {
      this.useCamera().then((r) => {
        cropUploadFlow(r, cameraFlow)
      })
    }
    const storageFlow = () => {
      this.useStorage().then((r) => {
        cropUploadFlow(r, storageFlow)
      })
    }
    const documentFlow = () => {
      this.useDocument().then((r) => {
        if (this.canUpload) {
          this.useUpload(r.blob, documentFlow, r.filename)
        }
      })
    }
    baseFlow()
  }

  public useCamera(optionsOverride?: CameraOptions): Promise<HTMLImageElement> {
    return new Promise<HTMLImageElement>((resolve, reject) => {
      if (device.platform.toLowerCase() === 'browser') {
        const cameraOptions = optionsOverride || this.preset.cameraOptions
        this.openDialog(new Dialog(CameraDialog, {
          cameraOptions,
          successCallback: (r: string) => {
            const img1 = new Image()
            img1.src = r
            this.sendEvent(CameraEvent.gotPicture, r)
            resolve(img1)
          },
        }))
      } else {
        navigator.camera.getPicture(
          (b64) => {
            const img1 = new Image()
            img1.src = 'data:image/jpeg;charset=utf-8;base64,' + b64
            this.sendEvent(CameraEvent.gotPicture, img1.src)
            resolve(img1)
          },
          (cameraError) => {
            reject(cameraError)
          },
          optionsOverride || this.preset.cameraOptions,
        )
      }
    })
  }

  public useDocument(): Promise<{ blob: Blob, filename?: string }> {
    return new Promise((resolve) => {
      const cameraOptions = this.preset.cameraOptions
      this.openDialog(new Dialog(DocumentUploadDialog, {
        cameraOptions,
        successCallback: (blob: Blob, filename?: string) => {
          this.sendEvent(CameraEvent.gotDocument, blob)
          resolve({blob, filename})
        },
      }))
    })
  }

  public useStorage(): Promise<HTMLImageElement> {
    return new Promise((resolve) => {
      const cameraOptions = this.preset.cameraOptions
      this.openDialog(new Dialog(CameraStorageDialog, {
        cameraOptions,
        successCallback: (r: string) => {
          const img1 = new Image()
          img1.src = r
          this.sendEvent(CameraEvent.gotPicture, img1.src)
          resolve(img1)
        },
      }))
    })
  }

  public useCrop(r: HTMLImageElement, cancelCb: () => void): Promise<Blob> {
    return new Promise((resolve) => {
      this.openDialog(new Dialog(CropDialog, {
        result: r,
        cameraOptions: this.preset.cameraOptions,
        successCallback: (cropped: Blob) => {
          this.sendEvent(CameraEvent.gotCroppedPicture, cropped)
          this.$dialog.close()
          resolve(cropped)
        },
        cancelCallback: () => {
          this.$dialog.close()
          cancelCb()
        },
      }))
    })
  }

  public useUpload(b: Blob, cancelCb: () => void, filename?: string) {
    this.openDialog(new Dialog(ImageUploadDialog, {
      blob: b,
      filename,
      successCallback: (r: Attachment) => {
        this.sendEvent(CameraEvent.gotUploadResponse, r)
        this.sendEvent(CameraEvent.gotToken, r.token)
      },
      cancelCallback: () => {
        this.$dialog.close()
        cancelCb()
      },
    }))

  }

  public sendEvent(e: CameraEvent, p: string | Blob | Attachment) {
    switch (e) {
      case CameraEvent.gotPicture:
        this.$emit('gotPicture', p)
        break
      case CameraEvent.gotCroppedPicture:
        this.$emit('gotCroppedPicture', p)
        break
      case CameraEvent.gotToken:
        this.$emit('gotToken', p)
        break
      case CameraEvent.gotUploadResponse:
        this.$emit('gotUploadResponse', p)
        break
      case CameraEvent.gotDocument:
        this.$emit('gotDocument', p)
    }
    this.$log(e, 1)
  }
}
