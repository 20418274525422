







import {Vue, Component, Prop} from 'vue-property-decorator'
import Utils from '@/utils'

@Component({
  components: {
    Layout: Utils.loadComponent('proxy/Layout'),
    Avatar: Utils.loadComponent('proxy/Avatar'),
  },
  name: 'CircleStatus',
})
export default class CircleStatus extends Vue {
  @Prop({type: String, default: () => 'grey'}) private color!: string
}
