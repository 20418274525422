
































import {Component, Prop, Vue} from 'vue-property-decorator'
import {Action, State} from 'vuex-class'
import CloseButton from '../components/CloseButton.vue'

import Button from '@/components/Button.vue'
import CustomIcon from '@/components/CustomIcon.vue'
import SelectTag from '@/components/proxy/Inputs/SelectTag.vue'
import Card from '@/components/proxy/Card/Card.vue'

@Component({
  components: {
    Card,
    SelectTag,
    CustomIcon,
    Button,
    CloseButton,
  },
  name: 'ChangeLangDialog',
})
export default class ChangeLangDialog extends Vue {
  @Action('setLang') public setLang!: any
  @State((state) => state.profile.language) public profileLang!: string
  @Prop({type: Boolean, default: false}) public embedded!: boolean

  public lang: string = ''
  public tempLang: string = ''

  public get confirmButton() {
    return this.$t('action.proceed')
  }

  protected get available() {
    return this.$langAvailable()
  }

  public confirm() {
    this.setLang(this.tempLang)
    this.$langLoader(this.tempLang)
    this.$dialog.close()
  }

  public langExceptions(lang: string) {
    if (lang === 'us') {
      lang = 'en'
    } else if (lang === 'srd') {
      lang = 'sc'
    }
    return lang
  }

  public changeLang(lang: string) {
    this.tempLang = this.langExceptions(lang)
  }

  public changeLangImmediate(lang: string) {
    const l = this.langExceptions(lang)

    this.setLang(l)
    this.$langLoader(l)
  }

  protected created() {
    const profileLang = this.profileLang || this.$currentLang()
    if (profileLang === 'en') {
      this.lang = 'us'
    } else if (profileLang === 'sc') {
      this.lang = 'srd'
    } else {
      this.lang = profileLang
    }
    this.changeLang(this.lang)
  }
}
