







import {Component, Prop} from 'vue-property-decorator'
import {SubscriptionPlan} from '@/lib/kepler/interfaces'
import Utils from '@/utils'
import CloseButton from '@/components/CloseButton.vue'
import {mixins} from 'vue-class-component'
import {PlanColorMixin} from '@/lib/vuetify/VuetifyColorHelper'

@Component({
  components: {
    CloseButton: Utils.loadComponent('CloseButton'),
    PlanDetail: Utils.loadView('PlanDetail'),
    Card: Utils.loadComponent('proxy/Card/Card'),
  },
  mixins: [PlanColorMixin],
  name: 'PlanDetailDialog',
})
export default class PlanDetailDialog extends mixins<PlanColorMixin>(PlanColorMixin) {
  @Prop({type: Object}) private plan!: SubscriptionPlan
  @Prop({type: Boolean, default: false}) private hideCta!: boolean

  protected get isLight() {
    return this.getPlanIsLight(this.plan)
  }
}
