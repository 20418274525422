





















import {Component, Prop, Vue} from 'vue-property-decorator'
import {Action} from 'vuex-class'
import Utils from '@/utils'

import ResetPasswordView from '@/views/ResetPassword.vue'
import LoginByPhone from '@/views/LoginByPhone.vue'

@Component({
  components: {
    Img: Utils.loadComponent('proxy/Image'),
    Button: Utils.loadComponent('Button'),
    CloseButton: Utils.loadComponent('CloseButton'),
    Sheet: Utils.loadComponent('proxy/Sheet'),
  },
  name: 'AlreadyHasAccount',
})
export default class AlreadyHasAccount extends Vue {
  @Action('closeOverlays') public closeOverlays!: () => void

  @Prop() protected confirmCallback!: () => void

  public closeAndResetPassword() {
    this.closeOverlays()
    this.$popup.open(ResetPasswordView, {title: this.$t('login.reset_your_password')})
  }

  public closeAndLoginBySMS() {
    this.closeOverlays()
    this.$popup.open(LoginByPhone, {title: this.$t('login.login_by_phone')})
  }
}
