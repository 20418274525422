
import {Component, Vue} from 'vue-property-decorator'

import { VDataTable } from 'vuetify/lib'

@Component({
  name: 'DataTable',
  extends: VDataTable as any,
})
export default class DataTable extends Vue {
}
