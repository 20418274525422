import AbstractHardware from '@/lib/hardware/classes/AbstractHardware'

import Dunasys from '@/lib/BtSDKs/Dunasys'

interface DunasysConf {
  device: string,
  commands: any,
}

export default class DunasysHardware extends AbstractHardware {
  private dunasysConf : DunasysConf

  constructor(conf: DunasysConf) {
    super(conf)
    this.dunasysConf = conf
  }

  public init(statusCB: (msg: string) => void) {
    if (window.cordova.platformId === 'browser') {
      return Promise.reject('browser is not supported on dunasys hardware')
    }

    statusCB('Launching dunasys init...')
    statusCB('DUNASYS DEVICE: '+this.dunasysConf.device)

    let manager = new Dunasys(statusCB, this.dunasysConf.device, this.dunasysConf.commands)

    return Promise.resolve()
      .then(() => {
        this.addAction('open', () => new Promise((resolve, reject) => {
          manager.unlock().then(resolve).catch(reject)
        }), 'ble')
        this.addAction('close', () => new Promise((resolve, reject) => {
          manager.lock().then(resolve).catch(reject)
        }), 'ble')
      })
  }
}
