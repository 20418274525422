








import {Component, Prop, Vue} from 'vue-property-decorator'

@Component({
  name: 'IllustrationBox',
})
export default class IllustrationBox extends Vue {

  @Prop() public position!: string

  get style() {
    return `${this.position}:0`
  }
}
