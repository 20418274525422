import {ActionTree, MutationTree} from 'vuex'
import sdk from '@/lib/kepler/sdk'
import {
  Wallet,
  Invoice,
  PaymentMethod,
  Card,
  InvoicePay,
  WalletRecord,
  ChangePaymentMethod,
  Deposit,
  AvailablePaymentMethod,
  Packet,
  ActivePacket,
  RecordRequest,
} from '@/lib/kepler/interfaces'
import {RootState} from '@/store'
import {EventBus} from '@/main'
import {AxiosResponse} from 'axios'
import {Vue} from 'vue-property-decorator'
import DateHelper from '@/lib/DateHelper'

export interface PaginatedByMonth<T> {
  [month: string]: T[]
}

export class WalletState {
  public wallets?: Wallet[] = undefined
  public invoices?: any = {}
  public deposits?: any = {}
  public paymentMethods?: PaymentMethod[] = undefined
  public paymentMethodSelected?: PaymentMethod = undefined
  public dateOverview?: string = undefined
  public dateInvoices?: string = undefined
  public dateDeposits?: string = undefined
  public packets?: Packet[] = undefined
  public activePackets?: ActivePacket[] = undefined
}

const mutations: MutationTree<WalletState> = {
  WALLETS(stateW, payload) {
    stateW.wallets = payload
  },
  UPDATE_WALLET(stateW, payload: Wallet) {
    if (stateW.wallets) {
      const i = stateW.wallets.findIndex((wallet) => {
        return wallet.id === payload.id
      })
      Vue.set(stateW.wallets, i, payload)
    } else {
      stateW.wallets = [payload]
    }
  },
  RECORDS(stateW, payload) {
    if (stateW.wallets) {
      const wallets: Wallet[] = stateW.wallets
      const month: string = payload.month || DateHelper.now('YYYY-MM')
      if (wallets && wallets.length) {
        const i = wallets.findIndex((w) => {
          if (!w.records) {
            Vue.set(w, 'records', {})
          }
          return w.id === payload.id
        })
        // wallets[i].records = {[month] : payload.data}
        Vue.set(wallets[i].records, month, {[payload.account_type]: payload.data})
      }
    }
  },
  INVOICES(stateW, payload) {
    stateW.invoices = Object.assign({}, stateW.invoices, {[payload.month]: payload.data})
  },
  DEPOSITS(stateW, payload) {
    stateW.deposits = Object.assign({}, stateW.deposits, {[payload.month]: payload.data})
  },
  UPDATE_INVOICE(stateW, payload: Invoice) {
    if (stateW.invoices !== undefined) {
      let index
      const key = stateW.dateInvoices as string
      stateW.invoices[key].forEach((invoice: Invoice, i: number) => {
        if (invoice.id === payload.id) {
          index = i
        }
      })

      if (index) {
        stateW.invoices[key][index] = payload
      }
    }
  },
  PAYMENT_METHODS(stateW, payload) {
    stateW.paymentMethods = payload
  },
  ADD_PAYMENT_METHOD(stateW, payload) {
    if (stateW.paymentMethods === undefined) {
      stateW.paymentMethods = []
    }
    stateW.paymentMethods.push(payload)
  },
  SELECT_PAYMENT_METHOD(stateW, payload) {
    stateW.paymentMethodSelected = payload
  },
  PURGE_INVOICES(stateW) {
    stateW.invoices = {}
  },
  PURGE_DEPOSITS(stateW) {
    stateW.deposits = {}
  },
  PURGE_WALLETS(stateW) {
    stateW.wallets = undefined
    stateW.dateOverview = undefined
    stateW.dateOverview = undefined
    stateW.invoices = {}
    stateW.deposits = {}
  },
  PURGE_PAYMENT_METHODS(stateW) {
    stateW.paymentMethods = undefined
  },
  DATE_OVERVIEW(stateW, date?: string) {
    stateW.dateOverview = date || DateHelper.now('YYYY-MM')
  },
  DATE_INVOICES(stateW, date?: string) {
    stateW.dateInvoices = date || DateHelper.now('YYYY-MM')
  },
  DATE_DEPOSITS(stateW, date?: string) {
    stateW.dateDeposits = date || DateHelper.now('YYYY-MM')
  },
  UPDATE_PACKETS(stateW, packets: Packet[]) {
    stateW.packets = packets
  },
  UPDATE_ACTIVE_PACKETS(stateW, packets: ActivePacket[]) {
    stateW.activePackets = packets
  },
}

const actions: ActionTree<WalletState, RootState> = {
  purge({commit}) {
    commit('PURGE_INVOICES')
    commit('PURGE_DEPOSITS')
    commit('PURGE_WALLETS')
    commit('PURGE_PAYMENT_METHODS')
  },
  getWallets({commit, dispatch}) {
    return sdk.billing.wallets()
      .then((r: AxiosResponse<Wallet[]>) => {
        commit('WALLETS', r.data)
        dispatch('sleep', 'wallets')
        EventBus.$emit('wallets')
        return r.data
        // r.data.forEach((wallet: Wallet) => {
        //   dispatch('getRecords', {
        //     id: wallet.id,
        //     accountType: wallet.payment_method,
        //     month,
        //   })
        // })
      })
  },
  getRecords({commit}, {id, account_type, month}: RecordRequest) {
    return sdk.billing.records(id, account_type, month)
      .then((r: AxiosResponse<[WalletRecord]>) => {
        const payload = {
          id,
          account_type,
          month,
          data: r.data,
        }
        commit('RECORDS', payload)
        return r
      })
  },
  getInvoices({commit, dispatch}, month) {
    return sdk.billing.invoices_list(month)
      .then((r: AxiosResponse<Invoice[]>) => {
        commit('INVOICES', {data: r.data, month})
        dispatch('sleep', 'invoices')
      })
  },
  getDeposits({commit, dispatch}, month) {
    return sdk.billing.deposits(month)
      .then((r: AxiosResponse<Deposit[]>) => {
        commit('DEPOSITS', {data: r.data, month})
        dispatch('sleep', 'deposits')
      })
  },
  payInvoice({commit, dispatch}, invoiceId: string, payload?: InvoicePay) {
    return sdk.billing.invoice_pay(invoiceId, payload)
      .then((r: AxiosResponse<Invoice>) => {
        commit('UPDATE_INVOICE', r.data)
        dispatch('sleep', 'pay_invoice')
      })
  },
  getPaymentMethods({commit, dispatch}) {
    return sdk.billing.payment_methods()
      .then((r: AxiosResponse<PaymentMethod[]>) => {
        commit('PAYMENT_METHODS', r.data)
        dispatch('sleep', 'payment_methods')
        EventBus.$emit('payment_methods')
        return r.data
      })
  },
  getAvailablePaymentMethods() {
    return sdk.billing.payment_methods_available()
      .then((r: AxiosResponse<AvailablePaymentMethod[]>) => {
        return r.data
      })
  },
  getPaymentMethodUrl({}, provider: string) {
    return sdk.billing.payment_method_url(provider)
      .then((r: AxiosResponse<string>) => {
        return r.data
      })
  },
  addPaymentMethod({commit, dispatch}, payload: Card) {
    return sdk.billing.payment_method_add(payload)
      .then((r: AxiosResponse<Card>) => {
        commit('ADD_PAYMENT_METHOD', r.data)
        dispatch('sleep', 'add_payment_method')
        EventBus.$emit('payment_methods')
      })
  },
  setDefaultPaymentMethod({commit, dispatch}, payload: ChangePaymentMethod) {
    return sdk.billing.change_payment_method(payload)
      .then((r: AxiosResponse<[Wallet]>) => {
          commit('UPDATE_WALLET', r.data)
          dispatch('sleep', 'update_wallet')
          EventBus.$emit('updated_payment_method')
        },
      )
  },
  deletePaymentMethod({commit, dispatch}, id: string) {
    return sdk.billing.payment_method_delete({payment_method_id: id})
      .then((r: AxiosResponse<[PaymentMethod]>) => {
          commit('PAYMENT_METHODS', r.data)
          dispatch('sleep', 'payment_methods')
          EventBus.$emit('deleted_payment_method')
        },
      )
  },
  selectPaymentMethod({commit}, payload: PaymentMethod) {
    return commit('SELECT_PAYMENT_METHOD', payload)
  },
  setDateOverview({commit}, date?: string) {
    commit('DATE_OVERVIEW', date)
  },
  setDateInvoices({commit}, date?: string) {
    commit('DATE_INVOICES', date)
  },
  setDateDeposits({commit}, date?: string) {
    commit('DATE_DEPOSITS', date)
  },
  getPackets({commit}) {
    return sdk.billing.packets.get().then((r) => {
      commit('UPDATE_PACKETS', r.data)
      return r.data
    })
  },
  // getActivePackets({commit}) { // TODO: show active packets somewhere
  //   return sdk.billing.packets.active().then((r) => {
  //     commit('UPDATE_ACTIVE_PACKETS', r.data)
  //     return r.data
  //   })
  // },
}

const getters = {
  defaultWallet: (state: WalletState) => {
    return state.wallets?.find((w) => w.default)
  },
  defaultPaymentMethodId: (state: WalletState) => {
    const defWallet = state.wallets?.find((w) => w.default)
    return defWallet?.payment_method?.id || null
  },
}

export default {
  state: new WalletState(),
  mutations,
  actions,
  getters,
}
