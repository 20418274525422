interface Status {
  authorized: boolean
  canChangeCamera: boolean
  canEnableLight: boolean
  canOpenSettings: boolean
  currentCamera: number
  denied: boolean
  lightEnabled: boolean
  prepared: boolean
  previewing: boolean
  restricted: boolean
  scanning: boolean
  showing: boolean
}

enum QRScannerErrorName {
  UNEXPECTED_ERROR,
  CAMERA_ACCESS_DENIED,
  CAMERA_ACCESS_RESTRICTED,
  BACK_CAMERA_UNAVAILABLE,
  FRONT_CAMERA_UNAVAILABLE,
  CAMERA_UNAVAILABLE,
  SCAN_CANCELED,
  LIGHT_UNAVAILABLE,
  OPEN_SETTINGS_UNAVAILABLE,
}

interface QRScannerError extends Error {
  name: keyof typeof QRScannerErrorName
}

export default class QrScanner {
  public status!: Status
  private readonly scanner: any

  public constructor() {
    this.scanner = (window as any).QRScanner
  }

  public prep(): Promise<Status> {
    return new Promise((resolve, reject) => {
      if (!this.scanner) {
        reject('no QRScanner present')
      }
      this.scanner.prepare((err: QRScannerError, status: Status) => {
        this.status = status
        if (err && !status) {
          reject(err)
        }
        resolve(this.status)
      })
    })
  }

  public destroy() {
    this.scanner.destroy()
  }

  public scan(scanFunction: (err: any, val: any) => void) {
    this.scanner.scan(scanFunction)
  }

  public cancelScan() {
    this.scanner.cancelScan()
  }

  public show() {
    this.scanner.show()
  }

  public hide() {
    this.scanner.hide()
  }

  public pausePreview() {
    this.scanner.pausePreview()
  }

  public resumePreview() {
    this.scanner.resumePreview()
  }

  public toggleLight() {
    switch (this.status.lightEnabled) {
      case true:
        this.scanner.disableLight()
        break
      case false:
        this.scanner.enableLight()
        break
    }
    this.updateStatus()
  }

  public enableLight() {
    this.scanner.enableLight()
  }

  public disableLight() {
    this.scanner.disableLight()
  }

  public useCamera() {
    this.scanner.useCamera()
  }

  public useFrontCamera() {
    this.scanner.useFrontCamera()
  }

  public useBackCamera() {
    this.scanner.useBackCamera()
  }

  public openSettings() {
    this.scanner.openSettings()
  }

  public updateStatus() {
    this.scanner.getStatus((status: Status) => {
      this.status = status
    })
  }
}
