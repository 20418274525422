








import {Component, Prop, Vue, Watch} from 'vue-property-decorator'
import {DamagePosition} from '../lib/kepler/interfaces'

@Component({
  components: {},
  name: 'DamagePoints',
})
export default class DamagePoints extends Vue {
  @Prop() protected points!: DamagePoints[]
}
