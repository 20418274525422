




import {Component, Vue, Prop} from 'vue-property-decorator'

@Component({
  name: 'HelpButton',
})
export default class HelpButton extends Vue {
}
