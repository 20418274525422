import {BleDevice} from "@/lib/BtSDKs/BleDevice";

export default class InversCloudBoxx extends BleDevice {
  private SERVICE_CAR_CONTROL = '869CEF80-B058-11E4-AB27-00025B03E1F4'
  private CHARACTERISTIC_AUTHORIZE_PHONE = '869CEF82-B058-11E4-AB27-00025B03E1F4'
  private CHARACTERISTIC_COMMAND_PHONE = '869CEF84-B058-11E4-AB27-00025B03E1F4'

  private SERVICE_CAR_INFO = '869CEFA0-B058-11E4-AB27-00025B03E1F4'
  private CHARACTERISTIC_COMMAND_CHALLENGE = '869CEFA2-B058-11E4-AB27-00025B03E1F4'
  private CHARACTERISTIC_DRIVING_INFORMATION_1 = '869CEFA3-B058-11E4-AB27-00025B03E1F4'
  private CHARACTERISTIC_STATUS_1 = '869CEFA5-B058-11E4-AB27-00025B03E1F4'
  private CHARACTERISTIC_CARD_MONITORING = '869CEFAA-B058-11E4-AB27-00025B03E1F4'

  private device: string = ""
  private sessionKey: string = ""
  private token: string = ""

  constructor(logger: any, device: string, sessionKey: string, token: string) {
    super(logger)
    this.device = device
    this.sessionKey = atob(sessionKey)
    this.token = atob(token)
    this.log("Initialized class for " + device)
  }

  isCorrectDevice(name: string, address: string, mode: string, data: any) {
    return name == this.device
  }

  getTokenChunks() {
    this.log(this.token)
    var input = this.stringToByte(this.token)

    const bag = []
    while (input.length > 0) {
      bag.push([(input.length & 0xFF), ((input.length >> 8) & 0xFF), ...input.slice(0, 18)])
      input = input.slice(18, input.length)
    }
    return bag
  }

  private initStatus: string = "UNKNOWN"

  init() {
    return new Promise<void>((resolve, reject) => {
      if (this.initStatus == "INITIALIZED") {
        this.isConnected().then(resolve).catch(()=>{
          this.initStatus = "UNKNOWN"
          this.init().then(resolve).catch(reject)
        })
      } else if (this.initStatus == "INITIALIZING") {
        reject("INIT_ALREADY_IN_PROGRESS")
      } else {
        this.initStatus = "INITIALIZING"
        this.scan().then(() => {
          this.connect().then(() => {
            this.log("Connected!")
            this.writeChunks(this.SERVICE_CAR_CONTROL, this.CHARACTERISTIC_AUTHORIZE_PHONE, this.getTokenChunks()).then(() => {
              this.initStatus = "INITIALIZED"
              resolve()
            }).catch(reject)
          }).catch(reject)
        }).catch(reject)
      }
    })

  }

  sendCommand(cmd: number) {
    return new Promise<void>((resolve, reject) => {
      this.init().then(() => {
        this.log("Requesting challenge...")
        this.read(this.SERVICE_CAR_INFO, this.CHARACTERISTIC_COMMAND_CHALLENGE).then((commandChallenge) => {
          //this.logJson(["gotChallenge", commandChallenge])

          const command = []
          command.push(...[cmd, 0, 0, 0, 0, 0, 0, 0, 0, 0]) // Expanded to 10 bytes

          const valueCommand = []
          valueCommand.push(...command, ...commandChallenge)

          const responseHMAC = this.base64ToByte(this.b64_hmac_sha1(this.sessionKey, this.intArrayToString(valueCommand)))

          const result = []
          result.push(...command, ...responseHMAC.slice(0, 10))

          this.write(this.SERVICE_CAR_CONTROL, this.CHARACTERISTIC_COMMAND_PHONE, result).then(resolve).catch(reject)
        }).catch(reject)
      }).catch(reject)
    })
  }

  unlock() {
    return this.sendCommand(0x02 + 0x08)
  }

  lock() {
    return new Promise<void>((resolve, reject) => {
      this.init().then(() => {
        this.read(this.SERVICE_CAR_INFO, this.CHARACTERISTIC_STATUS_1).then((rawData) => {
          if (rawData[0] == 0x02) {
            reject("IGNITION_ON")
          } else {
            this.read(this.SERVICE_CAR_INFO, this.CHARACTERISTIC_CARD_MONITORING).then((rawData) => {
              if (rawData[0] == 0x01) {
                reject("KEY_NOT_DETECTED")
              } else {
                this.sendCommand(0x01 + 0x04).then(resolve).catch(reject)
              }
            }).catch(reject)
          }
        }).catch(reject)
      }).catch(reject)
    })

  }

  data() {
    //Promise.all([
    //  this.read(this.SERVICE_CAR_INFO, this.CHARACTERISTIC_DRIVING_INFORMATION_1),
    //  this.read(this.SERVICE_CAR_INFO, this.CHARACTERISTIC_STATUS_1)
    //]).then((xy)=>{
    //  this.logJson(["x",xy])
    //})
    this.read(this.SERVICE_CAR_INFO, this.CHARACTERISTIC_STATUS_1).then((rawData) => {
      this.logJson({
        ignition: rawData[0] == 0x02 ? "running" : "not_running"
      })
    })
  }

  b64_hmac_sha1(k: string, d: string): string {
    // heavily optimized and compressed version of http://pajhome.org.uk/crypt/md5/sha1.js
    // _p = b64pad, _z = character size; not used here but I left them available just in case

    const _p = '='

    const _z = 8

    function _f(t: number, b: number, c: number, d: number) {
      if (t < 20) {
        return (b & c) | ((~b) & d)
      }
      if (t < 40) {
        return b ^ c ^ d
      }
      if (t < 60) {
        return (b & c) | (b & d) | (c & d)
      }
      return b ^ c ^ d
    }

    function _k(t: number) {
      return (t < 20) ? 1518500249 : (t < 40) ? 1859775393 : (t < 60) ? -1894007588 : -899497514
    }

    function _s(x: number, y: number) {
      const l = (x & 0xFFFF) + (y & 0xFFFF), m = (x >> 16) + (y >> 16) + (l >> 16)
      return (m << 16) | (l & 0xFFFF)
    }

    function _r(n: number, c: number) {
      return (n << c) | (n >>> (32 - c))
    }

    function _c(x: number[], l: number) {
      x[l >> 5] |= 0x80 << (24 - l % 32)
      x[((l + 64 >> 9) << 4) + 15] = l
      const w = [80]
      let a = 1732584193, b = -271733879, c = -1732584194, d = 271733878, e = -1009589776
      for (let i = 0; i < x.length; i += 16) {
        const o = a, p = b, q = c, r = d, s = e
        for (let j = 0; j < 80; j++) {
          if (j < 16) {
            w[j] = x[i + j]
          } else {
            w[j] = _r(w[j - 3] ^ w[j - 8] ^ w[j - 14] ^ w[j - 16], 1)
          }
          const t = _s(_s(_r(a, 5), _f(j, b, c, d)), _s(_s(e, w[j]), _k(j)))
          e = d
          d = c
          c = _r(b, 30)
          b = a
          a = t
        }
        a = _s(a, o)
        b = _s(b, p)
        c = _s(c, q)
        d = _s(d, r)
        e = _s(e, s)
      }
      return [a, b, c, d, e]
    }

    function _b(s: string) {
      const b: number[] = [], m = (1 << _z) - 1
      for (let i = 0; i < s.length * _z; i += _z) {
        b[i >> 5] |= (s.charCodeAt(i / 8) & m) << (32 - _z - i % 32)
      }
      return b
    }

    function _h(k: string, d: string) {
      let b = _b(k)
      if (b.length > 16) {
        b = _c(b, k.length * _z)
      }
      const p = [16], o = [16]
      for (let i = 0; i < 16; i++) {
        p[i] = b[i] ^ 0x36363636
        o[i] = b[i] ^ 0x5C5C5C5C
      }
      const h = _c(p.concat(_b(d)), 512 + d.length * _z)
      return _c(o.concat(h), 512 + 160)
    }

    function _n(b: number[]) {
      const t = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/'
      let s = ''
      for (let i = 0; i < b.length * 4; i += 3) {
        const r = (((b[i >> 2] >> 8 * (3 - i % 4)) & 0xFF) << 16) | (((b[i + 1 >> 2] >> 8 * (3 - (i + 1) % 4)) & 0xFF) << 8) | ((b[i + 2 >> 2] >> 8 * (3 - (i + 2) % 4)) & 0xFF)
        for (let j = 0; j < 4; j++) {
          if (i * 8 + j * 6 > b.length * 32) {
            s += _p
          } else {
            s += t.charAt((r >> 6 * (3 - j)) & 0x3F)
          }
        }
      }
      return s
    }

    function _x(k: string, d: string) {
      return _n(_h(k, d))
    }

    return _x(k, d)
  }
}
