
import {Component, Vue} from 'vue-property-decorator'

import { VBadge } from 'vuetify/lib'

@Component({
  name: 'Badge',
  extends: VBadge as any,
})
export default class Badge extends Vue {

}
