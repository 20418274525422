


















import {Vue, Component} from 'vue-property-decorator'
import {State} from 'vuex-class'
import Utils from '@/utils'
import {BookingState} from '@/store/modules/booking'
import {ProfileState} from '@/store/modules/profile'
import DateHelper from '@/lib/DateHelper'
import moment from 'moment'
import sdk from '@/lib/kepler/sdk'
import {EventBus} from '@/main'

import LoginView from '@/views/Login.vue'

@Component({
  components: {
    GradientCard: Utils.loadComponent('GradientCard'),
    Container: Utils.loadComponent('proxy/Container'),
    Layout: Utils.loadComponent('proxy/Layout'),
    LightActivitiesCalendar: Utils.loadView('LightActivitiesCalendar'),
    Button: Utils.loadComponent('Button'),
    LoopingBG: Utils.loadComponent('LoopingBG'),
  },
})

export default class LightActivities extends Vue {
  @State((state) => state.booking) public bookingState!: BookingState
  @State((state) => state.profile) public profileState!: ProfileState
  @State((state) => state.booking.fakeTerminatedReservations) public markedReservations!: number[]

  protected isLogged: boolean = false

  protected get futures() {
    return this.bookingState.futureReservations.filter((res) => {
      const start = res.start_timestamp
      if (start) {
        return start > DateHelper.getTimestamp()
      }
    })
  }

  protected get nextShift() {
    const startArr = this.futures.map((res) => {
      return res.start_timestamp || Infinity
    })
    const nextShiftDate = moment.unix(Math.min(...startArr))
    return nextShiftDate.isValid() ? moment().to(nextShiftDate) : false
  }

  protected checkLogin() {
    this.isLogged = sdk.people.isLogged()
    if (!this.isLogged) {
      this.$popup.open(LoginView, {title: this.$t('login.login'), hideTopbar: true})
    }
  }

  // lifecycle methods

  protected created() {
    EventBus.$on('login', this.checkLogin)
    this.checkLogin()
  }

  protected beforeDestroy(): void {
    EventBus.$off('login', this.checkLogin)
  }

  protected activated() {
    this.checkLogin()
  }
}
