import { BleDevice } from "./BleDevice";

export default class OmniPersonalLock extends BleDevice {

  private SERV_DATA_UUID = '6e400001-b5a3-f393-e0a9-e50e24dcca9e'
  private CHAR_DATA_WRITE_UUID = '6e400002-b5a3-f393-e0a9-e50e24dcca9e'
  private CHAR_DATA_NOTIFY_UUID = '6e400003-b5a3-f393-e0a9-e50e24dcca9e'

  private mac: string = ""

  private key: number[] = []

  private seed: number[] = [0x61, 0x66, 0x6B, 0x33, 0x74, 0x79, 0x73, 0x77, 0x34, 0x70, 0x73, 0x6B, 0x32, 0x36, 0x68, 0x6A]

  private CMD_VERIFY_KEY = 0x01
  private CMD_CLEAR_KEY = 0x02
  private CMD_CONTROL1 = 0x05
  private CMD_CONTROL2 = 0x06

  private randomNumber = 0

  private deviceStatus: any = {
    key: null,
    auth: null,
    comm: null,
    unlockMode: null,
    lockStatus: null
  }

  public basePin = ""

  public statusLogger = (a: any) => {}

  constructor(logger: any, mac: string, pin: string, statusLogger: any) {
    super(logger)
    this.mac = mac
    this.basePin = pin
    this.statusLogger = statusLogger
    this.log("Initialized class for " + mac)
  }

  isCorrectDevice(name: string, address: string, mode: string, data: any) {
    if (name != "OBL36") {
      return false
    }
    switch (mode) {
      case "manifacturer":
        return data.toUpperCase() == this.mac.replace(/:/g, "").toUpperCase()
      case "advertisement":
        return address.replace(/:/g, "").toUpperCase() == this.mac.replace(/:/g, "").toUpperCase()
    }
    return false
  }

  encryptData(sendData: number[]) {
    // Generate keys
    var keyLib: any = []
    var idx = 0
    for (let k = 0; k < 4; k++) {
      for (let i = 0; i < 4; i++) {
        for (let j = 0; j < this.seed.length; j++) {
          keyLib[idx] = ((sendData[i] + 0x30 + k) ^ this.seed[j])
          idx++;
        }
      }
    }
    if (true || this.deviceStatus.key == "ON") {
      // Encrypt
      var encryData: any = []

      for (let i = 0, start = this.randomNumber; i < sendData.length; i++) {
        encryData[i] = sendData[i] ^ keyLib[start]
        start++
      }
    } else {
      var encryData: any = sendData
      for (let i = 0; i < 4; i++) {
        encryData[i] = encryData[i] ^ this.seed[i];
      }
    }
    return encryData
  }

  // Format: 0xAB+0xDE+LEN+randomNum+cmd+DATA+CRC
  forgeCommand(cmd: number, data: number[]) {
    let allData: number[] = []
    allData = allData.concat([this.randomNumber]) // randomNum
    allData = allData.concat([cmd]) // cmd
    allData = allData.concat(data) // data

    let result: number[] = []
    result = result.concat([0xAB, 0xDE, allData.length + 1])
    result = result.concat(allData) // data
    result = result.concat([this.crc(allData)]) // crc
    return result
  }

  heartbeat() {
    this.logWrite("HEARTBEAT", this.SERV_DATA_UUID, this.CHAR_DATA_WRITE_UUID, [0xAB, 0xDE, 0x03, 0x07, 0x00, 0x07])
  }

  auth() {
    this.key = []
    this.basePin.split("").map((i) => {
      this.key.push(parseInt(i, 10))
    })
    //this.key = this.stringToByte("1234")
    this.logJson(this.intToHex(this.key).join(" "))
    return this.logWrite("AUTH", this.SERV_DATA_UUID, this.CHAR_DATA_WRITE_UUID, this.forgeCommand(this.CMD_VERIFY_KEY, this.encryptData(this.key)))
  }

  unlock() {
    return this.logWrite("UNLOCK", this.SERV_DATA_UUID, this.CHAR_DATA_WRITE_UUID, this.forgeCommand(this.CMD_CONTROL1, this.encryptData([0x80])))
  }

  clearPassword() {
    return this.logWrite("CLEAR", this.SERV_DATA_UUID, this.CHAR_DATA_WRITE_UUID, this.forgeCommand(this.CMD_CLEAR_KEY, this.encryptData(this.key)))
  }

  crc(data: number[]) {
    let crccheck = 0
    data.forEach((i) => {
      crccheck += i
    })
    return crccheck & 0xff
  }

  private heartbeatInterval: any = null

  public disconnect(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.unsubscribe(this.SERV_DATA_UUID, this.CHAR_DATA_NOTIFY_UUID).then(()=>{
        if (this.heartbeatInterval != null) {
          clearInterval(this.heartbeatInterval)
          this.heartbeatInterval = null
        }
        super.disconnect().then(resolve).catch(reject)
      })
    })
  }

  private initialized = false

  init() {
    var messageCount = 0
    //this.setAddress("E1:0D:65:29:9A:C2")
    return new Promise<void>((resolve, reject) => {
      this.deviceStatus.key = "?"
      this.statusLogger(this.deviceStatus)
      this.scanAndConnect().then(() => {
        this.log("connected")
        this.subscribe(this.SERV_DATA_UUID, this.CHAR_DATA_NOTIFY_UUID, (d) => {
          let data = d.slice(3, 2 + d[2])
          this.randomNumber = data[data.length - 2]
          messageCount++
          if (messageCount == 2) {
            this.auth()
          }
          //this.logJson({
          //  "KEY": B2bin[4] == 1 ? "ON" : "OFF",
          //  "AUTH": B2bin[6] == 1 ? "OK" : "KO",
          //  "COMM": B2bin[7] == 1 ? "OK" : "KO",
          //})
          //this.logJson({
          //  //"RING": B3bin[0] == 1 ? "ON" : "OFF",
          //  //"MOTOR": B3bin[1] == 1 ? "ON" : "OFF",
          //  //"LIGHT": B3bin[2] == 1 ? "ON" : "OFF",
          //  "UNLOCK_MODE": B3bin[5] == 1 ? "BleButton" : "BLE",
          //  //"ANTI_TEFTH": B3bin[6] == 1 ? "AUTO" : "MANUAL",
          //  "LOCK_STATUS": B3bin[7] == 1 ? "CLOSE" : "OPEN",
          //})
          this.logJson([
            "DATA-IN",
            this.intToHex(d).join(" "),
            //"DATALEN " + this.intToHex([d[2]]).join(""),
            //"DATA " + this.intToHex(d.slice(3, 2 + d[2])).join(" "),
            //"CRC " + this.intToHex([d[d.length - 1]]).join(""),
            //"CRCCHECK " + this.intToHex([this.crc(data)]).join(""),
          ])
          let B2bin = this.byteToBitArray(data[1])
          this.deviceStatus.key = B2bin[4] == 1 ? "ON" : "OFF"
          this.deviceStatus.auth = B2bin[6] == 1 ? "OK" : "KO"
          this.deviceStatus.comm = B2bin[7] == 1 ? "OK" : "KO"
          let B3bin = this.byteToBitArray(data[2])
          this.deviceStatus.unlockMode = B3bin[5] == 1 ? "BleButton" : "BLE"
          this.deviceStatus.lockStatus = B3bin[7] == 1 ? "CLOSE" : "OPEN"

          this.deviceStatus.time = Date.now()

          this.statusLogger(this.deviceStatus)

          if (this.deviceStatus.comm == "OK" && !this.initialized) {
            this.initialized = true
            resolve()
          }

        }).then(() => {
          this.log("subscribed")
           this.heartbeatInterval = setInterval(() => {
             this.heartbeat()
           }, 3000);
        }).catch(reject)
      
      })
    })
  }
}
