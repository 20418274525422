













import {Component, Prop} from 'vue-property-decorator'
import {mixins} from 'vue-class-component'
import Card from './proxy/Card/Card.vue'
import VuetifyColorHelper, {CustomColorableMixin} from '@/lib/vuetify/VuetifyColorHelper'

@Component({
  components: {Card},
  mixins: [CustomColorableMixin],
})
export default class ColorCard extends mixins<CustomColorableMixin>(CustomColorableMixin) {
  @Prop({type: String, default: () => ''}) protected bg!: string
  @Prop({type: Boolean, default: false}) protected ripple!: boolean
  @Prop({type: Boolean, default: true}) protected flat!: boolean

  protected get customStyle() {
    const arr: Array<Record<string, string>> = []
    if (this.bg) {
      const backgroundColor = this.color(this.bg) || 'transparent'
      arr.push({backgroundColor})
    }
    if (this.customCss) {
      arr.push(this.customCss)
    }
    return arr
  }

  protected get isLight() {
    const bg = this.bg ? this.color(this.bg) : null
    if (this.customClasses?.includes('theme--dark')) {
      return false
    }
    if (this.customClasses?.includes('theme--light')) {
      return true
    }
    if (this.contrastOverride !== null) {
      return this.contrastOverride
    } else if (bg) {
      return VuetifyColorHelper.lightContrast(bg)
    }
    const el = this.$el as HTMLElement | undefined
    if (el) {
      return VuetifyColorHelper.lightContrast(el.style.backgroundColor)
    }
    return !this.$vuetify.dark
  }
}
