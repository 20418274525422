







import {Vue, Component, Prop} from 'vue-property-decorator'
import Utils from '@/utils'

@Component({
  components: {
    Alert: Utils.loadComponent('proxy/Alert'),
    Layout: Utils.loadComponent('proxy/Layout'),
    CustomIcon: Utils.loadComponent('CustomIcon'),
  },
  name: 'OfflineAlert',
})
export default class OfflineAlert extends Vue {
  @Prop({
    type: Boolean,
    default: true,
  }) public online!: boolean
}
