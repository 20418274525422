import {ActionTree, MutationTree} from 'vuex'
import sdk from '@/lib/kepler/sdk'
import {TocResponse} from '@/lib/kepler/interfaces'
import {RootState} from '@/store'

export class TermOfServicesState {

  public termsAt?: number = undefined
  public tocs?: TocResponse[] = []
}

const mutations: MutationTree<TermOfServicesState> = {
  SET_TOCS(tos, payload) {
    tos.tocs = []
    for (const toc of payload) {
      tos.tocs.push(toc)
    }
  },
}

const actions: ActionTree<TermOfServicesState, RootState> = {
  init({dispatch}) {
    dispatch('getTocs')
  },
  getTocs({commit, dispatch}) {
    sdk.tocs.getTocs().then((response) => {
      commit('SET_TOCS', response.data)
      dispatch('sleep', 'tocs')
    })
  },
  getToc({dispatch}, id: string) {
    return sdk.tocs.getSingleToc(id).then((response) => {
      return response.data
    })
  },
  signToc({dispatch}, id: string) {
    return sdk.tocs.signToc(id).then((response) => {
      dispatch('getTocs')
      return response.data
    })
  },
  unsignToc({dispatch}, id: string) {
    return sdk.tocs.unsignToc(id).then((response) => {
      dispatch('getTocs')
      return response.data
    })
  },
}

export default {
  state: new TermOfServicesState(),
  mutations,
  actions,
}
