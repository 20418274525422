



























import {Vue, Component, Prop, Watch} from 'vue-property-decorator'

import {VDatePicker, VTextField} from 'vuetify/lib'
import {State} from 'vuex-class'
import moment from 'moment'

@Component({
  components: {VDatePicker, VTextField},
  name: 'DateField',
})
export default class DateField extends Vue {
  @State((state) => state.profile.language) public lang!: string

  @Prop({
    type: [String, Object],
    default: '',
  }) public readonly rules: any

  @Prop({
    type: String,
    default: null,
  }) public readonly value!: string | null

  @Prop() public errors!: any[]
  @Prop() public valid!: boolean

  @Prop() public label!: string
  @Prop() public closeOnContentClick!: boolean
  @Prop() public lazy!: boolean
  @Prop() public disabled?: boolean
  @Prop() public fullWidth!: boolean
  @Prop() public birthdate!: boolean
  @Prop() public required!: boolean
  @Prop() public type?: string
  @Prop() public prependIcon?: string
  @Prop() public format?: (date: string) => string

  public innerValue: string | null = null
  public date: string | null = null
  public modal: boolean = false
  public min: string | null = null
  public max: string | null = null

  @Watch('modal')
  public onModalToggle(val: boolean) {
    if (val) {
      setTimeout(() => {
        return ((this.$refs.picker as any).activePicker = 'YEAR')
      })
    }
  }

  @Watch('date')
  public onDateChange(date: string) {
    this.$emit('input', date)
  }

  public created() {
    if (this.value) {
      this.innerValue = this.value
    }
  }

  // datepicker logic

  public parseValidation(r: any) {
    let min
    let max

    const splitPipes = (s: string) => {
      const obj: { [k: string]: string | boolean } = {}
      s.split('|').forEach((v) => {
        const kv = v.split(':')
        obj[kv[0]] = kv[1] || true
      })
      return obj
    }

    if (r) {
      if (typeof r === 'string') {
        r = splitPipes(r)
      }

      if (typeof r === 'object') {
        if (r.hasOwnProperty('after_or_equal')) {
          min = r.after_or_equal
        }
        if (r.hasOwnProperty('after')) {
          min = moment(r.after).add(1, 'd').format('YYYY-MM-DD')
        }
        if (r.hasOwnProperty('before_or_equal')) {
          max = r.before_or_equal
        }
        if (r.hasOwnProperty('before')) {
          max = moment(r.before).subtract(1, 'd').format('YYYY-MM-DD')
        }

        this.min = min
        this.max = max
      }
    }
  }

  public mounted() {
    this.date = this.value ? this.value : null
    this.parseValidation(this.rules)
  }

  public get dateFormatted() {
    if (this.date !== null && this.date) {
      if (this.format !== undefined) {
        return this.format(this.date)
      }
      return moment(this.date).format('L')
    }
    return undefined
  }

  public save(date: string) {
    const dialog = (this.$refs.dialog as any)
    this.date = date
    dialog.save(date)
  }
}
