
import {Component, Vue} from 'vue-property-decorator'
import {VRadioGroup} from 'vuetify/lib'

@Component({
  name: 'RadioGroup',
  extends: VRadioGroup as any,
})
export default class RadioGroup extends Vue {
}
