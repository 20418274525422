
















import {Component} from 'vue-property-decorator'
import {mixins} from 'vue-class-component'
import {State, Action} from 'vuex-class'
import {WalletState} from '@/store/modules/wallet'
import TabHistoryMixin from '@/lib/TabHistory'
import collect from 'collect.js'
import Utils from '@/utils'
import {Wallet} from '@/lib/kepler/interfaces'

@Component({
  components: {
    IsLogged: Utils.loadComponent('IsLogged'),
    Layout: Utils.loadComponent('proxy/Layout'),
    Tabs: Utils.loadComponent('proxy/Tabs/Tabs'),
    Tab: Utils.loadComponent('proxy/Tabs/Tab'),
    TabItems: Utils.loadComponent('proxy/Tabs/TabItems'),
    TabItem: Utils.loadComponent('proxy/Tabs/TabItem'),
    Overview: Utils.loadComponent('wallet/Overview'),
    Invoices: Utils.loadComponent('wallet/Invoices'),
    Deposits: Utils.loadComponent('wallet/Deposits'),
  },
  name: 'Wallet',
  mixins: [TabHistoryMixin],
})

export default class WalletView extends mixins<TabHistoryMixin>(TabHistoryMixin) {
  @State('wallet') public walletState!: WalletState

  @Action('getWallets') public getWallets!: () => Promise<Wallet[]>

  public currentTab: number | null = null
  public tabMap: { [key: string]: number } = {
    '#': 0,
    '#invoices': 1,
    '#deposits': 2,
  }

  protected sortedWallets: Wallet[] = []

  public created() {
    this.getWallets().then(() => {
      this.sortedWallets = collect(this.walletState.wallets).sortByDesc('default').all()
    })
  }
}
