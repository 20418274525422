export default class {
  static save(key: string, payload: any) {
    localStorage.setItem(key, JSON.stringify(payload))
  }

  static load(key: string) {
    const data = localStorage.getItem(key)
    if (data) {
      return JSON.parse(data)
    }
  }

  static delete(key: string) {
    localStorage.removeItem(key)
  }

  static restore(key: string, target: any) {
    const data = this.load(key)
    if (data) {
      Object.assign(target, data)
    }
  }

  static check(key: string) {
    return !!localStorage.getItem(key)
  }
}
