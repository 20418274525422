

















import {Vue, Component, Prop} from 'vue-property-decorator'
import Utils from '@/utils'
import ListTileContent from '@/components/proxy/List/ListTileContent.vue'
import RateOptionsDialog from '@/views/RateOptionsDialog.vue'

@Component({
  components: {
    ListTileContent,
    GradientCard: Utils.loadComponent('GradientCard'),
    List: Utils.loadComponent('proxy/List/List'),
    ListTile: Utils.loadComponent('proxy/List/ListTile'),
    ListTileTitle: Utils.loadComponent('proxy/List/ListTileTitle'),
    ListTileSubTitle: Utils.loadComponent('proxy/List/ListTileSubTitle'),
  },
  name: 'RateOptionsButton',
})

export default class RateOptionsButton extends Vue {
  @Prop({
    type: Boolean,
    required: false,
    default: false,
  }) public dialog!: boolean

  public openRateOptions() {
    if (this.dialog) {
      this.$dialog.open(RateOptionsDialog, {
        props: {
          title: 'Rate Options',
          subtitle: 'subtitle here',
        },
      })
    } else {
      this.$router.push({name: 'rateOptions'})
    }
  }
}
