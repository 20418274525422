





import {Component, Vue} from 'vue-property-decorator'
@Component({
  name: 'ToolbarTitle',
})
export default class ToolbarTitle extends Vue {
}
