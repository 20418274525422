





import {Vue, Component, Prop} from 'vue-property-decorator'
import Vch from '@/lib/vuetify/VuetifyColorHelper'

@Component({
  name: 'RangeButton',
})
export default class RangeButton extends Vue {
  @Prop({default: true}) public available!: boolean
  @Prop({default: false}) public selected!: boolean
  @Prop({default: () => '---'}) public label!: string | number
  @Prop({default: () => 'accent'}) public color!: string

  public get classes() {
    const classes: string[] = []
    if (!this.available) {
      classes.push('v-btn--disabled')
    }
    if (this.selected) {
      classes.push('selected')
    }
    const textClass = Vch.lightContrast(Vch.color(this.color)) ? 'black--text' : 'white--text'
    this.selected ? classes.push(this.color, textClass, 'font-weight-bold') : classes.push(this.color + '--text')
    return classes
  }
}
