
import {Component, Vue} from 'vue-property-decorator'
import {VWindow} from 'vuetify/lib'

@Component({
  name: 'Window',
  extends: VWindow as any,
})
export default class Window extends Vue {

}
