import {ResourceLoader} from '../utils/ResourceLoader'
import {Base} from './Base'
import {LatLng} from '../models/LatLng'
import {MarkerLabel} from '@/lib/n-maps/src/models/MarkerLabel'

// import * as Leaf from 'leaflet' // decomment together with line 13 and 86

export class Leaflet extends Base {

  public get L() {
    // return window.L
    return (window as any).L
  }

  public loadResources() {
    return [
      ResourceLoader('css', 'leaflet-map', 'https://unpkg.com/leaflet@1.7.1/dist/leaflet.css'),
      ResourceLoader('script', 'leaflet-map', 'https://unpkg.com/leaflet@1.7.1/dist/leaflet.js'),
      // https://beautifytools.com/sass-compiler.php
      ResourceLoader('css-inline', 'leaflet-map', '.leaflet-marker-icon.marker-cluster-disabled{ z-index: 99999 !important; } .leaflet-control-attribution.leaflet-control { font-size: 10px; } .leaflet-pane {z-index: 1;} .leaflet-marker-label {background: none;border: none;box-shadow: none;margin: 0;padding: 0;text-align: center;left: -0.5rem;width: 1em;} .leaflet-marker-label:before {content: none;}'),
    ]
  }

  public initMap(element: HTMLElement, initConfig: any, s: any): void {
    this.map = this.L.map(element, {
      zoomControl: false,
      minZoom: 4,
    }).setView([initConfig.center.lat, initConfig.center.lng], initConfig.zoom).setMaxZoom(this.config.tiles.opt.maxZoom ?? 20)
    this.L.tileLayer(this.config.tiles.url, this.config.tiles.opt).addTo(this.map)

    // this.L.addVectorTile('http://localhost:8989/mvt/{z}/{x}/{y}.mvt').addTo(this.map)

    // https://github.com/Leaflet/Leaflet.VectorGrid
    //ResourceLoader('script', 'leaflet-map-vector', 'https://unpkg.com/leaflet.vectorgrid@latest/dist/Leaflet.VectorGrid.bundled.js').then(() => {
    //url: 'http://localhost:8989/mvt/{z}/{x}/{y}.mvt'
    //let l = VectorTileLayer('http://localhost:8989/mvt/{z}/{x}/{y}.mvt')
    //l = VectorTileLayer('https://{s}.tiles-api.maps.komoot.net/v1/tiles/poi/{z}/{x}/{y}.vector.pbf')
    //l = VectorTileLayer('https://api.mapbox.com/v4/mapbox.mapbox-streets-v8,mapbox.mapbox-terrain-v2/{z}/{x}/{y}.vector.pbf?sku=101PIdFjDdrM9&access_token=pk.eyJ1IjoiZXhhbXBsZXMiLCJhIjoiY2p0MG01MXRqMW45cjQzb2R6b2ptc3J4MSJ9.zA2W0IkI0c6KaAhJfk9bWg', {
    //  minDetailZoom: 1,
    //  maxDetailZoom: 13,
    // https://api.mapbox.com/v4/mapbox.mapbox-streets-v8,mapbox.mapbox-terrain-v2/{z}/{x}/{y}.vector.pbf?sku=101PIdFjDdrM9&access_token=pk.eyJ1IjoiZXhhbXBsZXMiLCJhIjoiY2p0MG01MXRqMW45cjQzb2R6b2ptc3J4MSJ9.zA2W0IkI0c6KaAhJfk9bWg
    //})
    //let l = this.L.vectorGrid.protobuf('http://localhost:8989/mvt/{z}/{x}/{y}.mvt')
    //this.map.addLayer(l)
    //})

    s()
  }

  public setClickListener(l: (p: LatLng) => void): void {
    this.map.on('click', (ev: any) => {
      l(new LatLng(ev.latlng.lat, ev.latlng.lng))
    })
  }

  public pan(p: LatLng): void {
    // map.flyTo([lat, lng], zoom);
    this.map.flyTo([p.lat, p.lng])
  }

  public zoom(level: number) {
    this.map.setZoom(level)
  }

  public destroy(): void {
    if (this.map.off) {
      this.map.off()
      this.map.remove()
      delete this.map
    }
  }

  public cleanChild(c: any): void {
    this.map.removeLayer(c)
  }

  public addPolyline(points: LatLng[], lineWidth: number, color2: string, tooltip: string | null = null) {
    const polyline = this.L.polyline(points?.map((p: LatLng) => {
      return [p.lat, p.lng]
    }), {
      weight: lineWidth,
      color: color2,
    }).addTo(this.map)

    if (tooltip != null) {
      polyline.bindTooltip(tooltip, {permanent: true})
    }

    return polyline
  }

  // openPopup: item.openPopup()
  public addMarker(point: LatLng, icon: string | HTMLElement, popupElement: any, click: () => void, clustered: boolean, label?: MarkerLabel) {
    // let markerOptions: Leaf.MarkerOptions = {}
    let markerOptions: any = {}
    if (icon) {
      if (typeof icon === 'string') {
        markerOptions.icon = this.L.icon({
          iconUrl: icon,
          iconAnchor: [16, 16], // Half the size to center
          iconSize: [32, 32],
        })
      } else {
        markerOptions.icon = this.L.divIcon({
          html: icon,
          className: '',
          iconAnchor: [16, 16], // Half the size to center
          iconSize: [32, 32],
        })
      }
    }

    let i

    if (label) {
      // i = this.L.marker([point.lat, point.lng], markerOptions)
      if (typeof icon === 'string') {
        markerOptions.icon = this.L.icon({
          iconUrl: icon,
          iconAnchor: [16, 16], // Half the size to center
          iconSize: [32, 32],
          tooltipAnchor: [-16, -16],
          popupAnchor: [0, -12],
        })
      } else {
        markerOptions.icon = this.L.divIcon({
          html: icon,
          className: '',
          iconAnchor: [16, 16],
          iconSize: [32, 32],
          tooltipAnchor: [-16, -16],
          popupAnchor: [0, -12],
        })
      }

      i = this.L.marker([point.lat, point.lng], markerOptions)
      // i = this.L.marker([point.lat, point.lng], {...markerOptions, icon: divIcon})
      i.bindTooltip(label.text, {
        permanent: true,
        className: 'leaflet-marker-label',
        direction: 'right',
        offset: label.origin,
      })
      // console.log(this.L.DomUtil.getStyle(i, 'height'))
    } else {
      i = this.L.marker([point.lat, point.lng], markerOptions)
    }

    // Infobox detection
    if (popupElement) {
      i.bindPopup(popupElement)
    }
    // Click detection
    if (click) {
      i.on('click', () => {
        click()
      })
    }
    // https://github.com/Leaflet/Leaflet.markercluster/issues/346#issuecomment-41742441
    // Maybe is best to not add the marker if not in root?
    if (this.map && !clustered) {
      i.addTo(this.map)
    }

    return i
  }

  public moveMarker(item: any, toPoint: LatLng) {
    item.setLatLng(toPoint)
  }

  public addCircle(point: LatLng, radius: number, popupElement: any, click: () => void, color?: string) {
    let i = this.L.circle([point.lat, point.lng], {
      radius: radius,
      color,
    })
    if (this.map) {
      i.addTo(this.map)
    }

    if (click) {
      i.on('click', () => {
        click()
      })
    }

    if (popupElement) {
      i.bindPopup(popupElement)
    }

    return i
  }

  public moveCircle(item: any, toPoint: LatLng) {
    item.setLatLng(toPoint)
  }

  public setCircle(item: any, radius: number) {
    item.setRadius(radius)
  }

  public markerClusterLib(): any[] {
    return [
      ResourceLoader('css', 'leaflet-map-marker-clusterer', 'https://unpkg.com/leaflet.markercluster@1.5.0/dist/MarkerCluster.css'),
      ResourceLoader('css', 'leaflet-map-marker-clusterer-def', 'https://unpkg.com/leaflet.markercluster@1.5.0/dist/MarkerCluster.Default.css'),
      ResourceLoader('script', 'leaflet-map-marker-clusterer', 'https://unpkg.com/leaflet.markercluster@1.5.0/dist/leaflet.markercluster.js'),
    ]
  }

  // https://github.com/Leaflet/Leaflet.markercluster
  public markerCluster(item: any, child: any) {
    // Bypass reactivity edge-case
    if (typeof this.L === undefined) {
      return
    }

    // Sanity check for double init problem: first markers will never be removed
    if (item) {
      item.clearLayers()
      //this.map.removeLayer(item)
    }
    let i = (this.L as any).markerClusterGroup({
      removeOutsideVisibleBounds: true,
      maxClusterRadius: 40,
      spiderfyDistanceMultiplier: 0.9,
      chunkedLoading: true,
    })
    child.forEach((childrenMarker: any) => {
      // Remove from map https://github.com/Leaflet/Leaflet.markercluster/issues/346#issuecomment-41742441
      // Hotfix, should be better to not add the marker in first place
      // this.cleanChild(childrenMarker.item)
      i.addLayer(childrenMarker.item)
    })
    this.map.addLayer(i)
    return i
  }

  public addPolygon(points: LatLng[], lineWidth: number, color: string, popupElement: any, click: () => void) {
    let i = this.L.polygon(points.map((p: LatLng) => {
      return [p.lat, p.lng]
    }), {
      weight: lineWidth,
      color: color,
    }).addTo(this.map)

    // this.map.on('zoomstart ', () => {
    //   i.setStyle({fillOpacity: 0, opacity: 0})
    // })

    // this.map.on('zoomend', () => {
    //   i.setStyle({fillOpacity: 0.2, opacity: 1})
    // })

    if (click) {
      i.on('click', () => {
        click()
      })
    }

    if (popupElement) {
      i.bindPopup(popupElement)
    }

    return i
  }

  public addHeatmap(points: LatLng[]) {
    Promise.all([
      ResourceLoader('script', 'leaflet-map-heat.js', 'https://www.unpkg.com/leaflet.heat@0.2.0/dist/leaflet-heat.js'),
    ]).then(() => {
      (this.L as any).heatLayer(points.map((p: LatLng) => {
        return [p.lat, p.lng, 1]
      }), {radius: 25}).addTo(this.map)
    })
  }

  public getBoundsAndPan(points: LatLng[]) {
    this.map.fitBounds(this.toLeafletArray(points))
  }

  // leaflet zoom to: map.fitBounds(polygon.getBounds());

  public refresh() {
    this.map.invalidateSize()
  }

  protected addEvent(ev: string, cb: () => void, once?: boolean) {
    // console.log(this.map.listens(ev), this.map._events[ev])
    if (!this.map.listens(ev)) {
      if (!once) {
        this.map.on(ev, cb)
      } else {
        this.map.once(ev, cb)
      }
    }
  }

  private toLeafletArray(points: LatLng[]) {
    return points.map((p: LatLng) => {
      return [p.lat, p.lng]
    })
  }
}
