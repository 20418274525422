











































import {VIcon, VRating} from 'vuetify/lib'
import Utils from '@/utils'
import CloseButton from '@/components/CloseButton.vue'
import ConfirmDialogCallback from '@/views/ConfirmDialogCallback.vue'
import {Component, Prop} from 'vue-property-decorator'
import {Action, State} from 'vuex-class'
import {RatingRequest, Survey, VehicleSlot} from '@/lib/kepler/interfaces'
import VehicleAddReportDialog from '@/views/Vehicle/VehicleAddReportDialog.vue'
import VehicleDamage from '@/components/entities/vehicle/VehicleDamage.vue'
import Container from '@/components/proxy/Container.vue'
import VehicleDamageAll from '@/components/VehicleDamageAll.vue'
import {AxiosPromise} from 'axios'
import {EventBus} from '@/main'

@Component({
  components: {
    VehicleDamageAll,
    Container,
    VehicleDamage,
    CloseButton,
    Button: Utils.loadComponent('Button'),
    Avatar: Utils.loadComponent('proxy/Avatar'),
    Layout: Utils.loadComponent('proxy/Layout'),
    Card: Utils.loadComponent('proxy/Card/Card'),
    VRating,
    VIcon,
  },
  name: 'SurveyDialog',
})
export default class SurveyDialog extends ConfirmDialogCallback {
  @State((state) => state.configuration.appConfig) public appConfigState!: any
  @State((state) => state.configuration.appConfig.damage_report_id) public damageReportId?: string | null

  @Action('bookingRating') public bookingRating!: (payload: RatingRequest) => AxiosPromise

  @Prop({type: Array, default: () => []}) public survey!: Survey[]
  @Prop({type: Object}) public vehicleSlot!: VehicleSlot
  @Prop({type: Number}) public reservationNumber!: number
  @Prop({type: String}) public reservationId!: string

  public ratings: { [key: string]: number } = {}

  public get category() {
    return this.vehicleSlot.vehicle.category
  }

  protected get valid() {
    const r = this.ratings
    return this.survey.every((s) => {
      return s.mandatory && !['report', 'picture'].includes(s.type) ? (r.hasOwnProperty(s.survey) && typeof r[s.survey] === 'number') : true
    })
  }

  protected get hasMandatory() {
    return this.survey.some((s) => {
      return s.mandatory
    }) && this.$isAvailable('ratings.mandatory')
  }

  public isDamageSurvey(s: Survey) {
    return !!(s.needs_report && s.needs_report === this.damageReportId) || s.survey === 'DAMAGE'
  }

  public booleanAction(s: Survey, bool: boolean) {
    this.$set(this.ratings, s.survey, Number(bool))
  }

  public openReport(s: Survey, pictureReport = false) {
    if (s.needs_report) {
      this.$dialog.open(VehicleAddReportDialog, {
        props: {
          vehicleSlot: this.vehicleSlot,
          vehicle: this.vehicleSlot.vehicle,
          reportType: s.needs_report,
          reservationId: this.reservationId,
          selectable: false,
          pictureReport,
        },
      })
    }
  }

  protected sendRatings() {
    return this.bookingRating({reservation: this.reservationNumber, ratings: this.ratings})
  }

  protected confirm() {
    if (Object.keys(this.ratings).length) {
      this.bookingRating({reservation: this.reservationNumber, ratings: this.ratings})
        .finally(() => {
          this.callAndClose(this.confirmCallback)
        })
    } else {
      this.callAndClose(this.confirmCallback)
    }
  }

  protected mounted() {
    EventBus.$once('sentReport', this.confirm)
  }
}
