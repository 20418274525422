











import {Vue, Component, Prop, Watch} from 'vue-property-decorator'
import {Action, State} from 'vuex-class'
import {FlowInputsState} from '@/store/modules/flowInputs'
import {FlowInputField, FlowInputStep} from '@/lib/kepler/interfaces'
import Utils from '@/utils'
import {VLayout, VSwitch} from 'vuetify/lib'
import {FlowOutputsState} from '@/store/modules/flowOutputs'

@Component({
  components: {
    VLayout,
    VSwitch,
    FlowField: Utils.loadComponent('flow/FlowField'),
  },
  name: 'SameAs',
})
export default class SameAs extends Vue {
  @State('flowOutputs') public flowOutputs!: FlowOutputsState
  @State('flowInputs') public flowInputs!: FlowInputsState

  @Prop() public context?: string
  @Prop() public step?: FlowInputStep
  @Prop() public field?: FlowInputField
  @Prop() public name?: string
  @Prop() public description?: string

  @Action('editField') public editField!: (payload: { path: string[], value: any }) => void

  @Prop({
    type: Object,
    default: () => new Object(),
  }) public readonly options!: Record<string, string>

  @Prop({
    type: Boolean,
    default: false,
  }) public disabled?: boolean

  public isSameValue: boolean = false

  public get outputToCopy() {
    if (this.field && this.fieldToCopy) {
      return this.flowOutputs[this.context ?? '']?.[this.options.id]
    }
  }

  @Watch('outputToCopy')
  @Watch('isSameValue')
  public onOutputChange() {
    if (this.isSameValue && this.context && this.field) {
      this.editField({path: [this.context, this.field.id], value: this.outputToCopy})
    }
  }

  public get fieldToCopy() {
    if (this.field && this.options.id && this.context) {
      const steps = this.flowInputs[this.context].steps
      const r = steps.flatMap(
        (step) => step.fields,
      ).find(
        (field) => field?.id === this.options.id,
      )
      if (r) {
        const x = structuredClone(r)
        x.id = this.field.id
        x.name = this.field.name
        x.description = this.field.description
        return x
      }
    }
    return null
  }

  public created() {
    this.isSameValue = !!this.options.checked
  }
}
