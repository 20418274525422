





import {Vue, Component, Prop} from 'vue-property-decorator'
import {VehicleSlot} from '@/lib/kepler/interfaces'
import VehicleSlotHelper from '@/lib/vehicleSlot'
import Chip from '@/components/proxy/Chip.vue'

@Component({
  components: {Chip},
  name: 'VehicleTypeChip',
})
export default class VehicleTypeChip extends Vue {
  @Prop({required: true, type: Object}) protected vehicleSlot!: VehicleSlot
  protected get slotHelper() {
    return new VehicleSlotHelper(this.vehicleSlot)
  }
}
