import {SerialDevice} from "@/lib/BtSDKs/SerialDevice";

export default class Teltonika extends SerialDevice {
  private commandsMap: any = null
  private deviceName: string = ""

  constructor(logger: any, deviceName: any, commandsMap: any,) {
    super(logger)
    this.commandsMap = commandsMap
    this.deviceName = deviceName
  }

  isCorrectDevice(name: string, address: string, mode: string, dgiata: any) {
    return name == this.deviceName + "_LE"
  }

  runCommand(cmd: string) {
    return new Promise<string>((resolve, reject) => {
      if (this.commandsMap[cmd]) {
        let commandCopy = JSON.parse(JSON.stringify(this.commandsMap[cmd]))
        this.queue(commandCopy).then(resolve).catch(reject)
      } else {
        reject("COMMAND NOT FOUND")
      }
    })
  }

  queue(commands: string[], results: any = []) {
    return new Promise<any>((resolve, reject) => {
      if (commands.length == 0) {
        resolve(results)
      } else {
        let h = (x: any) => {
          results.push(x)
          this.queue(commands, results).then(resolve).catch(reject)
        }
        this.run(commands.shift()).then(h).catch(reject)
      }
    })
  }

  run(command: any) {
    return new Promise<string>((resolve, reject) => {
      let payload = this.hexToBytes(command)

      this.send(payload).then((response) => {
        //this.logJson([
        //  "OK",
        //  this.intToHex(response).join("-"),
        //])
        resolve("OK-"+this.intToHex(response).join(""))
      }).catch((error) => {
        //this.logJson([
        //  "ERROR",
        //  command,
        //  error,
        //])
        reject(error)
      })
    })
  }
}
