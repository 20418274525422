import Vue from 'vue'
import {ActionTree, MutationTree} from 'vuex'
import {RegistrationRequest} from '@/lib/kepler/interfaces'
import sdk from '@/lib/kepler/sdk'
import {AxiosResponse} from 'axios'
import {RootState} from '@/store'

class RegistrationState {
  [key: string]: any
}

const mutations: MutationTree<RegistrationState> = {
  PURGE_REGISTRATION(stateF) {
    stateF = new RegistrationState()
  },
  PURGE_DOCUMENT(stateF, id: string) {
    if (stateF.documents) {
      Vue.delete(stateF.documents, id)
    }
  },
}

const actions: ActionTree<RegistrationState, RootState> = {
  init({commit}) {
    commit('PURGE_REGISTRATION')
  },
  purge({commit}) {
    commit('PURGE_REGISTRATION')
  },
  registrationSend({}, req: RegistrationRequest) {
    return sdk.flows.send(req.endpoint, req.payload)
      .then((r: AxiosResponse) => {
        return r.data
      })
  },
  stepsApi() {
    return sdk.flows.get().then((r) => r.data.quick_registration.steps)
  },
  deleteDocument({commit}, id: string) {
    commit('PURGE_DOCUMENT', id)
  },
}

export default {
  state: new RegistrationState(),
  mutations,
  actions,
}
