import {Vue} from 'vue-property-decorator'
import {EventBus} from '@/main'

class CameraDefaultOptions implements CameraOptions {
  allowEdit = false
  cameraDirection = 0
  correctOrientation = true
  destinationType = 0
  encodingType = 0
  mediaType = 0
  popoverOptions = undefined
  quality = 80
  saveToPhotoAlbum = false
  sourceType = 1
  targetHeight = 640
  targetWidth = 1280
}

export default class BrowserCameraClass {
  public localMediaStream!: MediaStream
  public video: HTMLVideoElement
  public options: CameraOptions
  private HIGHEST_POSSIBLE_Z_INDEX = 2147483647

  constructor(options?: CameraOptions) {
    this.video = document.createElement('video')
    this.options = options || new CameraDefaultOptions()
  }

  public takePicture = (success: (imageData: string) => void, error: (e: Error) => void) => {
    if (this.options.sourceType === 1) {
      this.capture(success, error)
    } else {
      const input = document.createElement('input')
      input.style.position = 'relative'
      input.style.zIndex = String(this.HIGHEST_POSSIBLE_Z_INDEX)
      input.className = 'cordova-camera-select'
      input.type = 'file'
      input.name = 'files[]'

      input.onchange = (inputEvent) => {
        const reader = new FileReader() /* eslint no-undef : 0 */
        reader.onload = (readerEvent) => {
          if (input.parentNode) {
            input.parentNode.removeChild(input)
          }

          if (readerEvent.target && typeof readerEvent.target.result === 'string') {
            const imageData = readerEvent.target.result
            return success(imageData.substring(imageData.indexOf(',') + 1))
          }
        }
        if (inputEvent.target) {
          reader.readAsDataURL((inputEvent.target as any).files[0]) // shady shit here
        }
      }

      document.body.appendChild(input)
    }
  }

  public cleanUp = () => {
    this.localMediaStream.getVideoTracks()[0].stop()
    this.localMediaStream.getTracks().forEach((track) => {
      track.stop()
    })
  }

  public styleBrowserCamera() {
    Promise.all([
      this.wait('.dummy-camera-box'),
      this.wait('.cordova-camera-capture'),
      this.wait('.cordova-camera-capture video'),
      this.wait('.cordova-camera-capture button'),
    ]).then(() => {
      const container = document.querySelector('.dummy-camera-box')!
      const camera = document.querySelector('.cordova-camera-capture')!
      const cameraVideo = document.querySelector('.cordova-camera-capture video') as HTMLVideoElement
      const cameraButton = document.querySelector('.cordova-camera-capture button')!
      container.appendChild(camera)

      Vue.nextTick(() => {
        cameraButton.classList.add('component-button', 'v-btn', 'v-btn--depressed', 'v-btn--round')
        cameraVideo.height = cameraVideo.videoHeight
        cameraVideo.width = cameraVideo.videoWidth
        Vue.set(this, 'width', cameraVideo.videoWidth)
      })
      EventBus.$emit('browserCameraLaunched')
    })
  }

  // TODO: this part below should be refactored inside capture, no need to wait anymore

  private capture = (success: (imageData: string) => void, error: (e: Error) => void) => {
    let targetWidth = this.options.targetWidth || 320
    let targetHeight = this.options.targetHeight || 240

    const button = document.createElement('button')
    const parent = document.createElement('div')
    parent.style.position = 'relative'
    parent.style.zIndex = String(this.HIGHEST_POSSIBLE_Z_INDEX)
    parent.className = 'cordova-camera-capture'
    parent.appendChild(this.video)
    parent.appendChild(button)

    this.video.width = targetWidth
    this.video.height = targetHeight
    button.innerHTML = 'Capture!'

    button.onclick = () => {
      // create a canvas and capture a frame from video stream
      const canvas = document.createElement('canvas')
      canvas.width = targetWidth
      canvas.height = targetHeight
      canvas.getContext('2d')!.drawImage(this.video, 0, 0, targetWidth, targetHeight)

      // convert image stored in canvas to base64 encoded image
      const imageData = canvas.toDataURL('image/png').replace('data:image/png;base64,', '')

      // stop video stream, remove video and button.
      // Note that MediaStream.stop() is deprecated as of Chrome 47.
      if ((this.localMediaStream as any).stop) {
        (this.localMediaStream as any).stop()
      } else {
        this.localMediaStream.getTracks().forEach((track) => {
          track.stop()
        })
      }
      parent.parentNode!.removeChild(parent)

      return success(imageData)
    }

    let facingMode = 'environment'
    if (this.options.cameraDirection === 1) {
      facingMode = 'user'
    }

    // @ts-ignore
    const constraints = {
      video: {
        width: this.options.targetWidth,
        height: this.options.targetHeight,
        facingMode,
      }, audio: false,
    }

    navigator.mediaDevices.getUserMedia(constraints)
      .then((stream) => {
        this.localMediaStream = stream
        if ('srcObject' in this.video) {
          this.video.srcObject = this.localMediaStream
        }
        this.video.play()
        document.body.appendChild(parent)
      })
      .catch((e: DOMException) => {
        error(e)
      })

    this.styleBrowserCamera()
  }

  private async wait(selector: string) {
    while (document.querySelector(selector) === null) {
      await new Promise(resolve => {
        requestAnimationFrame(resolve) //faster than set time out
      })
    }
    return document.querySelector(selector)
  }

}
