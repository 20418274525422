import OperationResult = BluetoothlePlugin.OperationResult

const SERVICE_AUTH_UUID = '43ba1a02-4cf5-46d8-a887-a846d9de712d'
const CHARACTERISTIC_AUTH_UUID = '43ba4200-4cf5-46d8-a887-a846d9de712d'

const SERVICE_OPEN_UUID = '43ba1a03-4cf5-46d8-a887-a846d9de712d'
const CHARACTERISTIC_OPEN_UUID = '43ba4300-4cf5-46d8-a887-a846d9de712d'
const CHARACTERISTIC_NOTY_UUID = '43ba4301-4cf5-46d8-a887-a846d9de712d'

const SERVICE_DATA_UUID = '43ba1a01-4cf5-46d8-a887-a846d9de712d'
const CHARACTERISTIC_DATA_UUID = '43ba4105-4cf5-46d8-a887-a846d9de712d'

// Data conversion function
function stringToB64(data: string) {
  let bytes = bluetoothle.stringToBytes(data)
  return bluetoothle.bytesToEncodedString(bytes)
}

function intArrayToB64(data: number[]) {
  let res = ''
  data.forEach(function (i: number) {
    res += String.fromCharCode(i)
  })
  return stringToB64(res)
}

function B64toIntArray(data: string) {
  let s = atob(data)
  let d = []
  for (let i = 0; i < s.length; i++) {
    d[i] = s.charCodeAt(i)

  }
  return d
}

function parseAdvertisingData(bytes: any) {
  let length, type, data, i = 0, advertisementData: any = {}

  while (length !== 0) {

    length = bytes[i] & 0xFF
    i++

    // decode type constants from https://www.bluetooth.org/en-us/specification/assigned-numbers/generic-access-profile
    type = bytes[i] & 0xFF
    i++

    data = bytes.slice(i, i + length - 1).buffer // length includes type byte, but not length byte
    i += length - 2  // move to end of data
    i++

    const inx = asHexString(type)

    if (inx == '0xff') {
      // @ts-ignore
      advertisementData[inx] = String.fromCharCode.apply(null, new Uint8Array(data.slice(-6)))
    } else {
      // @ts-ignore
      advertisementData[inx] = String.fromCharCode.apply(null, new Uint8Array(data))
    }

  }

  return advertisementData
}

// Helper functions
function convertPinToData(pin: string): number[] {
  if (pin.length == 40) {
    // Univocalpin (this need to be rewrote)
    let data = []
    data.length = 20
    let splitted = pin.match(/.{1,2}/g)
    for (let i = 0; i < splitted!.length; ++i) {
      data[i] = parseInt('0x' + splitted![i])
    }
    return data
  } else {
    // Root pin
    let pinNumeric: number = parseInt(pin)

    const byteArray = [0, 0, 0, 0]

    for (let index = 0; index < byteArray.length; index++) {
      const byte = pinNumeric & 0xff
      byteArray[index] = byte
      pinNumeric = (pinNumeric - byte) / 256
    }
    return byteArray
  }
}

function asHexString(i: any) {
  let hex = i.toString(16)

  // zero padding
  if (hex.length === 1) {
    hex = '0' + hex
  }

  return '0x' + hex
}

// SDK methods
export default {
  open: function (mac: string, pin: string, success: (status: string) => void, failure: (status: string) => void, log: (status: string) => void) {
    this.connectAndDo(mac, pin, () => {
      let openSent = false
      ////

      // Set up notifications
      bluetoothle.subscribe((successSubscribe: any) => {
          log('SUBSCRIBE SUCCESS ' + successSubscribe)
          if (successSubscribe.value) {
            const code = B64toIntArray(successSubscribe.value)[0]
            let codeHuman = 'NULL'
            switch (code) {
              case 0:
                codeHuman = 'CLOSE'
                break
              case 2:
                codeHuman = 'OPEN'
                break
            }
            success(codeHuman)
          }

        },
        (errorSubscribe: any) => {
          log('SUBSCRIBE ERROR' + errorSubscribe)
        }, {
          address: mac,
          service: SERVICE_OPEN_UUID,
          characteristic: CHARACTERISTIC_NOTY_UUID,
        })

      // Open
      bluetoothle.write(
        (successOpen: any) => {
          log('OPEN SUCCESS ' + JSON.stringify(successOpen))
          openSent = true
        },
        (errorOpen: any) => {
          log('OPEN FAIL ' + JSON.stringify(errorOpen))
        },
        {
          address: mac,
          service: SERVICE_OPEN_UUID,
          characteristic: CHARACTERISTIC_OPEN_UUID,
          value: 'AQ==',
        })

      let timeoutFunction = () => {
        if (!openSent) {
          failure('TIMEOUT')
        }
      }

      setTimeout(timeoutFunction, 15000)
    }, success, failure)
  },
  connectAndDo: function (address: string, pin: string, doFunction: () => void, success: (status: string) => void, failure: (status: string) => void) {
    // Connect to device
    console.log('CONNECTING...')

    const deviceParams = {
      address: address,
    }

    const doDiscover = function (discovered: () => void) {
      bluetoothle.isDiscovered(
        (success: any) => {
          if (success.isDiscovered) {
            discovered()
          } else {
            bluetoothle.discover(
              (success: any) => {
                discovered()
              },
              (error: any) => {
                console.log('DISCOVER ERROR DISCOVERING', error)
              },
              deviceParams,
            )
          }
        },
        (error: any) => {
          console.log('DISCOVER ERROR CHECKING', error)
          if ((error as any).error == 'isNotConnected') {
            bluetoothle.reconnect(() => {
              doDiscover(discovered)
              console.log('RECONNECTED')
            }, () => {

            }, deviceParams)

          }
        },
        deviceParams)
    }

    const doAuth = function (authenticated: () => void) {
      bluetoothle.write(
        (successAuth: any) => {
          console.log('AUTH SUCCESS', successAuth)
          authenticated()
        },
        (errorAuth: any) => {
          console.log('AUTH ERROR', errorAuth)
        },
        {
          value: intArrayToB64(convertPinToData(pin)),
          characteristic: CHARACTERISTIC_AUTH_UUID,
          address: address,
          service: SERVICE_AUTH_UUID,
        },
      )
    }

    bluetoothle.initialize((s: any) => {
      console.log('DEVICE INIT: ' + s.status)

      bluetoothle.isConnected(
        () => {
          console.log('ALREADY CONENCTED')
          doDiscover(() => {
            doAuth(doFunction)
          })
        },
        // Device is not connected
        () => {
          bluetoothle.connect(
            (success: any) => {
              console.log('CONNECT SUCCESS', success)
              doDiscover(() => {
                doAuth(doFunction)
              })
            },
            (error: any) => {
              console.log('CONNECT ERROR', error)
            },
            deviceParams,
          )
        }, deviceParams)
    })
  },
  disconnect: function (mac: string, success?: (status: string) => void, failure?: (status: string) => void) {
    const close = () => {
      bluetoothle.close(
        () => {
          success ? success('CONNECTION CLOSED') : null
        },
        () => {
          failure ? failure('CONNECTION NOT CLOSED') : null
        },
        {address: mac},
      )
    }
    bluetoothle.disconnect(
      () => {
        success ? success('DISCONNECTED CONNECTED') : null
        close()
      }, () => {
        success ? success('DISCONNECTED DISCONNECTED') : null
        close()
      },
      {address: mac},
    )
  },
  searchByPlate: function (plate: string, success: (address: string) => void, failure: (status: string) => void) {
    let timeoutHolder: any

    bluetoothle.initialize(() => {
      bluetoothle.isScanning((r: { isScanning: boolean }) => {
        if (r.isScanning) {
          failure('ALREADY_SCANNING')
        } else {
          timeoutHolder = setTimeout(() => {
            bluetoothle.stopScan(console.log, console.log)
            failure('SCAN_STOPPED')
          }, 15000)
          bluetoothle.startScan(
            (res: any) => {
              if (res.status == 'scanResult' && res.name == 'CABUBI') {
                let foundPlate
                if ((res.advertisement as any).manufacturerData) {
                  // iOS
                  foundPlate = atob((res.advertisement as any).manufacturerData).substring(2)
                } else {
                  // Android
                  const adv = parseAdvertisingData(bluetoothle.encodedStringToBytes(res.advertisement as any))
                  console.log(adv)
                  foundPlate = adv['0xff'] as string
                }
                if (foundPlate == plate) {
                  clearTimeout(timeoutHolder)
                  bluetoothle.stopScan(console.log, console.log)
                  success(res.address)
                }
              }
            },
            (error: any) => {
              if (error.message == "Bluetooth not enabled") {
                failure('BLUETOOTH_DISABLED')
              } else {
                failure('SCAN_FAILED' + error.message)
              }
            },
          )
        }
      })
    })
  },

  data: function (mac: string, pin: string, log: (status: string) => void) {
    log(mac + '>>' + pin)
    this.connectAndDo(mac, pin, () => {
        bluetoothle.subscribe((successSubscribe: any) => {
            log('SUBSCRIBE SUCCESS ' + JSON.stringify(successSubscribe))

            bluetoothle.write((s) => {
              log('WRITE ' + s)
            }, (f) => {
              log('NOT WRITE ' + f)
            }, {
              address: mac,
              service: SERVICE_DATA_UUID,
              characteristic: CHARACTERISTIC_DATA_UUID,
              value: intArrayToB64([
                0, // GET
                0, // GET
                0, // SQ0
                0, // SQ1
                50, // MESSAGE NO
              ]),
            })
          },
          (errorSubscribe: any) => {
            log('SUBSCRIBE ERROR' + JSON.stringify(errorSubscribe))
          }, {
            address: mac,
            service: SERVICE_DATA_UUID,
            characteristic: CHARACTERISTIC_DATA_UUID,
          })
      }, (s) => {
        log('SUCCESS: ' + s)
      },
      (f) => {
        log('FAILURE: ' + f)
      },
    )
  },

  status: function (mac: string, pin: string, log: (status: string) => void) {
    log(mac + '>>' + pin)
    this.connectAndDo(mac, pin, () => {
        bluetoothle.read((result: OperationResult) => {
          switch (result.value) {
            case "Ag==":
              log("OPEN")
              break;
            case "AA==":
              log("CLOSED")
              break;
            default:
              log("WTF")
              break;
          }
        }, (error: BluetoothlePlugin.Error) => {
          log("ERROR" + '>>' + JSON.stringify(error))
        }, {
          address: mac,
          service: SERVICE_OPEN_UUID,
          characteristic: CHARACTERISTIC_NOTY_UUID,
        })
      }, (s) => {
        log('SUCCESS CONNECT: ' + s)
      },
      (f) => {
        log('FAILURE CONNECT: ' + f)
      },
    )
  },

  //
  connect: function (address: string, pin: string, logger: (status: string) => void): Promise<string> {
    logger("connect: called")

    const deviceParams = {
      address: address,
    }

    return new Promise<string>(((resolve, reject) => {

      const discoverAndAuth = function () {
        const authenticate = function () {
          bluetoothle.write(
            (successAuth) => {
              logger('connect: auth success ' + JSON.stringify(successAuth))
              resolve('JUST_CONNECTED')
            },
            (errorAuth) => {
              logger('connect: auth error ' + JSON.stringify(errorAuth))
              reject('CANNOT_AUTH')
            },
            {
              value: intArrayToB64(convertPinToData(pin)),
              characteristic: CHARACTERISTIC_AUTH_UUID,
              address: address,
              service: SERVICE_AUTH_UUID,
            },
          )
        }

        bluetoothle.isDiscovered(
          (discoverStatus) => {
            logger("connect: isDiscovered " + JSON.stringify(discoverStatus))
            if (discoverStatus.isDiscovered) {
              logger("connect: device already discovered")
              authenticate()
            } else {
              logger("connect: device is not discovered")
              bluetoothle.discover(
                (success) => {
                  logger("connect: device discovering success " + JSON.stringify(success))
                  authenticate()
                },
                (error) => {
                  logger("connect: cannot discover " + JSON.stringify(error))
                  reject('CANNOT_DISCOVER')
                },
                deviceParams
              )
            }
          },
          (error) => {
            logger("connect: cannot check for discovered " + JSON.stringify(error))
            reject('IS_DISCOVERED_FAILED')
          },
          deviceParams
        )

      }

      const connectDeviceBT = function () {
        logger("connect: connecting... ")
        bluetoothle.connect(
          (deviceInfo) => {
            logger("connect: connected to device " + JSON.stringify(deviceInfo))
            discoverAndAuth()
          },
          (error: any) => {
            logger("connect: cannot connect to device " + JSON.stringify(error))
            if (error.error == "connect") {
              logger("connect: try re-connect")
              bluetoothle.reconnect(
                (success) => {
                  if (success.status == "connected") {
                    logger("connect: reconnected to device " + JSON.stringify(success))
                    discoverAndAuth()
                  } else {
                    logger("connect: cannot reconnect to device " + JSON.stringify(success))
                    reject('RECONNECT_FAILED')
                  }
                },
                (error) => {
                  logger("connect: reconnect failed " + JSON.stringify(error))
                  reject('RECONNECT_FAILED')
                },
                deviceParams
              )
            } else {
              reject('CONNECT_FAILED')
            }
          },
          deviceParams
        )
      }


      const connectToDevice = function () {
        bluetoothle.isConnected(
          (co) => {
            if (co.isConnected) {
              logger("connect: device already connected " + JSON.stringify(co))
              resolve("DEVICE_ALREADY_CONNECTED")
            } else {
              logger("connect: device disconnected, connecting " + JSON.stringify(co))
              connectDeviceBT()
            }

          },
          (error: any) => {
            if (error.error == "neverConnected") {
              connectDeviceBT()
            } else {
              logger("connect: cannot check for connected " + JSON.stringify(error))
              if (error.message == "Bluetooth not enabled") {
                reject('BLUETOOTH_DISABLED')
              } else {
                reject('IS_CONNECTED_FAILED_' + error.messageß)
              }
            }
          },
          deviceParams
        )
      }

      bluetoothle.isInitialized((initialized) => {
        logger("connect: initialized " + JSON.stringify(initialized))
        if (initialized) {
          logger("connect: already init, directly connecting")
          connectToDevice()
        } else {
          bluetoothle.initialize((result => {
            if (result.status == "enabled") {
              logger("connect: init done, connecting")
              connectToDevice()
            } else {
              logger("connect: cannot initialize " + result.status)
            }
          }))
        }
      })
    }))
  },

  openNew: function (address: string, pin: string, logger: (status: string) => void): Promise<string> {
    logger("open: called")
    return new Promise<string>(((resolve, reject) => {
      this.connect(address, pin, logger).then((s) => {
        logger("open: connected " + s)
        let openSent = false

        const unsubscribe = function () {
          // logger("open: unsubscribing")
          // bluetoothle.unsubscribe((s) => {
          //   logger("open: unsubscribe success " + JSON.stringify(s))
          // }, (e) => {
          //   logger("open: unsubscribe error " + JSON.stringify(e))
          // }, {
          //   address: address,
          //   service: SERVICE_OPEN_UUID,
          //   characteristic: CHARACTERISTIC_NOTY_UUID,
          // })
        }

        const sendOpen = function () {
          logger("open: sending command")
          bluetoothle.write(
            (successOpen: any) => {
              logger('open: success ' + JSON.stringify(successOpen))
              openSent = true
              resolve('WROTE')
            },
            (errorOpen: any) => {
              logger('open: fail ' + JSON.stringify(errorOpen))
              reject('OPEN FAILED')
            },
            {
              address: address,
              service: SERVICE_OPEN_UUID,
              characteristic: CHARACTERISTIC_OPEN_UUID,
              value: 'AQ==',
            })

          let timeoutFunction = () => {
            unsubscribe()
            if (!openSent) {
              reject('TIMEOUT')
            }
          }
          setTimeout(timeoutFunction, 6000)
        }

        // Set up notifications
        sendOpen()

        logger("open: subscribe for notifications")
        //bluetoothle.subscribe((successSubscribe: any) => {
        //    if (successSubscribe.value) {
        //      const code = B64toIntArray(successSubscribe.value)[0]
        //      let codeHuman = 'NULL'
        //      switch (code) {
        //        case 0:
        //          codeHuman = 'CLOSE'
        //          break
        //        case 2:
        //          codeHuman = 'OPEN'
        //          break
        //      }
        //      logger("open: lock status changed " + codeHuman)
        //      if (codeHuman == 'OPEN' && openSent) {
        //        resolve('OPENED')
        //      } else {
        //        reject("open: unexpexted status: "+codeHuman + " " + openSent)
        //      }
        //    } else if (successSubscribe.status == "subscribed") {
        //      logger('open: subscribed for change ' + JSON.stringify(successSubscribe))
        //      // Subscribed can open now
//
        //      sendOpen()
        //    } else {
        //      reject("open: unexpexted status: "+JSON.stringify(successSubscribe))
        //    }
//
        //  },
        //  (errorSubscribe: any) => {
        //    if (errorSubscribe.message == "Already subscribed") {
        //      logger('open: already subscribed' + JSON.stringify(errorSubscribe))
        //      sendOpen()
        //    } else {
        //      logger('open: cannot subscribe' + JSON.stringify(errorSubscribe))
        //      reject('CANNOT_SUBSCRIBE')
        //    }
        //  }, {
        //    address: address,
        //    service: SERVICE_OPEN_UUID,
        //    characteristic: CHARACTERISTIC_NOTY_UUID,
        //  })

      }).catch(reject)
    }))
  },

  statusNew: function (address: string, pin: string, logger: (status: string) => void): Promise<string> {
    logger("status: called")
    return new Promise<string>(((resolve, reject) => {
      this.connect(address, pin, logger).then((s) => {
        bluetoothle.read((result: OperationResult) => {
          logger("status: " + result.value)
          switch (result.value) {
            case "Ag==":
              resolve("OPENED")
              break;
            case "AA==":
              resolve("CLOSED")
              break;
            default:
              resolve("WTF " + result.value)
              break;
          }
        }, (error: BluetoothlePlugin.Error) => {
          logger("status: readError " + JSON.stringify(error))
        }, {
          address: address,
          service: SERVICE_OPEN_UUID,
          characteristic: CHARACTERISTIC_NOTY_UUID,
        })
      }).catch(reject)
    }))
  },
}
