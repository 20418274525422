



















import {Component, Vue, Prop, Watch} from 'vue-property-decorator'
import Validations from '@/lib/Validations'
import Utils from '@/utils'

@Component({
  components: {
    Container: Utils.loadComponent('proxy/Container'),
    Layout: Utils.loadComponent('proxy/Layout'),
    Flex: Utils.loadComponent('proxy/Flex'),
    FormTag: Utils.loadComponent('proxy/Inputs/FormTag'),
    TextField: Utils.loadComponent('proxy/Inputs/TextField'),
    Button: Utils.loadComponent('Button'),
    Btn: Utils.loadComponent('proxy/Btn'),
  },
  name: 'Digits',
})
export default class Digits extends Vue {
  @Prop({default: 6}) public digits?: number
  @Prop() public digitsMin?: number
  @Prop() public digitsMax?: number
  @Prop({default: 'number'}) public digitType?: string
  @Prop({default: false, type: Boolean}) public mixed?: boolean
  @Prop({default: false, type: Boolean}) public small?: boolean
  @Prop({default: false, type: Boolean}) public readonly?: boolean
  @Prop({default: false, type: Boolean}) public disabled?: boolean
  @Prop({default: () => [], type: Array}) public errorMessages?: string[]

  public rules: any = Validations.rules
  public valid: boolean = false

  public digitsField: string = ''

  public get digitsPlaceholder() {
    return '- '.repeat(this.digits || 0).trimEnd()
  }

  protected submit() {
    if (this.digitsField.length === this.digits) {
      this.$emit('digitsCompleteSubmit', this.digitsField)
    }
  }

  @Watch('digitsField', {immediate: true})
  private fieldChanged(field: string) {
    if (field.length === this.digits || this.digitsMin && field.length >= this.digitsMin) {
      this.$emit('digitsComplete', field)
    } else {
      this.$emit('digitsInComplete')
      return
    }
  }
}
