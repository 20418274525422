
import {Component, Vue} from 'vue-property-decorator'
import { VStepperStep } from 'vuetify/lib'

@Component({
  name: 'StepperStep',
  extends: VStepperStep as any,
})
export default class StepperStep extends Vue {

}
