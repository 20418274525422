





















import {Vue, Component, Prop} from 'vue-property-decorator'
import {Attribute} from '@/lib/kepler/interfaces'
import Utils from '@/utils'
import VuetifyColorHelper from '@/lib/vuetify/VuetifyColorHelper'
import {VChip} from 'vuetify/lib'

@Component({
  name: 'VehicleFeatures',
  components: {
    Container: Utils.loadComponent('proxy/Container'),
    Divider: Utils.loadComponent('proxy/Divider'),
    Layout: Utils.loadComponent('proxy/Layout'),
    Avatar: Utils.loadComponent('proxy/Avatar'),
    VChip,
    Icon: Utils.loadComponent('proxy/Icon'),
  },
})
export default class VehicleFeatures extends Vue {
  @Prop({
    type: Array,
    default: () => [],
  }) public attributes!: Attribute[]
  @Prop({
    type: Boolean,
    default: false,
  }) public small!: boolean
  @Prop({
    type: Boolean,
    default: false,
  }) public extended!: boolean

  protected detailed: string = ''

  protected textColor() {
    let contrast = VuetifyColorHelper.lightContrast(VuetifyColorHelper.color('primary'))
    const override = this.$branding.theme.buttonDefaultColorOverride as string | undefined
    if (override) {
      contrast = VuetifyColorHelper.lightContrast(VuetifyColorHelper.color(override))
    }
    return contrast ? 'black' : 'white'
  }

  protected attributeParser(attr: Attribute) {
    const list: Record<string, string> = {
      'seats': 'mdi-car-seat',
      'luggage': 'mdi-bag-carry-on',
      'hand-luggage': 'mdi-bag-personal',
      'transmission': 'mdi-car-shift-pattern',
      'pets-friendly': 'mdi-paw',
      'baby-seat': 'mdi-car-child-seat',
      'isofix': 'mdi-car-child-seat',
      'air-conditioning': 'mdi-air-conditioner',
      'doors': 'mdi-car-door',
      'bluetooth': 'mdi-bluetooth',
      'shitter': 'mdi-toilet',
      'luggage-large': 'mdi-bag-suitcase',
      'luggage-small': 'mdi-bag-personal',
    }
    return {icon: attr.icon || list[attr.name] || null}
  }

  protected detail(id: string) {
    if (this.detailed !== id) {
      this.detailed = id
    } else {
      this.detailed = ''
    }
  }
}
