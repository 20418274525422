
import {Component, Vue} from 'vue-property-decorator'
import { VAutocomplete } from 'vuetify/lib'

@Component({
  name: 'AutoComplete',
  extends: VAutocomplete as any,
})
export default class AutoComplete extends Vue {

}
