




import {Component, Prop, Vue} from 'vue-property-decorator'

@Component({})
export default class CircleToggle extends Vue {
  @Prop() private color!: string
  @Prop() private value!: boolean

  get style(): string {
    return 'border-color: ' + this.color + '; ' +
      'background-color: ' + (this.value ? this.color : 'rgba(255,255,255,0)') + ';'
  }

  private clicked() {
    this.$emit('input', !this.value)
  }
}
