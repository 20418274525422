
import {Component, Vue} from 'vue-property-decorator'

import { VListTileAction } from 'vuetify/lib'

@Component({
  name: 'ListTileAction',
  extends: VListTileAction as any,
})
export default class ListTileAction extends Vue {

}
