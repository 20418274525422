



















import {Vue, Component, Prop} from 'vue-property-decorator'
import {State} from 'vuex-class'
import {DialogState} from '@/store/modules/dialogs'
import {EventBus} from '@/main'
import Utils from '@/utils'
import QrScanner from '@/components/QrScanner.vue'
import Icon from '@/components/proxy/Icon.vue'
import Layout from '@/components/proxy/Layout.vue'
import ProgressCircular from '@/components/proxy/ProgressCircular.vue'
import {ExtraActionResponse} from '@/lib/kepler/interfaces'

@Component({
  components: {
    ProgressCircular,
    Layout,
    Icon,
    CloseButton: Utils.loadComponent('CloseButton'),
    Button: Utils.loadComponent('Button'),
    Card: Utils.loadComponent('proxy/Card/Card'),
    Img: Utils.loadComponent('proxy/Image'),
  },
  name: 'RechargeDialog',
})
export default class RechargeDialog extends Vue {
  @State('dialogState') public dialogState!: typeof DialogState
  @Prop({type: Function}) protected callback!: (port: string) => Promise<ExtraActionResponse>
  @Prop({type: String, required: false}) protected port?: string

  protected animate: boolean = false
  protected loading: boolean = false
  protected result: string = ''

  protected confirm() {
    EventBus.$emit(this.dialogState[this.dialogState.length - 1].dialogConfirmedEvent)
    this.$dialog.close()
  }

  protected created() {
    if (this.port) {
      this.loading = true
      this.callback(this.port)
        .then((r) => {
          this.result = r.result
          this.animate = true
        })
        .finally(() => {
          this.loading = false
        })
    }
  }

  protected openQr() {
    this.$dialog.close()
    this.$popup.open(QrScanner, {
      props: {
        callback: (port: string) => {
          this.$dialog.open(RechargeDialog, {
            props: {port, callback: this.callback},
          })
        },
      }, fullscreen: true, hideTopbar: true,
    })
  }
}
