import AbstractHardware from '@/lib/hardware/classes/AbstractHardware'
import sdk from '@/lib/kepler/sdk'

interface Ruptela {
  init: (accessToken: string | null, lockId: string, skipRelay: boolean, cb: (status: string) => void) => Promise<string>
  open: () => Promise<string>
  close: () => Promise<string>
  cleanup: () => Promise<string>
}

export interface RuptelaConf {
  reservationId: number
  lockId: string
  skipRelay: boolean
}

export default class RuptelaHardware extends AbstractHardware {

  private readonly conf: RuptelaConf

  private ruptela = (window as any).Ruptela as Ruptela

  constructor(conf: RuptelaConf) {
    super(conf)
    this.conf = conf
  }

  public init(statusCB: (msg: string) => void) {
    if (window.cordova.platformId === 'browser') {
      return Promise.reject('browser is not supported on ruptela hardware')
    }
    if (!this.ruptela) {
      return Promise.reject('ruptela hardware not supported')
    }
    statusCB('Launching ruptela init...')

    return new Promise((resolve, reject) => {
      statusCB('Fetching keys')

      let doInit = (accessToken: string | null) => {
        return this.ruptela.init(accessToken, this.conf.lockId, this.conf.skipRelay, (s) => {
          statusCB(s)
        })
          .then(() => {
            this.addAction('open', () => new Promise((resolve, reject) => {
              this.ruptela.open().then(resolve).catch(reject)
            }), 'ble')
            this.addAction('close', () => new Promise((resolve, reject) => {
              this.ruptela.close().then(resolve).catch(reject)
            }), 'ble')
          })
      }

      sdk.reservation.keys(this.conf.reservationId)
        .then((r) => {
          if (r.data.accessToken) {
            statusCB('Got keys! ' + JSON.stringify(r.data))
            doInit((r.data as any).accessToken).then(resolve).catch(reject)
          } else {
            statusCB('No keys in response!')
            doInit(null).then(resolve).catch(reject)
          }
        }).catch((c) => {
        statusCB('No keys available! ' + c)
        doInit(null).then(resolve).catch(reject)
      })
    })
  }


  cleanup() {
    if (window.cordova.platformId === 'browser') {
      return
    }
    this.ruptela.cleanup().finally(() => {

    })
  }
}
