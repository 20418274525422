













































































































import {Vue, Component, Prop, Watch} from 'vue-property-decorator'
import {DriverEditRequest, ExtraClientField, FieldConfigs, ModifyRequest} from '@/lib/kepler/interfaces'
import Validations from '../../lib/Validations'
import {Getter, State} from 'vuex-class'
import {ClientTypeState} from '@/store/modules/clientTypes'

import Utils from '@/utils'
import {AppConfigState} from '@/store/modules/configuration'
import moment from 'moment'

@Component({
  name: 'PersonalData',
  components: {
    CheckBox: Utils.loadComponent('proxy/Inputs/CheckBox'),
    Container: Utils.loadComponent('proxy/Container'),
    Layout: Utils.loadComponent('proxy/Layout'),
    Flex: Utils.loadComponent('proxy/Flex'),
    FormTag: Utils.loadComponent('proxy/Inputs/FormTag'),
    SelectTag: Utils.loadComponent('proxy/Inputs/SelectTag'),
    TextField: Utils.loadComponent('proxy/Inputs/TextField'),
    PhoneWithPrefix: Utils.loadComponent('PhoneWithPrefix'),
    CountrySelector: Utils.loadComponent('CountrySelector'),
    InputDatePicker: Utils.loadComponent('proxy/Inputs/InputDatePicker'),
  },
})
export default class PersonalData extends Vue {
  @State('configuration') public configuration!: AppConfigState
  @State('clientTypes') public types!: ClientTypeState

  @Getter('fieldConfigs') public fieldConfigs?: FieldConfigs

  @Prop() public driverEditRequest!: DriverEditRequest
  @Prop() public modifyRequest!: ModifyRequest

  @Prop() public business?: boolean
  @Prop() public main?: boolean

  public rules: any = Validations.rules
  public valid: boolean = false
  public titleList: string[] = ['Mr', 'Mrs', 'Ms', 'Miss', '-']
  public extraFields: ExtraClientField[] = []

  protected get birthDateLimits() {
    const min = moment().subtract(100, 'years').startOf('year').format('YYYY-MM-DD')
    const max = moment().format('YYYY-MM-DD')
    return {min, max}
  }

  @Watch('valid', {immediate: true})
  protected onValidChanged(val: boolean) {
    val ? this.$emit('validationStatus', {personal: true}) : this.$emit('validationStatus', {personal: false})
  }

  @Watch('driverEditRequest', {immediate: true, deep: true})
  protected onDriverEdit() {
    if (this.main && !this.business) {
      this.modifyRequest.name = this.driverEditRequest.name
      this.modifyRequest.surname = this.driverEditRequest.surname
      this.modifyRequest.phone = this.driverEditRequest.phone
      this.modifyRequest.mobile = this.driverEditRequest.mobile
      this.modifyRequest.email = this.driverEditRequest.email
      this.modifyRequest.address = this.driverEditRequest.address
    }
  }

  protected fieldConfig(field: string) {
    if (!this.fieldConfigs) {
      return {
        fields: [],
        defaultValue: null,
      }
    }
    const fields = (Utils.getProp(this.fieldConfigs, field.split('.')) || '').split('|') || []
    let defaultValue = fields.find((q: string) => q.startsWith('default:') && !!q.split(':')[1]) || null

    if (defaultValue) {
      defaultValue = defaultValue.split(':')[1]
      // remove default from fields
      fields.splice(fields.indexOf(defaultValue), 1)
    }

    return {
      fields,
      defaultValue,
    }
  }

  protected setDefaultsAddressIfHidden() {
    const config = (key: string) => this.fieldConfig(`driver.address.${key}`)
    const address = this.driverEditRequest.address
    const keys = Object.keys(address) as Array<keyof typeof address>
    keys.forEach((key) => {
      if (
        config(key).fields.includes('hidden') &&
        config(key).defaultValue !== null &&
        this.driverEditRequest.address[key] === null
      ) {
        this.driverEditRequest.address[key] = config(key).defaultValue || ''
      }
    })
  }

  protected setFiscalCodeIfHidden() { // PPH-711
    const config = this.fieldConfig(`client.fiscal_code`)
    if (config.fields.includes('hidden') && config.defaultValue && !this.modifyRequest.fiscal_code && !this.business) {
      this.modifyRequest.fiscal_code = config.defaultValue
    }
  }

  protected mounted() {
    this.extraFields = this.configuration.appConfig.extra_client_fields.filter((field) => field.validation !== 'hidden')
    this.$nextTick(() => {
      if (this.$refs.accountForm !== undefined) {
        (this.$refs.accountForm as any).validate()
      }
    })
    this.setDefaultsAddressIfHidden()
    this.setFiscalCodeIfHidden()
  }

  protected setBirthDate(birthdate: string) {
    this.driverEditRequest.birthdate = birthdate
  }

  protected extraRules(key: string) {
    if (this.extraFields.length) {
      const extra = this.extraFields.find((field) => {
        return field.value === key
      })
      if (extra && extra.validation) {
        const validation = extra.validation
        const rulesArray: [() => boolean] | Array<null> = []
        validation.split('|').forEach((rule) => {
          if (rule.startsWith('digits_between')) {
            const x = rule.split(':').pop()
            if (x) {
              const minMax = x.split(',') as string[]
              rulesArray.push(this.rules.digitsBetween(minMax[0], minMax[1]))
            }
          }
          switch (rule) {
            case 'required':
              rulesArray.push(this.rules.required)
              break
            case 'numeric':
              rulesArray.push(this.rules.number)
              break
            case 'boolean':
              rulesArray.push(this.rules.boolean)
              break
          }
        })
        if (validation && validation.includes('nullable')) {
          rulesArray.push(this.rules.nullable)
        }
        return rulesArray
      }
    }
  }
}
