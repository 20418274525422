










































import {Vue, Component, Watch, Prop} from 'vue-property-decorator'
import Utils from '../utils'
import {State} from 'vuex-class'
import {AppConfigState} from '@/store/modules/configuration'
import Countries, {Country} from '@/lib/kepler/countries'
import {VListTileAction, VListTileContent, VListTileTitle} from 'vuetify/lib'

interface Phone {
  [key: string]: any

  prefix: Country,
  number: string | number
}

@Component({
  components: {
    Input: Utils.loadComponent('proxy/Inputs/Input'),
    AutoComplete: Utils.loadComponent('proxy/Inputs/AutoComplete'),
    Container: Utils.loadComponent('proxy/Container'),
    Layout: Utils.loadComponent('proxy/Layout'),
    TextField: Utils.loadComponent('proxy/Inputs/TextField'),
    VListTileAction,
    VListTileContent,
    VListTileTitle,
  },
  name: 'PhoneWithPrefix',
})
export default class PhoneWithPrefix extends Vue {
  @State('configuration') public configState!: AppConfigState

  @Prop({
    type: String,
  }) public value!: string
  @Prop({
    type: String,
  }) public label!: string
  @Prop({}) public rules!: any[]
  @Prop({
    type: Boolean,
  }) public required!: boolean
  @Prop({
    type: Boolean,
    default: false,
  }) public disabled!: boolean

  protected delimiter: string = '-'
  protected countries: Country[] = Countries

  protected phoneObject: Phone = {
    prefix: {
      name: '',
      dial_code: '',
      code: '',
      flag: '',
    },
    number: '',
  }

  protected update(phone: Phone) {
    const p = phone.prefix.dial_code
    const n = phone.number
    this.$emit('input', p && n ? p + this.delimiter + n : '')
  }

  protected get lengthClass() {
    if (this.phoneObject.prefix && this.phoneObject.prefix.dial_code) {
      return `length-${this.phoneObject.prefix.dial_code.replace('+', '').length}`
    }
  }

  protected findPrefix(code: string, isNumber?: boolean) {
    const p = this.countries.filter((pfx) => {
      if (isNumber) {
        return pfx.dial_code === code
      }
      return pfx.code === code
    })[0]
    return p || this.phoneObject.prefix
  }

  protected mounted() {
    if (!this.phoneObject.prefix.dial_code && !this.value) {
      this.phoneObject.prefix = this.findPrefix(this.configState.appConfig?.default_country_code)
    } else if (!this.phoneObject.prefix.dial_code && !!this.value) {
      this.splitValue()
    }
  }

  protected splitValue() {
    const phoneString = this.phoneObject.prefix.dial_code + this.delimiter + this.phoneObject.number
    if (!!this.value && this.value.includes(this.delimiter) && this.value !== phoneString) {
      // value exists and includes delimiter and value is different from phoneString
      const split = this.value.split(this.delimiter)
      if (split.length > 1) {
        this.phoneObject.prefix = this.findPrefix(split[0], true)
        this.phoneObject.number = split[1]
      }
    }
  }

  @Watch('phoneObject', {deep: true, immediate: true})
  protected prefixChanged(phone: Phone) {
    this.update(phone)
  }

  @Watch('value', {immediate: false})
  protected valueChanged() {
    this.splitValue()
  }

  protected get prefixHint() {
    return this.$isAvailable('phone.prefixHint.' + this.phoneObject.prefix.code)
  }
}
