import moment from 'moment'

export default class DateHelper {

  private static date: any

  public static parse(date: string, format?: string | string[]) {
    return moment(date, format)
  }

  // get duration or offset between two dates and specify input format (es. X unix timestamp)
  public static getTimeOffset(date1: any, date2: any, format: string = 'X') {
    date1 = moment(date1, format)
    date2 = moment(date2, format)
    this.date = moment.duration(date1.diff(date2))
    return this
  }

  public static setTimeOffset(v: number, format = 'minutes') {
    this.date = moment.duration(v, format as any)
    return this
  }

  public static formatTimeOffset(format: string) {
    /**
     * months days hours minutes
     * days hours minutes
     * months
     * days
     * hours
     * minutes
     */
    switch (format) {
      case'mdhm':
        return `${-this.date.month()}m ${-this.date.day()}d ${-this.date.hours()}h ${-this.date.minutes()}m`
      case'dhm':
        let dhm = this.date._milliseconds > 0 ? '-' : ''

        const absolute = (unit: string) => {
          return Math.abs(this.date[unit]())
        }

        if (this.date.days() < 0) {
          dhm += `${absolute('days')}d `
        }

        dhm += `${absolute('hours')}h ${absolute('minutes')}m`
        return dhm
      case'month':
        return `${-this.date.month()}m`
      case'day':
        return `${-this.date.days()}d`
      case'hour':
        return `${-this.date.hour()}h`
      case'min':
        return `${-this.date.minute()}m`
      default:
        return 'unsupported format'
    }
  }

  public static now(format: string | undefined = 'YYYY-MM-DD') {
    return moment().format(format)
  }

  public static getTimestamp() {
    return moment().unix()
  }

  public static formatDate(date: string, format: string) {
    return moment(date).format(format)
  }

  // compares a date to current time and set the minutes of given date to "half past to" or to "o'clock"
  public static setToHalfPastOrToOClock(date: moment.Moment) {
    const now = this.parse(this.now('HH:mm:ss'))
    if (now.minutes() < 30) {
      date.minutes(0)
    } else {
      date.minutes(30)
    }
    return date
  }

  public static equalsToday(date: moment.Moment) {
    return this.now() === date.format('YYYY-MM-DD')
  }

  public static humanizeDuration(val: number, unit: any) {
    return moment.duration(val, unit).humanize()
  }

  public static parseMinutes(scope: any, val: number): string {
    if (val < 1440) {
      switch (val) {
        case 1:
          return `${scope.$t('common.time_units.minute')}`
        case 60:
          return `${scope.$t('common.time_units.hour')}`
        default:
          if (!(val % 60)) {
            return scope.$t('common.time_units.hours', {n: val / 60})
          } else {
            return scope.$t('common.time_units.minutes', {n: val})
          }
      }
    } else {
      const d = val / 1440
      switch (d) {
        case 1:
          return scope.$t('common.time_units.day')
        case 7:
          return scope.$t('common.time_units.week')
        case 30:
          return scope.$t('common.time_units.month')
        case 60:
          return scope.$t('common.time_units.two-month')
        case 90:
          return scope.$t('common.time_units.quarter')
        case 365:
          return scope.$t('common.time_units.year')
        default:
          if (!(d % 30)) {
            return scope.$t('common.time_units.months', {n: d / 30})
          } else if (!(d % 7)) {
            return scope.$t('common.time_units.weeks', {n: d / 7})
          } else {
            return scope.$t('common.time_units.days', {n: d})
          }
      }
    }
  }
}
