





import {Component, Prop} from 'vue-property-decorator'
import {VehicleType} from '@/lib/kepler/interfaces'
import ServiceMesh from '@/lib/serviceMesh'
import CustomIcon from '@/components/CustomIcon.vue'
import {Component as VueComponent} from 'vue/types/options'

@Component({
  components: {},
  name: 'VehicleIcon',
})
export default class VehicleIcon extends CustomIcon {
  @Prop({type: String, required: true}) protected type!: VehicleType

  protected image: VueComponent | null = null

  protected created() {
    ServiceMesh.getImagePin(this.type).then((r: VueComponent) => {
      this.image = r || null
    })
  }
}
