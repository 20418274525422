












import {Vue, Component, Prop} from 'vue-property-decorator'
import Utils from '@/utils'

@Component({
  name: 'ErrorDialog',
  components: {
    Img: Utils.loadComponent('proxy/Image'),
    Flex: Utils.loadComponent('proxy/Flex'),
    Layout: Utils.loadComponent('proxy/Layout'),
    Container: Utils.loadComponent('proxy/Container'),
    Card: Utils.loadComponent('proxy/Card/Card'),
    Button: Utils.loadComponent('Button'),
  },
})
export default class ErrorView extends Vue {
  @Prop({default: 'error.svg'}) private image?: string
  @Prop({default: '-TITLE-'}) private title!: string
  @Prop({default: '-SUBTITLE-'}) private subtitle!: string
}
