




import {Vue, Component, Prop, PropSync} from 'vue-property-decorator'
import Validations from '@/lib/Validations'
import {State} from 'vuex-class'
import Utils from '@/utils'

import {extend} from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import {FlowInputField as Field, FlowInputStep} from '@/lib/kepler/interfaces'

import TextFieldWithValidation from '@/components/fields/TextFieldWithValidation.vue'
import CountriesFieldWithValidation from '@/components/fields/CountriesFieldWithValidation.vue'
import CheckboxFieldWithValidation from '@/components/fields/CheckboxFieldWithValidation.vue'
import SelectFieldWithValidation from '@/components/fields/SelectFieldWithValidation.vue'
import DateFieldWithValidation from '@/components/fields/DateFieldWithValidation.vue'
import RegistrationImageComponent from '@/components/registration/RegistrationImageComponent.vue'
import {VueConstructor} from 'vue'

for (const [rule, validation] of Object.entries(rules)) {
  extend(rule, {
    ...validation,
  })
}

class ComponentConfig {
  public component: VueConstructor<Vue>
  public props: { [key: string]: any }

  constructor(component: VueConstructor<Vue>, props: { [key: string]: any }) {
    this.component = component
    this.props = props
  }
}

@Component({
  components: {
    RegistrationAddressComponent: Utils.loadComponent('registration/RegistrationAddressComponent'),
    CountriesFieldWithValidation: Utils.loadComponent('fields/CountriesFieldWithValidation'),
    SelectFieldWithValidation: Utils.loadComponent('fields/SelectFieldWithValidation'),
    DateFieldWithValidation: Utils.loadComponent('fields/DateFieldWithValidation'),
    TextFieldWithValidation: Utils.loadComponent('fields/TextFieldWithValidation'),
    CheckBox: Utils.loadComponent('proxy/Inputs/CheckBox'),
    Picture: Utils.loadComponent('registration/RegistrationImageComponent'),
  },
  name: 'FlowFieldSimple',
})

export default class FlowFieldSimple extends Vue {
  @State('flowOutputs') public flowOutputs!: { [k: string]: any }
  @Prop() public step?: FlowInputStep
  @Prop() public field!: Field
  @Prop() public disabled?: boolean
  @PropSync('value') public value!: any

  public rules: any = Validations.rules
  public currentRules: any[] = []
  public required: boolean = false

  protected get componentConfig() {
    /* tslint:disable: cyclomatic-complexity */
    const name = this.field.name
    const props: { [key: string]: any } = {
      name,
      label: this.$t(name),
      rules: this.field.validation,
      required: this.required,
      hint: this.$t(this.field.description || ''),
      disabled: this.disabled,
    }
    let component: VueConstructor
    switch (this.field.type) { // add specific field attributes
      case 'name':
        props.type = 'fname'
        props.autocomplete = 'given-name'
        break
      case 'surname':
        props.type = 'lname'
        props.autocomplete = 'family-name'
        break
      case 'email':
        props.type = 'email'
        props.autocomplete = 'email'
        break
      case 'date':
        props.fullWidth = true
        break
      case 'checkbox':
        props.defaultValue = this.field.value
        break
      case 'country':
      case 'select':
        props.items = this.field.options
    }

    switch (this.field.type) {
      case 'name':
      case 'surname':
      case 'email':
      case 'string':
        component = TextFieldWithValidation
        break
      case 'date':
        component = DateFieldWithValidation
        break
      case 'select':
        component = SelectFieldWithValidation
        break
      case 'checkbox':
        component = CheckboxFieldWithValidation
        break
      case 'country':
        component = CountriesFieldWithValidation
        break
      case 'image':
        component = RegistrationImageComponent
        break
      default:
        throw new Error('field type not supported')
    }

    return new ComponentConfig(component, props)
  }

  protected parseRules() {
    const arr = []
    const val = this.field.validation

    if (val) {
      if (typeof val === 'string') {
        const v = val.split('|')
        if (v.includes('required')) {
          this.required = true
          arr.push(this.rules.required)
        }
        if (v.includes('email')) {
          arr.push(this.rules.email)
        }
      } else if (typeof val === 'object') {
        if (val.hasOwnProperty('required')) {
          this.required = true
          arr.push(this.rules.required)
        }
        if (val.hasOwnProperty('email')) {
          arr.push(this.rules.email)
        }
      }
    }

    return arr
  }

  protected created() {
    this.currentRules = this.parseRules()
  }

}
