
import {Component, Prop, Vue} from 'vue-property-decorator'

import {VExpansionPanelContent} from 'vuetify/lib'

@Component({
  name: 'AccordionContent',
  components: {VExpansionPanelContent},
  extends: VExpansionPanelContent as any,
})
export default class AccordionContent extends Vue {
  @Prop({
    type: String,
    default: () => 'mdi-chevron-double-down',
  }) public expandIcon!: string
}
