







import {Vue, Component, Prop, Ref, Watch} from 'vue-property-decorator'
import {LatLng} from './models/LatLng'
import {GoogleMap} from './providers/Google'
import {Base} from './providers/Base'
import {Mapbox} from './providers/Mapbox'
import {Bing} from './providers/Bing'
import {Here} from './providers/Here'
import {Leaflet} from './providers/Leaflet'
import {Openlayers} from './providers/Openlayers'
import {Baidu} from './providers/Baidu'
import {Yandex} from './providers/Yandex'
import {MapLibre} from '@/lib/n-maps/src/providers/MapLibre'

@Component({
  components: {},
})
/*
 * TODO:
 *  - Click handler on map https://gis.stackexchange.com/questions/238414/leaflet-how-to-add-a-new-and-remove-an-old-marker-every-time-the-user-click-on
 *  - pan
 *  - zoom
 */
export default class NMap extends Vue {
  @Prop({type: String}) readonly provider?: string
  // Provider specific config
  @Prop({type: Object}) readonly config!: any

  // Common map settings
  @Prop({type: Object}) readonly center!: LatLng
  @Prop({type: Number, default: 13}) readonly zoomLevel!: number

  @Ref() readonly map!: HTMLElement

  public providerInstance: Base | null = null

  public isRootNMap = true

  public setUpProvider() {
    switch (this.provider) {
      case 'google':
        this.providerInstance = new GoogleMap(this.config)
        break
      case 'mapbox':
        this.providerInstance = new Mapbox(this.config)
        break
      case 'bing':
        this.providerInstance = new Bing(this.config)
        break
      case 'here':
        this.providerInstance = new Here(this.config)
        break
      case 'leaflet':
        this.providerInstance = new Leaflet(this.config)
        break
      case 'openlayers':
        this.providerInstance = new Openlayers(this.config)
        break
      case 'baidu':
        this.providerInstance = new Baidu(this.config)
        break
      case 'yandex':
        this.providerInstance = new Yandex(this.config)
        break
      case 'maplibre':
        this.providerInstance = new MapLibre(this.config)
        break
      case undefined:
        console.error('NMap', 'UNDEFINED PROVIDER')
        this.providerInstance = null
        break
      default:
        console.error('NMap', this.provider, 'NOT IMPLEMENTED')
        this.providerInstance = null
    }
  }

  public setup() {
    this.setUpProvider()
    if (this.providerInstance && !this.providerInstance.ready) {
      Promise.all(this.providerInstance.loadResources()).then(this.init)
    }
  }

  public mounted() {
    this.setup()
  }

  public init() {
    if (this.providerInstance) {
      this.providerInstance.initMap(this.map, {
        center: this.center,
        zoom: this.zoomLevel,
      }, () => {
        if (this.providerInstance) {
          this.providerInstance.ready = true
        }
        this.$emit('map-ready')

        // Set up click listener
        if (this.$listeners && this.$listeners.click) {
          this.providerInstance?.setClickListener((p: LatLng) => {
            // console.log('clicked ' + this.provider + ' map', p)
            this.$emit('click', p)
          })
        }
      })
    }
  }

  public pan(p: LatLng) {
    this.readyDo(() => {
      this.providerInstance?.pan(p)
    })
  }

  public zoom(l: number) {
    this.readyDo(() => {
      this.providerInstance?.zoom(l)
    })
  }

  public getBoundsAndPan(p: LatLng[]) {
    this.readyDo(() => {
      this.providerInstance?.getBoundsAndPan(p)
    })
  }

  public refresh() {
    this.readyDo(() => {
      this.providerInstance?.refresh()
    })
  }

  public log(message: string, type: string = 'info') {
    if (type === 'info') {
      // @ts-ignore
      console.log(message)
    } else {
      alert(type + ': ' + message)
    }
  }

  // Implement destroy

  @Watch('config', {immediate: true, deep: true})
  public refreshMapView() {
    if (this.providerInstance) {
      this.destroyMap()
      this.setup()
    }
  }

  public destroyMap() {
    if (this.providerInstance?.ready) {
      this.providerInstance.ready = false
      this.providerInstance.destroy()
      this.$set(this, 'providerInstance', null)
    }
  }

  public beforeDestroy() {
    this.destroyMap()
  }

  private readyDo(callback: () => void) {
    if (this.providerInstance && !this.providerInstance.ready) {
      // console.log("DELAYED")
      this.$once('map-ready', () => {
        this.readyDo(callback)
      })
    } else {
      // console.log("RAN")
      callback()
    }
  }
}
