





import {Vue, Component, Prop} from 'vue-property-decorator'
import {VChip} from 'vuetify/lib'
import VuetifyColorHelper from '@/lib/vuetify/VuetifyColorHelper'

@Component({components: {VChip}})
export default class Chip extends Vue {
  @Prop() protected color!: string

  protected parseColor(color: string) {
    return VuetifyColorHelper.color(color)
  }

  protected get dark() {
    return !VuetifyColorHelper.lightContrast(this.color)
  }
}
