
import {Component, Vue} from 'vue-property-decorator'

import {VSubheader} from 'vuetify/lib'

@Component({
  name: 'Subheader',
  extends: VSubheader as any,
})
export default class Subheader extends Vue {
}
