


































import {Vue, Component, Watch} from 'vue-property-decorator'
import {Action, Getter, State} from 'vuex-class'

import {SubscriptionPlan} from '@/lib/kepler/interfaces'
import Utils from '@/utils'
import {Route} from 'vue-router'

@Component({
  components: {
    Flex: Utils.loadComponent('proxy/Flex'),
    Divider: Utils.loadComponent('proxy/Divider'),
    GradientCard: Utils.loadComponent('GradientCard'),
    Layout: Utils.loadComponent('proxy/Layout'),
    Card: Utils.loadComponent('proxy/Card/Card'),
    ProgressCircular: Utils.loadComponent('proxy/ProgressCircular'),
    IsLogged: Utils.loadComponent('IsLogged'),
    CardPlan: Utils.loadComponent('subscriptions/CardPlan'),
    Container: Utils.loadComponent('proxy/Container'),
  },
})
export default class Plans extends Vue {
  @State((state) => state.subscriptions.plans) public plans?: SubscriptionPlan[]
  @State((state) => state.configuration.appConfig.default_plan) public defaultPlanId?: string

  @Getter('defaultPlan') public defaultPlan!: SubscriptionPlan | null

  @Action('getPlans') public getPlans!: () => Promise<SubscriptionPlan[]>

  public loading: boolean = true
  public wrapperScroll: number = 0

  public get notice() {
    return this.$isAvailable('plans.notice')
  }

  protected get availablePlans() {
    if (this.plans && this.plans.length) {
      return this.plans.filter((p) => {
        const available = p.status === 'AVAILABLE'
        const valid = (p.valid_to_timestamp === null) || (p.valid_to_timestamp > Math.floor(Date.now() / 1000))
        return available && valid && p.id !== this.defaultPlanId
      })
    }
    return []
  }

  public created() {
    this.getPlans().finally(() => {
        this.loading = false
    })
  }

  public openDetail(id: string) {
    this.$router.push({name: 'planDetail', params: {id}})
  }

  @Watch('$route', {deep: true, immediate: false})
  public onRouteChanged(to: Route) {
    const wrapperEl = this.$refs.wrapper as Element
    if (to.name === 'planDetail') {
      this.wrapperScroll = wrapperEl.scrollTop
      this.$nextTick(() => {
        this.$vuetify.goTo(0, {container: '#plans-wrapper', duration: 0})
      })
    } else if (to.name === 'plans') {
      this.$nextTick(() => {
        this.$vuetify.goTo(this.wrapperScroll, {container: '#plans-wrapper'})
      })
    }
  }
}
