







import {Component, Prop, Watch} from 'vue-property-decorator'

import {LatLng} from './models/LatLng'
import NMapChildren from './NMapChildren.vue'


@Component({
  components: {},
})
export default class NMapCircle extends NMapChildren {
  @Prop() public position!: LatLng
  @Prop() public color!: string
  @Prop({default: 400}) public readonly radius!: number
  public pos: LatLng | null = null
  public rad: number | null = null

  public init() {
    this.item = this.providerInstance().addCircle(
      this.position,
      this.radius,
      this.$slots.default ? this.$slots.default[0].elm : null,
      this.$listeners && this.$listeners.click ? () => {
        this.$emit('click',)
      } : ()=>{},
      this.color
    )
    this.setPos(this.position)
    this.setRad(this.radius)
  }

  public setPos(pos: LatLng) {
    this.pos = JSON.parse(JSON.stringify(pos))
  }
  public setRad(rad: number) {
    this.rad = rad
  }

  @Watch('radius')
  public valueChanged(rad: number) {
    const equals = rad !== this.rad
    if (!this.isReady() || equals) {
      return
    }
    if (this.item) {
      this.providerInstance().setCircle(this.item, rad)
    } else {
    this.clean()
    this.init()
    }
    this.setRad(rad)
  }

  @Watch('position', {deep: true})
  public positionChanged(pos: LatLng) {
    const equalsOrNull = this.pos ? LatLng.equals(pos, this.pos) : true
    if (!this.isReady() || equalsOrNull) {
      return
    }
    this.setPos(pos)
    if (this.item) {
      this.providerInstance().moveMarker(this.item, pos)
    } else {
      this.clean()
      this.init()
    }
  }
}
