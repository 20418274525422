













































































import {Vue, Component, Prop} from 'vue-property-decorator'
import {State} from 'vuex-class'

import {Pos, Position, ReservationResponse, VehicleSlot} from '@/lib/kepler/interfaces'
import Utils from '@/utils'
import {VDialog} from 'vuetify/lib'
import VehicleSlotHelper from '@/lib/vehicleSlot'
import Locate from '@/lib/location'

interface InfoButton {
  icon: string
  id: string
  text: string
  fullwidth?: boolean
  action?: string
}

@Component({
  components: {
    CloseButton: Utils.loadComponent('CloseButton'),
    Container: Utils.loadComponent('proxy/Container'),
    Layout: Utils.loadComponent('proxy/Layout'),
    Flex: Utils.loadComponent('proxy/Flex'),
    Button: Utils.loadComponent('Button'),
    Card: Utils.loadComponent('proxy/Card/Card'),
    Icon: Utils.loadComponent('proxy/Icon'),
    Img: Utils.loadComponent('proxy/Image'),
    Divider: Utils.loadComponent('proxy/Divider'),
    GradientCard: Utils.loadComponent('GradientCard'),
    TripList: Utils.loadComponent('entities/reservation/TripList'),
    VehicleLocation: Utils.loadComponent('entities/vehicle/VehicleLocation'),
    VehicleInstructions: Utils.loadComponent('VehicleInstructions'),
    VDialog,
  },
  name: 'VehicleDetail',
})
export default class VehicleDetail extends Vue {
  @State((state) => state.configuration.appConfig?.fields_configuration?.parking_distance_threshold ?? '75')
  public distanceThreshold!: string

  @Prop({type: Object, required: true}) public vehicleSlot!: VehicleSlot
  @Prop({type: Object, required: false}) public reservation?: ReservationResponse

  @Prop({type: Boolean, default: true}) public showLocation!: boolean
  @Prop({type: Boolean, default: true}) public showInstructions!: boolean
  @Prop({type: Boolean, default: true}) public showRates!: boolean
  @Prop({type: Boolean, default: false}) public showTrips!: boolean

  protected selectedButton: string | null = null

  protected get slotHelper() {
    return new VehicleSlotHelper(this.vehicleSlot)
  }

  protected get dialogOpen() {
    return !!this.selectedButton
  }

  protected set dialogOpen(v: boolean) {
    if (!v) {
      this.selectedButton = null
    }
  }

  protected get baseRate() {
    return this.vehicleSlot.base_rate
  }

  protected get buttons() {
    /* tslint:disable: cyclomatic-complexity */ // TODO: refactor, maybe?
    const xs = this.$vuetify.breakpoint.xsOnly
    const vs = this.vehicleSlot
    const conditions: Record<string, boolean> = {
      rate: !!this.baseRate && this.showRates && !this.baseRate.info.hidden,
      info: !!vs.vehicle.instructions?.length && this.showInstructions,
      trips: !!this.reservation?.trips?.length && this.showTrips,
      location: vs.position && this.showLocation,
    }
    const shownNum = Object.values(conditions).filter(Boolean).length

    const buttons: InfoButton[] = [{
      id: 'rate',
      icon: 'mdi-cash-multiple',
      text: this.baseRate?.name,
      fullwidth: xs && shownNum > 2 && !conditions.location,
      action: this.$isAvailable('booking.detail.rate'),
    }, {
      id: 'info',
      icon: 'mdi-information',
      text: this.$t('booking.instructions'),
      action: this.$isAvailable('booking.detail.info'),
    }, {
      id: 'trips',
      icon: 'mdi-map-marker-path',
      text: this.$t('booking.trips.trips'),
      action: this.$isAvailable('booking.detail.trips'),
    }, {
      id: 'location',
      icon: vs.reservation_type === 'FF' ? 'mdi-map-marker' : 'mdi-parking',
      text: vs.lot.name || '',
      fullwidth: xs,
      action: this.$isAvailable('booking.detail.location'),
    }]

    const arr: InfoButton[] = []
    buttons.forEach((button) => {
      if (conditions[button.id]) {
        arr.push(button)
      }
    })
    return arr
  }

  protected get image() {
    return this.vehicleSlot.lot.picture.includes('404') ? false : this.vehicleSlot.lot.picture
  }

  protected get distanceString() {
    const d: number | null = this.vehicleSlot.distance
    if (d !== null) {
      const str = this.$t('common.circa')
      if (this.$distance().toLowerCase() === 'km') {
        return str + ' ' + (d >= 1000 ? this.$distance(Math.round((d / 1000) * 10) / 10) : Math.floor(d) + 'm')
      } else {
        return str + ' ' + this.$distance(Math.round(d))
      }
    } else {
      // TODO: translate
      return false
    }
  }

  protected get destination(): Pos | undefined {
    const position = this.reservation?.current_vehicle_position || this.vehicleSlot.position
    if (position) {
      return {lat: position.latitude, lng: position.longitude}
    }
  }

  protected get distanceFromParking() {
    const res = this.reservation
    const pos = res?.current_vehicle_position
    if (pos) {
      const distance = Locate.calculateDistance(Locate.PositionToPos(pos), Locate.PositionToPos(this.vehicleSlot.position))
      return Math.floor(distance)
    }
    return null
  }

  protected get notYetStarted() {
    return this.reservation?.status === 'CONFIRMED'
  }

  protected get parkingButton() {
    if (this.reservation?.type) {
      return ['RT', 'CRT'].includes(this.reservation.type) && this.reservation.status === 'STARTED'
    }
    return false
  }

  public get parsedRate() {
    const r = this.vehicleSlot.base_rate
    const parsedistance = (val: string) => this.$distance(val && Number(val) !== 1 ? val : undefined)
    if (r) {
      const [timeCost, timeCycle] = r.info?.time_rate?.split('|') ?? [null, null]
      const [distanceCost, distanceCycle] = r.info?.distance_rate?.split('|') ?? [null, null]
      const time = timeCost !== null && timeCycle !== null ? {
        cost: this.$currency(timeCost),
        cycle: this.$parseMinutes(timeCycle),
      } : null
      const distance = distanceCost !== null && distanceCycle !== null ? {
        cost: this.$currency(distanceCost),
        cycle: parsedistance(distanceCycle),
      } : null
      const usageCost = r.info?.usage_cost || null
      return {time, distance, usageCost}
    }
  }

  protected select(idx?: number) {
    this.$set(this, 'selectedButton', typeof idx === 'number' ? this.buttons[idx] : null)
  }

  protected openInMaps(p: Position) {
    const {lat, lng} = Locate.PositionToPos(p)
    const url = `https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}&=travelmode=walking`
    cordova.InAppBrowser.open(url, '_system')
  }
}
