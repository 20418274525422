import {ActionTree, MutationTree} from 'vuex'
import sdk from '@/lib/kepler/sdk'
import {ReportRequest, ReportType} from '@/lib/kepler/interfaces'
import {RootState} from '@/store'

export class ReportingsState {
  public reportTypes: ReportType[] = []
}

const mutations: MutationTree<ReportingsState> = {
  REPORT_TYPES(stateO, payload) {
    stateO.reportTypes = payload
  },
}

const actions: ActionTree<ReportingsState, RootState> = {
  init({dispatch}) {
    if (sdk.people.isLogged()) {
      dispatch('reportTypes')
    }
  },
  sendReport({}, payload: ReportRequest) {
    return sdk.booking.report(payload)
  },
  reportTypes({commit, dispatch}) {
    return sdk.booking.reportingTypes().then((r) => {
      commit('REPORT_TYPES', r.data)
      dispatch('sleep', 'report_types')
    }).catch(() => {
      return
    })
  },
}

export default {
  state: new ReportingsState(),
  mutations,
  actions,
}
