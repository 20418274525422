
import {Component, Vue} from 'vue-property-decorator'

import { VBtn } from 'vuetify/lib'

@Component({
  name: 'Btn',
  extends: VBtn as any,
})
export default class Btn extends Vue {

}
