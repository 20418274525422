import {LatLng} from '../models/LatLng'
import {MarkerLabel} from '@/lib/n-maps/src/models/MarkerLabel'

export abstract class Base {
  public config: any
  public ready: boolean = false
  // Will be replaced with specific provider
  public map: any

  constructor(config: any) {
    this.config = config
  }

  public get provider() {
    return this.constructor.name.toLowerCase()
  }

  public abstract loadResources(): any[]

  public abstract initMap(element: HTMLElement, initConfig: any, s: () => void): void

  public abstract setClickListener(l: (p: LatLng) => void): void

  public abstract pan(p: LatLng): void

  public abstract zoom(level: number): void

  public destroy(): void {
    // Implement
  }

  public cleanChild(c: any): void {
    console.warn('cleanChild NOT IMPLEMENTED')
  }

  public addPolyline(points: LatLng[], lineWidth: number, color: string, tooltip: string | null = null): void {
    console.warn('addPolyline NOT IMPLEMENTED', this.constructor.name)
  }

  public addPolygon(points: LatLng[], lineWidth: number, color: string, popupElement: any, click: () => void): void {
    console.warn('addPolygon NOT IMPLEMENTED', this.constructor.name)
  }

  public addMarker(point: LatLng, icon: string | HTMLElement, popupElement: any, click: null | (() => void), clustered: boolean, label?: MarkerLabel): void {
    console.warn('addMarker NOT IMPLEMENTED', this.constructor.name)
  }

  public moveMarker(item: any, point: LatLng) {
    console.warn('moveMarker NOT IMPLEMENTED', this.constructor.name)
  }

  public addCircle(point: LatLng, radius: number, popupElement: any, click: () => void, color?: string) {
    console.warn('addCircle NOT IMPLEMENTED', this.constructor.name)
  }

  public moveCircle(item: any, point: LatLng) {
    console.warn('moveCircle NOT IMPLEMENTED', this.constructor.name)
  }

  public setCircle(item: any, radius: number) {
    console.warn('setCircle NOT IMPLEMENTED', this.constructor.name)
  }

  public markerClusterLib(): any[] {
    return []
  }

  public markerCluster(item: any, child: any): void {
    console.warn('addMarkerCluster NOT IMPLEMENTED', this.constructor.name)
  }

  public addHeatmap(points: LatLng[]): void {
    console.warn('addHeatmap NOT IMPLEMENTED', this.constructor.name)
  }

  public getBoundsAndPan(points: LatLng[]): void {
    console.warn('getBoundsAndPan NOT IMPLEMENTED', this.constructor.name)
  }

  public refresh(): void {
  }

  protected toPoint(p: LatLng): any {
    return p
  }
}
