
import {Component, Vue} from 'vue-property-decorator'

import { VCard } from 'vuetify/lib'

@Component({
  name: 'Card',
  extends: VCard as any,
})
export default class Card extends Vue {

}
