
























import {Component, Vue, Prop} from 'vue-property-decorator'
import VuetifyColorHelper from '@/lib/vuetify/VuetifyColorHelper'
import Utils from '@/utils'

@Component({
  components: {
    Btn: Utils.loadComponent('proxy/Btn'),
  },
})
export default class Button extends Vue {
  @Prop({}) public link?: string
  @Prop({
    type: Boolean,
    default: false,
  }) public stroked?: boolean
  @Prop({}) public textColor?: string
  @Prop({
    type: Boolean,
    default: false,
  }) public block?: boolean
  @Prop({
    type: Boolean,
    default: false,
  }) public disabled?: boolean
  @Prop({
    type: Boolean,
    default: false,
  }) public loading?: boolean
  @Prop({
    type: Boolean,
    default: false,
  }) public icon?: boolean
  @Prop({
    type: Boolean,
    default: true,
  }) public round?: boolean
  @Prop({
    type: Boolean,
  }) public light?: boolean
  @Prop({
    type: Boolean,
  }) public dark?: boolean
  @Prop({
    type: Boolean,
  }) public bottom?: boolean
  @Prop({
    type: Boolean,
  }) public top?: boolean
  @Prop({
    type: Boolean,
    default: false,
  }) public flat?: boolean
  @Prop({
    type: Boolean,
    default: false,
  }) public small?: boolean
  @Prop({
    default: 'buttonDefaultColor',
  }) public color!: string

  protected styleObj?: object = {}

  protected get colorName() {
    if (this.disabled) {
      return ''
    }

    if (this.color.startsWith('#')) {
      this.styleObj = {
        'background-color': this.color,
        'border-color': this.color,
        'color': this.textColor,
      }
    } else {
      return VuetifyColorHelper.color(this.color)
    }
  }

  private get isLight() {
    if (this.light) {
      return true
    }
    if (!this.disabled) {
      const override = this.$branding.theme.buttonDefaultColorOverride as string | undefined
      if (override) {
        return VuetifyColorHelper.lightContrast(VuetifyColorHelper.color(override))
      } else {
        return VuetifyColorHelper.lightContrast(VuetifyColorHelper.color(this.color))
      }
    } else {
      return !this.$vuetify.dark
    }
  }
}
