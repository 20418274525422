
import {Component, Vue} from 'vue-property-decorator'
import {Base} from '@/lib/n-maps/src/providers/Base'
import NMap from "@/lib/n-maps/src/NMap.vue";

@Component({})
export default class NMapChildren extends Vue {
  public item: any = null

  public isReady() {
    const p = this.providerInstance()
    return p && p.ready
  }

  public providerInstance() : Base {
    if ((this.$parent as any).isRootNMap) {
      return ((this.$parent as NMap).providerInstance as Base)
    } else {
      return ((this.$parent.$parent as NMap).providerInstance as Base)
    }
  }

  public mounted() {
    if (this.isReady()) {
      this.init()
    } else {
      this.$parent.$on('map-ready', () => {
        this.init()
      })
    }
  }

  // updated = cleanChild + init?

  public init() {}

  public beforeDestroy() {
    this.clean()
  }

  public clean() {
    if (this.item !== null) {
      const p = this.providerInstance()
      if (p) {
        p.cleanChild(this.item)
      }
    }
  }
}
