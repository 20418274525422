
import {Component, Vue} from 'vue-property-decorator'

import { VListTile } from 'vuetify/lib'

@Component({
  name: 'ListTile',
  extends: VListTile as any,
})
export default class ListTile extends Vue {

}
