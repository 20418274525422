














































import {Component, Prop, Vue, Watch} from 'vue-property-decorator'
import {State} from 'vuex-class'
import {ChangePasswordRequest} from '@/lib/kepler/interfaces'
import {ValidationProvider} from 'vee-validate'
import {VLayout, VForm, VTextField} from 'vuetify/lib'

@Component({
  components: {ValidationProvider, VLayout, VForm, VTextField},
  name: 'PasswordFieldWithValidation',
})
export default class PasswordFieldWithValidation extends Vue {
  @State('flowOutputs') public flowOutputs!: { [k: string]: any }

  @Prop({
    type: [String, Object],
    default: '',
  }) public readonly rules!: string | object // required, new

  @Prop({
    type: String,
  }) public context!: string

  @Prop({
    type: Object,
    default: null,
  }) public readonly value!: Partial<ChangePasswordRequest>

  public innerValue: Partial<ChangePasswordRequest> = {
    new_password: '',
    new_password_retype: '',
    old_password: '',
  }

  public showPassword: boolean = false

  public get isNewPass() {
    switch (typeof this.rules) {
      case 'string':
        return this.rules.includes('new_password')
      case 'object':
        return 'new_password' in this.rules ? (this.rules as any).new_password : false
    }
  }

  public get rulesRetype() {
    const i = this.innerValue
    const compare = this.isNewPass ? '' : ',' + i.old_password
    return this.rules + '|password:' + i.new_password + compare
  }

  public updateValue(key: keyof ChangePasswordRequest, value: string) {
    if (this.innerValue.hasOwnProperty(key)) {
      this.innerValue[key] = value
      if (this.isNewPass) {
        delete this.innerValue.old_password
      }
      this.$emit('update:value', this.innerValue)
    }
  }

  public transErrors(errors: string[]) {
    return errors.map((err) => this.$t(err))
  }

  public created() {
    if (this.value) {
      this.innerValue = this.value
    }
  }

}
