import {LatLng} from '../models/LatLng'

export function DecodePolyline(encoded: string, reverse: boolean = false, divider: number = 1) {
  let points: LatLng[] = []
  let index = 0, len = encoded.length
  let lat = 0, lng = 0
  while (index < len) {
    let b, shift = 0, result = 0
    do {
      b = encoded.charAt(index++).charCodeAt(0) - 63
      result |= (b & 0x1f) << shift
      shift += 5
    } while (b >= 0x20)

    lat += ((result & 1) !== 0 ? ~(result >> 1) : (result >> 1))
    shift = 0
    result = 0
    do {
      b = encoded.charAt(index++).charCodeAt(0) - 63
      result |= (b & 0x1f) << shift
      shift += 5
    } while (b >= 0x20)
    lng += ((result & 1) !== 0 ? ~(result >> 1) : (result >> 1))
    if (!reverse) {
      points.push({lat: (lat / 1E5) / divider, lng: ((lng / 1E5) / divider)})
    } else {
      points.push({lat: (lng / 1E5) / divider, lng: (lat / 1E5) / divider})
    }
  }
  return points
}
