















import {Vue, Component, Prop} from 'vue-property-decorator'
import {Action} from 'vuex-class'
import Utils from '../../utils'
import {VehicleSlot} from '@/lib/kepler/interfaces'

@Component({
  components: {
    Sheet: Utils.loadComponent('proxy/Sheet'),
    LoopingBG: Utils.loadComponent('LoopingBG'),
    Container: Utils.loadComponent('proxy/Container'),
    Layout: Utils.loadComponent('proxy/Layout'),
    Button: Utils.loadComponent('Button'),
    TextField: Utils.loadComponent('proxy/Inputs/TextField'),
  },
  name: 'VehicleConfirmBooking',
})
export default class VehicleConfirmBooking extends Vue {
  @Action('closeOverlays') public closeOverlays!: () => void
  @Prop() public vehicleSlot!: VehicleSlot
  @Prop({default: '20m'}) public amount!: string
  @Prop({default: 'confirm'}) public action!: string
  @Prop() public confirmCallback?: () => {}

  public closeConfirm() {
    this.closeOverlays()
    if (this.$route.name !== 'home') {
      this.$router.push({name: 'home'})
    }
    if (this.confirmCallback !== undefined) {
      this.confirmCallback()
    }
  }

  get textString() {
    return `booking.${this.action}.your_booking_has_been_confirmed`
  }

  get instructions() {
    return this.$isAvailable('booking.confirm.instructions')
  }
}
