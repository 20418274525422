





























import {Component} from 'vue-property-decorator'
import Utils from '@/utils'
import RateOptions from '@/views/RateOptions.vue'

@Component({
  components: {
    GradientCard: Utils.loadComponent('GradientCard'),
    List: Utils.loadComponent('proxy/List/List'),
    ListTile: Utils.loadComponent('proxy/List/ListTile'),
    ListTileContent: Utils.loadComponent('proxy/List/ListTileContent'),
    ListTileTitle: Utils.loadComponent('proxy/List/ListTileTitle'),
    AddCoupon: Utils.loadComponent('wallet/AddCoupon'),
    BuyTopUp: Utils.loadComponent('BuyTopUp'),
    BuyPackets: Utils.loadComponent('BuyPackets'),
    Card: Utils.loadComponent('proxy/Card/Card'),
    Img: Utils.loadComponent('proxy/Image'),
    Button: Utils.loadComponent('Button'),
    Btn: Utils.loadComponent('proxy/Btn'),
    CloseButton: Utils.loadComponent('CloseButton'),
    Layout: Utils.loadComponent('proxy/Layout'),
    Flex: Utils.loadComponent('proxy/Flex'),
  },
  name: 'RateOptionsDialog',
})
export default class RateOptionsDialog extends RateOptions {
  protected openPlans() {
    this.$dialog.close()
    this.$router.push({name: 'plans'})
  }
}
