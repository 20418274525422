








































import {Component} from 'vue-property-decorator'

import {ValidationProvider} from 'vee-validate'
import {Invoice} from '@/lib/kepler/interfaces'
import {VCard, VDivider, VExpansionPanel, VExpansionPanelContent, VIcon, VLayout, VTextField} from 'vuetify/lib'
import MultipleChoice from '@/components/flow/flowFields/MultipleChoice.vue'
import GradientCard from '@/components/GradientCard.vue'
import CardButton from '@/components/CardButton.vue'
import moment from 'moment'

type InvoiceChoice = Invoice & {
  value: string,
  download_url: string,
}

@Component({
  components: {
    GradientCard,
    ValidationProvider,
    CardButton,
    VLayout,
    VTextField,
    VIcon,
    VExpansionPanel,
    VExpansionPanelContent,
    VCard,
    VDivider,
  },
  name: 'MultipleInvoices',
})
export default class MultipleInvoices extends MultipleChoice {
  declare public readonly value: string[] | null
  declare public readonly items: InvoiceChoice[]

  public created() {
    if (this.value === null) {
      this.items.forEach((i) => {
        this.enable(i.value)
      })
    }
  }

  public openInvoice(invoice: InvoiceChoice) {
    cordova.InAppBrowser.open(invoice.download_url, '_system')
  }

  private formatDate(date: string) {
    return moment(date).format('LL')
  }
}
