




































import {Vue, Component} from 'vue-property-decorator'

import Utils from '@/utils'
import {ChangePinRequest} from '@/lib/kepler/interfaces'
import Validations from '@/lib/Validations'
import sdk from '@/lib/kepler/sdk'
import ConfirmDialog from '@/views/ConfirmDialog.vue'

@Component({
  components: {
    Layout: Utils.loadComponent('proxy/Layout'),
    Card: Utils.loadComponent('proxy/Card/Card'),
    CloseButton: Utils.loadComponent('CloseButton'),
    TextField: Utils.loadComponent('proxy/Inputs/TextField'),
    FormTag: Utils.loadComponent('proxy/Inputs/FormTag'),
    Button: Utils.loadComponent('Button'),
  },
  name: 'ChangePinDialog',
})
export default class ChangePinDialog extends Vue {

  public showPin: boolean = false
  public valid: boolean = false
  public changePinRequest: ChangePinRequest = {
    password: '',
    old_pin: '',
    new_pin: '',
  }
  protected rules: any = Validations.rules

  public created() {
    if (!this.$isLogged()) {
      this.$dialog.close()
    }
  }

  public submit() {
    sdk.profile.change_pin(this.changePinRequest).then((r) => {
      const response = r.data
      if (r.data.result_code === 'pin-changed') {
        this.$dialog.close()
        this.$dialog.open(ConfirmDialog, {
          props: {
            imageState: 'success.svg',
            confirmText: this.$t('common.continue'),
            code: '',
            subtitle: response.messages.join('\n'),
            title: response.result,
            singleAction: true,
            emitConfirm: false,
          },
        })
      }
    })
  }
}
