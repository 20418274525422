















































import {Vue, Component, Prop, Watch, PropSync} from 'vue-property-decorator'
import {Action, Getter, State} from 'vuex-class'
import sdk from '@/lib/kepler/sdk'
import {
  CheckupResponse,
  ReservationResponse,
  TerminateRequest,
  Dialog,
  FieldConfigs,
  TerminateResponse,
  CheckItem,
} from '@/lib/kepler/interfaces'
import {HardwareManager} from '@/lib/hardware/HardwareManager'
import {InversConfig} from '@/lib/hardware/classes/InversHardware'
import {BleBikeConfig} from '@/lib/hardware/classes/B810BikeLockHardware'
import {debounce} from '@/lib/Debounce'
import {AxiosError} from 'axios'
import Utils from '../utils'

import {VIcon, VLayout, VProgressCircular, VTooltip} from 'vuetify/lib'
import PinRequestCallback from '@/views/Vehicle/PinRequestCallback.vue'
import ConfirmDialogCallback from '@/views/ConfirmDialogCallback.vue'
import TerminateMode from '@/views/TerminateMode.vue'
import RateDialog from '@/views/RateDialog.vue'
import BikeLockInstructions from '@/views/Vehicle/BikeLockInstructions.vue'
import ErrorView from '@/views/Error.vue'
import SurveyDialog from '@/views/Booking/Survey.vue'
import {EventBus} from '@/main'
import ForceTerminateChecklist from '@/views/Booking/ForceTerminateChecklist.vue'
import Locate from '@/lib/location'
import ConfirmTerminateDialog from '@/views/Booking/ConfirmTerminateDialog.vue'
import ReservationOTPDialog from '@/views/ReservationOTPDialog.vue'
import Card from '@/components/proxy/Card/Card.vue'

interface ChanMan {
  manager: string
  channel: string | null
}

interface VehicleAction extends ChanMan {
  key: string
  color: string
  text: string
}

@Component({
  components: {
    Card,
    Layout: Utils.loadComponent('proxy/Layout'),
    Alert: Utils.loadComponent('proxy/Alert'),
    Btn: Utils.loadComponent('proxy/Btn'),
    Sheet: Utils.loadComponent('proxy/Sheet'),
    Button: Utils.loadComponent('Button'),
    VProgressCircular,
    VTooltip,
    VLayout,
    VIcon,
  },
  name: 'VehicleActions',
})
export default class VehicleActions extends Vue {
  @State((state) => state.profile.debugMode) public debugMode!: boolean
  @State((state) => state.profile.driver.id) public driverId!: string

  @Getter('fieldConfigs') public fieldConfigs!: FieldConfigs

  @Prop() public reservation!: ReservationResponse
  @PropSync('loaderSync', {
    type: Boolean || Number,
    default: true,
  }) public loader!: boolean | number

  @Action('endReservation') public endReservation!: any
  @Action('terminateReservation') public terminateReservation!: (payload: TerminateRequest) => Promise<TerminateResponse>
  @Action('checkupReservation') public checkupReservation!: (resNum: number) => Promise<CheckupResponse>
  @Action('hasOpenedVehicleInReservation') public hasOpenedVehicle!: (payload: {
    resnum: number,
    add?: boolean,
  }) => Promise<boolean>
  @Action('setDebugMode') public setDebugMode: any
  @Action('current') public current!: () => Promise<ReservationResponse[]>

  @Action('openDialog') public openDialog!: (dialog: Dialog) => void
  @Action('closeOverlays') public closeOverlays!: () => void

  public runningAction: string | null = null
  public operationInProgress: boolean = false
  public loaderMessage: string | null = null
  public debugMessage: string | null = null
  public hwManager: HardwareManager | null = null
  public blockingLoader: boolean = true
  public readyForOffline: boolean = false
  public skipRest: boolean = false
  public inActions: { [action: string]: ChanMan[] } = {}
  public versionNumber: string = this.$env.RELEASE
  public hwReady: boolean = false
  public hwMap: Map<string, any> = new Map()
  public t0: number = 0
  public online = true

  public get actions() {
    const actions: VehicleAction[] = []
    if (this.reservation.is_validated !== false) {
      for (const key of Object.keys(this.inActions)) {
        actions.push({
          key,
          color: 'success',
          text: this.commandStrings(key).name,
          manager: this.inActions[key][0].manager,
          channel: this.inActions[key][0].channel,
        })
      }
    }

    actions.push({
      key: 'terminate',
      color: 'error',
      text: this.commandStrings('terminate').name,
      manager: 'rest',
      channel: null,
    })
    if (!!actions.find((a) => a.channel === 'ble')) {
      this.readyForOffline = true
    }
    return actions
    // {key: 'unlock', color: 'success', text: this.$t('vehicle.unlock')},
    // {key: 'lock', color: 'warning', text: this.$t('booking.close_vehicle')},
    // {key: 'terminate', color: 'error', text: this.$t('booking.end_reservation')},
  }

  public runAction(action: string) {
    debounce(() => {
      if (this.hwManager) {
        this.runningAction = action
        this.statusDebug('LAUNCHING ' + action)
        this.status(this.commandStrings(action).progress)
        switch (action) {
          case 'terminate':
            this.hookHandler('terminate', '').then(this.confirmTerminate).catch(() => {
              this.operationInProgress = false
              this.loaderMessage = null
              this.loader = false
            })
            break
          default:
            this.operationInProgress = true
            this.hwManager.run(action).then((x) => {
              this.operationInProgress = false
              this.statusDebug(action + ' OK: ' + x)
              this.status(this.commandStrings(action).done)
              this.status(null)
              if (action === 'open') {
                this.hasOpenedVehicle({resnum: this.reservation.number, add: true})
              }
            }).catch((x) => {
              this.operationInProgress = false
              this.statusDebug(action.toUpperCase() + ' KO: ' + x)
              this.status(this.commandStrings(action).error)
              this.status(null)
              if (x !== 'ABORTED') {
                this.openDialog(new Dialog(ErrorView, {
                  title: this.commandStrings(action).error,
                  subtitle: this.getMessage(x),
                }))
              }
              this.loader = false
            })
            break
        }
      }
    }, 1000, {isImmediate: true})()
  }

  public commandStrings(action: string) {
    return {
      name: this.$t(`hardware.command.${action.toLowerCase()}.name`),
      progress: this.$t(`hardware.command.${action.toLowerCase()}.progress`),
      done: this.$t(`hardware.command.${action.toLowerCase()}.done`),
      error: this.$t(`hardware.command.${action.toLowerCase()}.error`),
    }
  }

  @Watch('reservation', {deep: true, immediate: false})
  public onReservationChange(nu: ReservationResponse) {
    const mapToString = (map: Map<string, any>) => JSON.stringify(Array.from(map.entries()))
    const nuMap = this.generateHwMap(nu.extra, true)
    this.statusDebug('---cron update--- hw: ' + Array.from(nuMap.keys()) || 'none')
    if (mapToString(nuMap) !== mapToString(this.hwMap)) {
      this.hwMap = nuMap
      this.initHw()
    }
  }

  public openPinPrompt(): Promise<string> {
    return new Promise((resolve, reject) => {
      this.$dialog.open(PinRequestCallback, {
        title: 'pinDialog',
        props: {
          confirmCallback: (pin: string) => {
            resolve(pin)
          },
          cancelCallback: () => {
            reject('ABORTED')
          },
        },
      })
    })
  }

  public generateHwMap(extra: { [k: string]: any }, force: boolean = false): Map<string, any> {
    if (this.hwReady && !force) {
      this.statusDebug('ALREADY READY')
      return this.hwMap
    }

    if (!this.hwMap.size) {
      this.statusDebug('MOUNTED HW MANAGER')
    }

    return this.setHwMap(extra)
  }

  public setHwMap(extra: { [p: string]: any }) {
    /* tslint:disable: cyclomatic-complexity */
    this.skipRest = !!this.fieldConfigs.rest_disabled
    const map: Map<string, any> = new Map()
    const statusDebug = (s: string) => {
      if (!this.hwMap.size) {
        this.statusDebug(s)
      }
    }
    // map.set('alertz', {initTime: 2000, fail: true})

    if (extra.hasOwnProperty('kuantic-mib')) {
      if (extra['kuantic-mib'].virtual_key) {
        statusDebug('ADDING MIB')
        map.set('mib', extra['kuantic-mib'])
      }
    }

    if (extra.hasOwnProperty('invers')) {
      statusDebug('ADDING INVERS')
      const conf: InversConfig = {
        pinRequester: this.openPinPrompt,
        ...extra.invers,
      }
      map.set('invers', conf)
    }

    if (extra.hasOwnProperty('geotab')) {
      if (extra.geotab.keyring) {
        statusDebug('ADDING GEOTAB')
        map.set('geotab', extra.geotab)
      }
    }

    if (extra.hasOwnProperty('ruptela')) {
      if (extra.ruptela.lockId) {
        statusDebug('ADDING RUPTELA')
        extra.ruptela.reservationId = this.reservation.number
        map.set('ruptela', extra.ruptela)
      }
    }

    if (extra.hasOwnProperty('huf')) {
      if (extra.huf.keyring) {
        statusDebug('ADDING HUF')
        map.set('huf', extra.huf)
      }
    }

    if (extra.hasOwnProperty('ble-bike')) {
      statusDebug('ADDING BLE BIKE')
      this.skipRest = true
      const conf: BleBikeConfig = {
        pinRequester: this.openPinPrompt,
        closeDialog: () => {
          this.$popup.open(BikeLockInstructions, {hideTopbar: true})
        },
        plate: extra['ble-bike'].plate,
        reservationNumber: this.reservation.number,
      }
      map.set('ble-bike', conf)
    }

    if (extra.hasOwnProperty('playconnect')) {
      if (extra.playconnect.manager && extra.playconnect.manager !== 'on-the-fly') {
        statusDebug('ADDING PLAYCONNECT:' + extra.playconnect.manager)
        if (extra.playconnect.config.skipRest) {
          this.skipRest = true
          this.readyForOffline = true
        }
        if (extra.playconnect.manager === 'omni-lock' || extra.playconnect.manager === 'omni-personal-lock') {
          extra.playconnect.config.closeDialog = () => {
            this.$popup.open(BikeLockInstructions, {hideTopbar: true})
          }
        }
        map.set(extra.playconnect.manager, extra.playconnect.config)
      }
      if (extra.playconnect.manager && extra.playconnect.manager === 'on-the-fly') {
        statusDebug('ON-THE-FLY')
        sdk.reservation.keys(this.reservation.number).finally(() => {
          statusDebug('FINALLY-ON-THE-FLY')
        })
      }
    }

    // Don't hate the game, hate the players
    if (this.reservation.vehicle_slot.vehicle.brand === 'CABUBI') {
      this.skipRest = true
    }

    if (!this.skipRest) {
      statusDebug('ADDING REST')
      map.set('rest', {
        reservationNumber: this.reservation.number,
        pinRequester: this.openPinPrompt,
      })
    } else {
      statusDebug('SKIPPING REST')
    }

    // hwMap.set('alert', {initTime: 500})

    return map
  }

  public checkAndroidPermissionScan() {
    return new Promise<void>((resolve, reject) => {
      bluetoothle.hasPermissionBtScan(({hasPermission}) => {
        if (hasPermission) {
          resolve()
        } else {
          bluetoothle.requestPermissionBtScan(({requestPermission}) => {
            requestPermission ? resolve() : reject()
          })
        }
      })
    })
  }

  public checkAndroidPermissionConnect() {
    return new Promise<void>((resolve, reject) => {
      bluetoothle.hasPermissionBtConnect(({hasPermission}) => {
        if (hasPermission) {
          resolve()
        } else {
          bluetoothle.requestPermissionBtConnect(({requestPermission}) => {
            requestPermission ? resolve() : reject()
          })
        }
      })
    })
  }

  public checkAndroidPermissions() {
    return new Promise<void>((resolve, reject) => {
      if (window.cordova.platformId === 'android') {
        this.checkAndroidPermissionScan().then(() => {
          this.checkAndroidPermissionConnect().then(resolve).catch(reject)
        }).catch(reject)
      } else {
        resolve()
      }
    })
  }

  public initHw() {
    if (window.cordova.platformId === 'android') {
      this.checkAndroidPermissions().finally(() => {
        this.initHwReal()
      })
    } else {
      this.initHwReal()
    }
  }

  public initHwReal() {
    if (this.hwMap.size) {
      this.hwManager = new HardwareManager(this.hwMap, this.hookHandler, (message, error) => {
        if (message.startsWith('HM:')
          && !message.includes('[BLE]')
          && !message.includes('@rest')
          && !message.toUpperCase().includes('INIT')
          && !message.includes('DUNASYS DEVICE')
        ) {
          this.pushOfflineData({
            HM: (error ? 'ERROR:' : '') + message.substring(3).trim(),
          })
        }
        this.statusDebug('SCB: ' + message + ' (' + error + ')')
        // Close screen if phone misses some permissions
        if (message.endsWith('MISSING_PERMISSIONS')) {
          this.openDialog(new Dialog(ConfirmDialogCallback, {
            imageState: 'error.svg',
            confirmText: this.$t('common.retry'),
            confirmColor: 'success',
            cancelText: this.$t('hardware.permission_popup.continue'),
            code: this.$t('hardware.permission_popup.missing_permissions'),
            title: this.$t('hardware.permission_popup.unable_to_configure_bt'),
            subtitle: this.$t('hardware.permission_popup.description'),
            showCloseButton: false,
            confirmCallback: () => {
              this.$router.push('/')
            },
            cancelCallback: () => {
              //
            },
          }))
        }
        if (message.endsWith('DEVICE_HAS_NO_AUTHENTICATION')) {
          this.openDialog(new Dialog(ConfirmDialogCallback, {
            imageState: 'error.svg',
            confirmText: this.$t('common.retry'),
            confirmColor: 'success',
            cancelText: this.$t('hardware.auth_popup.continue'),
            code: this.$t('hardware.auth_popup.no_auth'),
            title: this.$t('hardware.auth_popup.unable_to_configure_bt'),
            subtitle: this.$t('hardware.auth_popup.description'),
            showCloseButton: false,
            confirmCallback: () => {
              this.$router.push('/')
            },
            cancelCallback: () => {
              //
            },
          }))
        }
      })
      this.status('Initializing hardware')
      this.hwManager.init((x) => {
        const actions = []

        for (const [key, inner] of Object.entries(x)) {
          const i = inner as any[]
          actions.push(key + '> ' + i.map((actn: ChanMan) => {
            return (actn.channel ?? 'NULL') + '~' + actn.manager
          }).join('-'))
        }

        this.statusDebug('INCOMING ACTIONS: ' + actions.join(' | '))
        // This mofo is what updates the list
        this.$set(this, 'inActions', x)

        if (Object.keys(this.inActions).length > 0 && !this.operationInProgress) {
          this.status(null)
        }
      }).then(() => {
        // Cache hw state
        this.statusDebug('INIT ENDED')
        this.hwReady = true
        this.status('Hardware ready')
        this.status(null)
      }).catch(() => {
        this.statusDebug('INIT FAILED')
      })
    } else {
      this.status('Waiting for key...')
    }
  }

  public hookHandler(name: string, message: string): Promise<void> {
    this.statusDebug('hook check for ' + name)

    if (name.startsWith('fallback|')) {
      const arr = name.split('|')
      const from = arr[1]
      const to = arr[2]
      return new Promise((resolve, reject) => {
        if (to === 'rest') {
          if (this.online) {
            this.openDialog(new Dialog(ConfirmDialogCallback, {
              imageState: 'error.svg',
              confirmText: this.$t('hardware.use_network'),
              confirmColor: 'error',
              cancelText: this.$t('common.cancel'),
              code: (from === 'ble' ? 'bluetooth' : from) + ' failed ',
              title: this.getMessage(message),
              subtitle: null,
              showCloseButton: false,
              confirmCallback: resolve,
              cancelCallback: reject,
            }))
          } else {
            reject()
          }
        } else {
          resolve()
        }
      })
    } else {
      return this.checkup(name)
    }
  }

  public checkup(name: string): Promise<void> {
    return new Promise((resolve, reject) => {
      sdk.reservation.checkup(this.reservation.number).then((r) => {
        if (r.data.hasOwnProperty(name)) {
          const survey = r.data[name]
          if (survey.some((s) => {
            return s.mandatory
          })) {
            this.openDialog(new Dialog(SurveyDialog, {
              survey,
              reservationNumber: this.reservation.number,
              reservationId: this.reservation.id,
              vehicleSlot: this.reservation.vehicle_slot,
              confirmCallback: () => {
                this.$dialog.close()
                this.checkup(name).then(resolve).catch(reject)
              },
              cancelCallback: () => {
                reject('ABORTED')
              },
            }))
          } else {
            resolve()
          }
        } else {
          resolve()
        }
      }).catch(() => {
        resolve()
      })
    })
  }

  public statusDebug(s: string | null) {
    const deltaT: number = performance.now() - this.t0
    let dt = deltaT.toFixed()
    if (dt === '0') {
      dt = (deltaT * 1000).toFixed().padStart(3, '0') + 'μs'
    } else if (deltaT < 999) {
      dt = dt.padStart(2, '0') + 'ms'
    } else {
      dt = (deltaT / 1000).toFixed().padStart(3, '0') + 's'
    }

    this.debugMessage = (this.debugMessage || '') + '\n' + `${dt} #${s}`
  }

  public status(s: string | null) {
    if (this.debugMode) {
      this.statusDebug('*' + s)
    }
    if (typeof s !== 'string' && Object.keys(this.inActions).length === 0) {
      // DO FUCKING NOTHING
      // console.log('status ----> ' + s, 'actions ----> ', this.actions, 'inActions ----> ', this.inActions)
    } else if (typeof s !== 'string' && Object.keys(this.inActions).length > 0 && !this.operationInProgress) {
      setTimeout(() => {
        this.loader = false
        this.loaderMessage = ''
      }, 1500)
    } else {
      this.loader = true
      this.loaderMessage = s
    }
  }

  public copyLogToClipboard() {
    if (this.debugMessage) {
      navigator.clipboard.writeText(this.debugMessage)
      const selection = window.getSelection()
      const txt = document.getElementsByClassName('debug-messages')
      const range = document.createRange()
      range.selectNodeContents(txt[0])
      if (selection) {
        selection.removeAllRanges()
        selection.addRange(range)
      }
    }
  }

  public cls() {
    this.debugMessage = '\n\n'
  }

  public getMessage(s: string) {
    if (s.charAt(0) === '-') {
      return s.substring(1)
    } else if (this.$isAvailable('hardware.error.' + s)) {
      return this.$t('hardware.error.' + s)
    } else {
      return s + '<'
    }
  }

  public terminateForceChecklist(payload: TerminateRequest, list: CheckItem[]) {
    if (list?.length) {
      this.openDialog(new Dialog(ForceTerminateChecklist, {
        list,
        title: this.$t('booking.terminate_checklist.title'),
        description: this.$t('booking.terminate_checklist.description'),
        cb: (newPayload?: Partial<TerminateRequest>) => {
          payload = Object.assign(payload || {}, newPayload)
          this.terminateConfirmed(payload)
        },
      }))
    } else {
      payload = Object.assign(payload || {}, {user_declaration: []})
      this.terminateConfirmed(payload)
    }
  }

  public confirmTerminate() {
    this.status(this.commandStrings('terminate').progress)

    const vt = this.reservation.vehicle_slot.vehicle.category.type
    const str = 'booking.terminate_confirm'
    const title = this.$isAvailable(`${str}.title_${vt}`) || this.$t(`${str}.title`)
    const subtitle = this.$isAvailable(`${str}.subtitle_${vt}`) || this.$t(`${str}.subtitle`)

    this.openDialog(new Dialog(ConfirmDialogCallback, {
      title,
      subtitle,
      imageState: 'warn.svg',
      confirmText: this.$t('vehicle.terminate'),
      confirmColor: 'error',
      cancelText: this.$t('common.cancel'),
      code: '',
      showCloseButton: false,
      confirmCallback: () => this.terminateConfirmed(),
      cancelCallback: this.stopLoader,
    }))
  }

  public terminateConfirmed(payload?: Partial<TerminateRequest>) {
    this.hwManager?.terminateCheck()
      .then(() => {
        Locate.please_locate((pos) => {
          const request: TerminateRequest = {
            reservation_number: this.reservation.number,
            position: pos ? Object.assign({}, {
                latitude: pos.lat,
                longitude: pos.lng,
                timestamp: Math.floor(Date.now() / 1000),
              },
              pos.acc === null ? null : {accuracy: pos.acc},
              typeof pos.alt !== 'number' ? null : {altitude: pos.alt},
            ) : undefined,
          }
          if (payload) {
            Object.assign(request, payload)
          }
          this.terminateReservation(request).then((response) => {
            this.stopLoader()
            this.closeOverlays()
            this.$router.push({name: 'home'})
            this.openDialog(new Dialog(ConfirmTerminateDialog, {
              imageState: 'success.svg',
              reservation: this.reservation,
              title: this.$t('booking.terminate_confirmed.title'),
              subtitle: this.$t('booking.terminate_confirmed.subtitle'),
              response,
            }))
          }, (error: AxiosError) => {
            this.stopLoader()
            if (!navigator.onLine || error.message === 'Network Error') {
              this.statusDebug('TERMINATE FAILED: ' + 'Network Error')
              this.closeOverlays()
              this.openDialog(new Dialog(ErrorView, {
                code: 'network error',
                title: this.$t('hardware.offline.title'),
                subtitle: this.$t('hardware.offline.message'),
                singleAction: true,
              }))
              return
            }

            const code = error.response?.data?.result_code

            if (code) {
              this.statusDebug('TERMINATE FAILED: ' + code)
              const errorHandleFunctions: { [code: string]: () => void } = {
                'exceptions.reservation.reservation-survey-exception': () => {
                  this.$dialog.close()
                  this.openDialog(new Dialog(RateDialog, {
                    title: this.$isAvailable('ratings.title'),
                    subtitle: this.$isAvailable('ratings.subtitle'),
                    reservationNumber: this.reservation.number,
                    confirmCallback: this.terminateConfirmed,
                    cancelCallback: () => {
                      this.closeOverlays()
                    },
                  }))
                },
                'exceptions.reservation.zone-exception': () => {
                  this.closeOverlays()
                  this.$popup.open(TerminateMode, {
                    props: {
                      reservation: this.reservation,
                      terminateCb: this.terminateConfirmed,
                      loader: this.loader,
                    },
                    title: this.$t('booking.terminate_mode.title'),
                  })
                },
                'exceptions.reservation.vehicle-communication-exception': () => {
                  sdk.reservation.terminate_checkup(request.reservation_number)
                    .finally(this.closeOverlays)
                    .then(({data}) => {
                      this.terminateForceChecklist(request, data)
                    }).catch(() => {
                    this.terminateForceChecklist(request, [
                      {
                        id: 'doors_closed',
                        title: this.$t('booking.terminate_checklist.doors_closed.title'),
                        description: this.$t('booking.terminate_checklist.doors_closed.description'),
                        icon: 'mdi-key-variant',
                      },
                      {
                        id: 'ignition_off',
                        title: this.$t('booking.terminate_checklist.ignition_off.title'),
                        description: this.$t('booking.terminate_checklist.ignition_off.description'),
                        icon: 'mdi-lock-outline',
                      },
                      {
                        id: 'key_present',
                        title: this.$t('booking.terminate_checklist.key_present.title'),
                        description: this.$t('booking.terminate_checklist.key_present.description'),
                        icon: 'mdi-flash-off',
                      },
                      {
                        id: 'park_allowed',
                        title: this.$t('booking.terminate_checklist.park_allowed.title'),
                        description: this.$t('booking.terminate_checklist.park_allowed.description'),
                        icon: 'mdi-parking',
                      },
                    ])
                  })
                },
                'exceptions.reservation.not-current-reservation-exception': () => {
                  this.closeOverlays()
                  this.$router.push({name: 'home'})
                },
                'exceptions.reservation.reservation-exception': () => {
                  this.closeOverlays()
                  this.openDialog(new Dialog(ErrorView, {
                    title: error.response?.data.result,
                    subtitle: error.response?.data.messages.join('\n'),
                  }))
                },
                'exceptions.reservation.operation-cannot-be-completed-exception': () => {
                  this.closeOverlays()
                  this.$dialog.open(ErrorView, {props: {
                      title: this.commandStrings('terminate').error,
                      subtitle: error.response?.data.messages.join('\n'),
                    }})
                },
              }
              if (errorHandleFunctions.hasOwnProperty(code)) {
                errorHandleFunctions[code]()
              } else {
                this.closeOverlays()
                this.openDialog(new Dialog(ErrorView, {
                  title: error.response?.data.result,
                  subtitle: error.response?.data.messages.join('\n'),
                }))
              }
            } else {
              this.statusDebug('TERMINATE FAILED: ' + error.message)
            }
          })
        })
      })
      .catch((e) => {
        this.openDialog(new Dialog(ErrorView, {
          code: 'network error',
          title: this.$t('booking.terminate_error'),
          subtitle: this.getMessage(e),
          singleAction: true,
        }))
        this.stopLoader()
      })
  }

  public stopLoader() {
    this.loader = false
    this.loaderMessage = ''
  }

  protected created() {
    EventBus.$on('offline', () => {
      this.online = false
    })
    EventBus.$on('online', () => {
      this.online = true
    })
  }

  protected mounted() {
    this.t0 = performance.now()
    this.hwMap = this.generateHwMap(this.reservation.extra)
    this.initHw()
  }

  protected beforeDestroy() {
    EventBus.$off('offline')
    EventBus.$off('online')
    this.hwManager?.cleanup()
    if (this.debugMessage && this.debugMode) {
      const channel = 'hw-debug-' + this.$t('config.app_name').toUpperCase()
      sdk.booking.sendLogs(channel, this.debugMessage, this.driverId, cordova.platformId, this.versionNumber)
    }
  }

  private pushOfflineData(data: any) {
    sdk.hooks.pushReservationData(this.reservation.number, data).then(() => {
      this.statusDebug('data pushed to offline channel')
    }).catch(() => {
      this.statusDebug('cannot push data pushed to offline channel')
    })
  }

  private validateOTP() {
    this.openDialog(new Dialog(ReservationOTPDialog, {
      title: '',
      subtitle: '',
      requestText: this.$t('booking.validation.request'),
      verifyText: this.$t('booking.validation.validate'),
      reservationNumber: this.reservation.number,
      confirmCallback: this.current,
    }))
  }
}
