
import {Component, Vue} from 'vue-property-decorator'

import { VContainer } from 'vuetify/lib'

@Component({
  name: 'Container',
  extends: VContainer as any,
})
export default class Container extends Vue {

}
