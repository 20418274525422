export function ResourceLoader(type: string, id: string, url: string) {
  return new Promise<void>((resolve, reject) => {
    const completeId = id + '-' + type
    // Check if element already loaded
    const e = document.getElementById(completeId)
    if(e !== null){
      const a = e.getAttribute('status')
      if(a === 'loaded') {
        resolve()
      } else if (a === 'loading') {
        // Wait for element to load
        // This is just a hack! Implement proper waiting policy
        setTimeout(() => {
          if(e.getAttribute('status') === 'loaded') {
            resolve()
          } else {
            reject()
          }
        }, 500)
      }
    } else {
      // Start loading from remote
      switch (type) {
        case 'css':
          /* eslint-disable no-eval */
          const css = document.createElement('link')
          css.setAttribute('id', completeId)
          css.setAttribute('rel', 'stylesheet')
          css.setAttribute('href', url)
          css.setAttribute('status', 'loading')
          css.onload = () => {
            css.setAttribute('status', 'loaded')
            resolve()
          }
          css.onerror = reject
          document.head.appendChild(css)
          break
        case 'script':
          /* eslint-disable no-eval */
          const script = document.createElement('script')
          script.setAttribute('id', completeId)
          script.setAttribute('src', url)
          script.setAttribute('status', 'loading')
          script.onload = () => {
            script.setAttribute('status', 'loaded')
            resolve()
          }
          script.onerror = reject
          document.head.appendChild(script)
          break
        case 'css-inline':
          const cssinline = document.createElement('style')
          cssinline.setAttribute('id', completeId)
          cssinline.type = 'text/css';
          cssinline.innerHTML = url;
          cssinline.setAttribute('status', 'loading')
          cssinline.onload = () => {
            cssinline.setAttribute('status', 'loaded')
            resolve()
          }
          cssinline.onerror = reject
          document.head.appendChild(cssinline)
          break
      }
    }
  })
}
