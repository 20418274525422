





















import {Component, Prop} from 'vue-property-decorator'
import {Getter} from 'vuex-class'
import {FieldConfigs, SubscriptionPlan, TocDetail} from '@/lib/kepler/interfaces'
import Utils from '@/utils'
import ConfirmDialogCallback from '@/views/ConfirmDialogCallback.vue'

@Component({
  components: {
    ConfirmDialogCallback,
    Container: Utils.loadComponent('proxy/Container'),
    Layout: Utils.loadComponent('proxy/Layout'),
    CheckBox: Utils.loadComponent('proxy/Inputs/CheckBox'),
    Img: Utils.loadComponent('proxy/Image'),
    Button: Utils.loadComponent('Button'),
  },
})
export default class PlanTermsOfServiceDialog extends ConfirmDialogCallback {
  @Getter('fieldConfigs') public fieldConfigs?: FieldConfigs

  @Prop() public toc!: TocDetail
  @Prop() public plan!: SubscriptionPlan

  protected tocApproved: string = ''

  protected openLinkIfPresent() {
    if (this.logo && this.logo.url) {
      cordova.InAppBrowser.open(this.logo.url, '_system')
    }
  }

  protected get planString() {
    if (this.plan.cost > 0) {
      return this.$t('subscription.toc', {
        cost: this.$currency(this.plan.cost),
        duration: this.plan.duration,
        cycle: this.cycleTranslation,
      })
    } else {
      return this.$t('subscription.toc_free', {
        duration: this.plan.duration,
        cycle: this.cycleTranslation,
      })
    }
  }

  protected get cycleTranslation() {
    if (this.plan.billing_cycle === 30) {
      return this.$t('common.time_units.monthly')
    } else {
      return ''
    }
  }

  protected get logo() {
    const image = this.fieldConfigs?.plans?.termsOfServiceLogo
    if (image) {
      const [src, url] = image.split('|')
      return {src, url}
    }
  }
}
