
import {Component, Vue} from 'vue-property-decorator'

import { VExpansionPanel } from 'vuetify/lib'

@Component({
  name: 'Accordion',
  extends: VExpansionPanel as any,
})
export default class Accordion extends Vue {

}
