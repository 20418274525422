
import {Component, Vue} from 'vue-property-decorator'
import {VRadio} from 'vuetify/lib'

@Component({
  name: 'Radio',
  extends: VRadio as any,
})
export default class Radio extends Vue {
}
