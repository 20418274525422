import {ResourceLoader} from '../utils/ResourceLoader'
import {Base} from './Base'
import {LatLng} from '../models/LatLng'
import {MarkerLabel} from "@/lib/n-maps/src/models/MarkerLabel";

declare namespace google {
  let maps: any
}

export class GoogleMap extends Base {

  public meta: any = {}

  private loadedCallback = () => {
  }

  public loadResources() {
    // @ts-ignore
    window.googleMapLoadedCallback = () => {
      // @ts-ignore
      window.googleMapLoaded = true
      this.loadedCallback()
    }

    let loadedPromise = new Promise<void>((resolve, reject) => {
      // @ts-ignore
      if (window.googleMapLoaded) {
        resolve()
      } else {
        this.loadedCallback = () => {
          resolve()
        }
      }
    })
    const url = 'https://maps.googleapis.com/maps/api/js?libraries=visualization,marker&callback=googleMapLoadedCallback&v=beta&key=' + this.config.apiKey // TODO: Proxy url
    return [
      ResourceLoader('script', 'google-map', url),
      ResourceLoader('css-inline', 'google-map', '.google-maps-cluster-icon{ z-index: 99999; }'),
      loadedPromise
    ]
  }

  //if (window.nmapGoogleHolder) {
  //this.map = window.nmapGoogleHolder
  //} else {
  // window.nmapDivGoogleHolder = document.createElement('div')
  // window.nmapDivGoogleHolder.style.height = '100%'
  // window.nmapDivGoogleHolder.style.width = '100%'
  // this.map = new google.maps.Map(window.nmapDivGoogleHolder, {
  // window.nmapGoogleHolder = this.map


  public initMap(element: HTMLElement, initConfig: any, s: any): void {
    // @ts-ignore
    this.map = new google.maps.Map(element, {
      mapId: Math.random().toString(36).substring(2, 12),
      styles: this.config.style,
      center: this.toGoogleLatLng(initConfig.center),
      zoom: initConfig.zoom,
      // Disable buttons
      fullscreenControl: false,
      disableDefaultUI: true,
      zoomControl: false,
      scaleControl: false,
      clickableIcons: false,
      streetViewControl: false,
    })

    // Allow single infowindow and auto close (to match leaflet default behaviour)
    // @ts-ignore
    this.meta.infoWindow = new google.maps.InfoWindow

    this.map.addListener('click', () => {
      this.meta.infoWindow.close()
    })
    s()
  }

  public setClickListener(l: (p: LatLng) => void): void {
    this.map.addListener('click', (ev: any) => {
      l(new LatLng(ev.latLng.lat(), ev.latLng.lng()))
    })
  }

  public pan(p: LatLng): void {
    // @ts-ignore
    this.map.panTo(this.toGoogleLatLng(p))
  }

  public zoom(level: number): void {
    this.map.setZoom(level)
  }

  public cleanChild(c: any): void {
    c.setMap(null)
  }

  public addPolyline(points: LatLng[], lineWidth: number, color: string, tooltip: string | null = null): void {
    // @ts-ignore
    return new google.maps.Polyline({
      path: points,
      map: this.map,
      strokeWeight: lineWidth,
      strokeColor: color,
    })
  }

  public addPolygon(points: LatLng[], lineWidth: number, color: string, popupElement: any, click: () => void): void {
    // @ts-ignore
    let i = new google.maps.Polygon({
      path: points,
      map: this.map,
      strokeWeight: lineWidth,
      strokeColor: color,
      strokeOpacity: 1.0,
      fillColor: color,
      fillOpacity: 0.35,
    })

    if (click) {
      i.addListener('click', () => {
        click()
      })
    }

    if (popupElement) {
      i.addListener('click', (circle: any) => {
        this.meta.infoWindow.setPosition(circle.latLng)
        this.meta.infoWindow.setContent(popupElement)
        this.meta.infoWindow.open(this.map, i)
      })
    }

    return i
  }

  public addCircle(point: LatLng, radius: number, popupElement: any, click: () => void) {
    // @ts-ignore
    let i = new google.maps.Circle({
      // @ts-ignore
      center: new google.maps.LatLng(point.lat, point.longitude),
      map: this.map,
      radius: radius,
    })

    if (click) {
      i.addListener('click', () => {
        click()
      })
    }

    if (popupElement) {
      i.addListener('click', (circle: any) => {
        this.meta.infoWindow.setPosition(circle.latLng)
        this.meta.infoWindow.setContent(popupElement)
        this.meta.infoWindow.open(this.map, i)
      })
    }

    return i
  }

  public addMarker(point: LatLng, icon: string | HTMLElement, popupElement: any, click: () => void, clustered: boolean, label?: MarkerLabel): void {
    let i: any = null

    if (icon) {
      if (typeof icon === 'string') {
        let labelOpt = null
        if (label) {
          labelOpt = {
            text: label.text,
            fontSize: label.fontSize,
            color: label.color,
          }
        }
        // @ts-ignore
        i = new google.maps.Marker({
          position: this.toGoogleLatLng(point),
          map: this.map,
          icon: {
            url: icon,
            // @ts-ignore
            anchor: new google.maps.Point(15, 15), // Half size to center
            // @ts-ignore
            scaledSize: new google.maps.Size(30, 30),
            labelOrigin: new google.maps.Point(23, 23),
          },
          label: labelOpt
        })
      } else {
        let iconWrapper = document.createElement("div");
        iconWrapper.style.height = "30px";
        iconWrapper.style.width = "30px";
        iconWrapper.appendChild(icon)
        i = new google.maps.marker.AdvancedMarkerView({
          map: this.map,
          position: this.toGoogleLatLng(point),
          content: iconWrapper,
        });
      }
    } else {
      // @ts-ignore
      const i = new google.maps.Marker({
        // @ts-ignore
        position: this.toGoogleLatLng(point),
        map: this.map,
        icon: null,
      })
    }

    // Infobox detection
    if (popupElement) {
      i.addListener('click', () => {
        this.meta.infoWindow!.setContent(popupElement)
        this.meta.infoWindow!.open(this.map, i)
      })
    }
    // Click detection
    if (click) {
      i.addListener('click', () => {
        click()
      })
    }

    return i
  }

  public markerClusterLib(): any[] {
    const u = 'https://unpkg.com/@googlemaps/markerclusterer/dist/index.min.js'
    return [
      ResourceLoader('script', 'google-map-marker-clusterer', u),
    ]
  }

  // https://googlemaps.github.io/js-markerclusterer/
  public markerCluster(item: any, child: any): void {
    // Bypass reactivity edge-case
    // @ts-ignore
    if (typeof google === 'undefined') {
      return
    }

    if (item) {
      item.clearMarkers()
    }
    // @ts-ignore
    return new markerClusterer.MarkerClusterer({map: this.map, markers: child.map((c: any) => c.item)})
  }

  public moveMarker(item: any, toPoint: LatLng) {
    if (typeof item.setPosition == 'function') {
      item.setPosition(this.toGoogleLatLng(toPoint))
    } else {
      item.position = this.toGoogleLatLng(toPoint)
    }
  }

  public addHeatmap(points: LatLng[]): void {
    // @ts-ignore
    return new google.maps.visualization.HeatmapLayer({
      // @ts-ignore
      data: points.map((p) => new google.maps.LatLng(p.lat, p.longitude)),
      map: this.map,
      radius: 20,
      opacity: 0.8,
    })

  }

  public getBoundsAndPan(points: LatLng[]) {
    var bounds = new google.maps.LatLngBounds()
    points.forEach((p: LatLng) => {
      bounds.extend(new google.maps.LatLng(p.lat, p.lng))
    })
    this.map.fitBounds(bounds)
  }

  private toGoogleLatLng(pos: LatLng) {
    return new google.maps.LatLng(pos.lat, pos.lng)
  }
}
