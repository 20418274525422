






















































import {Component, Prop, Vue, Watch} from 'vue-property-decorator'
import {State} from 'vuex-class'
import {Address, AppConfig, FlowInputField, Pos} from '@/lib/kepler/interfaces'
import CountryList from '@/lib/kepler/countries'
import countries from '@/lib/kepler/countries'
import {debounce} from '@/lib/Debounce'
import {VAutocomplete, VContainer, VLayout} from 'vuetify/lib'
import Utils from '@/utils'
import TextField from '@/components/proxy/Inputs/TextField.vue'
import AutoComplete from '@/components/proxy/Inputs/AutoComplete.vue'
import Locate from '@/lib/location'
import {FlowOutputsState} from '@/store/modules/flowOutputs'

interface Place {
  description: string,
  place_id: string
}

interface AddressWithProvince extends Address {
  province?: string
}

@Component({
  components: {
    AutoComplete,
    TextField,
    VAutocomplete,
    VContainer,
    VLayout,
  },
  name: 'RegistrationAddressComponent',
})
export default class RegistrationAddressComponent extends Vue {
  @State('flowOutputs') public flowOutputs!: FlowOutputsState

  @Prop({
    type: String,
  }) public context!: string
  @Prop({
    type: [String, Object],
    default: '',
  }) public readonly rules!: string | object
  @Prop({
  }) public readonly options!: string | string[] | Record<string, any>

  @State((state) => state.profile.userPosition) public position!: Pos | null
  @State((state) => state.configuration.appConfig) public appConfig!: AppConfig
  @Prop() public field!: FlowInputField

  public required: boolean = false
  public valid: boolean = false
  public loading: boolean = false
  public errorMessages: string[] = []
  public google: typeof google | boolean = false
  public autoComplete: google.maps.places.AutocompleteService | null = null
  public countries: any = CountryList

  public details: google.maps.places.PlaceResult | null = null

  public req: google.maps.places.AutocompletionRequest = {
    input: '',
    radius: 10000,
    types: ['address'],
  }

  public places: Place[] = []

  public address: google.maps.places.AutocompletePrediction | null = null

  public geocoder: google.maps.Geocoder | null = null
  public placesService: google.maps.places.PlacesService | null = null

  public get selfContained() {
    return this.getOption('self_contained')
  }

  public get geolocate() {
    return this.getOption('geolocate')
  }

  public get currentFlow() {
    return this.flowOutputs[this.context]
  }

  public get addressOutput(): Partial<Address> {
    return this.currentFlow?.address || {}
  }

  public set addressOutput(payload: Partial<Address>) {
    this.$set(this.currentFlow, 'address', payload)
  }

  public get location(): google.maps.LatLng | undefined {
    const [lat, lng] = [this.appConfig.default_latitude, this.appConfig.default_longitude]
    if (this.position) {
      return new google.maps.LatLng(this.position.lat, this.position.lng)
    } else if (lat && lng) {
      return new google.maps.LatLng(lat, lng)
    }
  }

  public get hasAddressOutput() {
    return !!Object.keys(this.addressOutput).length
  }

  public resetAddress() {
    (this.$refs.addressAutocomplete as any).clearableCallback()
    this.addressOutput = {}
  }

  public getPosition(): Promise<google.maps.LatLng> {
    return new Promise((resolve, reject) => {
      Locate.please_locate(async (p) => {
        if (p) {
          const {LatLng} = await google.maps.importLibrary('core') as google.maps.CoreLibrary
          resolve(new LatLng(p.lat, p.lng))
        } else {
          reject()
        }
      })
    })
  }

  public reverseGeo() {
    this.resetAddress()
    this.loading = true
    this.getPosition().then((location) => {
      this.geocoder?.geocode({location}, (results) => {
        if (!results) {
          return
        }
        this.places = results
          // Use this to limit to addresses with street number or whatever
          // .filter((f) =>  f.address_components.find(f1 => f1.types.includes('street_number')))
          .map((r) => {
            return {description: r.formatted_address, place_id: r.place_id}
          }) ?? []
      })
    }).finally(() => {
      this.loading = false
    })
  }

  private get mapUrl() {
    const proxyUrl = this.$env.GOOGLE_MAP_PROXY_URL
    const apiKey = this.$env.GOOGLE_MAPS_KEY
    return proxyUrl && proxyUrl !== '' ? proxyUrl : `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places,visualization`
  }

  public search(req: google.maps.places.AutocompletionRequest) {
    if (req.input && this.autoComplete) {
      this.loading = true
      req.location = this.location
      this.autoComplete.getPlacePredictions(req, (data: google.maps.places.AutocompletePrediction[] | null) => {
        if (data !== null) {
          this.$set(this, 'places', data.map((r) => {
            return {
              description: r.description,
              place_id: r.place_id,
            }
          }) || [])
          this.loading = false
        }
      })
    } else {
      this.$set(this, 'places', [])
    }
  }

  @Watch('req', {deep: true})
  public onSearch(req: google.maps.places.AutocompletionRequest) {
    if (req.input && req.input.length > 5) {
      this.$emit('autocompleteSearchInput', req)
    }
  }

  @Watch('address', {deep: true})
  public async onAddressSelected(addr: Place) {
    if (!addr || this.placesService === null) {
      return
    }
    const req: google.maps.places.PlaceDetailsRequest = {
      // fields: ['address_components', 'adr_address', 'formatted_address'],
      fields: ['address_components'],
      placeId: addr.place_id,
    }
    this.placesService.getDetails(req, (result, status) => {
      this.details = result
      if (status !== google.maps.places.PlacesServiceStatus.OK) {
        throw new Error('get details error')
      }
      if (result?.address_components) {
        this.setAddressComponentProps(this.parseAddressComponents(result.address_components))
      }
      if (result?.adr_address) {
        this.parseAdrAddress(result.adr_address)
      }
    })
  }

  public parseAdrAddress(adr_address?: string) {
    const address = Utils.getProp(this.currentFlow, this.field.name.split('.'))
    if (adr_address && address) {
      const el = document.createElement('html')
      const getProp = (propName: string) => {
        const arr = el.getElementsByClassName(propName)
        if (arr.length) {
          return arr[0].innerHTML.toString()
        }
        return ''
      }

      el.innerHTML = adr_address

      address.street = getProp('street-address')
      address.postal_code = getProp('postal-code') || '--'
      address.city = getProp('locality') || getProp('extended-address') || '--'
      address.region = getProp('region') || '--'

      const country = countries.find((c) => {
        return c.name === getProp('country-name')
      })

      if (country) {
        address.country = country.code
      }

      return address
    }
    return false
  }

  public parseAddressComponents(addressComponents: google.maps.GeocoderAddressComponent[]) {
    // https://medium.com/@almestaadmicadiab/how-to-parse-google-maps-address-components-geocoder-response-774d1f3375d
    const shouldBeComponent = {
      street_number: ['street_number'],
      postal_code: ['postal_code'],
      street: ['street_address', 'route'],
      region: [
        'administrative_area_level_1',
        'administrative_area_level_2',
        'administrative_area_level_3',
        'administrative_area_level_4',
        'administrative_area_level_5',
      ],
      province: [
        'administrative_area_level_2',
        'administrative_area_level_3',
        'administrative_area_level_4',
        'administrative_area_level_5',
      ],
      city: [
        'locality',
        'sublocality',
        'sublocality_level_1',
        'sublocality_level_2',
        'sublocality_level_3',
        'sublocality_level_4',
      ],
      country: ['country'],
    }

    const address: AddressWithProvince = {
      street_number: '',
      postal_code: '',
      street: '',
      region: '',
      province: '',
      city: '',
      country: '',
    }
    addressComponents.forEach((component) => {
      const e = Object.entries(shouldBeComponent) as Array<[keyof typeof shouldBeComponent, string[]]>
      for (const [k, v] of e) {
        if (v.indexOf(component.types[0]) !== -1) {
          if (k === 'country' || k === 'province') {
            address[k] = component.short_name
          } else {
            address[k] = component.long_name
          }
        }
      }
    })
    // if (address.hasOwnProperty('street_number')) {
    //   address.street = (address.street + ` ${address.street_number}`).trim()
    //   delete address.street_number
    // }
    if (address.country === 'IT' && address.province) {
      address.region = address.province
      delete address.province
    }
    return address
  }

  public setAddressComponentProps(r: Address) {
    const strNumbErrIdx = this.errorMessages.indexOf(this.$t('registration.address.street_number_error'))

    const addressOut = Utils.getProp(this.currentFlow, this.field.name.split('.'))
    const setProp = (key: keyof Address, value: string) => {
      Utils.setProp(addressOut, [key], value)
    }

    for (const key of Object.keys(r) as Array<keyof typeof r>) {
      if (key === 'street') { // LET THE JANK BEGIN
        if (r.street_number) {
          if (this.$currentLang() === 'en') {
            setProp(key, `${r.street_number} ${r.street}`)
          } else {
            setProp(key, `${r.street} ${r.street_number}`)
          }

          if (strNumbErrIdx >= 0) {
            this.errorMessages.splice(strNumbErrIdx, 1)
          }
          continue
        } else if (strNumbErrIdx < 0) {
          this.errorMessages.push(this.$t('registration.address.street_number_error'))
        }
      }
      setProp(key, r[key])
    }
  }

  protected created() {
    this.loading = true
    if (!this.currentFlow) {
      this.$set(this.flowOutputs, this.context, {})
    }
    if (!Utils.getProp(this.currentFlow, this.field.name.split('.'))) {
      Utils.setProp(this.currentFlow, this.field.name.split('.'), {})
    }
    this.createGoogleMaps().then(async () => {
      this.google = window.google
      const {
        PlacesService,
        AutocompleteService,
      } = await google.maps.importLibrary('places') as google.maps.PlacesLibrary
      const {Geocoder} = await google.maps.importLibrary('geocoding') as google.maps.GeocodingLibrary

      this.geocoder = this.geocoder || new Geocoder()
      this.placesService = this.placesService || new PlacesService(this.$refs.attribution as HTMLDivElement)
      this.autoComplete = this.autoComplete || new AutocompleteService()
      this.loading = false
    }).catch(() => {
      this.loading = false
    })
  }

  protected mounted() {
    this.$on('autocompleteSearchInput', debounce(this.search, 300))
  }

  protected createGoogleMaps(): Promise<unknown> {
    return new Promise<void | Event>((resolve, reject) => {
      if (document.getElementById('google-map-loader') === null) {
        const gmap = document.createElement('script')
        gmap.setAttribute('id', 'google-map-loader')
        gmap.src = this.mapUrl
        gmap.type = 'text/javascript'
        gmap.onload = resolve
        gmap.onerror = reject
        document.body.appendChild(gmap)
      } else {
        resolve()
      }
    })
  }

  protected getOption(opt: string) {
    switch (typeof this.options) {
      case 'string':
        return this.options?.includes(opt)
      case 'object':
        if (Array.isArray(this.options)) {
          return this.options?.includes(opt)
        } else if (this.options !== null && opt in this.options) {
          return this.options.self_contained
        } else {
          return false
        }
      default: return false
    }
  }

  protected beforeDestroy() {
    this.$off('autocompleteSearchInput')
  }
}
