import {ResourceLoader} from '../utils/ResourceLoader'
import {Base} from './Base'
import {LatLng} from '../models/LatLng'

export class Mapbox extends Base {

  public loadResources() {
    return [
      ResourceLoader('script', 'mapbox-map', 'https://api.mapbox.com/mapbox-gl-js/v2.2.0/mapbox-gl.js'),
      ResourceLoader('css', 'mapbox-map', 'https://api.mapbox.com/mapbox-gl-js/v2.2.0/mapbox-gl.css'),
    ]
  }

  public initMap(element: HTMLElement, initConfig: any, s: any): void {
    // Classic way: mapboxgl.accessToken = this.config.apiKey
    // @ts-ignore
    this.map = new mapboxgl.Map({
      container: element,
      style: this.config.style,
      accessToken: this.config.apiKey,
      center: [initConfig.center.lng, initConfig.center.lat],
      zoom: initConfig.zoom,
    })
    s()
    // https://www.mapbox.com/install/js/cdn-complete/
  }

  public setClickListener(l: (p: LatLng) => void): void {
    this.map.on('click', (evt: any) => {
      l(new LatLng(evt.lngLat.lat, evt.lngLat.lng))
    })
  }

  public pan(p: LatLng): void {
    this.map.flyTo({
      center: [p.lng, p.lat],
    })
  }

  public zoom(level: number): void {
    this.map.flyTo({
      zoom: level,
    })
  }

  public destroy(): void {
    this.map.remove()
    delete this.map
  }

  public markerCluster(item: any, child: any) {
    //child.forEach((childrenMarker: any)=>{
    //  this.cleanChild(childrenMarker.item)
    //  childrenMarker.addTo(this.map)
    //})
  }

  // https://docs.mapbox.com/mapbox-gl-js/example/add-a-marker/
  // https://docs.mapbox.com/mapbox-gl-js/example/popup-on-click/
  public addMarker(point: LatLng, icon: string, popupElement: any, click: () => void) {

    var el = document.createElement('div')
    el.className = 'marker'
    el.style.backgroundImage = 'url(' + icon + ')'
    el.style.width = '32px'
    el.style.height = '32px'
    el.style.backgroundSize = '100%'

    el.addEventListener('click', function() {
      click()
    })

    // @ts-ignore
    const marker = new mapboxgl.Marker(el)
    marker.setLngLat([point.lng, point.lat])

    if (popupElement) {
      // @ts-ignore
      const popup = new mapboxgl.Popup()
      popup.setDOMContent(popupElement)
      marker.setPopup(popup) // sets a popup on this marker
    }

    if (this.map) {
      marker.addTo(this.map)
    }
  }
}
