
import {Component, Vue} from 'vue-property-decorator'
import { VSelect } from 'vuetify/lib'

@Component({
  name: 'SelectTag',
  extends: VSelect as any,
})
export default class SelectTag extends Vue {

}
