
import {Component, Vue} from 'vue-property-decorator'
import {VTimePicker} from 'vuetify/lib'

@Component({
  name: 'TimePicker',
  extends: VTimePicker as any,
})
export default class TimePicker extends Vue {
}
