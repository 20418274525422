




















import {Vue, Component, Prop, Watch} from 'vue-property-decorator'
import {State} from 'vuex-class'
import {ProfileState} from '@/store/modules/profile'
import Utils from '@/utils'

@Component({
  name: 'InputTimePicker',
  components: {
    SelectTag: Utils.loadComponent('proxy/Inputs/SelectTag'),
    TimePicker: Utils.loadComponent('proxy/Inputs/TimePicker'),
    TextField: Utils.loadComponent('proxy/Inputs/TextField'),
    AutoComplete: Utils.loadComponent('proxy/Inputs/AutoComplete'),
  },
})
export default class InputTimePicker extends Vue {
  @State('profile') protected profileState!: ProfileState
  @State((state) => state.configuration.appConfig.time_slot_dimension) protected timeSlot!: any

  @Prop({type: String, default: ''}) protected label!: string
  @Prop({type: Boolean, default: false}) protected disabled!: boolean
  @Prop({type: Boolean, default: false}) protected fullWidth!: boolean
  @Prop({type: String, required: false}) protected value?: string | null
  @Prop({type: String, default: () => ''}) protected prependIcon?: string
  @Prop({type: Array, default: () => []}) protected items!: Array<{ text: string, value: string }>
  @Prop({type: Boolean, default: false}) protected readonly?: boolean

  protected time?: string | null = ''
  protected modal: boolean = false

  protected get hasError() {
    return this.time !== '' && typeof this.time === 'string'
      && !this.items.find((item) => item.value === this.time || '')
  }

  @Watch('value')
  protected valueChanged(): void {
    this.time = this.value
  }

  @Watch('items')
  protected itemsChanged(): void {
    if (this.hasError && this.value) {
      this.$emit('error')
    }
  }

  protected mounted() {
    if (this.value && !this.items.find((item) => item.value === this.value)) {
      this.$emit('timeSelected', null)
    }
    this.time = this.value ? this.value : ''
  }

  protected save(time: string) {
    this.time = time
    if (this.hasError) {
      this.$emit('error')
    } else {
      this.$emit('timeSelected', time)
    }
  }
}
