


















import {Vue, Component, Prop} from 'vue-property-decorator'
import Utils from '../utils'
import ServiceMesh from '../lib/serviceMesh'
import VuetifyColorHelper from '@/lib/vuetify/VuetifyColorHelper'
import {Getter} from 'vuex-class'
import {FieldConfigs} from '@/lib/kepler/interfaces'

@Component({
  components: {
    Sheet: Utils.loadComponent('proxy/Sheet'),
    Layout: Utils.loadComponent('proxy/Layout'),
    CustomIcon: Utils.loadComponent('CustomIcon'),
  },
  name: 'FuelLevel',
})
export default class FuelLevel extends Vue {
  @Getter('fieldConfigs') public fieldConfigs!: FieldConfigs
  @Prop({type: String}) protected type!: string
  @Prop({type: String}) protected vehicleType!: string
  @Prop({type: Number}) protected level!: number
  @Prop({type: Number || null, default: null}) protected range!: number | null
  @Prop({
    type: Boolean,
    default: false,
  }) protected text!: boolean

  // TODO: gmaps mounts the component while still hidden, find some hook that works.
  protected barOverflow: boolean = false
  protected color: string = ''
  protected colorIsLight: boolean | null = null
  protected colorIsDark: boolean | null = null
  protected barText: string = ''
  protected barStyle = {backgroundColor: '', width: ''}

  protected get typeHidden() {
    return this.fieldConfigs.fuel_type === 'hidden'
  }

  protected get iconName(): string {
    let str = 'mdi-'
    if (this.type === 'ELECTRIC') {
      if (this.level) {
        str += 'battery-' + Math.round(this.level / 10) * 10
      } else {
        str += 'battery'
      }
    } else {
      if (this.level) {
        str += ['gauge-empty', 'gauge-low', 'gauge', 'gauge-full'][Math.round(this.level / 25)] // so fancy
      } else {
        str += 'gauge'
      }
    }
    return str
  }

  protected mounted() {
    if (this.vehicleType) {
      const sm = new ServiceMesh()
      const color = ServiceMesh.colors[this.vehicleType]

      this.color = color
      this.colorIsLight = VuetifyColorHelper.lightContrast(color)
      this.colorIsDark = !this.colorIsLight // fastest way to avoid !null as a :dark value
      this.barText = this.$t(sm.vehicleType[this.vehicleType])
      this.barStyle = {
        backgroundColor: color,
        width: `${this.level}%`,
      }
    }
  }
}
