












import {Vue, Component, Prop, Watch} from 'vue-property-decorator'

import {VTextField} from 'vuetify/lib'
import {ValidationProvider} from 'vee-validate'

@Component({
  components: {VTextField, ValidationProvider},
  name: 'TextFieldWithValidation',
})
export default class TextFieldWithValidation extends Vue {
  @Prop({
    type: [String, Object],
    default: '',
  }) public readonly rules!: string | { [rule: string]: string }

  @Prop({
    type: String,
    default: null,
  }) public readonly value!: string | null

  public innerValue: string | null = null

  public get mask() {
    if (this.rules && typeof this.rules === 'object' && this.rules.mask) {
      return this.rules.mask
    } else if (typeof this.rules === 'string' && this.rules.includes('mask')) {
      const m = this.rules.split('|').find((s) => s.includes('mask'))
      return m?.split(':')[1] || null
    }
    return null
  }

  @Watch('innerValue')
  public onInnerValueChange(newVal: any) {
    this.$emit('input', newVal)
  }

  @Watch('value')
  public onValueChange(newVal: any) {
    this.innerValue = newVal
  }

  protected created() {
    if (this.value) {
      this.innerValue = this.value
    }
  }
}
