








import {Component, Prop, Vue} from 'vue-property-decorator'
import CircleStatus from './CircleStatus.vue'

@Component({
  components: {CircleStatus},
  name: 'StatusLabel',
})
export default class StatusLabel extends Vue {
  @Prop() protected active!: boolean | null
  @Prop() protected color!: string
  @Prop({
    type: Boolean,
    default: true,
  }) protected allcaps!: boolean

  get statusColor(): string {
    if (this.color) {
      return this.color
    }
    if (this.active === null) {
      return '#ffc107'
    }
    return this.active ? '#21d37f' : 'red'
  }
}
