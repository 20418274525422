










import {Component, Vue} from 'vue-property-decorator'
import Container from '@/components/proxy/Container.vue'
import sdk from '@/lib/kepler/sdk'
import Loader from '@/components/Loader.vue'
import Button from '@/components/Button.vue'
import Layout from '@/components/proxy/Layout.vue'
import TextareaDialog from '@/views/TextareaDialog.vue'
import ConfirmDialogCallback from '@/views/ConfirmDialogCallback.vue'
import LoginDialog from '@/views/LoginDialog.vue'
import {Page} from '@/lib/kepler/interfaces'

@Component({
  components: {
    Layout,
    Button,
    Loader,
    Container,
  },
  name: 'Pages',
})
export default class Pages extends Vue {
  public loading: boolean = true
  public response: Page | null = null
  public actions: Record<string, () => void> = {
    'delete-account': () => {
      const del = () => {
        const t = (x: string) => this.$t('profile.settings.delete_account.' + x)
        this.$dialog.open(TextareaDialog, {
          props: {
            title: t('title'),
            subtitle: t('subtitle'),
            confirmText: t('confirm'),
            confirmColor: 'error',
            confirmCallback: (text: string) => {
              sdk.profile.request_deletion(text).then(() => {
                this.$dialog.open(ConfirmDialogCallback, {
                  props: {
                    title: t('confirmation.title'),
                    subtitle: t('confirmation.subtitle'),
                    confirmCallback: () => {
                      this.$router.replace({name: 'logout'})
                    },
                  },
                })
              })
            },
            cancelCallback: this.$dialog.close,
          },
        })
      }

      if (!this.$isLogged()) {
        this.$dialog.open(LoginDialog, {
          props: {leaveOpen: true, callback: () => del()}, hideTopbar: true,
        })
      } else {
        del()
      }
    },
  }

  public get actn() {
    const a = this.response?.action
    if (a) {
      for (const route of this.$router.getRoutes()) {
        if (route.name === a) {
          return () => this.$router.push({name: a})
        } else if (route.path === a) {
          return () => this.$router.push({path: a})
        }
      }
      if (Object.keys(this.actions).includes(a)) {
        return this.actions[a]
      }
    }
    return null
  }

  public checkUrl() {
    this.loading = true
    // call the sdk method to get the list of pages
    const pageName = this.$route.params.name
    // console.log(pageName)
    if (pageName) {
      sdk.app.pages(pageName).then((r) => {
        // console.log(r.data)
        this.response = r.data
      }).catch(() => {
        // console.error(e)
        this.$router.replace({name: 'home'})
      }).finally(() => {
        this.loading = false
      })
    }
  }

  public created() {
    this.checkUrl()
  }
}
