import NMap from './NMap.vue'
import NMapMarker from './NMapMarker.vue'
import NMapPolyline from './NMapPolyline.vue'
import NMapCircle from './NMapCircle.vue'
import NMapPolygon from './NMapPolygon.vue'
import NMapMarkerCluster from './NMapMarkerCluster.vue'
import {LatLng} from "./models/LatLng"
import {VueConstructor} from 'vue'

export default {
  install: (vue: VueConstructor, options: Record<string,any>) => {
    vue.component('n-map', {
      extends: NMap,
      props: {
        provider: {
          default: options.provider
        },
        config: {
          default: () => options.options
        },
        center: {
          default: () => new LatLng(options.center[0], options.center[1])
        }
      }
    })
    vue.component('n-map-marker', NMapMarker)
    vue.component('n-map-polyline', NMapPolyline)
    vue.component('n-map-circle', NMapCircle)
    vue.component('n-map-polygon', NMapPolygon)
    vue.component('n-map-marker-cluster', NMapMarkerCluster)
  }
}
