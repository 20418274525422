












import {Vue, Component, Prop} from 'vue-property-decorator'
import Utils from '@/utils'

@Component({
  components: {
    Icon: Utils.loadComponent('proxy/Icon'),
    Button: Utils.loadComponent('Button'),
    ProgressCircular: Utils.loadComponent('proxy/ProgressCircular'),
  },
})
export default class CenterMapButton extends Vue {
  @Prop( {
    type: Boolean,
    default: false,
  }) protected loading!: boolean
  @Prop( {
    type: String,
    default: 'primary',
  }) protected color!: string
}
