











import {Vue, Component, Prop} from 'vue-property-decorator'
import {DamagePosition} from '@/lib/kepler/interfaces'
import Layout from '@/components/proxy/Layout.vue'
import Flex from '@/components/proxy/Flex.vue'

@Component({
  name: 'VehicleDamage',
  components: {Flex, Layout},
})
export default class VehicleDamage extends Vue {
  @Prop({type: String}) protected vehicle!: string
  @Prop({type: String}) protected imgOutline!: string
  @Prop({type: Boolean, default: false}) protected readonly!: boolean

  protected points: DamagePosition[] = []

  protected get container() {
    const dmg: any = this.$refs.carOutline
    return {
      containerClass: dmg.className,
      width: dmg.clientWidth,
      height: dmg.clientHeight,
    }
  }

  protected addPin(e: any) {
    if (!this.readonly) {
      this.points.push({
        containerWidth: this.container.width,
        containerHeight: this.container.height,
        left: e.offsetX - 12.5,
        top: e.offsetY - 12.5,
      })
      this.$emit('pointUpdated', this.points)
    }
  }

  protected removePin(component: any) {
    if (!this.readonly) {
      this.points.forEach((item, key) => {
        if (component.left === item.left && component.top === item.top) {
          this.points.splice(key, 1)
          this.$emit('pointUpdated', this.points)
        }
      })
    }
  }

}
