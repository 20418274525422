










import {Component, Prop, Watch} from 'vue-property-decorator'

import {LatLng} from './models/LatLng'
import {MarkerLabel} from './models/MarkerLabel'
import NMapChildren from './NMapChildren.vue'

@Component({
  components: {},
})
export default class NMapMarker extends NMapChildren {
  @Prop() public position!: LatLng
  @Prop() public icon!: string | HTMLElement
  @Prop() public label?: MarkerLabel
  public pos: LatLng | null = null

  public init() {
    this.item = this.providerInstance().addMarker(
      this.position,
      !!this.$slots.icon ? this.$refs.icon as HTMLElement : this.icon,
      this.$slots.default ? this.$slots.default[0].elm : null,
      this.$listeners && this.$listeners.click ? () => {
        this.$emit('click')
      } : null,
      Boolean((this.$parent as any).isNMapMarkerCluster),
      this.label,
    )
    this.setPos(this.position)
  }

  public setPos(pos: LatLng) {
    this.pos = JSON.parse(JSON.stringify(pos))
  }

  @Watch('position', {deep: true, immediate: false})
  public positionChanged(pos: LatLng) {
    const equalsOrNull = this.pos ? LatLng.equals(pos, this.pos) : true
    if (!this.isReady() || equalsOrNull) {
      return
    }
    this.setPos(pos)
    if (this.item) {
      this.providerInstance().moveMarker(this.item, pos)
    } else {
      this.clean()
      this.init()
    }
  }
}
