
























import {Vue, Component, Prop} from 'vue-property-decorator'
import {Action, Getter} from 'vuex-class'
import sdk from '@/lib/kepler/sdk'
import Utils from '@/utils'
import ConfirmDialog from '@/views/ConfirmDialog.vue'
import {FieldConfigs} from '@/lib/kepler/interfaces'
import VuetifyColorHelper from '@/lib/vuetify/VuetifyColorHelper'

@Component({
  components: {
    FormTag: Utils.loadComponent('proxy/Inputs/FormTag'),
    TextField: Utils.loadComponent('proxy/Inputs/TextField'),
    GradientCard: Utils.loadComponent('GradientCard'),
  },
  name: 'AddCoupon',
})

export default class AddCoupon extends Vue {
  @Getter('fieldConfigs') public fieldConfigs?: FieldConfigs
  @Action('getProfile') public getProfile!: () => Promise<void>

  @Prop({type: String, default: () => 'coupon'}) public theme!: string

  protected coupon: string = ''
  protected loading: boolean = false
  protected error: boolean = false

  protected vch = VuetifyColorHelper

  protected get hasCoupons() {
    return !this.fieldConfigs?.coupon_disabled
  }

  protected get isLight() {
    const top = VuetifyColorHelper.color(this.theme + 'Top')
    const bottom = VuetifyColorHelper.color(this.theme + 'Bottom')
    if (top && bottom) {
      return VuetifyColorHelper.lightContrast(top, bottom)
    }
    return null
  }

  protected sendCoupon() {
    if (this.coupon && !this.error) {
      this.loading = true
      sdk.billing.coupon(this.coupon)
        .then((r) => {
          const response = r.data
          const amount = this.$currency(response.amount)
          this.$dialog.open(ConfirmDialog, {
            props: {
              imageState: 'success.svg',
              confirmText: this.$t('common.continue'),
              code: '',
              subtitle: this.$t('profile.wallet.coupon.success', {amount}),
              title: response.reason,
              singleAction: true,
              emitConfirm: false,
            },
          })
          this.coupon = ''
          this.getProfile()
        })
        .catch(() => {
          this.error = true
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
