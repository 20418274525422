
import {Component, Vue} from 'vue-property-decorator'

import { VList } from 'vuetify/lib'

@Component({
  name: 'List',
  extends: VList as any,
})
export default class List extends Vue {

}
