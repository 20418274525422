
































import {Vue, Component} from 'vue-property-decorator'
import {Action, State} from 'vuex-class'
import Utils from '@/utils'
import {BookingState} from '@/store/modules/booking'

import {ProfileState} from '@/store/modules/profile'
import sdk from '@/lib/kepler/sdk'
import {AppConfig, VehicleSlot} from '@/lib/kepler/interfaces'
import collect from 'collect.js'
import VehicleView from '@/views/Vehicle/Vehicle.vue'
import Locate from '@/lib/location'

@Component({
  components: {
    Button: Utils.loadComponent('Button'),
    ProgressCircular: Utils.loadComponent('proxy/ProgressCircular'),
    CardVehicleList: Utils.loadComponent('CardVehicleList'),
    Flex: Utils.loadComponent('proxy/Flex'),
    Card: Utils.loadComponent('proxy/Card/Card'),
    IsLogged: Utils.loadComponent('IsLogged'),
    Btn: Utils.loadComponent('proxy/Btn'),
    Icon: Utils.loadComponent('proxy/Icon'),
    Sheet: Utils.loadComponent('proxy/Sheet'),
    LoopingBG: Utils.loadComponent('LoopingBG'),
    Layout: Utils.loadComponent('proxy/Layout'),
    GradientCard: Utils.loadComponent('GradientCard'),
    Container: Utils.loadComponent('proxy/Container'),
    CustomIcon: Utils.loadComponent('CustomIcon'),
    Avatar: Utils.loadComponent('proxy/Avatar'),
  },
})

export default class ListingHomeMode extends Vue {
  @State((state) => state.booking) public bookingState!: BookingState
  @State((state) => state.profile) public profileState!: ProfileState
  @State((state) => state.configuration.appConfig) public appConfig!: AppConfig

  @Action('setTopbarTitle') public setTopbarTitle!: (t: string) => void

  private searchResponse: VehicleSlot[] | null = null
  private loading: boolean = false

  public get slotsByLots() {
    if (this.searchResponse) {
      const c = collect(this.searchResponse)
      return c.groupBy('name').all()
    }
    return null
  }

  protected created() {
    this.init()
  }

  private formatDistance(dist: number) {
    const num = (Math.round(dist) / 1000)
    return Math.round(num * 100) / 100
  }

  private selected(vehicleSlot: VehicleSlot) {
    this.$popup.open(VehicleView, {
      props: {vehicleSlot},
      title: this.$t('vehicle.vehicle_detail'),
    })
  }

  private init() {
    this.loading = true
    Locate.please_locate((pos) => {
      const latitude = pos?.lat || this.appConfig.default_latitude || 0
      const longitude = pos?.lng || this.appConfig.default_longitude || 0
      sdk.booking.search({latitude, longitude}).then((r) => {
        this.searchResponse = r.data
        this.setTopbarTitle('vehicle.vehicle_listing')
      }).finally(() => {
        this.loading = false
      })
    })
  }

}
