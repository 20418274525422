







import {Component, Vue} from 'vue-property-decorator'

@Component({})
export default class SwipeableBar extends Vue {
  public toggled: boolean = false

  get contentStyle(): string {
    if (this.toggled) {
      return 'height: auto;'
    } else {
      return 'height: 0;'
    }
  }
}
