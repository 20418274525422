import {ActionTree, MutationTree} from 'vuex'
import sdk from '@/lib/kepler/sdk'
import {TimestampEnum, Timestamps} from '@/lib/kepler/interfaces'
import moment from 'moment'
import {RootState} from '@/store'
import {log} from '@/lib/plugins/logger'

export class TimestampsState {
  public remoteTimestamps: Partial<Timestamps> = {}
  public localTimestamps: Partial<Timestamps> = {}
}

const formatTimestamp = (k: string, timestamp?: string) => {
  const o: { [key: string]: string | number } = {}
  const m = timestamp ? moment.unix(Number(timestamp)) : moment()
  o[k + '_timestamp'] = m.unix()
  o[k] = m.format('YYYY-MM-DD hh:mm:ss')
  return o
}

const mutations: MutationTree<TimestampsState> = {
  SET_REMOTE_TIMESTAMP(stateT, payload: [key: string, remoteTimestamp: string]) {
    const [key, timestamp] = payload
    Object.assign(stateT.remoteTimestamps, formatTimestamp(key, timestamp))
  },
  SET_LOCAL_TIMESTAMP(stateT, key: string) {
    Object.assign(stateT.localTimestamps, formatTimestamp(key))
  },
  PURGE_TIMESTAMPS(stateT) {
    stateT.remoteTimestamps = {}
  },
}

const actions: ActionTree<TimestampsState, RootState> = {
  timestamps({dispatch}) {
    if (!sdk.people.isLogged()) {
      return
    }
    return sdk.app.timestamps()
      .then((payload) => {
        const data = payload.data
        // console.log('cron 1m, timestamps')
        const timestampKeys = Object.keys(data).filter((key) => !key.includes('_timestamp'))
        timestampKeys.forEach((key) => {
          const timestampKey = key + '_timestamp' as `${keyof typeof TimestampEnum}_timestamp`
          dispatch('checkUpdateAvailable', [key, data[timestampKey]]).then((updateAvailable) => {
            if (updateAvailable) {
              dispatch('timestampDispatcher', key)
            }
          })
        })
        dispatch('sleep', 'timestamps')
      }).catch((e) => e)
  },
  updateEntity({dispatch}, key: string): Promise<void> {
    return new Promise((resolve) => {
      dispatch('checkUpdateAvailable', [key]).then((updateAvailable) => {
        if (updateAvailable) {
          return dispatch('timestampDispatcher', key).then(resolve)
        } else {
          resolve()
        }
      })
    })
  },
  checkUpdateAvailable({state, commit, rootGetters}, payload: [key: string, remoteTimestamp?: number]) {
    const [key, remote] = payload
    const timestampKey = key + '_timestamp' as `${keyof typeof TimestampEnum}_timestamp`
    const remoteTimestamp = state.remoteTimestamps[timestampKey]
    const localTimestamp = state.localTimestamps[timestampKey]

    if (!remoteTimestamp || remote && remote !== remoteTimestamp) {
      commit('SET_REMOTE_TIMESTAMP', payload)
      return true // remote timestamp is changed, there was an update on the backend or it is a first load scenario
    }
    if (rootGetters.debugMode) {
      log(`${key} | ${!localTimestamp || remoteTimestamp >= localTimestamp}`, 0)
    }
    return !localTimestamp || remoteTimestamp >= localTimestamp
  },
  timestampDispatcher({state, dispatch, commit}, key: keyof typeof TimestampEnum) {
    return new Promise((resolve) => {

      // Update Timestamp
      const action = TimestampEnum[key]
      if (action) {
        dispatch(action).then((response) => {
            dispatch('timestampUpdate', key)
            resolve(response)
          },
        )
        // in addition to history, update future reservation and current too
        if (key === 'reservation') {
          dispatch('current')
          dispatch('futureReservations')
        }
      } else {
        dispatch('timestampUpdate', key)
      }
    })
  },
  timestampUpdate({commit}, key) {
    commit('SET_LOCAL_TIMESTAMP', key)
  },
  purge({commit}) {
    commit('PURGE_TIMESTAMPS')
  },
}

export default {
  state: new TimestampsState(),
  mutations,
  actions,
}
