

import {Component, Prop} from 'vue-property-decorator'

import {LatLng} from './models/LatLng'
import NMapChildren from './NMapChildren.vue'
import {DecodePolyline} from '@/lib/n-maps/src/utils/PolylineDecoder'

@Component({
  components: {},
})
export default class NMapPolyline extends NMapChildren {
  @Prop({default: ()=>[]}) public positions!: LatLng[]
  @Prop({default: ""}) public geometry!: string

  @Prop({default: 4}) public readonly lineWidth!: number
  @Prop({default: '#ff0000'}) public readonly color!: string

  @Prop({default: null}) public readonly tooltip!: string

  public render() {
    return null
  }

  public init() {
    if (this.geometry !== "") {
      this.item = this.providerInstance().addPolyline(DecodePolyline(this.geometry), this.lineWidth, this.color, this.tooltip)
    } else {
      this.item = this.providerInstance().addPolyline(this.positions, this.lineWidth, this.color)
    }
  }
}
