
import {Component, Vue} from 'vue-property-decorator'

import { VToolbar } from 'vuetify/lib'

@Component({
  name: 'Toolbar',
  extends: VToolbar as any,
})
export default class Toolbar extends Vue {

}
