export default abstract class AbstractHardware {
  protected config: any = {}
  private actions: Map<string, { channel: string | null, run: () => Promise<any>, retry: number | undefined }> = new Map<string, { channel: string, run: () => Promise<any>, retry: number | undefined }>()
  private statusLogger: ((x: string) => void) | null = null

  constructor(conf: any) {
    this.config = conf
  }

  public init(statusCB: (msg: string) => void) {
    return new Promise(() => {
      throw new Error('no hardware found')
    })
  }

  public runAction(name: string) {
    this.statusLog('>>' + name)
    const action = this.actions.get(name)
    if (action) {
      return action.run()
    }
    return Promise.reject('command not found')
  }

  public getActions() {
    return this.actions
  }

  public setStatusLogger(cb: (x: string) => void) {
    this.statusLogger = cb
  }

  protected addAction(name: string, act: () => Promise<any>, channel: string | null = null, retry?: number) {
    this.statusLog(`ACTION+${name}`)
    this.actions.set(name, {run: act, channel: channel, retry: retry})
  }

  protected statusLog(x: string) {
    if (this.statusLogger) {
      this.statusLogger(x)
    }
  }

  public terminateCheck(): Promise<string | void> {
    return new Promise((resolve) => {
      resolve('OK')
    })
  }

  public cleanup(): void {
  }
}

export class AlertHardware extends AbstractHardware {
  public init() {
    return new Promise<void>((resolve) => {
      this.addAction('open', () => new Promise<void>((resolve, reject) => {
        if(this.config.fail) {
          reject("IGNITION_UNKNOWN")
        } else {
          //alert('OPEN CALLED')
          resolve()
        }
      }), 'js-alert', 2)

      this.addAction('close', () => new Promise<void>((resolve, reject) => {
        if(this.config.fail) {
          //alert('FAIL CLOSE CALLED')
          reject()
        } else {
          //alert('CLOSE CALLED')
          resolve()
        }
      }), 'js-alert')

      setTimeout(() => {
        resolve()
      }, this.config.initTime ?? 1)
    })

  }
}
