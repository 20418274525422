
import {Component, Vue} from 'vue-property-decorator'
import { VStepperHeader } from 'vuetify/lib'

@Component({
  name: 'StepperHeader',
  extends: VStepperHeader as any,
})
export default class StepperHeader extends Vue {

}
