import AbstractHardware from '@/lib/hardware/classes/AbstractHardware'
import InversCloudBoxx from '@/lib/BtSDKs/InversCloudBoxx'


export interface InversConfig {
  pinRequester: () => Promise<string>
  virtual_key: any
  device: string
  token: string
  session_key: string
}

export default class InversHardware extends AbstractHardware {
  constructor(conf: InversConfig) {
    super(conf)
  }

  public init(statusCB: (msg: string) => void) {
    if (window.cordova.platformId === 'browser') {
      return Promise.reject('browser is not supported on teltonika hardware')
    }
    const conf = this.config as InversConfig
    statusCB('Launching invers init...')

    let manager = new InversCloudBoxx(statusCB, conf.virtual_key.device, conf.virtual_key.session_key, conf.virtual_key.token)

    return Promise.resolve()
      .then(() => {
        this.addAction('open', () => new Promise((resolve, reject) => {
          manager.unlock().then(resolve).catch(reject)
        }), 'ble')
        this.addAction('close', () => new Promise((resolve, reject) => {
          manager.lock().then(resolve).catch(reject)
        }), 'ble')
      })
  }
}
