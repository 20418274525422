
























































































import {Vue, Component, Prop, Watch} from 'vue-property-decorator'
import {AppConfigState} from '@/store/modules/configuration'
import CountryList from '../../lib/kepler/countries'
import Validations from '../../lib/Validations'
import {State} from 'vuex-class'
import Utils from '@/utils'
import {ExtraClientField, ModifyRequest} from '@/lib/kepler/interfaces'

@Component({
  name: 'BillingInfo',
  components: {
    PhoneWithPrefix: Utils.loadComponent('PhoneWithPrefix'),
    CheckBox: Utils.loadComponent('proxy/Inputs/CheckBox'),
    Container: Utils.loadComponent('proxy/Container'),
    Button: Utils.loadComponent('Button'),
    FormTag: Utils.loadComponent('proxy/Inputs/FormTag'),
    TextField: Utils.loadComponent('proxy/Inputs/TextField'),
    CountrySelector: Utils.loadComponent('CountrySelector'),
  },
})
export default class BillingInfo extends Vue {
  @State('configuration') public configuration!: AppConfigState
  @Prop() public modifyRequest!: ModifyRequest

  public countries: any = CountryList
  public rules: any = Validations.rules
  public valid: boolean = false
  public extraFields: ExtraClientField[] = []

  protected mounted() {
    this.extraFields = this.configuration.appConfig.extra_client_fields
    this.$nextTick(() => {
      if (this.$refs.accountFormBilling !== undefined) {
        (this.$refs.accountFormBilling as any).validate()
      }
    })
  }

  protected extraRules(key: string) {
    if (this.extraFields.length) {
      const extra = this.extraFields.find((field) => {
        return field.value === key
      })
      if (extra && extra.validation) {
        const validation = extra.validation
        const rulesArray: [() => boolean] | Array<null> = []
        validation.split('|').forEach((rule) => {
          if (rule.startsWith('digits_between')) {
            const x = rule.split(':').pop()
            if (x) {
              const minMax = x.split(',') as string[]
              rulesArray.push(this.rules.digitsBetween(minMax[0], minMax[1]))
            }
          }
          switch (rule) {
            case 'required':
              rulesArray.push(this.rules.required)
              break
            case 'numeric':
              rulesArray.push(this.rules.number)
              break
            case 'boolean':
              rulesArray.push(this.rules.boolean)
              break
          }
        })
        if (validation && validation.includes('nullable')) {
          rulesArray.push(this.rules.nullable)
        }
        return rulesArray
      }
    }
  }

  protected copyAddress() {
    Object.assign(this.modifyRequest.billing_address, this.modifyRequest.address)
  }

  @Watch('valid', {immediate: true})
  protected onValidChanged(val: boolean) {
    val ? this.$emit('validationStatus', {billing: true}) : this.$emit('validationStatus', {billing: false})
  }
}
