


















import {Component, Prop, Vue, Watch} from 'vue-property-decorator'
import Icon from './proxy/Icon.vue'
import moment, {Moment} from 'moment'
import Layout from './proxy/Layout.vue'
import InputDatePicker from './proxy/Inputs/InputDatePicker.vue'

// TODO: direct selection from date

@Component({
  components: {
    InputDatePicker,
    Layout,
    Icon,
  },
})
export default class MonthSwitcher extends Vue {
  @Prop({}) public date?: string
  @Prop({
    default: false,
    type: Boolean,
  }) public pastOnly?: boolean

  public dateString: string = ''

  public get max() {
    return moment().format('YYYY-MM')
  }

  protected created() {
    this.dateString = this.date ? moment(this.date, 'YYYY-MM').format('YYYY-MM') : moment().format('YYYY-MM')
  }

  // Change month (add, subtract or set)
  private up() {
    this.dateString = moment(this.dateString).add(1, 'M').format('YYYY-MM')
  }

  private down() {
    this.dateString = moment(this.dateString).subtract(1, 'M').format('YYYY-MM')
  }

  private setDate(date: string) {
    this.dateString = date
  }

  // Watch and emit if changed
  @Watch('dateString')
  private onChangeEmit() {
    this.$emit('monthChanged', this.dateString)
  }

  // Check if the current date is in the future
  private isFuture() {
    const now = moment().format('YYYY-MM')
    return this.pastOnly && this.dateString === now
  }

  // Format callback for the date picker
  private formatDate: (date: Moment) => string = (date) => {
    return moment(date).format('MMMM YYYY')
  }
}
