





// based upon https://github.com/robbeman/vue-fit-text

import {Vue, Component, Prop} from 'vue-property-decorator'

@Component({
  components: {},
  name: 'FitText',
})
export default class FitText extends Vue {
  @Prop({
    default: 1,
    type: Number,
  }) public targetLineCount!: number
  @Prop({
    default: 'em',
    type: String,
  }) public unit!: string
  @Prop({
    default: 0.5,
    type: Number,
  }) public min!: number
  @Prop({
    default: 1,
    type: Number,
  }) public max!: number

  public observer: any = null

  private calculate() {
    const element = this.$el as HTMLElement
    // first make it an inline block and set the line height to a fixed pixel value
    element.style.display = 'inline-block'
    element.style.lineHeight = '1px'

    // then keep trying until it fits
    let fontSize = this.max
    const stepSize = (this.unit === 'px') ? 1 : 0.05
    element.style.fontSize = fontSize + this.unit

    while (element.offsetHeight > this.targetLineCount && fontSize > this.min) {
      fontSize -= stepSize
      element.style.fontSize = fontSize + this.unit
    }
    // found it!!

    // reset the styles
    element.style.display = ''
    element.style.lineHeight = ''
  }

  private mounted() {
    this.calculate()

    if ('MutationObserver' in window && this.observer === null) {
      // Create the observer (and what to do on changes...)
      this.observer = new MutationObserver(this.calculate)

      // Setup the observer
      this.observer.observe(
        this.$el,
        {subtree: true, characterData: true},
      )
    }
  }

  private beforeDestroy() {
    // Clean up
    this.observer.disconnect()
  }
}
