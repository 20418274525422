






























































import {Component, PropSync} from 'vue-property-decorator'
import Utils from '@/utils'
import {mixins} from 'vue-class-component'
import {FiltersLogic} from '@/components/filters/FiltersLogic'
import {VBtn, VSlider} from 'vuetify/lib'
import {VehicleType} from '@/lib/kepler/interfaces'

@Component({
  name: 'MapFilters',
  components: {
    VBtn,
    VSlider,
    Card: Utils.loadComponent('proxy/Card/Card'),
    Dialog: Utils.loadComponent('proxy/Dialog'),
    Icon: Utils.loadComponent('proxy/Icon'),
    RangeFilter: Utils.loadComponent('filters/RangeFilter'),
    Flex: Utils.loadComponent('proxy/Flex'),
    Divider: Utils.loadComponent('proxy/Divider'),
    CustomIcon: Utils.loadComponent('CustomIcon'),
    ProgressLinear: Utils.loadComponent('proxy/ProgressLinear'),
    CloseButton: Utils.loadComponent('CloseButton'),
    Container: Utils.loadComponent('proxy/Container'),
    Layout: Utils.loadComponent('proxy/Layout'),
    Button: Utils.loadComponent('Button'),
    FiltersLayout: Utils.loadComponent('filters/FiltersLayout'),
    VehicleIcon: Utils.loadComponent('VehicleIcon'),
    GradientCard: Utils.loadComponent('GradientCard'),
  },
})
export default class Filters extends mixins<FiltersLogic>(FiltersLogic) {
  @PropSync('showFilters', {type: Boolean}) public show!: boolean

  private advancedFilters: boolean = false

  public hide() {
    this.show = false
  }

  public bmString(marker: any, fallback?: string) { // top shelf lang hack
    const vt = marker.data[0]?.items[0]?.vehicle.category.type.toLowerCase()
    const bm = marker.data[0]?.items[0]?.reservation_type.toLowerCase()
    if (vt && bm) {
      const override = this.$isAvailable(`filters.${(bm + vt).toUpperCase()}`)
      return override || `activities.booking.${vt}.${bm}.action`
    } else {
      return fallback
    }
  }

  protected initAndClose() {
    this.init()
    this.advancedFilters = false
  }

  private has(type: string) {
    return Object.keys(this.filters[type]).length
  }

  private getvehicleType(name?: string) {
    const vt = (name || '')
      .split('.')
      .filter((s) => s !== 'vehicle')
      .map((s) => s.toUpperCase())[0]
    if (vt && Object.keys(VehicleType).includes(vt)) {
      return vt.toLowerCase()
    }
    return null
  }

  private get rangeFilter() {
    return this.fieldConfigs.range_filter ?? 'icon'
  }
}
