
















import {Vue, Component, Prop, Watch} from 'vue-property-decorator'
import Utils from '@/utils'
import {Action, State} from 'vuex-class'
import {FlowInputStep} from '@/lib/kepler/interfaces'
import smoothReflow from 'vue-smooth-reflow'

interface ParsedStrings {
  description: string | null
  title: string | null
}

@Component({
  components: {
    Img: Utils.loadComponent('proxy/Image'),
    Sheet: Utils.loadComponent('proxy/Sheet'),
    Layout: Utils.loadComponent('proxy/Layout'),
    Card: Utils.loadComponent('proxy/Card/Card'),
    FlowField: Utils.loadComponent('flow/FlowField'),
    Container: Utils.loadComponent('proxy/Container'),
    FormTag: Utils.loadComponent('proxy/Inputs/FormTag'),
    CardText: Utils.loadComponent('proxy/Card/CardText'),
    CardTitle: Utils.loadComponent('proxy/Card/CardTitle'),
  },
  name: 'FlowStep',
  mixins: [smoothReflow],
})
export default class RegistrationStepComponent extends Vue {
  @State('flowOutputs') public flowOutputs!: { [k: string]: any }
  @Action('createFlowOutput') public createFlowOutput: any

  @Prop({
    type: Object,
  }) public step!: FlowInputStep
  @Prop({
    type: String,
  }) public currentFlow!: string
  @Prop({
    type: Boolean,
    default: true,
  }) public absolute!: boolean

  public parsedStrings: {
    description: string | null;
    title: string | null
  } = {title: null, description: null}

  public imageError: boolean = false

  protected get outputFlow() {
    if (Object.values(this.flowOutputs).length) {
      return (this.flowOutputs[this.currentFlow] as object) || null
    }
    return null
  }

  protected get imgUrl() {
    if (this.step.image) {
      return this.$isAvailable(this.step.image) || this.step.image
    }
  }

  protected get imgStyle() {
    if (this.imgUrl?.includes('fullscreen')) {
      return {
        maxWidth: 'min(40em, calc(100% + 32px))',
        width: 'calc(100% + 32px)',
        left: '-16px',
      }
    }
    return {
      maxWidth: 'min(30em, 80%)',
      width: '100%',
    }
  }

  protected parseStrings() {
    const {title, description} = this.step
    const translation = (s?: string) => s ? this.$isAvailable(s) : null

    if (this.outputFlow) {
      this.checkExistingOutputOrCreateIt().then((output) => {
        const entries = Object.entries(output)
        const delimiters = ['[', ']'] // this could be somehow configurable from back end
        const regex = new RegExp('\\' + delimiters[0] + '(.*?)' + delimiters[1], 'gs')
        const f = (s?: string) => {
          if (s) {
            return s.replace(regex, (match: string, str: string) => {
              // return Utils.getProp(registration, [...str.split('.')]) || str // original without all this sweet madness
              // all this crazyness below does is to extract variables from the strings regardless of whitespace and other
              // words, e.g: [name surname da address.city, yo!] -> Tizio Nenno da Cagliari, yo! and to enable conditional
              // usage of said whitespace an words. if for example the street or city fields are missing or empty:
              // [mi chiamo name surname][ da address.city, address.region] -> mi chiamo Tizio Nenno
              let result = str
              for (const [key, value] of entries) {
                // cycle through registration fields to find words to replace
                if (str.includes(key) && value) {
                  if (typeof value === 'string') {
                    result = result.replace(key, output[key])
                  } else if (typeof value === 'object') {
                    // dot notation recognition
                    const r1 = new RegExp(`${key}(\\.[a-z_]+)+`, 'gm')
                    const m1 = str.match(r1)
                    if (m1 !== null) {
                      m1.forEach((m) => {
                        const x = Utils.getProp(output, [...m.split('.')])

                        if (x) {
                          result = result.replace(m, x)
                        }
                      })
                    }
                  }
                }
              }
              return result || ''
            })
          }
        }

        this.$set(this.parsedStrings, 'title', translation(title) || f(title))
        this.$set(this.parsedStrings, 'description', translation(description) || f(description))
      })
    } else {
      this.$set(this.parsedStrings, 'title', translation(title) || title)
      this.$set(this.parsedStrings, 'description', translation(description) || description)
    }
  }

  protected checkExistingOutputOrCreateIt() {
    return new Promise((resolve: (r: { [k: string]: any }) => void) => {
      const output = this.flowOutputs[this.step.context || this.currentFlow] as { [key: string]: any }
      if (output) {
        resolve(output)
      } else {
        this.createFlowOutput(this.step.context || this.currentFlow).then((r: { [k: string]: any }) => {
          resolve(r)
        })
      }
    })
  }

  protected imgError() {
    this.imageError = true
  }

  @Watch('step', {deep: true})
  protected onStepChange() {
    this.parseStrings()
  }

  protected mounted() {
    if (!this.absolute || true) {
      this.$smoothReflow?.({
        property: ['height', 'transform'],
        el: '.description-container',
        hideOverflow: true,
      })
    }
  }

  protected created() {
    this.parseStrings()
  }
}
