



























import {Component, Prop} from 'vue-property-decorator'
import VuetifyColorHelper from '@/lib/vuetify/VuetifyColorHelper'
import ColorCard from '@/components/ColorCard.vue'
import Utils from '@/utils'

@Component({
  components: {
    Card: Utils.loadComponent('proxy/Card/Card'),
    Container: Utils.loadComponent('proxy/Container'),
    Layout: Utils.loadComponent('proxy/Layout'),
    Avatar: Utils.loadComponent('proxy/Avatar'),
    Icon: Utils.loadComponent('proxy/Icon'),
  },
})
export default class CardButton extends ColorCard {
  @Prop({}) public circleColor?: string
  @Prop({}) public icon?: string
  @Prop({}) public iconColor?: string
  @Prop({}) public tile?: boolean
  @Prop({
    type: Boolean,
    default: true,
  }) declare public flat: boolean
  @Prop({
    type: Boolean,
    default: true,
  }) declare public ripple: boolean
  @Prop({
    type: Boolean,
    default: false,
  }) public wrap?: boolean
  @Prop({
    type: String,
    default: () => 'start',
  }) public justify!: string
  @Prop({
    type: String,
    default: () => 'center',
  }) public align!: string
  @Prop({type: String}) public action?: string
  @Prop({type: String}) public description?: string

  public get checkIsLight() {
    if (this.circleColor) {
      return VuetifyColorHelper.lightContrast(VuetifyColorHelper.color(this.circleColor))
    }
    return this.isLight
  }

  public get classes() {
    return ['justify-' + this.justify, 'align-' + this.align]
  }
}
