
import {Component, Vue} from 'vue-property-decorator'

import {VProgressCircular} from 'vuetify/lib'

@Component({
  name: 'ProgressCircular',
  extends: VProgressCircular as any,
})
export default class ProgressCircular extends Vue {

}
