

























import {Vue, Component, Prop} from 'vue-property-decorator'
import Utils from '@/utils'
import Card from '@/components/proxy/Card/Card.vue'
import Layout from '@/components/proxy/Layout.vue'
import Flex from '@/components/proxy/Flex.vue'
import BuyPackets from '@/components/BuyPackets.vue'
import BuyTopUp from '@/components/BuyTopUp.vue'
import AddCoupon from '@/components/wallet/AddCoupon.vue'
import {Getter} from 'vuex-class'
import {FieldConfigs} from '@/lib/kepler/interfaces'
import ListTileTitle from '@/components/proxy/List/ListTileTitle.vue'
import ListTileContent from '@/components/proxy/List/ListTileContent.vue'
import ListTile from '@/components/proxy/List/ListTile.vue'
import List from '@/components/proxy/List/List.vue'
import GradientCard from '@/components/GradientCard.vue'
import Container from '@/components/proxy/Container.vue'

type rateOptionsOptions = Partial<['plans', 'topups', 'packets', 'coupons']>

@Component({
  components: {
    Container,
    GradientCard,
    List,
    ListTile,
    ListTileContent,
    ListTileTitle,
    AddCoupon,
    BuyTopUp,
    BuyPackets,
    Card,
    Img: Utils.loadComponent('proxy/Image'),
    Button: Utils.loadComponent('Button'),
    Btn: Utils.loadComponent('proxy/Btn'),
    CloseButton: Utils.loadComponent('CloseButton'),
    Layout,
    Flex,
  },
  name: 'RateOptions',
})
export default class RateOptions extends Vue {
  @Getter('fieldConfigs') public configs?: FieldConfigs

  @Prop({
    type: Array,
    required: false,
    default: () => ['plans', 'topups', 'packets', 'coupons'],
  }) public options!: rateOptionsOptions

  @Prop({type: String, required: false}) protected title?: string
  @Prop({type: String, required: false}) protected subtitle?: string

  protected loading: boolean = false

  protected get hasPlans() {
    return this.options.includes('plans')
  }

  protected get hasCoupons() {
    return this.options.includes('coupons') && !this.configs?.coupon_disabled
  }

  protected get hasTopups() {
    return this.options.includes('topups') && !!this.configs?.topup_sizes
  }

  protected get hasPackets() {
    return this.options.includes('packets') && !!this.configs?.packets_enabled
  }

  protected openPlans() {
    this.$router.push({name: 'plans'})
  }
}
