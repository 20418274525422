







import {Component, Prop} from 'vue-property-decorator'

import {LatLng} from './models/LatLng'
import NMapChildren from './NMapChildren.vue'


@Component({
  components: {},
})
export default class NMapPolygon extends NMapChildren {
  @Prop() public positions!: LatLng[]

  @Prop({default: 1}) public readonly lineWidth!: number
  @Prop({default: '#00ff00'}) public readonly color!: string

  public init() {
    this.item = this.providerInstance().addPolygon(
      this.positions,
      this.lineWidth,
      this.color,
      this.$slots.default ? this.$slots.default[0].elm : null,
      this.$listeners && this.$listeners.click ? () => {
        this.$emit('click')
      } : () => {})
  }
}
