export class FirebaseHelper {
  public static run(onNewToken: (token: string) => void, onMessage: (title: string, body: string, data: any) => void) {
    const fbx = window.FirebasePlugin
    const privateKeys = [
      'google.delivered_priority',
      'google.sent_time',
      'google.ttl',
      'google.original_priority',
      'messageType',
      'title',
      'body',
      'from',
      'google.message_id',
      'gcm.n.analytics_data',
      'collapse_key',
      'google.c.sender.id',
      'google.c.a.e',
      'aps',
      'gcm.message_id',
      'google.c.fid',
      'fcm_options',
      'id',
      'ttl',
      'sent_time',
      'show_notifications',
    ]

    fbx?.grantPermission((hasPermission) => {}, (error) => {})

    fbx?.getToken(onNewToken, (error) => {})

    fbx?.onTokenRefresh(onNewToken, (error) => {})

    fbx?.onMessageReceived((message: Record<string, any>) => {
      let parsedData: Record<string, any> = {}
      Object.keys(message).forEach((k) => {
        if (!privateKeys.includes(k)) {
          parsedData[k] = message[k]
        }
      })
      onMessage(message.title, message.body, parsedData)
    }, (error) => {
    })
  }

  public static unregister() {
    window.FirebasePlugin?.unregister()
  }
}
