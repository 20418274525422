import {Vue, Component} from 'vue-property-decorator'
import {PaginatedRequest, PaginatedResponse} from '@/lib/kepler/interfaces'

export interface infiniteHandlerState {
  loaded: () => void,
  complete: () => void,
  reset: () => void
}

@Component
export default class InfiniteScrollHandler extends Vue {

  public handler(state: infiniteHandlerState, max: number, paginated: PaginatedResponse<any>, func: Function,) {
    const loader = (this.$refs.infiniteLoader as any)

    const failure = (p: PaginatedResponse<any>) => {
      const currentPage: number = p.current_page_count === 0 ? 0 : p.current_page
      const failConditions = [
        currentPage === max,
        typeof p.items_count === 'number' && p.items_count < 10,
        p.current_page === p.page_count || p.current_page === 0
      ]
      return failConditions.findIndex(v => v) >= 0
    }

    if (failure(paginated)) {
      loader.isLoading = false
      if (paginated.items_count) {
        state.loaded()
      }
      state.complete()
      return
    }

    const req: PaginatedRequest = {per_page: 10, page_number: paginated.current_page + 1}
    func(req).then((r: PaginatedResponse<any>) => {
      state.loaded()
      if (failure(r)) {
        loader.isLoading = false
        state.complete()
        return
      }
    })
  }

}
