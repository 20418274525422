export class CameraFileUploader {
  readonly uploadUrl!: string
  /**
   CAMERA: 1
   PHOTOLIBRARY: 0
   SAVEDPHOTOALBUM: 2
   */
  readonly sourceType!: number

  public constructor(uploadUrl: string, sourceType: number = 1) {
    this.uploadUrl = uploadUrl
    this.sourceType = sourceType
    Camera.Direction.FRONT
  }

  public openCamera(uploadCallBack: (data: any) => void, cameraErrorCallback: (data: any) => void, allowEdit: boolean = true, cameraDirection: number = 0) {
    navigator.camera.getPicture((file: any) => {
      this.uploadFile(file, uploadCallBack)
    }, cameraErrorCallback, {
      quality: 90,
      sourceType: this.sourceType,
      encodingType: Camera.EncodingType.JPEG,
      destinationType: Camera.DestinationType.DATA_URL,
      allowEdit,
      cameraDirection,
    })
  }

  private uploadFile(file: any, cb: (data: any) => void) {
    const formData = new FormData()
    formData.append('file', this.getBlob(file, 'image/JPEG'))
    const oReq = new XMLHttpRequest()


    oReq.open('POST', this.uploadUrl, true)
    oReq.onreadystatechange = function () {
      if (oReq.readyState == XMLHttpRequest.DONE) {
        cb(JSON.parse(oReq.responseText))
      }
    }
    // Pass the blob in to XHR's send method
    oReq.send(formData)
  }

  private getBlob(b64Data: string, contentType: string, sliceSize: number = 512) {
    contentType = contentType || ''
    sliceSize = sliceSize || 512

    let byteCharacters = atob(b64Data)
    let byteArrays = []

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      let slice = byteCharacters.slice(offset, offset + sliceSize)

      let byteNumbers = new Array(slice.length)
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i)
      }

      let byteArray = new Uint8Array(byteNumbers)

      byteArrays.push(byteArray)
    }

    let blob = new Blob(byteArrays, {type: contentType})
    return blob
  }
}
