






















import {Vue, Component} from 'vue-property-decorator'
import {State} from 'vuex-class'
import {ProfileState} from '@/store/modules/profile'
import Utils from '../utils'

@Component({
  components: {
    Container: Utils.loadComponent('proxy/Container'),
    Layout: Utils.loadComponent('proxy/Layout'),
    StatusLabel: Utils.loadComponent('StatusLabel'),
    Chip: Utils.loadComponent('proxy/Chip'),
  },
  name: 'ClientDriverStatus',
})
export default class ClientDriverStatus extends Vue {
  @State('profile') public profileState!: ProfileState

  public get clientStatus() {
    if (this.profileState.client !== null) {
      return this.profileState.client.status
    }
  }

  public get driverStatus() {
    if (this.profileState.driver !== null) {
      return this.profileState.driver.status
    }
  }

  public status(status: string): boolean | null {
    const red: string[] = [
      'CANCELED',
      'SUSPENDED',
      'REFUSED',
    ]
    if (status === 'ACTIVE' || status === 'APPROVED') {
      return true
    } else if (red.includes(status)) {
      return false
    }
    return null
  }

  public description(status: string, type: string) {
    if (status) {
      return this.$t(`status.${type}.${status.replace(/ /g, '_')}`)
    }
  }

  private statusColor(status: boolean | null): string {
    if (status === null) {
      return 'warning'
    }
    return status ? 'success' : 'error'
  }

}
