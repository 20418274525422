





















import {Vue, Component, Prop} from 'vue-property-decorator'
import Utils from '@/utils'
import {ReservationResponse} from '@/lib/kepler/interfaces'

@Component({
  components: {
    Card: Utils.loadComponent('proxy/Card/Card'),
    CloseButton: Utils.loadComponent('CloseButton'),
    Dialog: Utils.loadComponent('proxy/Dialog'),
    Layout: Utils.loadComponent('proxy/Layout'),
    Button: Utils.loadComponent('Button'),
    ActiveReservationBar: Utils.loadComponent('entities/reservation/ActiveReservationBar'),
  },
  name: 'MaintenanceReservationBar',
})
export default class MaintenanceReservationBar extends Vue {
  @Prop({type: Array, default: () => []}) public reservations!: ReservationResponse[]
  public detail: boolean = false
}
