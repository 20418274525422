




















































import {Component, Prop, Vue} from 'vue-property-decorator'
import {
  VBtn,
  VCard,
  VContainer,
  VDivider,
  VIcon,
  VTabItem,
  VTabsItems,
} from 'vuetify/lib'
import {ValidationObserver} from 'vee-validate'
import Utils from '@/utils'
import {FlowInputField, FlowInputStep, FlowInputValidation} from '@/lib/kepler/interfaces'
import FlowField from '@/components/flow/FlowField.vue'
import {State} from 'vuex-class'

@Component({
  components: {
    Layout: Utils.loadComponent('proxy/Layout'),
    Button: Utils.loadComponent('Button'),
    FlowField,
    VIcon,
    VContainer,
    VCard,
    VDivider,
    ValidationObserver,
    VTabsItems,
    VTabItem,
    VBtn,
  },
  name: 'RegistrationDocumentAddComponent',
})
export default class RegistrationDocumentAddComponent extends Vue {
  @State('flowOutputs') public flowOutputs!: { [k: string]: any }
  @Prop({}) protected doc!: FlowInputStep | null
  @Prop({
    type: String || null,
    default: () => null,
  }) protected flowName!: string | null
  protected tab: number = 0

  protected get tabs() {
    return (this.attachmentFields?.length || 0) + (this.docFields?.length ? 1 : 0)
  }

  protected get attachmentFields() {
    return this.doc?.fields?.filter((f) => {
      return f.type === 'image'
    })
  }

  protected get docFields() {
    return this.doc?.fields?.filter((f) => {
      return f.type !== 'image'
    })
  }

  protected get output() {
    const atts = this.attachmentFields
    if (this.flowOutputs && this.flowName && this.doc?.id) {
      const docs = this.flowOutputs[this.flowName]?.documents
      if (docs) {
        const doc = docs[this.doc.id]
        if (doc) {
          const outputAtts = doc.attachments
          return atts?.map((att) => {
            return Object.keys(outputAtts).includes(att.name)
          })
        }
      }
    }
    return atts?.map(() => true)
  }

  protected mounted() {
    this.tab = 0
  }

  protected saveAndClose(validate: () => Promise<boolean>) {
    validate().then((pass) => {
      if (pass) {
        this.$emit('close')
      }
    })
    // TODO: something resets those fields on save
  }

  protected getMask(f: FlowInputField) {
    const v = f.validation
    let result
    if (v && (v.includes('mask') || (v as FlowInputValidation).mask)) {
      if (typeof v === 'string') {
        v.split('|').find((rule) => {
          const maskRegex = /mask:(.+)/
          const m = rule.match(maskRegex)
          if (m) {
            result = m[1]
          }
        })
      } else {
        result = v.mask
      }
    }
    return result
  }
}
