

















import {Component, Prop} from 'vue-property-decorator'
import Utils from '@/utils'
import {BookTimeSelectRequest, CheckInOut, ReservationResponse} from '@/lib/kepler/interfaces'
import ConfirmDialogCallback from '@/views/ConfirmDialogCallback.vue'
import moment from 'moment'

@Component({
  components: {
    Container: Utils.loadComponent('proxy/Container'),
    RangeEstimate: Utils.loadComponent('rangeSelector/rangeEstimate'),
    Card: Utils.loadComponent('proxy/Card/Card'),
    CloseButton: Utils.loadComponent('CloseButton'),
    VehicleQuickSummary: Utils.loadComponent('entities/vehicle/VehicleQuickSummary'),
    Divider: Utils.loadComponent('proxy/Divider'),
    CheckInOutPicker: Utils.loadComponent('CheckInOutPicker'),
    Button: Utils.loadComponent('Button'),
  },
  name: 'BookingEdit',
})
export default class BookingEdit extends ConfirmDialogCallback {
  @Prop({type: Object, required: true}) public readonly reservation!: ReservationResponse

  protected editRequest: CheckInOut | null = null
  protected estimateRequest: BookTimeSelectRequest | null = null

  protected get confirmDisabled() {
    if (!this.estimateRequest) {
      return true
    }
    const r = this.reservation
    const start = moment(r.start).unix()
    const end = moment(r.end).unix()
    const empty = r.start === null || r.end === null
    return (start === r.start_timestamp) && (end === r.end_timestamp) || empty
  }

  protected pupulateFields(val: { start: number, end: number }) {
    // TODO: should not modify a prop FFS
    this.reservation.start_timestamp = val.start
    this.reservation.end_timestamp = val.end

    this.$set(this, 'estimateRequest', {...val})
  }

  private mounted() {
    this.$nextTick(() => {
      const start_timestamp = this.reservation.start_timestamp
      const end_timestamp = this.reservation.end_timestamp
      if (start_timestamp && end_timestamp) {
        this.editRequest = {
          checkInDate: moment.unix(start_timestamp).format('YYYY-MM-DD'),
          checkOutDate: moment.unix(end_timestamp).format('YYYY-MM-DD'),
          checkInTime: moment.unix(start_timestamp).format('HH:mm:ss'),
          checkOutTime: moment.unix(end_timestamp).format('HH:mm:ss'),
        }
      }
    })
  }
}
