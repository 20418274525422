
import {Component, Prop, Vue} from 'vue-property-decorator'
import { VDatePicker } from 'vuetify/lib'

@Component({
  name: 'DatePicker',
  extends: VDatePicker as any,
})
export default class DatePicker extends Vue {
  @Prop() private year!: boolean

  private activePicker: string = 'DATE'

  public mounted() {

    if (this.year) {
      this.activePicker = 'YEAR'
    }
  }
}
