import {Vue, Component} from 'vue-property-decorator'
import Utils from '@/utils'
import {Action, State} from 'vuex-class'
import {FlowInputsState} from '@/store/modules/flowInputs'
import {FlowOutputRequest, FlowOutputsState} from '@/store/modules/flowOutputs'

@Component
export class FlowMixin extends Vue {
  @State('flowInputs') public flowInputs!: FlowInputsState
  @State('flowOutputs') public flowOutputState!: FlowOutputsState

  @Action('getFlows') public getFlows!: () => Promise<void>
  @Action('updateFlows') public updateFlows: any
  @Action('sendFlowOutput') public send!: (req: FlowOutputRequest) => Promise<any>
  @Action('getPersistent') public getPersistent!: () => Promise<void>
  @Action('deleteFlowOutput') public purgeFlow!: (flowName: string) => void

  public getFieldValueObject(obj: { [key: string]: any }, val: object, context?: string) {
    const o: { [k: string]: any } = {}
    Object.entries(val).forEach(([k, v]) => {
      const value = this.getFieldValue(obj, v, context)
      if (value) {
        o[k] = value
      }
    })
    return o
  }

  public getFieldValue(obj: { [key: string]: any }, val: string | object, context?: string) {
    if (typeof val === 'string') {
      const valArr = val.split('.')
      if (context) {
        valArr.unshift(context)
      }
      return Utils.getProp(obj, valArr)
    } else if (typeof val === 'object') {
      return this.getFieldValueObject(obj, val, context)
    }
  }

  public getPayload(output: object, context?: string) {
    const payload: { [k: string]: any } = {}
    const entries = Object.entries(output)
    entries.forEach(([key, value]) => {
      payload[key] = this.getFieldValue(this.flowOutputState, value, context)
    })
    return payload
  }
}
