import {ResourceLoader} from '../utils/ResourceLoader'
import {Base} from './Base'
import {LatLng} from '../models/LatLng'

// @ts-ignore
export class Openlayers extends Base {

  public loadResources() {
    // https://openlayers.org/en/latest/doc/quickstart.html
    return [
      ResourceLoader('script', 'openlayers-map', 'https://cdn.jsdelivr.net/gh/openlayers/openlayers.github.io@master/en/v6.3.0/build/ol.js'),
      ResourceLoader('css', 'openlayers-map', 'https://cdn.jsdelivr.net/gh/openlayers/openlayers.github.io@master/en/v6.3.0/css/ol.css'),
    ]
  }

  public initMap(element: HTMLElement, initConfig: any, s: any): void {
    // @ts-ignore
    this.map = new ol.Map({
      target: element,
      layers: [
        // @ts-ignore
        new ol.layer.Tile({
          // @ts-ignore
          source: new ol.source.OSM(),
        }),
      ],
      // @ts-ignore
      view: new ol.View({
        // @ts-ignore
        center: ol.proj.fromLonLat([initConfig.center.longitude, initConfig.center.latitude]),
        zoom: initConfig.zoom,
      }),
    })
    s()
  }

  public setClickListener(l: (p: LatLng) => void): void {
    this.map.on('click', (evt: any) => {
      // @ts-ignore
      let k = ol.proj.toLonLat(evt.coordinate)
      l(new LatLng(k[1], k[0]))
    })
  }

  public pan(p: LatLng): void {
    // https://docs.mapbox.com/mapbox-gl-js/example/flyto-options/
    // WTF Openlayers go home
    // @ts-ignore
    let v = new ol.View({
      // @ts-ignore
      center: ol.proj.fromLonLat([p.lat, p.lng]),
      zoom: this.map.getView().getZoom(),
    })
    this.map.setView(v)
  }
}
