
import {Component, Vue} from 'vue-property-decorator'

import { VCardActions } from 'vuetify/lib'

@Component({
  name: 'CardActions',
  extends: VCardActions as any,
})
export default class CardActions extends Vue {

}
