
import {Component, Vue, Prop} from 'vue-property-decorator'

import {VTabs} from 'vuetify/lib'

@Component({
  name: 'Tabs',
  extends: VTabs as any,
})
export default class Tabs extends Vue {
  @Prop({
    default: true,
  }) private grow?: boolean
  @Prop({
    default: true,
  }) private fixedTabs?: boolean
}
