


















import {Component, Vue, Prop} from 'vue-property-decorator'
import Button from './Button.vue'
import Layout from './proxy/Layout.vue'
import Container from './proxy/Container.vue'
import BadgeProfileImage from './profile/BadgeProfileImage.vue'
import {Action} from 'vuex-class'
import {Driver, Attachment, ProfileImageRequest, Client} from '@/lib/kepler/interfaces'
import Flex from '@/components/proxy/Flex.vue'
import Icon from '@/components/proxy/Icon.vue'

@Component({
  components: {
    Icon,
    Flex,
    BadgeProfileImage,
    Container,
    Layout,
    Button,
  },
})
export default class ProfileWithImage extends Vue {

  @Prop({required: true}) private driver!: Driver
  @Prop({}) private client!: Client
  @Prop({
    default: false,
  }) private small?: boolean
  @Prop({
    default: 'manage',
  }) private button?: string

  @Action('addProfilePicture') private addProfilePicture!: (payload: ProfileImageRequest) => Promise<string>

  protected uploadImage(response: Attachment) {
    return this.addProfilePicture({attachment_token: response.token})
  }
  protected get size() {
    return this.small ? 65 : Math.min(0.22 * (this.$vuetify as any).clientWidth, 100)
  }
}
