



























import {Vue, Component, Prop} from 'vue-property-decorator'
import Utils from '@/utils'
import {VehicleSlot} from '@/lib/kepler/interfaces'

@Component({
  components: {
    VehicleFeatures: Utils.loadComponent('entities/vehicle/VehicleFeatures'),
    VehicleTypeChip: Utils.loadComponent('entities/vehicle/VehicleTypeChip'),
    Container: Utils.loadComponent('proxy/Container'),
    FuelLevel: Utils.loadComponent('FuelLevel'),
    Layout: Utils.loadComponent('proxy/Layout'),
    Plate: Utils.loadComponent('Plate'),
    Flex: Utils.loadComponent('proxy/Flex'),
    Img: Utils.loadComponent('proxy/Image'),
  }, name: 'VehicleQuickInfo',
})
export default class VehicleQuickInfo extends Vue {
  @Prop() private vehicleSlot!: VehicleSlot
  @Prop({type: Boolean, default: false}) private hideAttibutes!: boolean
}
