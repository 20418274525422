
import {Component, Prop, Vue} from 'vue-property-decorator'
import {VInput} from 'vuetify/lib'

@Component({
  name: 'Input',
  extends: VInput as any,
})
export default class Input extends Vue {
}
