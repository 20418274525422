export class LatLng {
  public lat: number
  public lng: number

  constructor(latitude: number, longitude: number) {
    this.lat = latitude
    this.lng = longitude
  }

  public static parse(what: any): LatLng | undefined {
    if (what === null || what === undefined) {
      throw new Error("WTF: Pass something not null, dumbass")
    }

    if (Array.isArray(what)) {
      return new LatLng(what[0], what[1])
    }

    if (typeof what == "string" || what instanceof String) {
      let v = what.split(",")
      return new LatLng(parseFloat(v[0]), parseFloat(v[1]))
    }

    if (what.hasOwnProperty('lat') && what.hasOwnProperty('lng')) {
      return new LatLng(what.lat, what.lng)
    }

    if (what.hasOwnProperty('lat') && what.hasOwnProperty('lon')) {
      return new LatLng(what.lat, what.lon)
    }

    if (what.hasOwnProperty('latitude') && what.hasOwnProperty('longitude')) {
      return new LatLng(what.latitude, what.longitude)
    }

    throw new Error("WTF: Pass something valid")
  }

  public toString(reverse: boolean = false) {
    if (reverse) {
      return this.lng + ',' + this.lat
    } else {
      return this.lat + ',' + this.lng
    }
  }

  public static equals(one: any, two: any ) {
    const o = this.parse(one)
    const t = this.parse(two)
    return o?.lat === t?.lat && o?.lng === t?.lng
  }
}
