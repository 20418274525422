





import {Vue, Component, Prop} from 'vue-property-decorator'

@Component({})
export default class Plate extends Vue {
  @Prop({type: String, required: true, default: () => ''}) public plate!: string
  @Prop({type: Boolean, default: false}) public overline!: boolean
  @Prop({type: Boolean, default: false}) public block!: boolean
}
