























import {Vue, Component, Prop} from 'vue-property-decorator'
import Utils from '@/utils'
import {State} from 'vuex-class'

import {
  UserMesh,
  VehicleCategory,
} from '@/lib/kepler/interfaces'

@Component({
  components: {
    Container: Utils.loadComponent('proxy/Container'),
    Layout: Utils.loadComponent('proxy/Layout'),
    Accordion: Utils.loadComponent('proxy/Accordion/Accordion'),
    AccordionContent: Utils.loadComponent('proxy/Accordion/AccordionContent'),
    Chip: Utils.loadComponent('proxy/Chip'),
    Button: Utils.loadComponent('Button'),
  },
  name: 'CategorySelector',
})

export default class CategorySelector extends Vue {
  @State((state) => state.booking.categories) protected categoriesState!: VehicleCategory[]
  @State((state) => state.profile.userMesh) protected userMesh!: UserMesh[]

  @Prop({type: String || null}) protected value!: string | null
  @Prop({type: String}) protected type!: string

  protected get categories() {
    let arr = this.categoriesState
    if (arr) {
      arr.forEach((category) => {
        this.$set(category, 'selected', true)
      })
      const type = this.type
      if (type) {
        arr = arr.filter((cat) => {
          return (cat.type.toLowerCase() === type.toLowerCase()) || type === 'any'
        })
      }
    }
    if (arr.length === 1) {
      this.$emit('input', arr[0].id)
    }
    return arr
  }

  protected categoryString(category: VehicleCategory) {
    let type = category.type.toLowerCase()
    const name = category.name.toLowerCase()
    type = name.includes(type) ? '' : type + ' - '
    return type + name
  }
}
