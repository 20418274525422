import {BleDevice} from "@/lib/BtSDKs/BleDevice";

export default class Dunasys extends BleDevice {
  // 2100 SPI BRIDGE
  private SERVICE_SPI_BRIDGE_UUID = '00002100-1212-efde-1523-785fef13d123'
  // 2101 (INDICATE, WRITE) SPI GATEWAY ACK
  private CHAR_SPI_BRIDGE_ACK_UUID = '00002101-1212-efde-1523-785fef13d123'
  // 2102 (NOTIFY) SPI RESPONSE INFO
  private CHAR_SPI_BRIDGE_INFO_UUID = '00002102-1212-efde-1523-785fef13d123'

  // 2700 BOOT GATEWAY
  private SERVICE_BOOT_UUID = '00002700-0000-1000-8000-00805f9b34fb'
  // 2701 (INDICATE, READ, WRITE) gateway to jump in bootloader
  private CHAR_BOOTLOADER_UUID = '00002701-0000-1000-8000-00805f9b34fb'

  private deviceSerial: string = ""

  private commands = {
    AntiTheft_Deactivate: "MQ==",
    Door_Lock_Unlock: "Mg==",
    AntiTheft_Activate: "Mw==",
    Door_Lock_Lock: "NA==",
  }

  constructor(logger: any, device: string, payload: any) {
    super(logger)
    this.deviceSerial = device
    this.commands.AntiTheft_Activate = payload.AntiTheft_Activate
    this.commands.AntiTheft_Deactivate = payload.AntiTheft_Deactivate
    this.commands.Door_Lock_Lock = payload.Door_Lock_Lock
    this.commands.Door_Lock_Unlock = payload.Door_Lock_Unlock
    //this.logJson(["CONSTRUCT", device, payload])
  }

  isCorrectDevice(name: string, address: string, mode: string, dgiata: any) {
    // this.logJson(["DEVICE", name, address])
    return name == this.deviceSerial
  }

  private sizeData = 0
  private inData: number[] = []

  dataIn(data: number[]) {
    let sizeString = ""

    // Start of frame
    if (data[0] == 0x10) {
      data.shift() // Remove type
      sizeString += data.shift()!.toString(16).padStart(2, '0'); // Remove size0
      sizeString += data.shift()!.toString(16).padStart(2, '0'); // Remove size1
      data.shift() // Remove PayloadID
      data.shift() // Remove PayloadSubID
      data.shift() // Remove ENC TYPE
      data.shift() // Remove size0
      data.shift() // Remove size1
      this.sizeData = parseInt(sizeString, 16) - 5
      this.inData = []
      this.inData.push(...data)
    } else if (data[0] >= 0x20 && data[0] <= 0x2f) { // Data in
      data.shift() // Remove counter
      this.inData.push(...data)
    }

    if (this.inData.length == this.sizeData) {
      this.inData.pop()
      this.logJson({
        "raw": this.intArrayToString(this.inData),
        "parsed": JSON.parse(this.intArrayToString(this.inData))
      })
    }
  }

  private wakeupResolved = false

  wakeup() {
    return new Promise<void>((resolve, reject) => {
      if (this.wakeupResolved) {
        resolve()
        return
      }
      this.init().then(() => {
        this.subscribe(this.SERVICE_SPI_BRIDGE_UUID, this.CHAR_SPI_BRIDGE_INFO_UUID, (data) => {
          // Check for segmentations
          this.logJson({"read_info": this.intToHex(data).join(" ")})
          this.dataIn(data)
        }).then(() => {
          this.subscribe(this.SERVICE_SPI_BRIDGE_UUID, this.CHAR_SPI_BRIDGE_ACK_UUID, (data) => {
            this.logJson({"read_ack": this.intToHex(data).join(" ")})
          }).then(() => {
            this.write(this.SERVICE_SPI_BRIDGE_UUID, this.CHAR_SPI_BRIDGE_ACK_UUID, [0xDE]).then(() => {
              setTimeout(() => {
                this.wakeupResolved = true
                resolve()
              }, 2000)
            }).catch(reject)
          }).catch(reject)
        }).catch(reject)
      }).catch(reject)
    })
  }

  lock() {
    return new Promise<void>((resolve, reject) => {
      this.wakeup().then(() => {
        this.sendCommand(this.commands.AntiTheft_Activate).then(() => {
          setTimeout(() => {
            this.sendCommand(this.commands.Door_Lock_Lock).then(resolve).catch(reject)
          }, 2000)
        }).catch(reject)
      }).catch(reject)
    })
  }

  unlock() {
    return new Promise<void>((resolve, reject) => {
      this.wakeup().then(() => {
        this.sendCommand(this.commands.AntiTheft_Deactivate).then(() => {
          setTimeout(() => {
            this.sendCommand(this.commands.Door_Lock_Unlock).then(resolve).catch(reject)
          }, 2000)
        }).catch(reject)
      }).catch(reject)
    })
  }

  sendCommand(command: string) {
    let commandPayload = this.base64ToByte(command)
    let payload: number[] = []
    payload.push(0x01)
    payload.push(...this.size2(commandPayload.length))
    payload.push(...commandPayload)
    let crc = this.crc8(payload)
    payload.push(crc)
    let enc = this.stringToByte(btoa(this.intArrayToString(payload)))
    this.logJson({
      "command": command,
      "payload": this.intToHex(payload).join(" "),
      "b64_payload": this.intToHex(enc).join(" ")
    })
    return this.sendSegmented(enc)
  }

  size2(lenNumber: number) {
    let lenArray = [0, 0]
    for (var index = 0; index < lenArray.length; index++) {
      var byte = lenNumber & 0xff;
      lenArray[lenArray.length - 1 - index] = byte;
      lenNumber = (lenNumber - byte) / 256;
    }
    return lenArray
  }

  sendSegmented(data: number[]) {
    // SF (Single frame type)
    if (data.length <= 18) {
      return this.write(this.SERVICE_SPI_BRIDGE_UUID, this.CHAR_SPI_BRIDGE_ACK_UUID, [0x00, data.length, ...data])
    } else {
      let payload = []
      // First frame
      let firstFrame = [0x10, ...this.size2(data.length + 1), 0x20]
      while (data.length > 0 && firstFrame.length < 20) {
        firstFrame.push(data.shift()!)
      }
      payload.push(firstFrame)

      let startCounter = 0x20
      while (data.length > 0) {
        let frame = [startCounter]
        while (frame.length < 20 && data.length > 0) {
          frame.push(data.shift()!)
        }
        payload.push(frame)
        startCounter++
        if (startCounter > 0x2f) {
          startCounter = 0x20
        }
      }
      return this.writeChunks(this.SERVICE_SPI_BRIDGE_UUID, this.CHAR_SPI_BRIDGE_ACK_UUID, payload)
    }
  }

  crc8(byte_array: number[]): number {
    let table = [
      0, 94, 188, 226, 97, 63, 221, 131, 194, 156, 126, 32, 163, 253, 31, 65,
      157, 195, 33, 127, 252, 162, 64, 30, 95, 1, 227, 189, 62, 96, 130, 220,
      35, 125, 159, 193, 66, 28, 254, 160, 225, 191, 93, 3, 128, 222, 60, 98,
      190, 224, 2, 92, 223, 129, 99, 61, 124, 34, 192, 158, 29, 67, 161, 255,
      70, 24, 250, 164, 39, 121, 155, 197, 132, 218, 56, 102, 229, 187, 89, 7,
      219, 133, 103, 57, 186, 228, 6, 88, 25, 71, 165, 251, 120, 38, 196, 154,
      101, 59, 217, 135, 4, 90, 184, 230, 167, 249, 27, 69, 198, 152, 122, 36,
      248, 166, 68, 26, 153, 199, 37, 123, 58, 100, 134, 216, 91, 5, 231, 185,
      140, 210, 48, 110, 237, 179, 81, 15, 78, 16, 242, 172, 47, 113, 147, 205,
      17, 79, 173, 243, 112, 46, 204, 146, 211, 141, 111, 49, 178, 236, 14, 80,
      175, 241, 19, 77, 206, 144, 114, 44, 109, 51, 209, 143, 12, 82, 176, 238,
      50, 108, 142, 208, 83, 13, 239, 177, 240, 174, 76, 18, 145, 207, 45, 115,
      202, 148, 118, 40, 171, 245, 23, 73, 8, 86, 180, 234, 105, 55, 213, 139,
      87, 9, 235, 181, 54, 104, 138, 212, 149, 203, 41, 119, 244, 170, 72, 22,
      233, 183, 85, 11, 136, 214, 52, 106, 43, 117, 151, 201, 74, 20, 246, 168,
      116, 42, 200, 150, 21, 75, 169, 247, 182, 232, 10, 84, 215, 137, 107, 53
    ]
    var c = null;

    for (var i = 0; i < byte_array.length; i++)
      // @ts-ignore
      c = table[(c ^ byte_array[i]) % 256]

    return c!;
  }
}
