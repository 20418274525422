








































import {Component, Prop} from 'vue-property-decorator'
import ConfirmDialogCallback from '@/views/ConfirmDialogCallback.vue'
import {
  ReservationResponse,
  TerminateResponse,
} from '@/lib/kepler/interfaces'
import {duration} from 'moment'
import DateHelper from '@/lib/DateHelper'
import Utils from '@/utils'
import sdk from '@/lib/kepler/sdk'

interface Estimate {
  time: string
  distance: string
  cost: string
}

@Component({
  methods: {duration},
  components: {
    Layout: Utils.loadComponent('proxy/Layout'),
    Icon: Utils.loadComponent('proxy/Icon'),
    CloseButton: Utils.loadComponent('CloseButton'),
  },
})
export default class ConfirmTerminateDialog extends ConfirmDialogCallback {
  @Prop({type: Object}) public reservation!: ReservationResponse
  @Prop({type: Object}) public response!: TerminateResponse

  protected canRequest = false

  protected get messages() {
    return 'messages' in this.response ? this.response.messages : []
  }

  protected estimate: Estimate | null = null
  protected disclaimer = this.$isAvailable(
    'booking.terminate_confirmed.disclaimer',
  )

  protected created() {
    if ('messages' in this.response) {
      this.canRequest = true
    } else {
      const tc = this.response.time_cost ?? 0
      const dc = this.response.distance_cost ?? 0
      const d = this.response.distance
      const t = this.response.duration
      this.estimate = {
        cost: tc + dc ? this.$currency(dc + tc) : '',
        distance: d !== undefined ? this.$distance(d) : '',
        time: t !== undefined ? DateHelper.setTimeOffset(-t).formatTimeOffset('dhm') : '',
      }
    }
  }

  protected requestEstimate() {
    this.loading = true
    sdk.booking
      .estimateCurrent(this.reservation.number)
      .then((r) => {
        const e = r.data
        const time = DateHelper.setTimeOffset(-e.duration).formatTimeOffset('dhm')
        const distance = this.$distance(e.distance)
        const cost = e.cost !== null ? ` ${this.$currency(e.cost)}` : ''
        this.estimate = {time, distance, cost}
        this.canRequest = false
      })
      .finally(() => {
        this.loading = false
      })
  }
}
