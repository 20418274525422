
import {Component, Vue} from 'vue-property-decorator'

import { VTabItem } from 'vuetify/lib'

@Component({
  name: 'TabItem',
  extends: VTabItem as any,
})
export default class TabItem extends Vue {

}
