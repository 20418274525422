



















import {Vue, Component} from 'vue-property-decorator'
import Utils from '@/utils'

@Component({
  name: 'FiltersLayout',
  components: {
    Sheet: Utils.loadComponent('proxy/Sheet'),
    Container: Utils.loadComponent('proxy/Container'),
    Layout: Utils.loadComponent('proxy/Layout'),
    Flex: Utils.loadComponent('proxy/Flex'),
    Divider: Utils.loadComponent('proxy/Divider'),
  },
})
export default class Filters extends Vue {
  private hasSlot(name: string) {
    return Object.keys(this.$slots).length > 1 && !!this.$slots[name]?.length
  }
}
