

import {Vue, Component, Prop} from 'vue-property-decorator'
import {Action, State} from 'vuex-class'
import {LoginResponse} from '@/lib/kepler/interfaces'
import {EventBus} from '@/main'

@Component({
  components: {},
  name: 'FlowLogin',
})
export default class FlowLogin extends Vue {
  @State('flowOutputs') public flowOutputs!: { [k: string]: any }
  @Action('loginFromRegistrationResponse') public login!: (payload: LoginResponse) => void
  @Prop({
    type: String,
    default: () => 'quick_registration',
  }) public flowName!: string

  public loading: boolean = true

  public loginAction() {
    // stuff to do on login
  }

  public created() {
    EventBus.$on('login', this.loginAction)
    const payload = this.flowOutputs[this.flowName].registration_response as LoginResponse | undefined
    if (payload) {
      this.login(payload)
    }
  }

  public beforeDestroy(): void {
    EventBus.$off('login', this.loginAction)
  }
}
