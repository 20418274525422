







































import {Component, Prop, Watch} from 'vue-property-decorator'
import {mixins} from 'vue-class-component'
import {Action, Getter, State} from 'vuex-class'
import {EventBus} from '@/main'
import Utils from '@/utils'

import {TopbarButton, TopbarState} from '@/store/modules/topbar'
import {ProfileState} from '@/store/modules/profile'

import ConfirmDialog from '@/views/ConfirmDialog.vue'
import ChangeLangDialog from '@/views/ChangeLangDialog.vue'
import FlowAutoload from '@/views/Flows/FlowAutoload.vue'
import {FlowPersistent, PersistentNotification} from '@/store/modules/flowPersistent'
import VuetifyColorHelper, {CustomColorableMixin} from '@/lib/vuetify/VuetifyColorHelper'
import FlowDialog from '@/views/Flows/FlowDialog.vue'
import {FieldConfigs} from '@/lib/kepler/interfaces'
import {FlowInputsState} from '@/store/modules/flowInputs'

@Component({
  name: 'TopBar',
  components: {
    Btn: Utils.loadComponent('proxy/Btn'),
    Alert: Utils.loadComponent('proxy/Alert'),
    Layout: Utils.loadComponent('proxy/Layout'),
    ToolbarTitle: Utils.loadComponent('proxy/ToolbarTitle'),
    Toolbar: Utils.loadComponent('proxy/Toolbar'),
    Divider: Utils.loadComponent('proxy/Divider'),
    CustomIcon: Utils.loadComponent('CustomIcon'),
    Avatar: Utils.loadComponent('proxy/Avatar'),
    Img: Utils.loadComponent('proxy/Image'),
    Icon: Utils.loadComponent('proxy/Icon'),
    Button: Utils.loadComponent('Button'),
  },
  mixins: [CustomColorableMixin],
})
export default class TopBar extends mixins<CustomColorableMixin>(CustomColorableMixin) {
  @State('profile') public profileState!: ProfileState
  @State('topbar') public topbar!: TopbarState
  @State('flowInputs') public flowInputs!: FlowInputsState
  @State('flowPersistent') public flowPersistent!: FlowPersistent
  @State((state) => state.configuration.appConfig?.persistent_help) public persistentHelp?: string
  @State((state) => state.topbar.topBarTitle) public topBarTitle!: string | null

  @Getter('fieldConfigs') public fieldConfigs?: FieldConfigs

  @Action('setActionButtons') public setActionButtons!: (buttons: TopbarButton[]) => void
  @Action('flushActionButtons') public flushActionButtons!: () => void
  @Action('getPersistent') public getPersistent!: () => void

  @Prop({
    type: [String, Boolean],
  }) protected alertMessage!: string | boolean
  @Prop({
    type: Boolean,
    default: true,
  }) protected enableBackButton!: boolean
  @Prop({
    type: Function,
  }) protected backButtonAction!: () => void
  @Prop({
    type: Boolean,
    default: true,
  }) protected actionsEnabled!: boolean
  @Prop({
    type: Boolean,
    default: true,
  }) protected flat?: boolean

  protected title: null | string = ''

  protected get homeMenuStyle() {
    return this.fieldConfigs?.home_menu_style
  }

  protected get backAction() {
    return this.backButtonAction ? this.backButtonAction : this.closeAction
  }

  protected get isHelpAvailable() {
    const str = `contexthelp.${this.$route.fullPath}`
    const trn = this.$t(str)
    return trn && trn !== str
  }

  protected get addContextualHelp() {
    return {
      setButtons: () => {
        const buttons: TopbarButton[] = [
          {
            action: this.addContextualHelp.action,
            icon: 'mdi-help-circle-outline',
          },
        ]
        this.setActionButtons(buttons)
      },
      action: () => {
        this.$dialog.open(ConfirmDialog, {
          props: {
            imageState: '',
            code: '',
            title: '',
            subtitle: this.$t(`contexthelp.${this.$route.fullPath}`),
            confirmText: this.$t('action.proceed'),
            singleAction: true,
          }, confirmedEvent: 'forceBooking',
        })
        return
      },
    }
  }

  protected get addProfileButton() {
    const driver = this.profileState.driver
    return {
      setButtons: () => {
        if (this.isHome && !this.fieldConfigs?.hide_profile_button) {
          const buttons: TopbarButton[] = [
            {
              action: this.addProfileButton.action,
              component: this.addProfileButton.component(),
              icon: this.addProfileButton.icon(),
            },
          ]
          this.setActionButtons(buttons)
        }
      },
      action: () => this.$router.push({name: driver ? 'profile' : 'login'}),
      component: () => driver?.profile_picture,
      icon: () => !driver ? 'mdi-account-circle' : undefined,
    }
  }

  protected get addActivitiesButton() {
    return {
      setButtons: () => {
        if (this.isHome) {
          const buttons: TopbarButton[] = [
            {
              action: () => this.$router.push({name: 'activities'}),
              icon: 'mdi-menu',
            },
          ]
          this.setActionButtons(buttons)
        }
      },
    }
  }

  protected get addLangChangeButton() {
    let lang = ''

    if (!(this.$langAvailable()?.length > 1)) {
      return {
        setButtons: () => null,
        component: () => null,
      }
    }

    if (this.profileState.language === 'sc') {
      lang = 'srd'
    } else {
      lang = this.profileState.language
    }

    // not useful for now but meh
    // const flags = this.$langAvailable().map((l) => {
    //   return `img/flags/4x3/${l.code}.svg`
    // })

    this.flushActionButtons()

    {
      return {
        setButtons: () => {
          const routes = ['profile', 'activities', 'login']
          if (this.homeMenuStyle !== 'hidden') {
            routes.push('home')
          }
          if (routes.includes(this.$route.name || '')) {
            const buttons: TopbarButton[] = [
              {
                action: this.addLangChangeButton.action,
                component: this.addLangChangeButton.component(),
              },
            ]
            this.setActionButtons(buttons)
          }
        },
        action: () => {
          this.$dialog.open(ChangeLangDialog)
        },
        component: () => {
          return lang ? `img/flags/4x3/${lang}.svg` : null
        },
      }
    }
  }

  protected get isHome() {
    return ['home', 'map'].includes(this.$route.name ?? '')
  }

  protected get hidden() {
    return !!this.$route.meta?.topbar?.hidden
  }

  protected get extended() {
    return Object.keys(this.$slots).length
  }

  protected get customStyle() {
    const arr: Array<Record<string, string>> = []
    const top = this.color('topBarTop') || 'transparent'
    const bottom = this.color('topBarBottom') || 'transparent'
    arr.push({
      'background-image': `linear-gradient(${this.gradientAngle || this.angle}deg, ${top}, ${bottom})`,
    })
    if (this.customCss) {
      arr.push(this.customCss)
    }
    return arr
  }

  protected get gradientAngle() {
    if (this.themeName && VuetifyColorHelper?.gradientAngle('topBar')) {
      return VuetifyColorHelper.gradientAngle(this.themeName)
    }
    return this.angle || null
  }

  protected get isLight() {
    const top = this.color('topBarTop')
    const bottom = this.color('topBarBottom')
    if (this.customClasses?.includes('theme--dark')) {
      return false
    }
    if (this.customClasses?.includes('theme--light')) {
      return true
    }
    if (this.contrastOverride !== null) {
      return this.contrastOverride
    } else if (top && bottom) {
      return VuetifyColorHelper.lightContrast(top, bottom)
    }
    return !this.$vuetify.dark
  }

  protected persistentHelpAction(h: string) {
    let uri = null
    if (h.startsWith('+')) {
      uri = 'tel:' + h
    } else if (h.startsWith('http')) {
      uri = h
    }
    if (uri) {
      cordova.InAppBrowser.open(uri, '_system')
    }
  }

  protected persistentNotifications(name: 'icon_top_bar' | 'alert_bar' | 'profile' | 'pre_booking' | 'post_booking') {
    const alertbar = this.flowPersistent[name]
    const arr: Array<PersistentNotification & { flow: string }> = []
    if (alertbar) {
      for (const [flow, v] of Object.entries(alertbar)) {
        arr.push({
          flow,
          ...v,
        })
      }
    }
    return arr
  }

  protected closeAction() {
    this.$router.go(-1)
  }

  protected setupButtons() {
    this.addLangChangeButton.setButtons()
    if (this.isHelpAvailable) {
      this.addContextualHelp.setButtons()
    }
    if (this.homeMenuStyle === 'hidden') {
      this.addActivitiesButton.setButtons()
    } else {
      this.addProfileButton.setButtons()
    }
  }

  protected flushAndSetup() {
    this.flushActionButtons()
    this.setupButtons()
    this.getPersistent()
  }

  protected openFlow(flowName?: string) {
    const flow = this.flowInputs[flowName || '']
    if (flowName && flow) {
      if (flow.dialog) {
        this.$dialog.open(FlowDialog, {props: {flowName}, title: flowName})
      } else {
        this.$popup.open(FlowAutoload, {props: {flowName}, hideTopbar: true})
      }
    }
  }

  @Watch('$route', {immediate: true})
  protected onRouteUpdate() {
    this.title = this.topbar.topBarTitle
    this.setupButtons()
  }

  protected created() {
    EventBus.$on('login', this.flushAndSetup)
    EventBus.$on('loginByPhone', this.flushAndSetup)
    EventBus.$on('logout', this.flushAndSetup)
    EventBus.$on('refreshButtons', this.flushAndSetup)

    this.flushAndSetup()
  }

  protected beforeDestroy() {
    EventBus.$off('login', this.flushAndSetup)
    EventBus.$off('loginByPhone', this.flushAndSetup)
    EventBus.$off('logout', this.flushAndSetup)
    EventBus.$off('refreshButtons', this.flushAndSetup)
  }
}
