import {ActionTree, GetterTree, MutationTree} from 'vuex'
import {RootState} from '@/store'
import Utils from '@/utils'

export interface MenuButton {
  name?: string,
  icon?: string,
  image?: string,
  url?: string,
  path?: string,
  routeName?: string,
  flow?: string,
  color?: string,
  class?: string
  loggedIn?: string | boolean
  loggedOut?: string | boolean
  exclude?: string
}

interface MenuObj extends Partial<Record<string, string>> {
  class?: string
  src?: string
  icon?: string
  label?: string
}

export class MenuState {
  public name: string = ''
  public obj: MenuObj = {}
  public buttons: MenuButton[] = []
}

const mutations: MutationTree<MenuState> = {
  SET_MENU(state, payload) {
    Utils.setProps(payload, state)
  },
}

const actions: ActionTree<MenuState, RootState> = {
  init({dispatch}) {
    dispatch('getMenu')
  },
  loginDispatch({dispatch}) {
    dispatch('getMenu')
  },
  logout({dispatch}) {
    dispatch('getMenu')
  },
  getMenu({state, rootGetters, commit}, override?: string) {
    const logged = rootGetters.isLogged
    const homeMenuStyle: string = override ?? rootGetters.fieldConfigs?.home_menu_style
    const homeMenuType = homeMenuStyle?.match(/^([^|]*)/)?.[0]
    let obj: MenuObj = {}
    switch (homeMenuType) {
      case 'logo':
        // example config string: logo|src=img/logo.png|class=accent--text
        obj = {src: 'img/icons/android-chrome-192x192.png', class: 'accent--text'}
        homeMenuStyle.split('|').forEach((s) => {
          const prop = s.split('=')
          if (prop.length > 1) {
            obj[prop[0]] = prop[1]
          }
        })
        commit('SET_MENU', {name: 'logo', buttons: [], obj})
        break
      case 'labeled':
        // example config string: labeled|icon=mdi-arrow-right-bold|label=go
        obj = {label: 'menu.bottom_bar.activities', icon: 'mdi-shape', class: ''}
        homeMenuStyle.split('|').forEach((s) => {
          const prop = s.split('=')
          if (prop.length > 1) {
            obj[prop[0]] = prop[1]
          }
        })
        commit('SET_MENU', {name: 'labeled', buttons: [], obj})
        break
      case 'buttons':
        // example config string:
        // buttons|icon=mdi-car-key&name=Share&path=bookingRequest
        // |icon=mdi-view-list&name=Booking&path=history
        // |icon=mdi-map&name=Map&path=home
        // |icon=mdi-account&name=Profile&path=profile&color=accent&class=accent--text
        // encode each button as a query string, like https://www.coderstool.com/querystring-encode
        const buttons: MenuButton[] = []
        homeMenuStyle.split('|').slice(1).forEach((b) => {
          const params = new URLSearchParams(b)
          const entries = params.entries()
          const button: MenuButton = Object.fromEntries(entries)
          const loggedIn = 'loggedIn' in button
          const loggedOut = 'loggedOut' in button
          if (loggedIn && logged || loggedOut && !logged || (!loggedIn && !loggedOut)) {
            buttons.push(button)
          }
        })
        commit('SET_MENU', {name: 'buttons', buttons, obj: {}})
        break
      default:
        commit('SET_MENU', {name: 'hidden', buttons: [], obj: {}})
    }
  },
}

const getters: GetterTree<MenuState, RootState> = {
  menuHasProfileButton: (state, g, rootState, rootGetters) => {
    return !!state.buttons.find((b) => b.path === 'profile' || b.routeName === 'profile')
  },
}

export default {
  state: new MenuState(),
  mutations,
  actions,
  getters,
}
