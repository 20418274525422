















































import {Vue, Component, Prop} from 'vue-property-decorator'
import {Getter} from 'vuex-class'
import Scanner from '../qrScanner'
import VuetifyColorHelper from '@/lib/vuetify/VuetifyColorHelper'
import Utils from '@/utils'

interface ElementList {
  [element: string]: {
    el: Element | null,
    style: string,
    class: string,
  }
}

@Component({
  components: {
    Card: Utils.loadComponent('proxy/Card/Card'),
    CloseButton: Utils.loadComponent('CloseButton'),
    Container: Utils.loadComponent('proxy/Container'),
    Layout: Utils.loadComponent('proxy/Layout'),
    Button: Utils.loadComponent('Button'),
    Img: Utils.loadComponent('proxy/Image'),
    Icon: Utils.loadComponent('proxy/Icon'),
  },
})
export default class QrScanner extends Vue {
  @Getter('debugMode') public debugMode!: boolean

  @Prop() public qrImage!: string
  @Prop({type: Function, required: false}) public callback?: (code: string) => void

  protected scanner: Scanner | null = null

  protected elements: ElementList = {}

  protected isBrowser = this.$isBrowser()

  public scan(err: any, val: string) {
    if (err) {
      return err
    } else {
      if (val.includes('=')) {
        val = val.split('=').reverse()[0]
      }
      this.close()
      if (this.callback) {
        this.callback(val)
      }
    }
  }

  public toggleLight() {
    this.scanner?.toggleLight()
  }

  protected hideStuff() {
    document.documentElement.classList.add('no-bg')
  }

  protected showStuff() {
    document.documentElement.classList.remove('no-bg')
  }

  protected get status() {
    return this.scanner?.status || null
  }

  protected get lightStatus() {
    const status = this.scanner?.status
    return status?.canEnableLight ? status.lightEnabled : null
  }

  protected get canOpenSettings() {
    return !!this.scanner?.status?.canOpenSettings
  }

  protected openSettings() {
    this.scanner?.openSettings()
  }

  protected prepScanner() {
    return new Promise<void>((resolve) => {
      this.scanner = !!this.scanner?.status ? this.scanner : new Scanner()
      const s = this.scanner
      if (s && !s.status?.prepared) {
        s.prep().then((status) => {
          if (status.denied) {
            this.$popup.close()
          } else {
            s.scan(this.scan)
            s.show()
            resolve()
          }
        }).catch((e) => {
          this.$log(e, 3)
        })
      } else {
        s!.scan(this.scan)
        s!.show()
        resolve()
      }
    })
  }

  protected mounted() {
    this.prepScanner().then(this.hideStuff)
  }

  protected beforeDestroy() {
    if (this.scanner?.status) {
      this.scanner.destroy()
    }
  }

  protected close() {
    this.showStuff()
    this.$popup.close()
  }

  protected get isLight() {
    return VuetifyColorHelper.lightContrast(VuetifyColorHelper.color('secondary'))
  }
}
