






import {Vue, Component} from 'vue-property-decorator'
import {Action, Getter, State} from 'vuex-class'
import {EventBus} from '@/main'
import {FlowInputsState} from '@/store/modules/flowInputs'
import {AppConfig, HomeModeEnum} from '@/lib/kepler/interfaces'
import FlowAutoload from '@/views/Flows/FlowAutoload.vue'
import FlowDialog from '@/views/Flows/FlowDialog.vue'
import Loader from '@/components/Loader.vue'

@Component({
  components: {FlowAutoload, Loader},
})
export default class ConfigLoadedView extends Vue {
  @State((state) => state.profile.language) public lang!: string
  @State('flowInputs') public flowInputs!: FlowInputsState
  @State((state) => state.configuration.homeMode) public homeMode!: HomeModeEnum

  @Action('loadConfig') public loadConfig!: () => Promise<AppConfig>
  @Action('getFlows') public getFlows!: () => Promise<void>
  @Getter('autoOpenQueue') public autoOpenQueue!: Array<keyof typeof FlowInputsState>
  @Getter('popupIsOpen') public popupIsOpen!: number
  @Getter('dialogIsOpen') public dialogIsOpen!: number
  @Getter('isOnline') public isOnline!: boolean

  protected isConfigLoaded: boolean = false

  protected created() {
    EventBus.$on('login', this.resetConfig)
    this.init()
  }
  protected init() {
    if (!this.isOnline) {
      this.isConfigLoaded = true
      return
    }

    if (this.$route.name === 'home') {
      this.loadConfig().then((r) => {
        if (r.default_language) {
          this.$langLoader(this.lang ? this.lang : r.default_language)
        }
        this.getFlows().then(() => {
          const flowName = this.autoOpenQueue[0]
          const flow = this.flowInputs[flowName]
          if (flowName && !this.popupIsOpen && !this.dialogIsOpen) {
            if (flow?.steps.length) {
              if (flow.dialog) {
                this.$dialog.open(FlowDialog, {props: {flowName}, hideTopbar: true})
              } else {
                this.$popup.open(FlowAutoload, {props: {flowName}, hideTopbar: true})
              }
            }
          }
        })
        this.isConfigLoaded = true
      })
    } else {
      this.isConfigLoaded = true
    }
  }

  protected beforeDestroy() {
    EventBus.$off('login', this.resetConfig)
  }

  protected resetConfig() {
    this.isConfigLoaded = false
    this.init()
  }
}
