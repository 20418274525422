
import {Component, Vue} from 'vue-property-decorator'

import { VTab } from 'vuetify/lib'

@Component({
  name: 'Tab',
  extends: VTab as any,
})
export default class Tab extends Vue {

}
