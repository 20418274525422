
import {Component, Vue} from 'vue-property-decorator'

import { VSpeedDial } from 'vuetify/lib'

@Component({
  name: 'SpeedDial',
  extends: VSpeedDial as any,
})
export default class SpeedDial extends Vue {
}
