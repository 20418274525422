




import {Vue, Component} from 'vue-property-decorator'
import {VProgressCircular} from 'vuetify/lib'

@Component({
  name: 'Loader',
  components: {VProgressCircular},
})
export default class Loader extends Vue {
}
