





import {Vue, Component, Prop} from 'vue-property-decorator'
import Card from '@/components/proxy/Card/Card.vue'

@Component({
  components: {Card},
})
export default class Ribbon extends Vue {
  @Prop({type: String || null, default: () => null}) private readonly message!: string | null
  @Prop({type: String, default: () => 'error'}) private readonly color!: string
}
