import Vue from 'vue'
import Router from 'vue-router'

import Activities from './views/Activities.vue'
import AddDocumentView from '@/components/registration/RegistrationDrivingLicenseStep.vue'
import BookingActivities from '@/views/BookingActivities.vue'
import BookingDetail from './views/Booking/BookingDetail.vue'
import ConfigLoadedView from './views/ConfigLoadedView.vue'
import Contracts from '@/views/Contracts.vue'
import Dev from './views/Dev.vue'
import Documents from '@/components/profile/Documents.vue'
import Drivers from './views/Drivers.vue'
import FlowBooking from '@/views/Flows/FlowBooking.vue'
import FlowBookingRequest from '@/views/Flows/FlowBookingRequest.vue'
import FlowQuickRegistration from './views/Flows/FlowQuickRegistration.vue'
import History from './views/History.vue'
import Home from '@/views/Home.vue'
import Login from '@/views/Login.vue'
import Logout from '@/views/Logout.vue'
import Maintenance from '@/views/Maintenance.vue'
import Map from './views/Map.vue'
import MySubscriptions from './views/MySubscriptions.vue'
import Pages from '@/views/Pages.vue'
import PaymentMethods from '@/components/wallet/PaymentMethods.vue'
import PlanDetail from './views/PlanDetail.vue'
import Plans from '@/components/subscriptions/Plans.vue'
import Profile from './views/Profile.vue'
import ProfileManager from './views/ProfileManager.vue'
import RateOptions from '@/views/RateOptions.vue'
import Reportings from './views/Reportings.vue'
import Settings from '@/views/Settings.vue'
import Update from '@/views/Update.vue'
import VehicleView from './views/Vehicle/Vehicle.vue'
import Wallet from './views/Wallet.vue'

Vue.use(Router)

export default new Router({
  // https://stackoverflow.com/questions/40899826/vue-v2-vue-router-and-cordova
  // mode: 'history',
  routes: [
    {
      path: '/',
      component: ConfigLoadedView,
      children: [
        {
          path: '/',
          alias: '/home',
          name: 'home',
          component: Home,
          meta: {
            topbar: {
              title: 'appName',
            },
          },
        }, {
          path: '/map',
          name: 'map',
          component: Map,
          meta: {
            topbar: {
              title: 'appName',
            },
          },
        }, {
          path: '/history',
          name: 'history',
          component: History,
          meta: {
            topbar: {
              title: 'history.title',
              hidden: false,
            },
          },
        }, {
          name: 'activities',
          path: 'activities',
          component: Activities,
          meta: {
            topbar: {
              title: 'activities.title',
            },
          },
          children: [
            {
              name: 'book',
              path: 'booking',
              component: BookingActivities,
              children: [
                {
                  path: '/activities/booking/flow',
                  redirect: {name: 'booking-flow'},
                },
                {
                  name: 'bookingType',
                  path: ':type',
                  children: [
                    {
                      name: 'bookingMode',
                      path: ':mode',
                    },
                  ],
                },
              ],
            },
          ],
        }, {
          name: 'wallet',
          path: 'wallet/',
          component: Wallet,
          meta: {
            topbar: {
              title: 'profile.wallet.my_wallet',
            },
          },
        }, {
          name: 'rateOptions',
          path: 'rate-options',
          component: RateOptions,
          meta: {
            topbar: {
              title: 'rate-options.title',
            },
          },
        }, {
          name: 'profile',
          path: 'profile',
          component: Profile,
          meta: {
            topbar: {
              title: 'profile.title',
            },
          },
          children: [
            {
              path: 'switch',
              name: 'profile-switch',
              component: Profile,
              meta: {
                topbar: {
                  title: 'profile.switch.title',
                },
              },
            }, {
              path: 'add-document',
              name: 'add new document',
              component: AddDocumentView,
            }, {
              path: 'my-documents',
              name: 'My documents',
              component: Documents,
              meta: {
                topbar: {
                  title: 'profile.documents.title',
                },
              },
            }, {
              path: 'manage',
              name: 'manage profile',
              component: ProfileManager,
              meta: {
                topbar: {
                  title: 'profile.manage',
                },
              },
            }, {
              name: 'my subscriptions',
              path: 'subscriptions',
              component: MySubscriptions,
              meta: {
                topbar: {
                  title: 'profile.subscriptions.title',
                },
              },
            }, {
              name: 'drivers',
              path: 'drivers',
              component: Drivers,
              meta: {
                topbar: {
                  title: 'My Drivers',
                },
              },
            }, {
              name: 'payment methods',
              path: 'payment-methods',
              component: PaymentMethods,
              meta: {
                topbar: {
                  title: 'menu.account.payment_methods',
                },
              },
            }, {
              name: 'reportings',
              path: 'reportings',
              component: Reportings,
              meta: {
                topbar: {
                  title: 'profile.reportings.title',
                },
              },
            }, {
              name: 'terms',
              path: 'terms',
              component: Contracts,
              meta: {
                topbar: {
                  title: 'profile.terms.title',
                },
              },
            }, {
              name: 'settings',
              path: '/settings',
              component: Settings,
              meta: {
                topbar: {
                  title: 'profile.settings.title',
                },
              },
            },
            {
              name: 'logout',
              path: 'logout',
              component: Logout,
              meta: {
                topbar: {
                  title: 'profile.logout.title',
                },
              },
            },
          ],
        }, {
          name: 'reservation',
          path: '/reservation/:id?',
          component: BookingDetail,
          meta: {
            topbar: {
              title: 'booking.reservation',
            },
          },
        }, {
          name: 'vehicle',
          path: '/vehicle',
          component: VehicleView,
          meta: {
            topbar: {
              title: 'vehicle.vehicle_detail',
            },
          },
        },
      ],
    }, {
      name: 'update',
      path: '/update',
      component: Update,
    }, {
      name: 'dev',
      path: '/dev',
      component: Dev,
      meta: {
        topbar: {
          title: 'kitchen sink',
        },
      },
    }, {
      name: 'plans',
      path: '/plans',
      component: Plans,
      children: [
        {
          path: ':id',
          name: 'planDetail',
          component: PlanDetail,
          meta: {
            topbar: {
              title: '',
            },
          },
        },
      ],
      meta: {
        topbar: {
          title: 'activities.plans.title',
        },
      },
    }, {
      name: 'login',
      path: '/login',
      component: Login,
      meta: {
        topbar: {
          title: 'login.login',
        },
      },
    }, {
      name: 'login by phone',
      path: '/login_by_phone',
      component: Login,
      meta: {
        topbar: {
          title: 'login.login',
        },
      },
    }, {
      name: 'registration',
      path: '/registration',
      component: FlowQuickRegistration,
      meta: {
        topbar: {
          hidden: true,
        },
      },
    }, {
      name: 'rent',
      path: '/rent',
      alias: '/activities/booking/flow',
      component: FlowBooking,
      meta: {
        topbar: {
          hidden: true,
        },
      },
    }, {
      name: 'bookingRequest',
      path: '/booking_request',
      alias: '/activities/booking/request',
      component: FlowBookingRequest,
      meta: {
        topbar: {
          hidden: true,
        },
      },
    }, {
      name: 'maintenance',
      path: '/maintenance',
      component: Maintenance,
      meta: {
        topbar: {
          title: 'maintenance.title',
        },
      },
    }, {
      name: 'pages',
      path: '/pages',
      component: Pages,
      children: [
        {
          path: ':name?',
        },
      ],
    },
  ],
  scrollBehavior() {
    document.querySelector('div.view-home')?.parentElement?.scroll(0, 0)
    return {x: 0, y: 0}
  },
})
