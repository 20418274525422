














import {Component, Prop, Vue} from 'vue-property-decorator'
import Utils from '@/utils'
import Card from '@/components/proxy/Card/Card.vue'
import ImageCrop from '@/components/ImageCrop.vue'

@Component({
  components: {
    CloseButton: Utils.loadComponent('CloseButton'),
    ImageCrop,
    Card,
  },
  name: 'CropDialog',
})
export default class CropDialog extends Vue {
  @Prop({
    required: true,
  }) public result!: HTMLImageElement

  @Prop({
    type: Object,
    required: true,
  }) public cameraOptions!: CameraOptions

  @Prop() public successCallback!: (r: Blob) => void
  @Prop() public cancelCallback!: () => void

}
