























import {Component} from 'vue-property-decorator'

import {ValidationProvider} from 'vee-validate'
import RadioButtons from '@/components/flow/flowFields/RadioButtons.vue'
import GradientCard from '@/components/GradientCard.vue'
import {VLayout, VIcon, VContainer, VImg, VCard, VFlex} from 'vuetify/lib'

@Component({
  components: {
    ValidationProvider,
    GradientCard,
    VCard,
    VContainer,
    VLayout,
    VFlex,
    VImg,
    VIcon,
  },
  name: 'ParkingLots',
})
export default class ParkingLots extends RadioButtons {
}
