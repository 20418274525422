
import {Component, Vue} from 'vue-property-decorator'

import {VListTileSubTitle} from 'vuetify/lib'

@Component({
  name: 'ListTileSubTitle',
  extends: VListTileSubTitle as any,
})
export default class ListTileSubTitle extends Vue {

}
