












import {Component, Prop, Vue} from 'vue-property-decorator'
import Utils from '@/utils'
import Card from '@/components/proxy/Card/Card.vue'
import Layout from '@/components/proxy/Layout.vue'
import Icon from '@/components/proxy/Icon.vue'
import {CameraHelperSimple} from '@/lib/CameraHelperSimple'
import TextField from '@/components/proxy/Inputs/TextField.vue'
import ProgressCircular from '@/components/proxy/ProgressCircular.vue'

@Component({
  components: {
    ProgressCircular,
    TextField,
    Icon,
    Layout,
    Card,
    Img: Utils.loadComponent('proxy/Image'),
    Button: Utils.loadComponent('Button'),
    Btn: Utils.loadComponent('proxy/Btn'),
    CloseButton: Utils.loadComponent('CloseButton'),
  },
  name: 'CameraStorageDialog',
})
export default class CameraStorageDialog extends Vue {

  @Prop({type: Function}) public cancelCallback!: () => void
  @Prop({type: Function}) public successCallback!: (image: string) => void
  @Prop({type: Boolean, default: true}) public showCloseButton!: boolean
  @Prop({type: Boolean, default: true}) public closeOnCallback!: boolean
  @Prop({type: Object, required: true}) public cameraOptions!: CameraOptions

  protected loading: boolean = false
  protected resultImageUrl: string | null = null
  protected fileName: string | null = null

  protected getPicturePhotolibrary() {
    const options = Object.assign({}, this.cameraOptions)
    options.sourceType = Camera.PictureSourceType.PHOTOLIBRARY
    const camera = new CameraHelperSimple(options)
    return camera.getData().then((r) => {
      this.successCallback(URL.createObjectURL(this.dataURIToBlob(r)))
      this.$dialog.close()
    })
  }

  protected onPickFile() {
    if (!this.$isBrowser) {
      this.getPicturePhotolibrary()
    } else {
      const input = this.$refs.fileInput as HTMLElement
      input.click()
    }
  }

  protected onImagePicked(inputEvent: Event) {
    const file = (inputEvent.target as any)?.files[0]

    if (file) {
      this.loading = true
      const reader = new FileReader()
      reader.onload = (readerEvent) => {
        if (readerEvent.target && typeof readerEvent.target.result === 'string') {
          const imageData = readerEvent.target.result
          this.loading = false
          this.gotBrowserCameraOutput(imageData)
        }
      }
      reader.readAsDataURL(file) // shady shit here
    }
  }

  private gotBrowserCameraOutput(r: string) {
    this.successCallback(r)
    this.$dialog.close()
  }

  private dataURIToBlob(dataURI: string) {
    dataURI = dataURI.replace(/^data:/, '')

    const match = dataURI.match(/image\/[^;]+/)
    const type = match ? match.toString() : undefined
    const base64 = dataURI.replace(/^[^,]+,/, '')
    const arrayBuffer = new ArrayBuffer(base64.length)
    const typedArray = new Uint8Array(arrayBuffer)

    for (let i = 0; i < base64.length; i++) {
      typedArray[i] = base64.charCodeAt(i)
    }

    return new Blob([arrayBuffer], {type})
  }
}
