







import {Vue, Component} from 'vue-property-decorator'
import MapFilters from '@/components/filters/MapFilters.vue'
import MapFiltersButton from '@/components/MapFiltersButton.vue'

@Component({
  name: 'MapFiltersWrapper',
  components: {
    MapFilters,
    MapFiltersButton,
  },
})
export default class MapFiltersWrapper extends Vue {
  protected showFilters = false
  protected initialized = false

  protected get component() {
    return this.showFilters || !this.initialized ? MapFilters : MapFiltersButton
  }

  protected mounted() {
    // swiches on the filters component to have its mounted function run in background,
    // then switches back to button, keeping the filters component alive
    this.initialized = true
  }
}
