















import {Vue, Component, Prop} from 'vue-property-decorator'
import {ReservationResponse} from '@/lib/kepler/interfaces'
import Utils from '@/utils'

@Component({
  components: {
    GradientCard: Utils.loadComponent('GradientCard'),
    Card: Utils.loadComponent('proxy/Card/Card'),
    CardTitle: Utils.loadComponent('proxy/Card/CardTitle'),
    VehicleQuickSummary: Utils.loadComponent('entities/vehicle/VehicleQuickSummary'),
    FuelLevel: Utils.loadComponent('FuelLevel'),
  },
  name: 'ReservationVehicleAndDates',
})
export default class ReservationVehicleAndDates extends Vue {
  @Prop() public reservation!: ReservationResponse
  @Prop() public hideStatus!: boolean
}
