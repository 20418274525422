
import {Component, Vue} from 'vue-property-decorator'
import { VWindowItem } from 'vuetify/lib'

@Component({
  name: 'WindowItem',
  extends: VWindowItem as any,
})
export default class WindowItem extends Vue {

}
