













import {Vue, Component, Prop} from 'vue-property-decorator'
import {Attachment} from '@/lib/kepler/interfaces'
import Utils from '@/utils'

@Component({
  components: {
    Badge: Utils.loadComponent('proxy/Badge'),
    CameraButton: Utils.loadComponent('uploader/CameraButton'),
    Icon: Utils.loadComponent('proxy/Icon'),
    Avatar: Utils.loadComponent('proxy/Avatar'),
    Sheet: Utils.loadComponent('proxy/Sheet'),
    Img: Utils.loadComponent('proxy/Image'),
  },
  name: 'BadgeProfileImage',
})

export default class BadgeProfileImage extends Vue {
  @Prop({
    type: Function,
    required: true,
  }) protected uploadAction!: (att: Attachment) => Promise<void>
  @Prop({
    type: String,
    required: false,
  }) protected imageUrl?: string
  @Prop({
    type: Number,
    default: () => 100,
  }) protected size!: number

  protected loading: boolean = false
  protected uploadResponseUrl: string | null = null

  protected get profileImage() {
    return this.uploadResponseUrl ? this.uploadResponseUrl : this.imageUrl ? this.imageUrl : 'img/icons/profile.svg'
  }

  protected gotAttachment(att: Attachment) {
    this.uploadResponseUrl = att.url
    this.loading = true
    this.uploadAction(att).finally(() => {
      this.loading = false
    })
  }
}
