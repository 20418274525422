
















































import {Vue, Component, Prop} from 'vue-property-decorator'
import Card from './proxy/Card/Card.vue'
import Layout from './proxy/Layout.vue'
import Cropper from 'vue-croppa/src/cropper.vue'
import {VBtn, VIcon, VProgressCircular} from 'vuetify/lib'
import Sheet from '@/components/proxy/Sheet.vue'
import Container from '@/components/proxy/Container.vue'
import Flex from '@/components/proxy/Flex.vue'
import Button from '@/components/Button.vue'
import Img from '@/components/proxy/Image.vue'

@Component({
  components: {
    Img,
    Button,
    Flex,
    Container,
    Sheet,
    VBtn,
    Cropper,
    VIcon,
    Layout,
    Card,
    VProgressCircular,
  },
  name: 'ImageCrop',
})
export default class ImageCrop extends Vue {
  @Prop({}) public readonly image!: string | HTMLImageElement

  @Prop({type: Number}) public readonly width!: number
  @Prop({type: Number}) public readonly height!: number
  @Prop({type: Boolean, default: () => false}) public readonly fullscreen!: boolean

  public crop: any = {}
  public scaleFactor: number = 1
  public loading: boolean = false
  public chosenFile: boolean = false

  public get computedSize() {
    return {
      width: this.width / this.scaleFactor,
      height: this.height / this.scaleFactor,
    }
  }

  public loadEnd() {
    this.chosenFile = (this.$refs?.cropper as any)?.chosenFile
  }

  public onResize() {
    const ref = (this.$refs.cropperWrapper as Vue).$el
    if (ref) {
      const wrapperWidth = ref.clientWidth
      const ratio = this.width / wrapperWidth
      if (ratio > 1) {
        this.$set(this, 'scaleFactor', this.width / wrapperWidth)
        if (!this.crop.hasImage) {
          this.crop.refresh()
        }
      }
    }
  }

  public saveAndUpload() {
    this.loading = true
    this.crop.promisedBlob('image/jpg', 0.8).then((blob: Blob) => {
      this.$emit('cropped', blob)
    })
  }

  public remove() {
    this.crop.remove()
    this.$emit('removed')
  }

  public mounted() {
    this.onResize()
    this.crop.refresh()
  }
}
