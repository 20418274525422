import {ResourceLoader} from '../utils/ResourceLoader'
import {Base} from './Base'
import Vue from 'vue'
import {LatLng} from '../models/LatLng'

export class Bing extends Base {

  private eventBus!: Vue // Hackyty hack!

  public loadResources() {
    // https://docs.microsoft.com/en-us/bingmaps/v8-web-control/creating-and-hosting-map-controls/creating-a-basic-map-control
    this.eventBus = new Vue()
    // @ts-ignore
    window.initBingMap = () => {
      this.eventBus.$emit('initBingMap')
    }
    const bingUrl = 'https://www.bing.com/api/maps/mapcontrol?callback=initBingMap&key=' + this.config.apiKey
    return [
      ResourceLoader('script', 'bing-map', bingUrl),
    ]
  }

  public initMap(element: HTMLElement, initConfig: any, s: any): void {
    this.eventBus.$on('initBingMap', () => {
      // @ts-ignore
      this.map = new Microsoft.Maps.Map(element, {
        // @ts-ignore
        center: new Microsoft.Maps.Location(initConfig.center.lat, initConfig.center.lng),
        zoom: initConfig.zoom,
      })
      s()
    })
  }

  public setClickListener(l: (p: LatLng) => void): void {
    // @ts-ignore
    Microsoft.Maps.Events.addHandler(this.map, 'click', (evt: any) => {
      l(new LatLng(evt.location.latitude, evt.location.longitude))
    })
  }

  public pan(p: LatLng): void {
    this.map.setView({
      // @ts-ignore
      center: new Microsoft.Maps.Location(p.lat, p.lng),
    })
  }

  public zoom(level: number) {
    this.map.setView({
      zoom: level,
    })
  }
}
