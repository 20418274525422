

























































































import {Vue, Component} from 'vue-property-decorator'
import {State, Action} from 'vuex-class'
import {LoginRequest, Dialog, LoginByPhoneCodeRequest, AppConfig} from '@/lib/kepler/interfaces'
import Utils from '@/utils'

import ResetPasswordView from '@/views/ResetPassword.vue'
import ConfirmDialogCallback from '@/views/ConfirmDialogCallback.vue'
import {VSlideYTransition} from 'vuetify/lib'
import PhoneVerificationView from '@/views/PhoneVerification.vue'
import Validations from '@/lib/Validations'
import smoothReflow from 'vue-smooth-reflow'
import sdk from '@/lib/kepler/sdk'
import {AxiosError} from 'axios'

@Component({
  components: {
    Sheet: Utils.loadComponent('proxy/Sheet'),
    LoopingBG: Utils.loadComponent('LoopingBG'),
    Img: Utils.loadComponent('proxy/Image'),
    FormTag: Utils.loadComponent('proxy/Inputs/FormTag'),
    TextField: Utils.loadComponent('proxy/Inputs/TextField'),
    Button: Utils.loadComponent('Button'),
    Container: Utils.loadComponent('proxy/Container'),
    Layout: Utils.loadComponent('proxy/Layout'),
    CloseButton: Utils.loadComponent('CloseButton'),
    PhoneWithPrefix: Utils.loadComponent('PhoneWithPrefix'),
    VSlideYTransition,
  },
  name: 'Login',
  mixins: [smoothReflow],
})
export default class Login extends Vue {
  @State((state) => state.configuration.appConfig) public appConfig?: AppConfig

  @Action('loadConfig') public loadConfig: any

  @Action('openDialog') public openDialog!: (dialog: Dialog) => void

  @Action('login') public login: any
  @Action('requestLoginByPhone') public requestLoginByPhone!: (req: LoginByPhoneCodeRequest) => Promise<any>

  protected rules = Validations.rules
  protected showPassword: boolean = false
  protected credentials: LoginRequest = {username: '', password: ''}
  protected loading: boolean = false
  protected valid: boolean = false
  protected phone: string = ''
  protected dialog = false
  protected dialogSMSlogin = false

  protected usernameRules: any = [
    (v: any) => !!v || 'Name is required',
  ]

  protected passwordRules: any = [
    (v: any) => !!v || 'Password is required',
  ]

  protected get registrationMode() {
    return this.appConfig?.registration_mode || 'enabled'
  }

  protected get hackerMode() { // 🕶 //
    const hash = '50457b728b94662722782bc66503205a'
    return this.credentials?.username === hash && this.credentials?.password === hash
  }

  protected loginRequest() {
    if ((this.$refs as any).loginForm.validate()) {
      this.loading = true
      this.login(this.credentials).then(() => {
        this.$popup.close()
        if (this.$route.name !== 'home') {
          this.$router.push({name: 'home'})
        }
      }).finally(() => {
        this.loading = false
      })
    }
  }

  protected get smsLoginEnabled() {
    return this.appConfig?.sms_login_enabled ?? true
  }

  protected get smsLogin() {
    if (this.dialog) {
      return this.dialogSMSlogin
    }
    return this.$route.hash === '_by_phone' || this.$route.path === '/login_by_phone'
  }

  protected set smsLogin(val: boolean) {
    if (!this.dialog) {
      this.$router.replace({name: 'login', hash: val ? '_by_phone' : ''})
    } else {
      this.dialogSMSlogin = val
    }
  }

  protected toggleSmsLogin() {
    this.smsLogin = !this.smsLogin
  }

  protected checkSMS(title?: string) {
    this.openDialog(new Dialog(PhoneVerificationView, {
      mobileNumber: this.phone, isDialog: true, quick: false, title, callback: () => {
        this.$popup.close()
        this.$dialog.close()
        if (this.$route.name !== 'home') {
          this.$router.push({name: 'home'})
        }
      },
    }))
  }

  protected verifyPhone() {
    if ((this.$refs as any).loginForm?.validate()) {
      this.loading = true
      sdk.people.requestLoginByPhone({mobile_number: this.phone})
        .then(() => {
          this.checkSMS()
        })
        .catch((e: AxiosError) => {
          this.$dialog.close()
          this.checkSMS(e.response?.data?.messages?.[0])
        })
        .finally(() => {
          this.loading = false
        })
    }
  }

  protected openRegistration() {
    this.$popup.close()
    this.$router.push({name: 'registration'})
  }

  protected openResetPasswordView() {
    this.$popup.open(ResetPasswordView, {title: this.$t('login.reset_your_password')})
  }

  protected ee() {
    this.openDialog(new Dialog(ConfirmDialogCallback, {
      imageState: null,
      code: null,
      title: null,
      subtitle: 'using md5 and tapping on random red buttons doesn\'t make you a hacker',
      cancelText: 'oh no',
      confirmText: this.$t('common.ok'),
      emitConfirm: false,
      showCloseButton: false,
      confirmColor: 'error',
      confirmCallback: () => {
        this.$router.push('/dev')
      },
    }))
  }

  protected mounted() {
    this.$smoothReflow?.({
      property: ['height'],
      el: 'form.login-form',
      hideOverflow: true,
      transitionEvent: {
        selector: '.layout',
      },
    })
  }
}
