































import {Component, Prop, Vue} from 'vue-property-decorator'
import {Action} from 'vuex-class'
import Utils from '@/utils'
import sdk from '@/lib/kepler/sdk'

@Component({
  components: {
    Layout: Utils.loadComponent('proxy/Layout'),
    TextField: Utils.loadComponent('proxy/Inputs/TextField'),
    TextArea: Utils.loadComponent('proxy/Inputs/TextArea'),
    Card: Utils.loadComponent('proxy/Card/Card'),
    Img: Utils.loadComponent('proxy/Image'),
    Button: Utils.loadComponent('Button'),
    Btn: Utils.loadComponent('proxy/Btn'),
    CloseButton: Utils.loadComponent('CloseButton'),
  },
  name: 'ReservationOTPDialog',
})
export default class ReservationOTPDialog extends Vue {
  @Action('closeDialog') public closeDialog!: (index?: number) => void

  @Prop({type: Function}) protected cancelCallback!: () => void

  @Prop({type: Boolean, default: true}) private showCloseButton!: boolean
  @Prop({type: Boolean, default: true}) private closeOnCallback!: boolean
  @Prop({type: String, default: ''}) private image!: string
  @Prop({type: String, default: ''}) private placeholder!: string

  // make defaults of above props
  @Prop({type: Function}) private requestCallback?: () => void
  @Prop({type: Function}) private confirmCallback?: () => void

  @Prop({type: String, default: ''}) private title!: string
  @Prop({type: String, default: ''}) private subtitle!: string
  @Prop({type: String, default: ''}) private requestText!: string
  @Prop({type: String, default: ''}) private validateText!: string

  @Prop({type: Number, required: true}) private reservationNumber!: number

  @Prop({type: Boolean, default: false}) private alreadyRequested!: boolean

  private otp: string = ''
  private loading: boolean = false
  private requested: boolean = false
  private response: string[] = []

  private requestOTP() {
    this.loading = true
    sdk.reservation.otp.request(this.reservationNumber)
      .then((r) => {
        this.requested = true
        this.response = r.data.messages ?? ''
      }).finally(() => {
      this.loading = false
    })
    if (typeof this.requestCallback === 'function') {
      this.requestCallback()
    }
  }

  private validateOTP() {
    this.loading = true
    sdk.reservation.otp.validate(this.reservationNumber, this.otp)
      .then(() => {
        if (typeof this.confirmCallback === 'function') {
          this.confirmCallback()
        }
        this.closeDialog()
      }).catch(() => {
      // this.otp = ''
    }).finally(() => {
      this.loading = false
    })
  }

  private created() {
    this.requested = this.alreadyRequested
  }
}
