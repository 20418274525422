




























import {Component, Vue, Prop} from 'vue-property-decorator'
import CardTitle from './proxy/Card/CardTitle.vue'
import Button from './Button.vue'
import Layout from './proxy/Layout.vue'
import Container from './proxy/Container.vue'
import Flex from './proxy/Flex.vue'
import CustomIcon from '@/components/CustomIcon.vue'
import Avatar from '@/components/proxy/Avatar.vue'
import Utils from '@/utils'

@Component({
  components: {
    Avatar,
    CustomIcon,
    Flex,
    Container,
    Layout,
    GradientCard: Utils.loadComponent('GradientCard'),
    CardTitle,
    Button,
  },
})
export default class CardWithReport extends Vue {
  @Prop({
  }) private title?: string
  @Prop({
  }) private value?: string
  @Prop({
  }) private valueLabel?: string
  @Prop({
  }) private description?: string
  @Prop({
  }) private button?: string
  @Prop({
    type: Boolean,
    default: false,
  }) private allClickable?: boolean
  @Prop({
    type: Boolean,
    default: false,
  }) private slim?: boolean
  @Prop({
    type: Boolean,
    default: false,
  }) private bottomButton?: boolean
  @Prop({
    type: Boolean,
    default: false,
  }) private hasAlert?: boolean
}
