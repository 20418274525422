





















































import {Vue, Component, Prop, Watch} from 'vue-property-decorator'
import {Action, State} from 'vuex-class'
import Utils from '@/utils'
import {AxiosError} from 'axios'
import {LoginByPhoneCodeRequest, LoginByPhoneRequest, FlowInputStep} from '@/lib/kepler/interfaces'
import {VCheckbox} from 'vuetify/lib'

@Component({
  components: {
    Divider: Utils.loadComponent('proxy/Divider'),
    Avatar: Utils.loadComponent('proxy/Avatar'),
    Card: Utils.loadComponent('proxy/Card/Card'),
    VCheckbox,
    ProgressCircular: Utils.loadComponent('proxy/ProgressCircular'),
    CustomIcon: Utils.loadComponent('CustomIcon'),
    Layout: Utils.loadComponent('proxy/Layout'),
    CloseButton: Utils.loadComponent('CloseButton'),
    TopBar: Utils.loadComponent('TopBar'),
    Button: Utils.loadComponent('Button'),
    Digits: Utils.loadComponent('Digits'),
    Container: Utils.loadComponent('proxy/Container'),
    FormTag: Utils.loadComponent('proxy/Inputs/FormTag'),
    LoopingBG: Utils.loadComponent('LoopingBG'),
  },
  name: 'RegistrationPhoneVerification',
})
export default class RegistrationPhoneVerification extends Vue {
  @State('flowOutputs') public flowOutputsState!: { [k: string]: any }

  @Action('closeOverlays') public closeOverlays!: () => void
  @Action('requestPhoneVerification') public requestPhoneVerification!: (payload: LoginByPhoneCodeRequest) => any
  @Action('phoneVerification') public phoneVerification!: (payload: LoginByPhoneRequest) => any

  @Prop({
    type: [String, Object],
    default: '',
  }) public readonly rules: any

  @Prop({
    type: String,
    default: null,
  }) public readonly value!: string | null

  @Prop() public readonly name!: string
  @Prop() public readonly label!: string
  @Prop() public readonly hint?: string
  @Prop({type: Boolean, default: false}) public readonly required!: boolean
  @Prop({type: Boolean, default: false}) public readonly disabled!: boolean
  @Prop() public readonly step!: FlowInputStep

  protected alertMessage: string = ''
  protected digits: string = ''

  protected loading: boolean = false
  protected phoneVerificationSent: boolean = false
  protected formValid: boolean = false

  protected verifiedNumber: string | null = null

  protected rejectedDigits: string[] = []
  protected errorMessages: string[] = []

  protected innerValue: string | null = null

  @Watch('innerValue')
  protected onInnerValueChange(newVal: any) {
    this.$emit('input', newVal)
  }

  @Watch('value')
  protected onValueChange(newVal: any) {
    this.innerValue = newVal
  }

  @Watch('phone')
  protected onPhoneChange(newPhone: string) {
    if (newPhone !== this.verifiedNumber) {
      this.phoneVerificationSent = false
      this.$emit('input', null)
    } else {
      this.phoneVerificationSent = true
      this.$emit('input', this.digits)
    }
  }

  protected created() {
    if (this.value === undefined) {
      this.$emit('input', false)
    }
  }

  protected get phone() {
    const context = this.step.context
    const field = this.step.output_format?.mobile_number

    if (context && field) {
      return Utils.getProp(this.flowOutputsState, [context, field]) as string
    }
    return null
  }

  protected completed(val: string) {
    this.formValid = true
    this.digits = val
  }

  protected resendVerification() {
    this.alertMessage = 'Message sent'
    this.digits = ''
    this.requestPhoneVerification({mobile_number: this.phone}).then(() => {
      this.alertMessage = ''
    })
  }

  protected verifyPhone() {
    if (this.phone) {
      this.loading = true
      this.requestPhoneVerification({mobile_number: this.phone}).then(() => {
        this.phoneVerificationSent = true
      }).finally(() => {
        this.loading = false
      })
    }
  }

  protected get rejected() {
    return this.rejectedDigits.includes(this.digits)
  }

  protected verify() {
    if (!this.loading && !this.rejected) {
      this.loading = true
      this.$emit('input', null)

      this.phoneVerification({mobile_number: this.phone, token: this.digits}).then(() => {
        this.$emit('input', this.digits)
        this.verifiedNumber = this.phone
      }).catch((e: AxiosError) => {
          if (e.response) {
            const data = e.response.data
            if (data.result_code === 'exceptions.auth.invalid-contact-verification-exception') {
              this.errorMessages.push(data.result)
            }
            this.rejectedDigits.push(this.digits)
          }
        },
      ).finally(() => {
        this.loading = false
      })
    }
  }
}
