
import {Component, Vue} from 'vue-property-decorator'

import { VTabsItems } from 'vuetify/lib'

@Component({
  name: 'TabItems',
  extends: VTabsItems as any,
})
export default class TabItems extends Vue {

}
