import {extend} from 'vee-validate'
import moment from 'moment'
import {$dateformat} from '@/lib/plugins/lang'

extend('string', {
  validate(value) {
    return typeof value === 'string'
  },
  params: ['string'],
})

extend('single', {
  validate(value) {
    return !value.includes('|')
  },
  params: ['string'],
})

extend('after', {
  validate(value, args: Record<string, any>) {
    return moment(value, [$dateformat(), 'YYYY-MM-DD']).isAfter(moment(args.min))
  },
  params: ['min'],
})

extend('after_or_equal', {
  validate(value, args: Record<string, any>) {
    return moment(value, [$dateformat(), 'YYYY-MM-DD']).isSameOrBefore(moment(args.min))
  },
  params: ['min'],
})

extend('before', {
  validate(value, args: Record<string, any>) {
    return moment(value, [$dateformat(), 'YYYY-MM-DD']).isBefore(moment(args.max))
  },
  params: ['max'],
})

extend('before_or_equal', {
  validate(value, args: Record<string, any>) {
    return moment(value, [$dateformat(), 'YYYY-MM-DD']).isSameOrBefore(moment(args.max))
  },
  params: ['max'],
})

extend('date_format', {
  validate(value, args: Record<string, any>) {
    return moment(value, args.format).isValid()
  },
  params: ['max'],
})

extend('default', {
  validate() {
    return true
  },
  params: ['default'],
})

extend('mask', {
  validate() {
    return true
  },
  params: ['mask'],
})

extend('nullable', {
  validate() {
    return true
  },
  params: ['nullable'],
})

extend('showOnlySelected', {
  validate() {
    return true
  },
  params: ['showOnlySelected'],
})

extend('readonly', {
  validate() {
    return true
  },
  params: ['readonly'],
})

extend('all_of', (value: any[], values) => {
  return values.every((v: any) => value.includes(v))
})

extend('password', {
  validate(value, args: Record<string, any>) {
    if (!args.target || value === args.target) {
      if (!args.oldpass || value !== args.oldpass) {
        return Validations.rules.password(value)
      } else {
        return 'profile.change_password.errors.same'
      }
    } else {
      return 'profile.change_password.errors.confirm'
    }
  },
  params: ['target', 'oldpass'],
})

extend('new_password', {
  validate(value) {
    return Validations.rules.password(value)
  },
  params: ['target', 'newpass'],
})
// extend('phone', {
//   message (fieldName) {
//     return `${fieldName} is not a valid phone number`;
//   },
//   validate (value) {
//     return new Promise(resolve => {
//       let phone = new PhoneNumber(value);
//       resolve({ valid: phone.isValid() })
//     });
//   }
// });

interface Rules {
  // much less sloppy than 'any' but IDGAF for now, will be back later
  // [key: string] : (...value: any) => true | string
  [key: string]: any
}

export default class Validations {
  public static rules: Rules = {
    required: (value: string | boolean) => {
      if (typeof value === 'boolean') {
        return true
      }
      return !!value || 'required'
    },
    requiredCheckboxes: (requiredValues: string[], curr: string) => {
      return (value: string[]) => {
        if (!requiredValues.includes(curr)) {
          return true
        }
        if (value.includes(curr)) {
          return true
        }
        return requiredValues.every(v => value.includes(v)) || ''
      }
    },
    email: (value: string) => {
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return pattern.test(value) || 'invalid email'
    },
    phone: (value: string) => {
      const pattern = /^\+[0-9]{10,15}$/
      return pattern.test(value) || 'invalid phone'
    },
    number: (value: string) => {
      const pattern = /^[0-9]*$/
      return pattern.test(value) || 'only numbers are allowed'
    },
    string: (value: any) => {
      return typeof value === 'string' || 'must be a string'
    },
    single: (value: any) => {
      return !value.includes('|') || 'must be a single value'
    },
    boolean: (value: any) => {
      value = !!value
      return typeof value === 'boolean' || 'input must be a boolean'
    },
    nullable: () => {
      return true
    },
    readonly: () => {
      return true
    },
    digitsBetween: (min: string | number, max: string | number) => {
      return (value: number | string) => {
        const v = value.toString()
        return v.length >= min && v.length <= max || `must be a number between ${min} and ${max} digits`
      }
    },
    password: (value: string) => {
      return !!value.match('^[a-zA-Z0-9!@#$&()\\-_`.+,/\"]*$') || 'profile.change_password.errors.format'
    },
    new_password: (value: string) => {
      return !!value.match('^[a-zA-Z0-9!@#$&()\\-_`.+,/\"]*$') || 'profile.change_password.errors.format'
    },
    pin: (value?: string) => {
      return value === undefined || (!!value.match('^[0-9]') && value.length === 4) || 'profile.change_pin.errors.format'
    },
    passwordLength: (min: number) => {
      return (v: string) => {
        return v.length >= min || `the password should have at least ${min} characters`
      }
    },
    passwordNoSpaces: (value: string) => {
      return !value.match('^\S+$') || 'the password must contain no spaces'
    },
    showOnlySelected: () => true,
    sameAs: (value2: string | number) => {
      return (value: number | string) => {
        return value === value2 || `mismatch`
      }
    },
  }
}
