





import {Component} from 'vue-property-decorator'
import CameraButton from '@/components/uploader/CameraButton.vue'
import Button from '@/components/Button.vue'

@Component({
  components: {Button},
})
export default class CameraButtonCustom extends CameraButton {}
