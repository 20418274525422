import {Base} from '@/lib/n-maps/src/providers/Base'
import {LatLng} from '@/lib/n-maps/src/models/LatLng'
import {ResourceLoader} from '@/lib/n-maps/src/utils/ResourceLoader'

export class MapLibre extends Base {

  loadResources(): any[] {
    return [
      ResourceLoader('css', 'map-libre-map', 'https://unpkg.com/maplibre-gl@1.14.0-rc.1/dist/maplibre-gl.css'),
      ResourceLoader('script', 'map-libre-map', 'https://unpkg.com/maplibre-gl@1.14.0-rc.1/dist/maplibre-gl.js'),
    ]
  }

  initMap(element: HTMLElement, initConfig: any, s: () => void): void {
    // @ts-ignore
    this.map = new maplibregl.Map({
      container: element,
      style: 'https://tiles.stadiamaps.com/styles/osm_bright.json', // stylesheet location
      center: [initConfig.center.lng, initConfig.center.lat], // starting position [lng, lat]
      zoom: 9, // starting zoom
    })
    s()
  }

  pan(p: LatLng): void {
    console.warn('pan NOT IMPLEMENTED', 'MapLibre')
  }

  zoom(level: number) {
    console.warn('zoom NOT IMPLEMENTED', 'MapLibre')
  }

  setClickListener(l: (p: LatLng) => void): void {
  }

  addMarker(point: LatLng, icon: string, popupElement: any, click: () => void) {
    const el = document.createElement('div')
    el.className = 'marker'
    el.style.backgroundImage = 'url(' + icon + ')'
    el.style.width = 30 + 'px'
    el.style.height = 30 + 'px'
    el.style.backgroundSize = '100%'

    // @ts-ignore
    const marker = new maplibregl.Marker(el)
      .setLngLat([point.lng, point.lat])

    if (popupElement) {
      // @ts-ignore
      marker.setPopup(new maplibregl.Popup().setDOMContent(popupElement))
    }

    if (click && !popupElement) {
      el.addEventListener('click', function() {
        click()
      })
    }

    marker.addTo(this.map)
  }
}
