















import {Component, Watch} from 'vue-property-decorator'
import {mixins} from 'vue-class-component'

import {Action, Getter} from 'vuex-class'
import TabHistoryMixin from '@/lib/TabHistory'
import Utils from '@/utils'
import {ReservationResponse} from '@/lib/kepler/interfaces'

@Component({
  components: {
    Layout: Utils.loadComponent('proxy/Layout'),
    Tabs: Utils.loadComponent('proxy/Tabs/Tabs'),
    Tab: Utils.loadComponent('proxy/Tabs/Tab'),
    TabItems: Utils.loadComponent('proxy/Tabs/TabItems'),
    TabItem: Utils.loadComponent('proxy/Tabs/TabItem'),
    BookingSummary: Utils.loadView('Booking/BookingSummary'),
    VehicleStatus: Utils.loadComponent('entities/vehicle/VehicleStatus'),
    ProgressCircular: Utils.loadComponent('proxy/ProgressCircular'),
  },
  name: 'BookingDetail',
  mixins: [TabHistoryMixin],
})
export default class BookingDetail extends mixins<TabHistoryMixin>(TabHistoryMixin) {
  @Action('setTopbarTitle') public setTopbarTitle!: (t: string) => void
  @Getter('activeReservations') public activeReservations!: ReservationResponse[]
  public activeReservation: ReservationResponse | null = null
  public loading: boolean = true
  public currentTab: number = 0
  public vehicleCategory: string | null = null
  public reservationColor: string = 'accent'
  public tabMap: { [key: string]: number } = {
    '#summary': 0,
    '#reports': 1,
  }

  @Watch('$route.params.id')
  @Watch('activeReservations', {immediate: true, deep: true})
  public idChanged() {
    const r = this.activeReservations.find((active) => active.id === this.$route.params.id) || null
    if (r) {
      this.activeReservation = r
      const vehicleSlot = r.vehicle_slot
      this.setTopbarTitle(this.$t('booking.reservation', {number: r.number}))
      this.vehicleCategory = vehicleSlot.vehicle.category.type.toLowerCase() || null
      this.reservationColor = (vehicleSlot.reservation_type + this.vehicleCategory).toUpperCase()
    } else {
      this.$router.replace({name: 'home'})
    }
    this.loading = false
  }
}
