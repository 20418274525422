import AbstractHardware from '@/lib/hardware/classes/AbstractHardware'

import Teltonika from '@/lib/BtSDKs/Teltonika'

interface TeltonikaConf {
  device_name: string,
  commands: any,
}

export default class OmniScooterHardware extends AbstractHardware {
  private teltonikaConf : TeltonikaConf

  constructor(conf: TeltonikaConf) {
    super(conf)
    this.teltonikaConf = conf
  }

  public init(statusCB: (msg: string) => void) {
    if (window.cordova.platformId === 'browser') {
      return Promise.reject('browser is not supported on teltonika hardware')
    }

    statusCB('Launching teltonika init...')

    let manager = new Teltonika(statusCB, this.teltonikaConf.device_name, this.teltonikaConf.commands)

    return Promise.resolve()
      .then(() => {
        this.addAction('open', () => new Promise((resolve, reject) => {
          manager.runCommand("START").then(resolve).catch(reject)
        }), 'ble')
        this.addAction('close', () => new Promise((resolve, reject) => {
          manager.runCommand("END").then(resolve).catch(reject)
        }), 'ble')
      })
  }
}
