var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationProvider',{attrs:{"name":_vm.$attrs.label,"rules":_vm.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('VAutocomplete',_vm._g(_vm._b({staticClass:"countries-list-field",attrs:{"error-messages":errors,"append-outer-icon":valid? 'mdi-check' : errors? 'mdi-close': '',"return-object":"","items":_vm.countries,"item-text":"name","item-value":"code"},on:{"click":function($event){_vm.innerValue = null}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('img',{staticClass:"mr-2 flag-icon",attrs:{"src":("img/flags/4x3/" + (item.code.toLowerCase()) + ".svg")}}),_c('div',{staticClass:"v-list__tile__content"},[_c('div',{staticClass:"v-list__tile__title"},[_vm._v(_vm._s(item.name))])])]}},{key:"selection",fn:function(ref){
var item = ref.item;
var selected = ref.selected;
return [_c('VLayout',{staticClass:"fill-width",on:{"click":function($event){_vm.innerValue = null}}},[_c('img',{staticClass:"mr-2 flag-icon",attrs:{"src":("img/flags/4x3/" + (item.code.toLowerCase()) + ".svg")}}),_c('span',[_vm._v(_vm._s(item.name))])])]}}],null,true),model:{value:(_vm.innerValue),callback:function ($$v) {_vm.innerValue=$$v},expression:"innerValue"}},'VAutocomplete',_vm.$attrs,false),_vm.$listeners))]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }