import sdk from '@/lib/kepler/sdk'
import AbstractHardware from '@/lib/hardware/classes/AbstractHardware'

import Locate from '@/lib/location'
import {OpenVehicleRequest, RestHWRequest} from '@/lib/kepler/interfaces'
import store from '@/store'

const addPosition = async (name: string, req: RestHWRequest): Promise<RestHWRequest> => {
  const checkOpDist = store.getters.fieldConfigs?.check_operation_distance?.split('|')
  if (!req.position && checkOpDist?.includes(name)) {
    return Locate.please_promise_me_a_position()
      .then(({lat, lng}) => {
        req.position = {latitude: lat, longitude: lng}
        return req
      }).catch(() => {
        return req
      })
  } else {
    return Promise.resolve(req)
  }
}

export default class RestHardware extends AbstractHardware {
  public init() {
    this.addAction('open', () => new Promise((resolve, reject) => {
      const reservation_number = this.config.reservationNumber
      this.config.pinRequester().then((pin: string) => {
        const req: OpenVehicleRequest = {reservation_number, pin}
        addPosition('open', req).then(() => {
          sdk.booking.open(req).then(() => {
            resolve('SUCCESS')
          }).catch((x) => {
            if (x.response.status == 422 && x.response.data.result.includes('exception.messages.invalid-pin')) {
              reject('INVALID_PIN')
            } else if (x.response.data.result.includes('exceptions.reservation.operation-cannot-be-completed-exception')) {
              reject([x.response.data.result, x.response.data.messages.join('-')])
            } else if (x.response.data.messages) {
              const m = x.response.data.messages.join('-')
              if (m.toUpperCase().includes('TIMEOUT')) {
                reject('TIMEOUT')
              } else {
                reject(x.response.status + ': ' + m)
              }
            } else {
              reject(x.response.status + ': ' + JSON.stringify(x.response.data))
            }
          })
        })
      }).catch(() => {
        reject('ABORTED')
      })
    }))
    this.addAction('close', () => new Promise((resolve, reject) => {
        const reservation_number = this.config.reservationNumber
        addPosition('close', {reservation_number}).then((req) => {
          sdk.booking.close(req).then(() => {
            resolve('SUCCESS')
          }).catch((x) => {
            if (x.response.data.messages) {
              reject([x.response.data.result, x.response.data.messages.join('-')])
            } else {
              reject(x.response.status + ': ' + JSON.stringify(x.response.data))
            }
          })
        })
      }),
    )
    return Promise.resolve()
  }
}
