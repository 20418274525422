import {ReservationResponse, VehicleSlot} from '@/lib/kepler/interfaces'
import ServiceMesh from '@/lib/serviceMesh'
import VuetifyColorHelper from '@/lib/vuetify/VuetifyColorHelper'
import {branding} from '@/lib/plugins/theme'

function getVehicleSlot(obj: VehicleSlot | ReservationResponse) {
  return (obj as ReservationResponse).vehicle_slot || obj
}

export default class VehicleSlotHelper {
  private readonly sm = new ServiceMesh()
  private readonly vs: VehicleSlot

  constructor(obj: VehicleSlot | ReservationResponse) {
    this.vs = getVehicleSlot(obj)
  }

  public get vehicleTypeKey() {
    return `${this.vs.reservation_type}${this.vs.vehicle.category.type}`.toUpperCase()
  }

  public get localizableName() {
    return this.sm.vehicleType[this.vehicleTypeKey]
  }

  public get color() {
    return ServiceMesh.colors[this.vehicleTypeKey]
  }

  public get textColor() {
    const vch = VuetifyColorHelper
    let contrast = vch.lightContrast(vch.color(this.color))

    const override = branding.theme.buttonDefaultColorOverride as string | undefined
    if (override) {
      contrast = vch.lightContrast(vch.color(override))
    }

    return contrast ? 'black' : 'white'
  }

  public static vehicleTypeKey(obj: VehicleSlot | ReservationResponse) {
    const vs = getVehicleSlot(obj)
    return `${vs.reservation_type}${vs.vehicle.category.type}`.toUpperCase()
  }

  public static localizableName(obj: VehicleSlot | ReservationResponse) {
    const vs = getVehicleSlot(obj)
    const sm = new ServiceMesh()
    return sm.vehicleType[VehicleSlotHelper.vehicleTypeKey(vs)]
  }

  public static color(obj: VehicleSlot | ReservationResponse) {
    const vs = getVehicleSlot(obj)
    return ServiceMesh.colors[VehicleSlotHelper.vehicleTypeKey(vs)]
  }

  public static textColor(obj: VehicleSlot | ReservationResponse) {
    const vs = getVehicleSlot(obj)
    const vch = VuetifyColorHelper
    let contrast = vch.lightContrast(vch.color(VehicleSlotHelper.color(vs)))

    const override = branding.theme.buttonDefaultColorOverride as string | undefined
    if (override) {
      contrast = vch.lightContrast(vch.color(override))
    }

    return contrast ? 'black' : 'white'
  }
}
