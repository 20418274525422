






























import {Vue, Component, Prop, Watch} from 'vue-property-decorator'
import Utils from '@/utils'
import {State} from 'vuex-class'
import {ClientTypeState} from '@/store/modules/clientTypes'
import {AppConfig, Attachment, RegistrationImage} from '@/lib/kepler/interfaces'
import {AppConfigState} from '@/store/modules/configuration'

import {ValidationProvider} from 'vee-validate'
import {VTextField, VIcon, VLayout, VImg} from 'vuetify/lib'

// noinspection JSUnusedGlobalSymbols
@Component({
  components: {
    ValidationProvider,
    VTextField,
    VLayout,
    VIcon,
    VImg,
    Btn: Utils.loadComponent('proxy/Btn'),
    CameraButtonCustom: Utils.loadComponent('uploader/CameraButtonCustom'),
  },
  name: 'RegistrationImageComponent',
})
export default class RegistrationImageComponent extends Vue {
  @State('clientTypes') public types!: ClientTypeState
  @State('configuration') public configState!: AppConfigState
  @State('registration') public registrationState!: { [k: string]: any }
  @State((state) => state.profile.language) public profileLang!: string
  @State((state) => state.configuration.appConfig) public appConfig!: AppConfig

  @Prop() public readonly rules!: string
  @Prop() public readonly name!: string
  @Prop() public readonly placeholder!: string
  @Prop() public readonly buttonText!: string
  @Prop() public readonly label!: string
  @Prop({type: Boolean, default: false}) public readonly required!: boolean
  @Prop({type: Object}) public readonly value!: RegistrationImage
  @Prop({type: String, default: () => ''}) public readonly mask!: string

  public loading: boolean = false

  public result: string | null = null

  protected cannotLoadImage = false

  protected imageUploadResponse = this.value ? this.value : new RegistrationImage()

  protected buttonColor: string = 'accent'

  protected get showCamera() {
    return !(this.result && !this.loading)
  }

  @Watch('imageUploadResponse', {deep: true})
  public onValueChange(res: RegistrationImage) {
    if (res && res.token) {
      this.$emit('input', res)
    }
  }

  protected gotUploadResponse(r: Attachment) {
    this.imageUploadResponse = Object.assign(new RegistrationImage(), {...r})
    this.loading = true
    this.$emit('input', this.imageUploadResponse)
    this.result = r.url
  }

  protected mounted() {
    if (typeof this.value === 'object' && this.value?.url) {
      this.loading = true
      this.result = this.value.url
    }
  }

  protected onLoadUploadedImage() {
    this.loading = false
  }

  protected deleteAttachment() {
    this.result = null
    this.loading = false
  }
}
