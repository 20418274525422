



















import {Vue, Component, Prop, VModel} from 'vue-property-decorator'
import ClickOutside from '@/lib/ClickOutside.vue'
import {ParkingLot, VehicleSlot} from '@/lib/kepler/interfaces'
import VehicleCardPopup from '@/components/entities/vehicle/VehicleCardPopup.vue'
import ParkingCardPopup from '@/components/entities/vehicle/ParkingCardPopup.vue'
import Utils from '@/utils'

@Component({
  components: {
    Card: Utils.loadComponent('proxy/Card/Card'),
    Icon: Utils.loadComponent('proxy/Icon'),
    Layout: Utils.loadComponent('proxy/Layout'),
    Sheet: Utils.loadComponent('proxy/Sheet'),
    ClickOutside,
  },
})
export default class CarouselContainer extends Vue {
  @Prop({
    type: Array,
    default: () => [],
  }) public elements!: VehicleSlot[] | ParkingLot[] | []

  @VModel({
    type: String,
    default: 'hidden',
  }) public type!: 'hidden' | 'vehicle' | 'parking' | 'poi'

  public get show() {
    return this.type !== 'hidden'
  }

  public set show(val: boolean) {
    if (!val) {
      this.type = 'hidden'
    }
  }

  public get component() {
    switch (this.type) {
      case 'vehicle':
        return VehicleCardPopup
      case 'parking':
        return ParkingCardPopup
      case 'poi':
        return 'html'
      default:
        return null
    }
  }

  public get parkingLot(): { name: string, distance: string | null } | null {
    if (this.elements.length && this.type === 'vehicle') {
      const first = this.elements[0] as VehicleSlot
      if (first) {
        return {
          name: first.lot.name,
          distance: this.getDistance(first) || null,
        }
      }
    } else if (this.elements.length && this.type === 'parking') {
      const first = this.elements[0] as ParkingLot
      if (first) {
        return {
          name: first.name,
          distance: null,
        }
      }
    }
    return null
  }

  public parsedProps(element: any) {
    switch (this.type) {
      case 'vehicle':
        return {vehicleSlot: element}
      case 'parking':
        return {lot: element}
      case 'poi':
        return {poi: element}
    }
  }

  public close() {
    this.show = false
  }

  public getDistance(vehicleSlot: VehicleSlot) {
    const d = vehicleSlot.distance
    let str: string | null = null
    if (d !== null) {
      str = `${this.$t('common.circa')} `
      if (this.$distance().toLowerCase() === 'km') {
        str += d >= 1000 ? `${this.$distance(Math.round((d / 1000) * 10) / 10)}` : `${Math.floor(d)}m`
      } else {
        str += this.$distance(d)
      }
    }
    return str
  }
}
