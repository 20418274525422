import {MutationTree, ActionTree} from 'vuex'
import sdk from '@/lib/kepler/sdk'
import {ClientType} from '@/lib/kepler/interfaces'
import {RootState} from '@/store'

export class ClientTypeState {
  public clientType: ClientType[] = []
  public clientTypeAt: number | null = null
}

const mutations: MutationTree<ClientTypeState> = {
  SET_CLIENT_TYPES(state1, payload) {
    state1.clientType = payload
  },
  SET_CLIENT_TYPES_AT(state1, payload) {
    state1.clientTypeAt = payload
  },
}

const actions: ActionTree<ClientTypeState, RootState> = {
  init({state, commit, dispatch}) {
    if (sdk.timestamps.verify(state.clientTypeAt)) {
      sdk.people.clientTypes().then((response) => {
        commit('SET_CLIENT_TYPES', response.data)
        commit('SET_CLIENT_TYPES_AT', sdk.timestamps.now())
        dispatch('sleep', 'client_types')
      })
    }
  },
}

export default {
  state: new ClientTypeState(),
  mutations,
  actions,
}
