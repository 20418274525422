
import {Component, Vue} from 'vue-property-decorator'

import { VIcon } from 'vuetify/lib'

@Component({
  name: 'Icon',
  extends: VIcon as any,
})
export default class Icon extends Vue {

}
