import {ResourceLoader} from '../utils/ResourceLoader'
import {Base} from './Base'
import {LatLng} from '../models/LatLng'

declare namespace BMap {
  let Point: any
}

// @ts-ignore
export class Baidu extends Base {

  public loadResources() {
    // http://lbsyun.baidu.com/jsdemo.htm#webgl0_1
    // https://github.com/huiyan-fe/react-bmap/tree/master/src
    return [
      ResourceLoader('script', 'baidu-map', 'https://api.map.baidu.com/getscript?v=3.0&ak=' + this.config.apiKey),
    ]
  }

  public initMap(element: HTMLElement, initConfig: any, s: any): void {
    // @ts-ignore
    this.map = new BMap.Map(element)
    // @ts-ignore
    this.map.centerAndZoom(new BMap.Point(initConfig.center.longitude, initConfig.center.latitude), initConfig.zoom)
    this.map.enableScrollWheelZoom(true)
    s()
  }

  public setClickListener(l: (p: LatLng) => void): void {

  }

  public pan(p: LatLng): void {
    this.map.centerAndZoom(new BMap.Point(p.lat, p.lng), this.map.getZoom())
  }
}
