import _Vue from 'vue'
import {Theme} from '@/lib/kepler/interfaces'
import JSONtheme from '@/lib/vuetify/theme.json'
import Utils from '@/utils'

export interface Branding {
  dark?: boolean
  theme: Theme
  customCss: Record<string,{ [cssRule: string]: string }>
  gradientAngle: Record<string, number>
  customClasses: Record<string, string[]>
}

export const branding = JSONtheme as Branding
export function refreshBranding(val: Branding) {
  Utils.setProps(val, branding)
}

export default {
  install(Vue: typeof _Vue): void {
    Vue.prototype.$branding = branding
  },
}
