













import {Vue, Component} from 'vue-property-decorator'
import Utils from '@/utils'
import AddPacket from '@/views/AddPacket.vue'

@Component({
  components: {
    GradientCard: Utils.loadComponent('GradientCard'),
    List: Utils.loadComponent('proxy/List/List'),
    ListTile: Utils.loadComponent('proxy/List/ListTile'),
    ListTileTitle: Utils.loadComponent('proxy/List/ListTileTitle'),
  },
  name: 'BuyPackets',
})

export default class BuyPackets extends Vue {
  public openAddPacket() {
    this.$dialog.open(AddPacket)
  }
}
