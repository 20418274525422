



















































import {Vue, Component, PropSync} from 'vue-property-decorator'
import {State, Action, Getter} from 'vuex-class'
import Utils from '@/utils'
import {VSlider} from 'vuetify/lib'
import {FieldConfigs} from '@/lib/kepler/interfaces'
import {MapFiltersState, SearchParameters} from '@/store/modules/map'

@Component({
  name: 'RangeFilter',
  components: {
    TextField: Utils.loadComponent('proxy/Inputs/TextField'),
    CustomIcon: Utils.loadComponent('CustomIcon'),
    ProgressLinear: Utils.loadComponent('proxy/ProgressLinear'),
    CloseButton: Utils.loadComponent('CloseButton'),
    Container: Utils.loadComponent('proxy/Container'),
    Layout: Utils.loadComponent('proxy/Layout'),
    Button: Utils.loadComponent('Button'),
    FiltersLayout: Utils.loadComponent('filters/FiltersLayout'),
    VSlider,
  },
})
export default class RangeFilter extends Vue {
  @State('filters') public filters!: MapFiltersState

  @Getter('fieldConfigs') public fieldConfigs?: FieldConfigs

  @Action('_parameter') public updateParameter!: (p: SearchParameters) => void

  @PropSync('modified', {type: Boolean}) public mod!: boolean

  public internalValue: number | string | null = null
  public buttonMode = true

  public get range() {
    if (this.hideRange === 'hidden' || typeof this.filters.parameters?.min_range !== 'number') {
      return null
    } else {
      return this.filters.parameters?.min_range
    }
  }

  public set range(r) {
    if (!this.hideRange && r !== null) {
      this.updateParameter({min_range: r})
    }
  }

  public get hideRange() {
    return this.fieldConfigs?.filters_range
  }

  public get rangeStep(): string | number | undefined {
    return this.fieldConfigs?.filters_range_step || 30
  }

  public get rangeMax(): string | number | undefined {
    return this.fieldConfigs?.filters_range_max || 450
  }

  public get rangeMin(): string | number | undefined {
    return this.fieldConfigs?.filters_range_min || 0
  }

  public internalValueChange(num: number, ignore?: boolean) {
    if (this.internalValue === null) {
      this.internalValue = 0
    }
    this.internalValue = Number(this.internalValue) + num
    this.applyRange(ignore)
  }

  public applyRange(ignore?: boolean) {
    if (typeof this.internalValue === 'string') {
      this.internalValue = Number(this.internalValue)
    }
    if (this.range !== this.internalValue) {
      this.range = this.internalValue
      if (ignore !== true) {
        this.mod = true
      }
    } else {
      this.mod = false
    }
  }

  public resetRange() {
    this.internalValueChange(-(this.internalValue || 0), true)
    this.$emit('request-init')
  }

  protected created() {
    this.internalValue = this.range
    if (!this.filters.parameters?.hasOwnProperty('min_range')) {
      this.range = 0
    }
  }
}
