













import {Component, Prop} from 'vue-property-decorator'
import {mixins} from 'vue-class-component'
import Card from './proxy/Card/Card.vue'
import VuetifyColorHelper, {CustomColorableMixin} from '@/lib/vuetify/VuetifyColorHelper'

@Component({
  components: {Card},
  mixins: [CustomColorableMixin],
})
export default class GradientCard extends mixins<CustomColorableMixin>(CustomColorableMixin) {
  @Prop({type: String, default: () => ''}) protected colorTop?: string
  @Prop({type: String, default: () => ''}) protected colorBottom?: string

  @Prop({type: Boolean, default: false}) protected ripple?: boolean
  @Prop({type: Boolean, default: true}) protected flat?: boolean

  protected get customStyle() {
    const arr: Array<Record<string, string>> = []
    if (this.colorTop && this.colorBottom) {
      const top = this.color(this.colorTop) || 'transparent'
      const bottom = this.color(this.colorBottom) || 'transparent'
      arr.push({
        'background-image': `linear-gradient(${this.gradientAngle || this.angle}deg, ${top}, ${bottom})`,
      })
    } else {
      arr.push({
        background: VuetifyColorHelper.color('primary'),
      })
    }
    if (this.customCss) {
      arr.push(this.customCss)
    }
    return arr
  }

  protected get themeName() {
    if (typeof this.customCSS === 'string') {
      return this.customCSS
    }
    if (this.colorTop?.includes('Top')) {
      return this.colorTop?.replace('Top', '')
    }
    if (this.colorBottom?.includes('Bottom')) {
      return this.colorBottom?.replace('Bottom', '')
    }
    return null
  }

  protected get gradientAngle() {
    if (this.themeName && VuetifyColorHelper?.gradientAngle(this.themeName)) {
      return VuetifyColorHelper.gradientAngle(this.themeName)
    }
    return this.angle || null
  }

  protected get isLight() {
    const top = this.colorTop ? this.color(this.colorTop) : null
    const bottom = this.colorBottom ? this.color(this.colorBottom) : null
    if (this.customClasses?.includes('theme--dark')) {
      return false
    }
    if (this.customClasses?.includes('theme--light')) {
      return true
    }
    if (this.contrastOverride !== null) {
      return this.contrastOverride
    } else if (top && bottom) {
      return VuetifyColorHelper.lightContrast(top, bottom)
    }
    return !this.$vuetify.dark
  }
}
