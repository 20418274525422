var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VLayout',{attrs:{"column":""}},[_c('VForm',[(!_vm.isNewPass)?_c('ValidationProvider',{attrs:{"name":_vm.$attrs.name,"rules":_vm.rules,"detectInput":false,"vid":"old_password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('VTextField',_vm._g({attrs:{"value":_vm.innerValue.old_password,"error-messages":_vm.transErrors(errors),"append-outer-icon":valid? 'mdi-check' : errors? 'mdi-close': '',"label":_vm.$t('profile.change_password.old_password'),"type":_vm.showPassword ? 'text' : 'password',"append-icon":_vm.showPassword ? 'mdi-eye-off' : 'mdi-eye',"browser-autocomplete":"current-password"},on:{"input":function($event){return _vm.updateValue('old_password', $event)},"click:append":function($event){_vm.showPassword = !_vm.showPassword}}},_vm.$listeners))]}}],null,false,1819411935)}):_vm._e(),(_vm.innerValue.old_password || _vm.isNewPass)?_c('ValidationProvider',{attrs:{"name":_vm.$attrs.name,"rules":_vm.rules,"vid":"new_password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('VTextField',_vm._g({attrs:{"value":_vm.innerValue.new_password,"error-messages":_vm.transErrors(errors),"append-outer-icon":valid? 'mdi-check' : errors? 'mdi-close': '',"label":_vm.$t(_vm.isNewPass?'profile.change_password.password':'profile.change_password.new_password'),"type":_vm.showPassword ? 'text' : 'password',"append-icon":_vm.showPassword ? 'mdi-eye-off' : 'mdi-eye',"browser-autocomplete":"new-password"},on:{"input":function($event){return _vm.updateValue('new_password', $event)},"click:append":function($event){_vm.showPassword = !_vm.showPassword}}},_vm.$listeners))]}}],null,false,833932081)}):_vm._e(),(_vm.innerValue.new_password || _vm.isNewPass)?_c('ValidationProvider',{attrs:{"name":_vm.$attrs.name,"rules":_vm.rulesRetype},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('VTextField',_vm._g({directives:[{name:"show",rawName:"v-show",value:(_vm.innerValue.new_password),expression:"innerValue.new_password"}],attrs:{"value":_vm.innerValue.new_password_retype,"error-messages":_vm.transErrors(errors),"append-outer-icon":valid? 'mdi-check' : errors? 'mdi-close': '',"label":_vm.$t('profile.change_password.new_password_retype'),"type":_vm.showPassword ? 'text' : 'password',"append-icon":_vm.showPassword ? 'mdi-eye-off' : 'mdi-eye',"browser-autocomplete":"new-password"},on:{"input":function($event){return _vm.updateValue('new_password_retype', $event)},"click:append":function($event){_vm.showPassword = !_vm.showPassword}}},_vm.$listeners))]}}],null,false,1186857432)}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }