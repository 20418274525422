






import {Vue, Component, Prop} from 'vue-property-decorator'
import ServiceMesh from '@/lib/serviceMesh'
import {VehicleType} from '@/lib/kepler/interfaces'
import {VLayout, VImg} from 'vuetify/lib'

@Component({
  components: {VLayout, VImg},
  name: 'VehicleConfirmImage',
})
export default class VehicleConfirmImage extends Vue {
  @Prop({
    type: String,
    required: false,
  }) private vehicleType!: VehicleType

  private get confirmImage() {
    return this.vehicleType ? ServiceMesh.getConfirmationImage(this.vehicleType) : null
  }
}
