






















import {Component, Prop} from 'vue-property-decorator'
import Utils from '@/utils'
import CardButton from './CardButton.vue'
import {Driver} from '@/lib/kepler/interfaces'

@Component({
  components: {
    Button: Utils.loadComponent('Button'),
    Img: Utils.loadComponent('proxy/Image'),
    Avatar: Utils.loadComponent('proxy/Avatar'),
    CardButton,
  },
})
export default class CardDriver extends CardButton {
  @Prop({required: true, type: Object}) protected readonly driver!: Driver
  @Prop({type: Boolean, default: false}) protected hasPlan!: boolean
  @Prop({type: Boolean, default: false}) protected checkSubscription!: boolean

  protected get statusFormatted() {
    const str = this.driver.status.replace(' ', '_').toLowerCase()
    return {
      status: this.$t(`profile.status.${str}`),
      color: (() => {
        switch (this.driver.status) {
          case 'ACTIVE':
            return 'success'
          case 'SUSPENDED':
            return 'error'
          case 'PENDING REVIEW':
            return 'warning'
          default:
            return 'accent'
        }
      })(),
    }
  }

  protected goToSubs() {
    this.$router.push({name: 'my subscriptions'})
  }
}
