







import {Vue, Component, Prop} from 'vue-property-decorator'
import CenterPointIcon from '../../public/img/map/markers/map_position_icon.svg?inline'
import {Position} from '@/lib/location'

@Component({
  components: {CenterPointIcon},
})
export default class CenterPoint extends Vue {
  @Prop({type: Object, required: true}) public centerPoint!: Position
}
