
import {Component, Vue} from 'vue-property-decorator'
import { VStepperContent } from 'vuetify/lib'

@Component({
  name: 'VStepperContent',
  extends: VStepperContent as any,
})
export default class StepperContent extends Vue {
}
