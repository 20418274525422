






import {Vue, Component, Prop} from 'vue-property-decorator'

@Component({
  components: {},
})
export default class CardGrid extends Vue {
  @Prop({
    type: String,
    default: '20em',
  }) protected readonly minCardWidth!: string
  @Prop({
    type: String,
    default: '1fr',
  }) protected readonly maxCardWidth!: string
  @Prop({
    type: Boolean,
    default: false,
  }) protected readonly center!: boolean

  protected ready: boolean = false

  protected observer: ResizeObserver | null = new ResizeObserver(() => {
    this.$nextTick(this.resizeMasonryItems)
  })

  protected get grid() {
    let g = this.$refs.card_grid
    if (!g) {
      return null
    }
    g = Array.isArray(g) ? g[0] : g // get first item in case of array
    g = '$el' in g ? g.$el : g // get Element in case of Vue component
    return g as HTMLElement
  }

  protected get style() {
    return {
      gridTemplateColumns: `repeat(auto-fit, minmax(min(${this.minCardWidth}, 100%), ${this.maxCardWidth}))`,
      justifyContent: this.center ? 'center' : null,
    }
  }

  protected resizeMasonryItems() {
    /**
     * Set appropriate spanning to any masonry item
     *
     * Get different properties we already set for the masonry, calculate
     * height or spanning for any cell of the masonry grid based on its
     * content-wrapper's height, the (row) gap of the grid, and the size
     * of the implicit row tracks.
     *
     */

    /* Get the grid object, its row-gap, and the size of its implicit rows */

    if (this.grid) {
      const gridStyle = window.getComputedStyle(this.grid)
      const rowGap = parseInt(gridStyle.getPropertyValue('grid-row-gap'), 10)
      const rowHeight = parseInt(gridStyle.getPropertyValue('grid-auto-rows'), 10)

      const gridChildren = Array.from(this.grid.children)

      /*
       * Spanning for any child = S
       * Grid's row-gap = G
       * Size of grid's implicitly create row-track = R
       * Height of item content = H
       * Net height of the item = H1 = H + G
       * Net height of the implicit row-track = T = G + R
       * S = H1 / T
       */
      for (const gridChild of gridChildren) {
        const rowSpan = Math.ceil((gridChild.getBoundingClientRect().height + rowGap) / (rowHeight + rowGap));
        /* Set the spanning as calculated above (S) */
        (gridChild as HTMLElement).style.gridRowEnd = 'span ' + rowSpan
      }
      this.ready = true
    }
  }

  protected mounted() {
    this.observer = new ResizeObserver(this.resizeMasonryItems)
    this.observer.observe(this.$el, {})
  }

  protected beforeDestroy() {
    this.observer?.disconnect()
  }

}
