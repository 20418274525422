import {ActionTree, MutationTree} from 'vuex'
import sdk from '@/lib/kepler/sdk'
import {VehicleSlot, ZoneResponse} from '@/lib/kepler/interfaces'
import {RootState} from '@/store'

export class VehicleSlotState {
  public zones: Map<string, ZoneResponse> = new Map<string, ZoneResponse>()
  public selectedVehicle?: VehicleSlot
  public cachedVehicleSlots: string[] = []
}

export interface VehicleSlotZones {
  id: string
  zone: ZoneResponse
}

const mutations: MutationTree<VehicleSlotState> = {
  INIT_ZONE(state: VehicleSlotState) {
    state.zones = new Map<string, ZoneResponse>()
    state.cachedVehicleSlots = []
  },
  SET_ZONE(state: VehicleSlotState, payload: VehicleSlotZones) {
    state.zones.set(payload.id, payload.zone)
  },
  SET_VEHICLE_SLOT(state: VehicleSlotState, payload: VehicleSlot) {
    state.selectedVehicle = payload
  },
  CLEAN_VEHICLE_SLOT(state: VehicleSlotState) {
    state.selectedVehicle = undefined
  },
  PURGE_VEHICLE_SLOTS(state) {
    if ('clear' in state.zones) {
      state.zones.clear()
    }
  },
}

const actions: ActionTree<VehicleSlotState, RootState> = {
  init({commit}) {
    commit('INIT_ZONE')
  },
  setZone({state, commit}, id: string) {
    if (!state.cachedVehicleSlots.includes(id)) {
      return sdk.booking.zones(id).then((r) => {
        state.cachedVehicleSlots.push(id)
        commit('SET_ZONE', {id, zone: r.data})
      })
    }
  },
  setVehicleSlot({commit}, payload: VehicleSlot) {
    commit('SET_VEHICLE_SLOT', payload)
  },
  cleanVehicleSlot({commit}) {
    commit('CLEAN_VEHICLE_SLOT')
  },
  purge({commit}) {
    commit('PURGE_VEHICLE_SLOTS')
  },
  diaryReports({}, payload: string) {
    return sdk.booking.diaryReports(payload)
  },
}

export default {
  state: new VehicleSlotState(),
  mutations,
  actions,
}
