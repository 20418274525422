
import {Component, Vue} from 'vue-property-decorator'

import { VLayout } from 'vuetify/lib'

@Component({
  name: 'Layout',
  extends: VLayout as any,
})
export default class Layout extends Vue {

}
