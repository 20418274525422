
















import {Component, Prop, Vue} from 'vue-property-decorator'
import BottomSheet from './proxy/BottomSheet.vue'
import Button from './Button.vue'
import List from './proxy/List/List.vue'
import Subheader from './proxy/Subheader.vue'
import ListTile from './proxy/List/ListTile.vue'
import ListTileTitle from './proxy/List/ListTileTitle.vue'
import ListTileAvatar from './proxy/List/ListTileAvatar.vue'
import Avatar from './proxy/Avatar.vue'
import Icon from './proxy/Icon.vue'
import {CameraFileUploader} from '../lib/CameraFileUploader'

@Component({
  components: {Icon, Avatar, ListTileAvatar, ListTileTitle, ListTile, Subheader, List, Button, BottomSheet},
})
export default class UploadTypeChooser extends Vue {
  @Prop() protected open!: boolean
  @Prop() protected disabled!: boolean
  @Prop() protected allowEdit!: boolean
  @Prop() protected cameraDirection!: number

  protected openSource(source: number) {
    this.$emit('toggleChooser')
    this.$emit('sourceChosen')
    const uploadUrl = `${this.$env.API_URL}v1/attachment/upload`
    const camera = new CameraFileUploader(uploadUrl, source)
    camera.openCamera(this.successUpload, this.errorCallback, this.allowEdit, this.cameraDirection)
  }

  private successUpload(data: any) {
    this.$emit('successUpload', data)
  }

  private errorCallback(data: any) {
    this.$emit('uploadError', data)
  }
}
