































import {Component, Watch} from 'vue-property-decorator'
import {mixins} from 'vue-class-component'
import {State, Getter, Action} from 'vuex-class'
import {ProfileState} from '@/store/modules/profile'
import {AppConfigState} from '@/store/modules/configuration'
import {
  ModifyRequest,
  Client,
  DriverEditRequest,
  Driver,
  Dialog,
  LoginByPhoneCodeRequest,
} from '@/lib/kepler/interfaces'
import TabHistoryMixin from '@/lib/TabHistory'
import PhoneVerification from '@/views/PhoneVerification.vue'
import ConfirmDialog from '@/views/ConfirmDialog.vue'
import Utils from '@/utils'

@Component({
  components: {
    Layout: Utils.loadComponent('proxy/Layout'),
    Icon: Utils.loadComponent('proxy/Icon'),
    Button: Utils.loadComponent('Button'),
    PersonalData: Utils.loadComponent('profile/PersonalData'),
    BillingInfo: Utils.loadComponent('profile/BillingInfo'),
    TabItem: Utils.loadComponent('proxy/Tabs/TabItem'),
    Tab: Utils.loadComponent('proxy/Tabs/Tab'),
    TabItems: Utils.loadComponent('proxy/Tabs/TabItems'),
    Tabs: Utils.loadComponent('proxy/Tabs/Tabs'),
    Container: Utils.loadComponent('proxy/Container'),
    Divider: Utils.loadComponent('proxy/Divider'),
    Btn: Utils.loadComponent('proxy/Btn'),
    CloseButton: Utils.loadComponent('CloseButton'),
  },
  name: 'ModifyProfile',
  mixins: [TabHistoryMixin],
})
export default class ModifyProfile extends mixins<TabHistoryMixin>(TabHistoryMixin) {
  @State('profile') public profileState!: ProfileState
  @State('configuration') public configuration!: AppConfigState
  @Getter('mainDriver') public isMainDriver!: boolean
  @Action('updateClient') public updateClient!: (r: ModifyRequest) => Promise<Client>
  @Action('updateDriver') public updateDriver!: (r: DriverEditRequest) => Promise<Driver>
  @Action('openDialog') public openDialog!: (dialog: Dialog) => void
  @Action('requestLoginByPhone') public requestLoginByPhone!: (req: LoginByPhoneCodeRequest) => Promise<any>

  public currentTab: number | null = null
  public tabMap: { [key: string]: number } = {
    '#personal': 0,
  }
  public driverEditRequestOriginal: DriverEditRequest = {
    title: '',
    name: '',
    surname: '',
    birthdate: '',
    gender: '',
    email: '',
    phone: '',
    mobile: '',
    address: {
      country: '',
      region: '',
      city: '',
      postal_code: '',
      street: '',
      street_number: '',
    },
  }
  public driverEditRequest: DriverEditRequest = this.driverEditRequestOriginal
  public driverEditRequestChanged?: boolean = false
  public modifyRequestOriginal: ModifyRequest = {
    name: '',
    surname: '',
    phone: '',
    mobile: '',
    email: '',
    fiscal_code: '',
    vat_number: '',
    organization_name: '',
    extra: {},
    address: {
      country: '',
      region: '',
      city: '',
      postal_code: '',
      street: '',
      street_number: '',
    },
    billing_address: {
      country: '',
      region: '',
      city: '',
      postal_code: '',
      street: '',
      street_number: '',
    },
  }
  public modifyRequest: ModifyRequest = this.modifyRequestOriginal
  public modifyRequestChanged?: boolean = false
  public validation: { [key: string]: boolean | undefined } = {
    personal: undefined,
    billing: undefined,
  }

  public get isBusiness(): boolean {
    if (!this.profileState.client) {
      return false
    }
    return this.isMainDriver && this.profileState.client.client_type.type.toLowerCase() === 'business'
  }

  public get changed() {
    return this.driverEditRequestChanged || this.modifyRequestChanged
  }

  public alertOk() {
    this.openDialog(new Dialog(ConfirmDialog, {
      imageState: 'success.svg',
      confirmText: this.$t('common.ok'),
      code: '',
      subtitle: '',
      title: this.$t('profile.edit.profile_updated'),
      singleAction: true,
      emitConfirm: false,
    }))
    this.$router.push({name: 'profile'})
  }

  public alertHold() {
    this.openDialog(new Dialog(ConfirmDialog, {
      imageState: 'warn.svg',
      confirmText: this.$t('common.ok'),
      code: '',
      title: this.$t('profile.edit.on_hold_confirm'),
      subtitle: this.$t('profile.edit.on_hold_confirm_message'),
      singleAction: true,
      emitConfirm: false,
    }))
    this.$router.push({name: 'profile'})
  }

  protected created() {
    if (this.isBusiness) {
      this.tabMap = {
        '#personal': 0,
        '#billing': 1,
      }
    }
    this.init()
  }

  protected init() {

    const driver = this.profileState.driver
    const client = this.profileState.client
    const clientExtras = this.configuration.appConfig.extra_client_fields

    if (this.configuration.appConfig.hasOwnProperty('extra_client_fields')) {
      if (client && (!client.extra || !Object.keys(client.extra).length)) {
        this.$set(client, 'extra', {})
        clientExtras.forEach((extra) => {
          Object.assign(client.extra, {[extra.value]: ''})
        })
      }
    }

    if (driver) {
      // noinspection JSUnusedLocalSymbols
      const {id, status, profile_picture, username, main_driver, ...der} = driver
      this.driverEditRequest = der
      this.driverEditRequestOriginal = JSON.parse(JSON.stringify(this.driverEditRequest))
    }

    if (client) {
      // noinspection JSUnusedLocalSymbols
      const {id, status, logo_url, client_type, main_driver, drivers, ...mr} = client
      this.modifyRequest = mr as ModifyRequest
      this.modifyRequestOriginal = JSON.parse(JSON.stringify(this.modifyRequest))
    }
  }

  protected setValidation(val: { [key: string]: boolean }) {
    const validation = this.validation
    delete validation.allValid
    const key: string = Object.keys(val)[0]
    Utils.setProp(this.validation as any, [key], val[key])
    Utils.setProp(this.validation as any, ['allValid'], Object.keys(validation).every((k: string) => {
      return validation[k] === true || validation[k] === undefined
    }))
  }

  protected phoneValidation(phone: string) {
    this.requestLoginByPhone({mobile_number: phone})
    this.openDialog(new Dialog(PhoneVerification, {
      mobileNumber: phone,
    }, 'confirmedPhone', false))
  }

  @Watch('driverEditRequest', {immediate: true, deep: true})
  protected driverChanged() {
    this.driverEditRequestChanged = this.dataChanged('driverEditRequest')
    Object.assign(this.modifyRequest.address, this.driverEditRequest.address)
    if (!this.isBusiness) {
      Object.assign(this.modifyRequest.billing_address, this.driverEditRequest.address)
      this.modifyRequest.organization_name = `${this.modifyRequest.name} ${this.modifyRequest.surname}`
    }
  }

  @Watch('modifyRequest', {immediate: true, deep: true})
  protected clientChanged() {
    this.modifyRequestChanged = this.dataChanged('modifyRequest')
  }

  protected dataChanged(s: 'driverEditRequest' | 'modifyRequest') {
    const o: object = (this as any)[s + 'Original']
    const m: object = (this as any)[s]
    return JSON.stringify(o) !== JSON.stringify(m)
  }

  protected save() {
    // be sure that all steps are valid
    if (this.validation && this.validation.allValid) {
      const phoneUpdated: boolean = this.changedPhone()
      // finally send the registration
      if (this.isMainDriver) {
        this.modifyRequest.phone = this.modifyRequest.mobile
        this.driverEditRequest.phone = this.driverEditRequest.mobile

        Promise.all([this.updateClient(this.modifyRequest), this.updateDriver(this.driverEditRequest)]).then(
          (response: [Client, Driver]) => {
            let hold: boolean = false
            response.forEach((r) => {
              hold = r.status === 'ON HOLD' ? true : hold
              if (typeof r.main_driver === 'boolean' && phoneUpdated) {
                this.phoneValidation(r.phone)
              }
            })
            hold ? this.alertHold() : this.alertOk()
          },
        )
      } else {
        this.updateDriver(this.driverEditRequest)
          .then((r: Driver) => {
            if (phoneUpdated) {
              this.phoneValidation(r.phone)
            }
            if (r.status === 'ON HOLD') {
              this.alertHold()
            } else {
              this.alertOk()
            }
          })
      }
      this.init()

    } else {
      alert('please fill all fields')
    }
  }

  private changedPhone() {
    const request = this.driverEditRequest
    const original = this.driverEditRequestOriginal
    const changed = this.driverEditRequestChanged
    return changed && (request.mobile !== original.mobile) || false
  }
}
