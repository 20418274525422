




import {Vue, Component, Prop} from 'vue-property-decorator'
import {Action} from 'vuex-class'
import VehicleAddReport from '@/views/Vehicle/VehicleAddReport.vue'
import {Vehicle} from '@/lib/kepler/interfaces'

@Component({
  components: {},
})
export default class HelpButton extends Vue {
  @Action('secondAction') public secondAction!: () => void
  @Prop() public circle!: boolean
  @Prop() public vehicle!: Vehicle

  get image() {
    return this.circle ? 'img/icons/circlehelp.svg' : 'img/icons/help.svg'
  }

  protected openHelp() {
    this.$emit('click')
    this.$popup.open(VehicleAddReport, {
      props: {vehicle: this.vehicle},
      title: this.$t('vehicle.report.diary_report'),
    })
  }
}
