
import {Component, Vue} from 'vue-property-decorator'
import { VCardText } from 'vuetify/lib'

@Component({
  name: 'CardText',
  extends: VCardText as any,
})
export default class CardText extends Vue {

}
