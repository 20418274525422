
import {Component, Vue} from 'vue-property-decorator'

import { VListTileTitle } from 'vuetify/lib'

@Component({
  name: 'ListTileTitle',
  extends: VListTileTitle as any,
})
export default class ListTileTitle extends Vue {

}
